import { SupportedChainId } from 'constants/chainsinfo'
import { useVoterPositions } from 'hooks/gql'
import { useActiveWeb3React } from 'hooks/web3'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'
import { useBattleStakerPositionInfo } from 'pages/NftBattlesPage/hooks'
import { LogoCoin, EtherscanIcon } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { Flex } from 'rebass'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'
import { LogoIcon, NFTAddress } from './styles'
import zNFT from '../../../../assets/images/battles/zNFT.png'
import zVote from '../../../../assets/images/battles/zVote.png'
import { NftName, useCDNUrl } from '../NftOverviewCard'
import { BigNumber } from 'ethers'
import { useJackpotBAddress } from 'constants/zoodao'

export const StakingPosition = ({
  positionId,
  jackpotPositionId,
  panelType,
}: {
  positionId: string
  jackpotPositionId?: string
  panelType: IPanelJackpotsVariant
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()
  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  const { id, token } = positionInfo || {}

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  const tokenImage = useCDNUrl(token, id)

  return (
    <>
      <LogoIcon src={tokenImage} />

      <Flex padding={16} flexDirection="column">
        <Flex>
          <NftName token={token} tokenId={id} />
        </Flex>

        <Flex alignItems="center" mt={16} mb={0}>
          <LogoCoin src={panelType === IPanelJackpotsVariant.JackpotA ? zNFT : zVote} />
          {jackpotPositionId && <NFTAddress>zNFT #{jackpotPositionId}</NFTAddress>}
          <Flex flex={1} />
          <EtherscanIcon exporerLink={exporerLink} />
        </Flex>
      </Flex>
    </>
  )
}

export const useStakingPositionByVoting = (votingPositionId: string | BigNumber) => {
  const jackpotB = useJackpotBAddress()
  const { positions: voted } = useVoterPositions(undefined, votingPositionId, jackpotB, '', false, false)

  const target = voted ? voted[0] : undefined

  const { stakingPositionId } = target || {}
  return stakingPositionId
}

export const VotingPosition = ({
  positionId,
  jackpotPositionId,
  panelType,
}: {
  positionId: string
  jackpotPositionId: string
  panelType: IPanelJackpotsVariant
}) => {
  const stakingPositionId = useStakingPositionByVoting(positionId)

  return <StakingPosition panelType={panelType} positionId={stakingPositionId} jackpotPositionId={jackpotPositionId} />
}
