import styled from 'styled-components/macro'
import { ShadowCard } from '../../../../components/Card'
import { Flex, Text } from 'rebass/styled-components'
import { Base as BaseButton } from '../../../../components/Button'
import LogoIcon from '../LogoIcon/LogoIcon'

export const Container = styled(ShadowCard)<{ inactive?: boolean; color?: string }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.4' : '1')};
  border-top: 10px solid ${({ color }) => color};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%
  `};
  background-color: #27273f;
  overflow: hidden;
  border-radius: 24px;

  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  :hover {
    box-shadow: 6px 12px 24px rgba(0, 0, 0, 0.25);
    transform: scale3d(1.015, 1.015, 1.015);
  }

  width: calc((100% - 75px) / 3);
  margin-right: 25px;
  margin-bottom: 25px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      width: calc((100% - 50px) / 2);
  `};
`

export const NFTAddress = styled(Text)`
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  color: ${({ theme }) => theme.text5};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
  `};
`

export const InfoValue = styled(Text)`
  font-size: 14px;
  color: white;
  word-break: break-word;
`

export const InfoTitle = styled(Text)`
  color: #9998b8;
  font-size: 12px;
`

export const LogoIconWrapper = styled(LogoIcon)`
  width: 100%;
  height: 116px;
  object-fit: cover;
`

export const NFTImage = styled(LogoIcon)`
  width: 75px;
  height: 75px;
  border-radius: 50%;
`

export const VoteButton = styled(BaseButton)`
  align-self: center;
  background: #f8ac00;
  color: #413938;
  box-shadow: none;
  border: 2px solid transparent;
  max-width: 250px;
  height: 48px;
  padding: 0;
  border-radius: 24px;
  font-weight: 700;
  margin-top: 25px;

  &:focus,
  &:hover,
  &:focus {
    background-color: #27273f;
    color: #f8ac00;
    border-color: #f8ac00;
  }
`

export const Info = styled(Flex).attrs({
  flexDirection: 'column',
  mt: 15,
  flex: 1,
})``
