import { Trans } from '@lingui/macro'
import IconZOO from '../../assets/svg/token.svg'
import { ButtonRedSecond, ButtonVote } from 'components/Button'
import { GetZOO } from 'components/GetStablecoin'
import { ZooReVoting } from 'components/VoteForBattle/VoteForBattle'
import { BigNumber } from 'ethers'
import { useZooBalanceTest } from 'hooks/zoodao/zooToken'
import { useZooCurrencies } from 'hooks/useZooCurrencies'
import { ApproveCheckerBattleArena } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import {
  useBattleVotingPositionInfo,
  useBattleStage,
  BATTLE_STAGES,
  useAddZooToVoting,
  useWithdrawZooFromVotingPosition,
  useBattleStakerPositionInfo,
} from 'pages/NftBattlesPage/hooks'
import { Dots } from 'pages/Pool/styleds'
import React, { RefObject, useMemo, useState } from 'react'
import { fromWei } from 'utils/fromWei'
import { formatDecimal } from 'utils/numberWithCommas'
import {
  CardHeadBox,
  LogoCoin,
  CardHead,
  CardContentBox,
  BalanceLine,
  BoxBtn2,
  BalanceExplanation,
  BalanceValue,
  InfoText,
  RowPicker,
} from './CardMiniForNftInfo'
import { CardMedium, CardLine } from './styleds'
import styled from 'styled-components/macro'
import { ZERO } from 'utils/isZero'
import { BalanceInputWrapper } from 'pages/Portfolio/Panels/BalanceInput'
import { BoxLink, LockedLabel } from './CardVotingDAI'
import { MouseoverTooltip } from 'components/Tooltip'

const GetZOOStyled = styled(GetZOO)`
  margin: 0 0 0 16px;
`

const DopBtn = styled.div``
const DopBtnL = styled.div`
  margin-left: 9px;
`

const CardMediumStyled = styled(CardMedium)`
  margin-top: 16px;
`

export function min(balance: BigNumber, maxValue: BigNumber) {
  if (balance.gt(maxValue)) {
    return maxValue
  } else {
    return balance
  }
}

export const CardVotingZOO = ({ item, zooBoostRef }: { item: any; zooBoostRef?: RefObject<HTMLDivElement> }) => {
  const votingPositionId = useMemo(() => item.votingPositionId.toString(), [item])
  const stakingPositionId = useMemo(() => item.stakingPositionId.toString(), [item])

  const [value, onUserInput] = useState<number | undefined>()

  const { vote: vote, pending: pendingVote } = useAddZooToVoting(value, votingPositionId)
  const { withdraw, pending: pendingWithdraw } = useWithdrawZooFromVotingPosition(votingPositionId, value)

  const { info: { zooInvested = ZERO, daiInvested = ZERO } = {}, loading } =
    useBattleVotingPositionInfo(votingPositionId)

  const { endDate } = useBattleStakerPositionInfo(stakingPositionId)

  const zooInvestedBalance = useMemo(() => {
    return zooInvested ? zooInvested : ZERO
  }, [zooInvested])
  const zooBalance = useZooBalanceTest()

  const { baseCurrency: zoo } = useZooCurrencies()

  const stage = useBattleStage()

  const canAdd = stage === BATTLE_STAGES.FOURTH
  const canWithdraw = stage === BATTLE_STAGES.FIRST || (endDate && !endDate.isZero())

  const minMax = useMemo(() => {
    if (canAdd && daiInvested) return min(zooBalance, daiInvested.sub(zooInvested))
    else return zooInvested || ZERO
  }, [canAdd, zooBalance, daiInvested, zooInvested])

  const disabled = (!canAdd && !canWithdraw) || loading

  const canNotAdd = (canAdd && daiInvested && daiInvested.sub(zooInvested).isZero()) || value === 0
  return (
    <CardMediumStyled inactive={disabled} ref={zooBoostRef}>
      <CardLine index={13} />
      <MouseoverTooltip
        text={
          <Trans>{`Boosting your votes with ZOO is possible during Stage 4, you can match your FRAX Votes with ZOO at a 1:1 ratio. ZOO Boosts are equal to FRAX Votes in raw voting power.`}</Trans>
        }
      >
        <CardHeadBox>
          <LogoCoin src={IconZOO} />
          <CardHead>
            <Trans>ZOO Boost</Trans>
          </CardHead>
        </CardHeadBox>
      </MouseoverTooltip>

      <CardContentBox>
        <BalanceExplanation>
          <InfoText>
            <Trans>My ZOO Balance:</Trans>
          </InfoText>
          <BalanceValue>{formatDecimal(zooBalance)}</BalanceValue>
          {false && <GetZOOStyled />}
        </BalanceExplanation>
        <BalanceInputWrapper inactive={disabled} balance={value} total={minMax} setBalance={onUserInput} />

        <RowPicker>
          <BoxLink>
            <LockedLabel>
              <Trans>My Locked ZOO:</Trans>
            </LockedLabel>
            &nbsp;{formatDecimal(zooInvestedBalance)}
          </BoxLink>
        </RowPicker>

        <BalanceLine>
          <BoxBtn2>
            {zoo ? (
              <ApproveCheckerBattleArena currency={zoo} border={value}>
                {stage === BATTLE_STAGES.FOURTH && (
                  <DopBtn>
                    <ZooReVoting votingPositionId={votingPositionId} />
                  </DopBtn>
                )}
                <DopBtnL>
                  <ButtonVote
                    disabled={pendingVote || !canAdd || +formatDecimal(zooBalance) === 0 || loading || canNotAdd}
                    onClick={vote}
                  >
                    <MouseoverTooltip
                      placement="top"
                      text={canNotAdd ? <Trans>You already have the maximum ZOO Boost</Trans> : null}
                    >
                      {pendingVote ? (
                        <Dots>
                          <Trans>Boosting</Trans>
                        </Dots>
                      ) : (
                        <Trans>Boost</Trans>
                      )}
                    </MouseoverTooltip>
                  </ButtonVote>
                </DopBtnL>
                <DopBtnL>
                  <ButtonRedSecond
                    disabled={
                      pendingWithdraw ||
                      !canWithdraw ||
                      zooInvestedBalance.isZero() ||
                      !value ||
                      value > +fromWei(zooInvestedBalance) ||
                      loading
                    }
                    onClick={withdraw}
                  >
                    {pendingWithdraw ? (
                      <Dots>
                        <Trans>Withdrawing</Trans>
                      </Dots>
                    ) : (
                      <Trans>Withdraw</Trans>
                    )}
                  </ButtonRedSecond>
                </DopBtnL>
              </ApproveCheckerBattleArena>
            ) : null}
          </BoxBtn2>
        </BalanceLine>
      </CardContentBox>
    </CardMediumStyled>
  )
}
