export const Paths = {
  YIELD: '/yield',
  YIELD_DASHBOARD: '/yield/dashboard',
  HOME: '/yield/home',
  NFT_STAKING: '/yield/nft-staking',
  INFO: '/yield/info',
  FARMING: '/yield/farming',
  GUIDE_BATTLES: '/yield/guides/battles-flowchart.pdf',
  BATTLES: '/battles',
  BATTLES_BOARD_STAKING: '/battles/nft-staking',
  BATTLES_INFO: '/battles/info',
  BATTLES_NFTS: '/battles/arena',
  BATTLES_PAIRS: '/battles/pairs',
  BATTLES_PAIR_INFO: '/battles/info/:epoch/:pairId',
  BATTLES_PAIR: '/battles/pair/:pairId',
  BATTLES_STAKED: '/battles/nft/:positionId',
  BATTLES_BATTLEBOARD: '/battles/battleboard',
  BATTLES_PORTFOLIO: '/battles/portfolio',
  BATTLES_YIELD: '/battles/yield',
  BATTLES_JACKPOTS: '/battles/jackpots',
  BATTLES_FAUCET: '/battles/faucet',
  BATTLES_GUIDE: '/battles/guide',
  BATTLES_BRIDGE: '/battles/bridge',
  BATTLES_VE_PIE: '/battles/vezoo',

  PRE_SALE: '/pre-sale',
  COMMUNITY_ROUND_A: '/community/round/a',
  COMMUNITY_ROUND_B: '/community/round/b',
}
