import { SupportedChainId } from 'constants/chainsinfo'
import { useActiveWeb3React } from 'hooks/web3'
import IconZOO from './../../assets/svg/token.svg'

const MOONBEAM_ITEMS_UNFORMATTED: { [key: string]: string } = {
  '0x8C3c07B7Edd2F0BE5b4Ed8064Ec722Da57F9A9d2':
    'https://i.seadn.io/gae/hp-q7jZqnz32oZtahZ7_qkO78WBJZzhZvooqp4DB95T8fPKqV_GHwZtjkhr_OTrkidgWKHLyt1-GqbNcOM-WDmLitgFUY1QEMOpgyw?auto=format&w=1920',
  '0x62fA8dE3e52541e9e0cc07bf577e1BCEfD0aDAB6':
    'https://i.seadn.io/gcs/files/e904cd2ad144751fce0a1fb4d70fcb60.gif?auto=format&w=1920',
  '0x4316f538ED997e2e92aB92ee5C1b9F126e21578e':
    'https://i.seadn.io/gcs/files/9dd6a4129e7396655ceee13682577025.png?auto=format&w=1920',
  '0xA521e4cAe215cb56DF9Cf09b8A81a113A16C7C11':
    'https://i.seadn.io/gcs/files/aa066e29bf132de57e1095343999521c.png?auto=format&w=1920',
  '0xF348800916C2e84c7B1630EB16FB0eFc52F3c235':
    'https://i.seadn.io/gae/DBDdMRdRfWD8E8CmwK0nE7VosmD5l20nkdS6fLneVwlEPrY2TwzdBNag5l3Afo1b7YC58-0ePxb7b7gkDq-5wJ-q404hS6QjFcx5rg?auto=format&w=1920',
  '0x87C359d86bD8C794124090A82E0930c3Cab88F01':
    'https://i.seadn.io/gae/y6bgGpYaz_ETBXqS_TCz0eJNR9Wkclfj2O4LAm85EVCqHw9eYe_Fu-TKn1QABKc1JeD53wE9jG6ljFaLNjyReo0LUqBRgQGXXqoTOLQ?auto=format&w=1920',
  '0x207F3F8ff92312DCB092f4087CdBeC3626E26cd4':
    'https://i.seadn.io/gcs/files/c857e619fb4104bd4e7366be9bd779ae.gif?auto=format&w=1920',
  '0xCd49e7b7515f053A07f2b577b6e87Cfd9B2589FC':
    'https://i.seadn.io/gcs/files/eed193d8602862fdd92cf963ae9e1902.gif?auto=format&w=1920',
  '0xE665F4Cb94D176641E6c79f7dDcd7590d7cCb647':
    'https://i.seadn.io/gcs/files/20b4a50f79fca184d4173c564314be7d.png?auto=format&w=1920',
  '0x6f126696447A2B32D9A3160f5bB734d039B67B5A':
    'https://i.seadn.io/gcs/files/480481337f49d629442adcffcbdc8c13.png?auto=format&w=1920',
  '0x43DF558DE9F4619600956fF7AC1894F74d26BBc6':
    'https://i.seadn.io/gae/wrElaEBwneqKR669amNlLLxXC7rKuXoc50Et36hnwqYQcs2oa2cBtuKN1ogPnSJN8okccSoeqvpRTvdYwPFwPVyu8-4p33p6RCuwL_g?auto=format&w=1920',
  '0x332d68561a7aee5879a369411e059856d4580094':
    'https://i.seadn.io/gcs/files/ae224c35f2ca47a728b7bde298f6aa3d.png?auto=format&w=1920',
  '0x357FbA23587e47C6Ed25f0B3650Ed3F86e046627':
    'https://i.seadn.io/gcs/files/eae1fd3d26c6558ec5bba12c8aa29bd7.png?auto=format&w=1920',
  '0x81C60843A985636186CfdC8162Ec548c75aA42c9':
    'https://i.seadn.io/gae/UC-Pq58qGkwdNejYOmcdP6JUDfNusuglxNa8vAnIiSFiOnzs8MkwcMCPl1llNuqz3bej1jhFZncjKNDmNoQ7rvr18HTm0CR3RS1XxA?auto=format&w=1920',
  '0xec17cD142795431B45b6FEce06B8b3682621A84C':
    'https://i.seadn.io/gae/ePkxwCTZ3U3jlkQxnHK63fKVqDtsuHz5K7FDzQE8x0_4U8kVyyRHsowuQ0F9Q48kuMuURObsxGeUICmM3aBDDULCutSP9X5mjaFS?auto=format&w=1920',
  '0x3EdDc6f8C1cf8a6d7b95C456Bba80887226533AA':
    'https://i.seadn.io/gae/wc22LMY7QMyjuvsL1NUFQ0_aiUvVUSbqSCnh1hKYaoqTzUrHM-aOhgYHtGUk7aA2_vqBl_0mT24gOF3oLuiCc9yxhRdcL8s6ZLgvNQ?auto=format&w=1920',
  '0x80f7931E971317402002c1BE3B582867B9A24828':
    'https://i.seadn.io/gae/NOxPoiGoqOvRJczI43oxFozZcWvf27_UGzF-W4i27noeOY5cY8gK-yYC3zWvMVNrTKRaFFjg9SpgWwK-LMa8rDqt0YKn4n5gDflX0Q?auto=format&w=1920',
  '0xE9fDF067327411AaE71D6869631767926f600Ac6':
    'https://i.seadn.io/gae/wOCA1qNThueuf__jy7o4I4f1SbCSYskWzpaADbwlmDxMexDVxhyP96jRAuS1A3to7Yeu-IOhfOoedAZTStojV5D4-ITwDOC4pBxr?auto=format&w=1920',
  '0xd0F8F69FF554f4bDb1fF0c877DaFEbF74628E791':
    'https://i.seadn.io/gae/MXHQ-Yps-g7Lz6D25NXgEh-aGP0VVIkG8p5-2pN5EeKJXNmfQ1kv_Pzy6kQfU776FIASm-tAJ5z-zhCergLBtMB52L8v3HS5ZnkF9JA?auto=format&w=1920',
  '0x8953356fb37cee93C601c48B249264923B2fFf66':
    'https://i.seadn.io/gae/o188a7rJC6irq7MheuNbDG3qGWsj060KCyR_KLXRxRd8v3mfjJQfAfwdlgKUeEKGYx3sWyWspi_PvetZTCQdPLRCuQc2M0VRpg8ILQ?auto=format&w=1920',
  '0xC27FB62c388Ac0f5e55B91D6491f4f469e56Bf71':
    'https://i.seadn.io/gae/u8COX0-c2kbQMP_H56AwgC0eXrNBvRGKcXqrB8GOgsUFz8Gn8wZa3Bib2Uxrki2JZmS3PpdOfd1Vk54bD64_DJwnW1QXUT0xDfxo8ag?auto=format&w=1920',
  '0x6ec049DB1142a51F13967A2C04143255CC99410C':
    'https://i.seadn.io/gcs/files/93ea4724fcb5596e34f3af1b63c9d7c6.gif?auto=format&w=1920',
  '0xe5d6806396d4679Ab21D4BaB3fb5e724Fe82f6a0':
    'https://i.seadn.io/gae/nwvYgwZp-ln5cix5p4r7axRPxhhAsgQrrOcaf5vVsxNBecjzRhXyRDkgd5m0ZO3SB7zzfAZNGZkIOFiNg4GlNmBrn0TLpe1pTp_PTAw?auto=format&w=1920',
  '0x958B6289e048C5BC21F3c9c61e58022d2358d06F':
    'https://i.seadn.io/gae/ooq7xxvwJjzXffNZ_pyct8-234WwTyHhQZUzD1oBp82EQVitaYdoxxP3s3X8i6dtYqF0bDiDQC0VoGjQDtAxaGqg97_TVoMlNYW55Q?auto=format&w=1920',
  '0xf25a1CF5be78A1C57b306Ad5999F255bFd2d9225':
    'https://i.seadn.io/gae/ZQgbyFanb1ZljV1IicSZyV0ZlG-zRBuf7G70BZFHaVqgxyBfAS3NrvKbYXbTWG3DYDfL-Ujbcm2hg-_AjTGI330G3TkKvsuvs3Z4?auto=format&w=1920',
  '0xeb628551b94ef928b8D32b5F94f9C9c1E8599652':
    'https://i.seadn.io/gae/KZL1PdsYtykWd0qoiRciw_cLP_Tur_KRrPl3y6QzynyH_V8E-htVr0k-O0z9p8yZ4bMiORW8En9CvVIbENkR9r5KUW459-ps2s5G?auto=format&w=1920',
  '0xa29b7e9dC598bFF4034758d91E0272d62ED6d3fB':
    'https://i.seadn.io/gae/5TNn5wVTyXOPVbC_BaCdHGYahiptP84lpb3F_nTdeXL8hFx5rdxwlj-cOPjZ3pq_WLbLxHErSyu0uDV8ybgQFZJMLOgRPUdkNUX-Aw?auto=format&w=1920',
  '0x135092F51D80E92185f8dA8a400b3828b3c4C094':
    'https://i.seadn.io/gcs/files/c16fbcfc4df446f9fbb132544f2a1684.png?auto=format&w=1920',
  '0xcBdcb6Ff9F6A80173EFb63Af006a36446946A95a':
    'https://i.seadn.io/gcs/files/4304cc4b00a480f8df5705201af6f345.gif?auto=format&w=1920',
  '0xEC66944391b743D87A3146905C2B9b8b85B2bDf1':
    'https://i.seadn.io/gae/ZoC0EZPOaQeMGdAmqXh-PbOqEdrINf37NnD7wxI8FRa0Ymt8corMCzOP0xMPXjx2P12cvB6pDLWWnPSFJ1cOwbjqZc2_c3haN3n_8A?auto=format&w=1920',
  '0x06D1a42382B2196eedDe76e0C90C3A49eE97233B':
    'https://i.seadn.io/gcs/files/785aa6b9f07435e690956aa27ada6eee.png?auto=format&w=1920',
  '0x145B65171c064738F65c99bfC59042a957aC5182':
    'https://i.seadn.io/gcs/files/2feea67794656538b0804ec663717a27.png?auto=format&w=1920',
  '0x5bd95E49994B4D637a3511B50c17ac115Dc31Fe8':
    'https://i.seadn.io/gae/SW2jJsJmSLx_MuBPtniABC_hvuN4k0dSZzlwoxxyGbRa7tmSRHgHGqNQm9NUPuu5oaKoxboKy5v3tAEaLTKOBBJcpyLuJZ3_TQzGDQ?auto=format&w=1920',
  '0x2159762693C629C5A44Fc9baFD484f8B96713467':
    'https://preview.ordswap.io/png/24169537418e5c6ee63585c035f4c2a1b4b603ad9836d0f2a6f4abbf0c4a0d4fi0',
  '0x16688d4047021755b7358f515864f706b1d24405':
    'https://i.seadn.io/gcs/files/d066005c73266ebe3f2160d13ef32716.jpg?auto=format&w=1920',
  '0x02A6DeC99B2Ca768D638fcD87A96F6069F91287c':
    'https://images.nftrade.com/uploads/211c20b7a3987985b8da8821316fbc7e1662628603114.webp',
  '0x8fBE243D898e7c88A6724bB9eB13d746614D23d6': 'https://www.glmrapes.com/cropped_logo.png',
  '0x648763838b02054A237bdF86Cc1B70238cb50aF5':
    'https://i.seadn.io/gcs/files/0252cab2eba5f341e0b95c18cab337a7.png?auto=format&w=1920',
  '0x756A9DC8181ABea18c2CC6575DCFcdD2c604aB6F':
    'https://i.seadn.io/gcs/files/5b46e51309d486d96cca0b036a73b28d.png?auto=format&w=1920',
  '0x8976127eEBBEBF9A6ac0AdE67B2F3B90Ed89b640':
    'https://i.seadn.io/gae/QS1j-mnAMI0pcXe3MXCDr9tMpIu0rwog3lDzQzldNTZsSTavtE-9MQlZajsIsLXl-1jl8QInJc_gC3RaEXWAK70zhK_589fQHcBnYGA?auto=format&w=1920',
  '0x129f156FC1EF1EDF48186a123804B1510637F71B':
    'https://i.seadn.io/gae/MSl4jx6ZMxlr-YpH61CUCmEcV81-M4xPyPA9L83w2U83oc8f7t4CamnyBkgny6v-elUSbB2_qmo-UVlhU11hfTtVvXN5jXzKw0zCbtE?auto=format&w=1920',
  '0x43BaA338734C1387391bb3C9a8Dd6952C46D1223':
    'https://i.seadn.io/gcs/files/dd0f52067fc60a909f581f8cb1757a46.gif?auto=format&w=1920',
  '0x139e9BA28D64da245ddB4cF9943aA34f6d5aBFc5':
    'https://img.tofunft.com/v2/1284/0x139e9ba28d64da245ddb4cf9943aa34f6d5abfc5/355/1440/image.jpg',
  '0x0f00f81162abc95ee6741a802a1218c67c42e714':
    'https://i.seadn.io/gcs/files/99eb5f4977a453565ab020d1e1bba011.png?auto=format&w=1920',
  '0xc75deb97ca0afcb9f25c38d72ad34f22d6c58260':
    'https://i.seadn.io/gae/MACJRvA4MOLHonq73rxU8TaHPrbYuGbJ5_rqV7pw9ZGd97h5mo5NyU6PpQNOvZzdoVu2KRVs3jP-oMK3FF1XQtNSxN6xf1ZVd9is?auto=format&w=384',
  '0xbE1F1F4E1A4907d3a6debc110E1E9f551909c89C': IconZOO,
  '0xe2bF99A2714e6ae3132c7C2bAA59756D09f59Ea2':
    'https://i.seadn.io/gae/9G_SbzGpWnId_ouP-rsTfv1UIeSaaayelyZVyo7eVETYp2uDXDXeH82Yvw4AchRHuKSrixHhGQ7ri5UUepQLaK9BpwhMH1yjImnXHA?auto=format&w=384',
  '0xA5c8C3EF88916E4f0D2A55E1F80Df8C5bBf5617D':
    'https://i.seadn.io/gae/iofetZEyiEIGcNyJKpbOafb_efJyeo7QOYnTog8qcQJhqoBU-Vu9l3lXidZhXOAdu6dj4fzWW6BZDU5vLseC-K03rMMu-_j2LvwcbHo?auto=format&w=384',
  '0x382c957863f0c3e53d9ebff713d171423c7f3e70':
    'https://i.seadn.io/gcs/files/f30db0e88ed49c9dd7514f745e699ea1.png?auto=format&w=384',
  '0x2A314f5611BA26D947b346537AEB685f911fc26A':
    'https://i.seadn.io/gcs/files/9f0626e45fb65b280fad61e06d786a3e.png?auto=format&w=384',
  '0xd6E8A1d5E0675168aF078391Ec3AbD983Eb18EA0':
    'https://i.seadn.io/gae/Qe8aSGNrG0RdjeWuy-PfG9GGZk66nBdUfIY9f4Ym8SgDv4D5fVwXIPLnqGbsIIBY5GDalYzCw8OpNLljg9otqTjroLOzfEsVYcK9ew?auto=format&w=384',
  '0x803dD875bC19524C40674397c565Ee0ea61864FF':
    'https://i.seadn.io/gae/S1vejGw7SoWg2r8zOYNet_94lzI42Kl0lX6FP5YPOUNb-8UO9vyK5Rsyo4m2DxA6jFekEmaYEhBCICSLzjP8vx9HJu0yX9k7tJbX?auto=format&w=256',
}

const MOONBEAM_ITEMS = Object.keys(MOONBEAM_ITEMS_UNFORMATTED).reduce((acc: any, key: string) => {
  acc[key.toLowerCase()] = MOONBEAM_ITEMS_UNFORMATTED[key]
  return acc
}, {})

export const useNftPartnersLogos = (address: string) => {
  const { chainId } = useActiveWeb3React()

  if (address && chainId === SupportedChainId.MOONBEAM) {
    return MOONBEAM_ITEMS[address.toLowerCase()] || ''
  }

  return ''
}
