import { useActiveWeb3React } from 'hooks/web3'
import { Trans } from '@lingui/macro'
import { ButtonClaim } from 'components/Button'
import { Dots } from 'pages/Pool/styleds'
import { useBattleFaucetContract } from 'constants/zoodao'
import { useCallback } from 'react'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { useTokensGiven } from 'hooks/gql'

const BattlesFaucet = () => {
  return (
    <>
      <FaucetNfts />
    </>
  )
}

const useFaucet = () => {
  const contract = useBattleFaucetContract()
  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.getZoo()
  }, [contract])

  return useTxTemplate(
    'battle_faucet_call',
    'Print tokens for battles',
    dataFunc,
    <Trans>You have already used the faucet</Trans>
  )
}

export const useFaucedUsage = () => {
  const { account } = useActiveWeb3React()

  const { faucetZooGivens, loading } = useTokensGiven(account)

  return {
    isUsed: faucetZooGivens.length > 0,
    loading,
  }
}

export const FaucetNfts = () => {
  const { action, pending, disabled } = useFaucet()
  const { isUsed } = useFaucedUsage()

  if (isUsed) {
    return null
  }

  return (
    <ButtonClaim style={{ marginLeft: 24, maxHeight: 48 }} disabled={pending || disabled} onClick={action}>
      {pending ? (
        <Dots>
          <Trans>Minting</Trans>
        </Dots>
      ) : (
        <>
          <Trans>Faucet</Trans>
        </>
      )}
    </ButtonClaim>
  )
}

export default BattlesFaucet
