import React, { useState, useMemo, useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { CardContent, CardLine, CardMedium, CardMini } from './styleds'
import styled from 'styled-components/macro'
import IconZOO from '../../assets/svg/token.svg'
import { ButtonVote } from '../../components/Button'
import { useChainStablecoinBalance } from '../../hooks/zoodao/zooToken'
import { formatDecimal } from '../../utils/numberWithCommas'
import Info from 'components/Info'
import {
  BATTLE_STAGES,
  useBattlesData,
  useBattleStage,
  useCreateNewVotingPosition,
  useNftsInfo,
  useTotalRewardsBattle,
} from '../NftBattlesPage/hooks'
import { useZooCurrencies } from '../../hooks/useZooCurrencies'
import { ApproveCheckerBattleArena } from '../NftBattlesPage/ApproveCheckerBattleArena'
import { Dots } from '../../components/swap/styleds'
import PairNft, { PairedTemplate } from '../../components/PairNft/PairNft'
import { LogDescription } from 'ethers/lib/utils'
import {
  useAllBattleNftEvents,
  useBattlePairCurrentRewards,
  useAllVotersAndStakers,
  useBattleNftEventsForUser,
  useCreatedStakerPositions,
  useNftBattlePairingInfo,
  useBattlePairCurrentRewardsTotal,
} from '../../hooks/zoodao/useBattlesEventsData'
import { useSingleCallResult, useSingleContractMultipleData } from '../../state/multicall/hooks'
import { ZERO } from '../../utils/isZero'
import { isLooser, useGoToBattle, useGoToJackpots, useGoToTab, WINNER } from './utils'
import ZooLoader from '../../components/ZooLoader/ZooLoader'
import { getMonthLabel, RenderDates } from './BattleClosing'
import { useBattleStageTime, useTargetBattleStageTime } from './StageIndicator'
import { DaiBoostBtn } from './DaiBoostBtn'
import { useBattleArenaContract } from 'constants/zoodao'
import { BalanceInputWrapper } from 'pages/Portfolio/Panels/BalanceInput'
import { UsdOptionPicker } from 'pages/Portfolio/Panels/UsdOptionPicker'
import { GetOnStellarswap } from 'components/GetStablecoin'
import IconDAI from '../../assets/images/dai.png'
import { InputLabel, MaxButtonPurple } from 'pages/Portfolio/Panels/styles'
import { useStableCurrency } from '../../hooks/Tokens'
import { SupportedChainId } from '../../constants/chainsinfo'
import { useActiveWeb3React } from '../../hooks/web3'
import { ZooTextGradient } from 'components/ZooText/ZooText'
import hoverbgstage1 from '../../assets/svg/hoverbox/hoverbox-stage1.svg'
import hoverbgstage2 from '../../assets/svg/hoverbox/hoverbox-stage2.svg'
import hoverbgstage3 from '../../assets/svg/hoverbox/hoverbox-stage3.svg'
import hoverbgstage4 from '../../assets/svg/hoverbox/hoverbox-stage4.svg'
import hoverbgstage5 from '../../assets/svg/hoverbox/hoverbox-stage5.svg'
import justbgstage1 from '../../assets/svg/hoverbox/justbox-stage1.svg'
import justbgstage2 from '../../assets/svg/hoverbox/justbox-stage2.svg'
import justbgstage5 from '../../assets/svg/hoverbox/justbox-stage5.svg'
import { MouseoverTooltip } from '../../components/Tooltip'
import { Paths } from '../../constants/paths'

const UsdOptionPickerStyled = styled(UsdOptionPicker)`
  width: 100%;
`

const GetDaiStyled = styled(GetOnStellarswap)`
  margin: 0 0 0 16px;
  min-width: 112px;
`

export const CardMiniForBattleBoard = styled(CardMini)`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  min-height: 240px;
  margin: 0;
  flex-grow: 1;
  width: initial;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-height: 215px;
  `};
`

interface IStageCard {
  stage: number
  active: boolean
}

const CardStageForBattleBoard = styled(CardMini)<IStageCard>`
  align-self: center;
  width: 100%;
  height: 100%;
  justify-self: stretch;
  margin: 0px;

  min-height: 120px !important;

  border-radius: 0px;
  box-shadow: none;
  padding: 20px 35px;
  display: grid;
  justify-content: center;

  background: url(${({ stage, active, theme }) => {
    if (active) {
      switch (stage) {
        case BATTLE_STAGES.FIRST: {
          return hoverbgstage1
        }
        case BATTLE_STAGES.SECOND: {
          return hoverbgstage2
        }
        case BATTLE_STAGES.THIRD: {
          return hoverbgstage3
        }
        case BATTLE_STAGES.FOURTH: {
          return hoverbgstage4
        }
        case BATTLE_STAGES.FIFTH: {
          return hoverbgstage5
        }
      }
    } else {
      switch (stage) {
        case BATTLE_STAGES.FIRST: {
          return justbgstage1
        }
        case BATTLE_STAGES.SECOND: {
          return justbgstage2
        }
        case BATTLE_STAGES.THIRD: {
          return justbgstage2
        }
        case BATTLE_STAGES.FOURTH: {
          return justbgstage2
        }
        case BATTLE_STAGES.FIFTH: {
          return justbgstage5
        }
      }
    }
    return theme.bg2
  }});

  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  ${({ active }) =>
    active &&
    `
    cursor:pointer;
  `}

  ${({ theme }) => theme.mediaWidth.upToXMedium`
    padding: 20px 15px;
  `};
`

export const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
`
export const CardHeadBoxForStage = styled(CardHeadBox)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  margin-bottom: 0px;
`
export const LogoCoin = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
  image-rendering: optimize;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  width: 38px;
  height: 38px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 24px;
  height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
`
export const NumberText = styled.div<{ withMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};
  margin-block-end: ${(props) => (props.withMargin ? '10px' : undefined)};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 24px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};˙
`
export const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  white-space: nowrap;
  color: ${({ theme }) => theme.text2};

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 21px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

const GetLinkDAIBoard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-top: 15px;
  text-decoration: none;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f8ac00;

  transition: transform 0.3s ease;

  :hover {
    transform: translateX(6px);
    background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 16px;
  line-height: 20px;
 `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 16px;
  line-height: 20px;
   `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 13px;
     line-height: 20px;
   `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
     font-size: 13px;
     line-height: 20px;
   `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
     font-size: 13px;
     line-height: 20px;
   `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     font-size: 14px;
     line-height: 18px;
   `}
`

export const GetLinkRewards = styled(GetLinkDAIBoard)`
  color: #f56738;
  text-decoration: none;

  :hover {
    transform: translateX(6px);
    background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
export const GetLinkPairs = styled(GetLinkDAIBoard)`
  color: #1fad1f;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 18px;

  :hover {
    transform: translateX(0px);
    background: #1fad1f;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const GetLinkDAI = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-top: 5px;
  text-decoration: none;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f8ac00;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  transition: transform 0.3s ease;

  :hover {
    transform: translateX(6px);
    background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 21px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`
export const InfoTextNumber = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-left: 5px;
`
export const CardHead = styled.div`
  color: ${({ theme }) => theme.text1};
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.75px;
  display: flex;
  word-break: break-word;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 22px;
  line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
    line-height: 22px;
  `};
`

const CardHeadTooltip = styled.div`
  align-self: flex-start;
`

export const CardHeadForStage = styled(CardHead)`
  color: ${({ theme }) => theme.text1};
  font-size: 15px;
  line-height: 20px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 15px;
  line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 15px;
  line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 15px;
  line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
  font-size: 15px;
  line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 15px;
  line-height: 20px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 15px;
  line-height: 20px;
  `}
`

export const CardHeadTitle = styled(CardHead)`
  font-size: 36px;
  line-height: 42px;
  font-weight: 900;
  text-transform: capitalize;
`

export const CardContentBox = styled.div<{ inactive?: boolean }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.4' : '1')};
  margin-top: auto;
`

export const BoxPercentMax = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  width: 100%;
  margin-top: 25px;
`

interface IBoxPower {
  active: boolean
}

export const BoxPower_1 = styled.div<IBoxPower>`
  padding: 25px;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: bold;
  font-size: 48px;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 24px;

  background-color: ${({ active }) => (active ? '#f56738' : 'rgba(254, 94, 0, 0.05)')};
  border: ${({ active }) => (active ? '2px solid #f56738' : '2px solid #f56738')};
`

export const BoxPower_2 = styled.div<IBoxPower>`
  padding: 25px;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: bold;
  font-size: 48px;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 24px;

  background-color: ${({ active }) => (active ? '#f64562' : 'rgba(246, 69, 98, 0.05)')};
  border: ${({ active }) => (active ? '2px solid #f64562' : '2px solid #f64562')};
`

export const BoxPower_3 = styled.div<IBoxPower>`
  padding: 25px;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: bold;
  font-size: 48px;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 24px;

  background-color: ${({ active }) => (active ? '#e138f5' : 'rgba(194, 45, 225, 0.05)')};
  border: ${({ active }) => (active ? '2px solid #e138f5' : '2px solid #e138f5')};
`

export const BoxBtn = styled.div`
  display: flex;
  margin-top: 5px;
`
export const BoxBtn2 = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  margin-left: auto;
`

export const InputLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 12px;
`
export const BalanceLine = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`
export const LinkToMax = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
  justify-content: end;
  width: 50%;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #f64562;
  cursor: pointer;
`

export const CardPairNft = ({ positionId, disabled = false }: { positionId: string; disabled?: boolean }) => {
  return (
    <CardMini>
      <CardLine index={7} />
      <CardHeadBox>
        <CardHead>Pair NFT</CardHead>
      </CardHeadBox>
      <CardContentBox>
        <PairNft stakingPositionId={positionId} showWarning={true} disabled={disabled} />
      </CardContentBox>
    </CardMini>
  )
}

export const CardVotes = ({ positionId }: { positionId: string }) => {
  const { votingsByUser } = useBattleNftEventsForUser(positionId)
  const { allVotings } = useAllBattleNftEvents(positionId)

  const { allVotesAmount, userVotesAmount } = useDaiVotes(allVotings, votingsByUser)

  return (
    <CardMini>
      <CardLine index={2} />
      <CardHeadBox>
        <CardHead>Total Votes</CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{formatDecimal(allVotesAmount)}</NumberText>
        <InfoText>
          My Votes <InfoTextNumber>{formatDecimal(userVotesAmount)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMini>
  )
}

const VotesContainer = styled.div`
  display: grid;
  margin-top: auto;
`

const TotalVotes = styled.div`
  font-size: 34px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 34px;
    line-height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 24px;
    line-height: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    font-size: 24px;
    line-height: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

export const CardMiniForNftInfo = ({ loading, allVotesAmount }: { allVotesAmount: BigNumber; loading: boolean }) => (
  <VotesContainer>
    <TotalVotes>
      {loading ? <Trans>Loading...</Trans> : formatDecimal(allVotesAmount ? allVotesAmount : ZERO)}
    </TotalVotes>
    <InfoText>
      <Trans>total votes</Trans>
    </InfoText>
  </VotesContainer>
)

export const IsNftPaired = ({ positionId }: { positionId: string }) => {
  const { paired, loading } = useNftBattlePairingInfo(positionId)

  if (loading || !paired) {
    return null
  }

  return <PairedTemplate />
}

const InBattleContainer = styled.div`
  margin-top: -40px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: auto;

  background-color: #6b2e45;
  text-align: center;
  height: 40px;
  padding: 5px 25px;

  color: ${({ theme }) => theme.red};
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;

  transition: all 0.3s ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.red};
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
`

const IconTarget = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-right: 15px;
`

const InJackpotsContainer = styled.div`
  padding: 12px;
  position: relative;
  cursor: pointer;
  width: 100%;

  background-color: none;
  text-align: center;

  color: ${({ theme }) => theme.white};
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 15px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;

  transition: all 0.3s ease-in-out;
  :hover {
    background-color: #1fad1f;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
`

const IconSvg = styled.svg`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-4deg);
  }

  width: 20px;
  height: 20px;
  margin-top: 5px;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 20px;
    height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 20px;
  `};
`

const IconBattle = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  font-size: 22px;
  padding: 12px 0px;
  transition: background-color 0.2s ease-in;

  border-radius: 10px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const RowPicker = styled(Row)`
  margin-top: 25px;
`

export const InBattleSvg = <IconBattle></IconBattle>

export const InBattleIcon = ({ positionId }: { positionId: string; showLabel?: boolean }) => {
  const { pair, loading } = useNftBattlePairingInfo(positionId)

  const { currentEpoch, pairIndex = '' }: any = pair || {}

  const openPair = useGoToBattle(`${pairIndex.toString()}`, currentEpoch)

  if (loading || !pair) {
    return null
  }

  return (
    <InBattleContainer onClick={openPair}>
      <IconTarget></IconTarget>
      <Trans>See Battle</Trans>
    </InBattleContainer>
  )
}

export const InJackpotsIcon = ({ className }: { className?: string }) => {
  const openJackpots = useGoToJackpots()

  return (
    <InJackpotsContainer onClick={openJackpots} className={className}>
      <IconTarget></IconTarget>
      <Trans>Staked In Jackpots</Trans>
    </InJackpotsContainer>
  )
}

const ExternalLinkStyled = styled.div`
  margin-left: auto;
  cursor: pointer;
`

const Box = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
`

export const EtherscanIcon = ({ exporerLink, chainIdExternal }: { exporerLink: string; chainIdExternal?: any }) => {
  const onClick = useCallback(() => {
    window.open(exporerLink, '_blank')
  }, [exporerLink])

  const { chainId } = useActiveWeb3React()

  const target = chainIdExternal || chainId
  return (
    <ExternalLinkStyled onClick={onClick}>
      {target === SupportedChainId.MAINNET && (
        <IconSvg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.4818 17.1403C7.48177 16.9392 7.52144 16.7401 7.59854 16.5545C7.67564 16.3688 7.78865 16.2002 7.93108 16.0584C8.0735 15.9166 8.24254 15.8043 8.42848 15.728C8.61442 15.6517 8.81359 15.6129 9.01456 15.6138L11.5557 15.6222C11.9609 15.6222 12.3495 15.7832 12.636 16.0698C12.9225 16.3564 13.0835 16.7451 13.0835 17.1504V26.7629C13.3697 26.6781 13.7369 26.5876 14.139 26.4932C14.4183 26.4275 14.6672 26.2693 14.8453 26.0443C15.0234 25.8194 15.1204 25.5408 15.1205 25.2538V13.3304C15.1205 13.1297 15.16 12.9309 15.2368 12.7455C15.3135 12.56 15.4261 12.3915 15.5679 12.2495C15.7098 12.1076 15.8782 11.995 16.0636 11.9182C16.249 11.8413 16.4477 11.8018 16.6483 11.8018H19.1945C19.5997 11.8018 19.9882 11.9629 20.2747 12.2495C20.5612 12.536 20.7222 12.9247 20.7223 13.33V24.397C20.7223 24.397 21.3595 24.1388 21.9807 23.8766C22.2113 23.779 22.4081 23.6156 22.5466 23.4068C22.685 23.1981 22.759 22.9531 22.7592 22.7026V9.50903C22.7592 9.30834 22.7987 9.10961 22.8754 8.92419C22.9522 8.73877 23.0647 8.5703 23.2066 8.42838C23.3484 8.28647 23.5168 8.17389 23.7022 8.09709C23.8875 8.02028 24.0862 7.98075 24.2868 7.98075H26.833C27.2382 7.98075 27.6268 8.14176 27.9133 8.42837C28.1998 8.71497 28.3608 9.10369 28.3608 9.50903V20.3732C30.5683 18.7729 32.8056 16.8479 34.581 14.5333C34.8386 14.1974 35.009 13.8028 35.0771 13.3849C35.1452 12.9671 35.1089 12.5388 34.9713 12.1384C33.77 8.63625 31.5167 5.59077 28.5195 3.41835C25.5223 1.24594 21.9275 0.0526487 18.2266 0.00163185C8.24683 -0.132477 -0.000893988 8.01692 8.63529e-05 18.0014C-0.00971253 21.1608 0.814597 24.2668 2.38967 27.0054C2.60688 27.3799 2.92644 27.6847 3.3108 27.8839C3.69516 28.0831 4.12837 28.1684 4.55953 28.1298C5.04124 28.0874 5.64097 28.0275 6.35392 27.9438C6.66424 27.9084 6.95073 27.7602 7.15883 27.5272C7.36693 27.2942 7.4821 26.9928 7.48241 26.6804V17.1403"
            fill="#9998B8"
          />
          <path
            d="M7.422 32.5574C10.1059 34.5106 13.2776 35.6829 16.5863 35.9448C19.8949 36.2067 23.2115 35.5479 26.1691 34.0412C29.1266 32.5346 31.6099 30.2388 33.3442 27.408C35.0784 24.5771 35.996 21.3215 35.9955 18.0014C35.9955 17.5869 35.9763 17.1771 35.9486 16.7695C29.3751 26.5764 17.238 31.1611 7.42188 32.5577"
            fill="#9998B8"
          />
        </IconSvg>
      )}

      {target === SupportedChainId.MOONBEAM && (
        <IconSvg xmlns="http://www.w3.org/2000/svg" width="35px" height="31px" viewBox="0 0 35 31" version="1.1">
          <title>GLMR</title>
          <g id="current" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="GLMR" transform="translate(0.000000, 0.529494)" fillRule="nonzero">
              <g id="Group">
                <path
                  d="M34.6,23.2705058 C34.6,23.2705058 34.6,23.2705058 34.6,23.2705058 C34.3,23.5705058 33.9,23.5705058 33.5,23.5705058 C27.6,23.5705058 21.8,23.5705058 15.9,23.5705058 C15.7,23.5705058 15.6,23.5705058 15.4,23.5705058 C15.2,23.4705058 15,23.1705058 15,22.8705058 C15,22.5705058 15.3,22.2705058 15.6,22.2705058 C15.7,22.2705058 15.8,22.2705058 16,22.2705058 C21.9,22.2705058 27.8,22.2705058 33.6,22.2705058 C34,22.2705058 34.3,22.2705058 34.6,22.6705058 C34.6,22.6705058 34.6,22.6705058 34.6,22.6705058 C34.6,22.6705058 34.6,22.6705058 34.6,22.6705058 C34.6,22.7705058 34.6,23.0705058 34.6,23.2705058 Z"
                  id="Path"
                  fill="#D91376"
                />
                <path
                  d="M0,16.5705058 C0.1,16.5705058 0.1,16.5705058 0.2,16.4705058 C0.5,16.1705058 0.9,16.1705058 1.2,16.4705058 C1.5,16.7705058 1.4,17.1705058 1.2,17.3705058 C0.9,17.6705058 0.5,17.5705058 0.2,17.3705058 C0.2,17.3705058 0.1,17.2705058 0,17.2705058 C0,17.0705058 0,16.7705058 0,16.5705058 Z"
                  id="Path"
                  fill="#D81376"
                />
                <path
                  d="M20.6,11.3705058 C17.3,11.3705058 13.9,11.3705058 10.6,11.3705058 C9.9,11.3705058 9.7,11.2705058 9.8,10.4705058 C10,4.97050582 14,0.670505825 19.5,0.0705058247 C25.5,-0.629494175 31,3.97050582 31.4,9.97050582 C31.4,10.2705058 31.4,10.5705058 31.4,10.7705058 C31.4,11.0705058 31.2,11.2705058 30.8,11.3705058 C30.7,11.3705058 30.6,11.3705058 30.5,11.3705058 C27.2,11.3705058 23.9,11.3705058 20.6,11.3705058 Z"
                  id="Path"
                  fill="#52C9C6"
                />
                <path
                  d="M20.6,14.5705058 C16.5,14.5705058 12.4,14.5705058 8.3,14.5705058 C7.7,14.5705058 7.4,14.3705058 7.4,13.8705058 C7.4,13.4705058 7.7,13.1705058 8.3,13.1705058 C16.5,13.1705058 24.6,13.1705058 32.8,13.1705058 C32.9,13.1705058 33.1,13.1705058 33.2,13.1705058 C33.5,13.2705058 33.7,13.4705058 33.7,13.8705058 C33.7,14.1705058 33.5,14.4705058 33.1,14.4705058 C33,14.4705058 32.8,14.4705058 32.7,14.4705058 C28.7,14.5705058 24.7,14.5705058 20.6,14.5705058 Z"
                  id="Path"
                  fill="#DE1379"
                />
                <path
                  d="M19.1,19.2705058 C22.7,19.2705058 26.3,19.2705058 29.9,19.2705058 C30,19.2705058 30.1,19.2705058 30.2,19.2705058 C30.6,19.2705058 30.8,19.5705058 30.8,19.9705058 C30.8,20.2705058 30.5,20.5705058 30.2,20.6705058 C30.1,20.6705058 30,20.6705058 29.9,20.6705058 C22.7,20.6705058 15.6,20.6705058 8.4,20.6705058 C8.4,20.6705058 8.3,20.6705058 8.3,20.6705058 C7.8,20.6705058 7.5,20.3705058 7.5,19.9705058 C7.5,19.5705058 7.8,19.2705058 8.3,19.2705058 C11.9,19.2705058 15.5,19.2705058 19.1,19.2705058 Z"
                  id="Path"
                  fill="#DA1377"
                />
                <path
                  d="M12.1,16.2705058 C15.1,16.2705058 18.1,16.2705058 21.1,16.2705058 C21.7,16.2705058 22,16.6705058 21.9,17.1705058 C21.8,17.4705058 21.6,17.5705058 21.3,17.6705058 C21.2,17.6705058 21.1,17.6705058 21,17.6705058 C15.1,17.6705058 9.2,17.6705058 3.2,17.6705058 C3.1,17.6705058 2.9,17.6705058 2.8,17.6705058 C2.5,17.5705058 2.3,17.2705058 2.3,16.9705058 C2.3,16.6705058 2.6,16.3705058 2.9,16.3705058 C3,16.3705058 3.1,16.3705058 3.2,16.3705058 C6.2,16.2705058 9.2,16.2705058 12.1,16.2705058 Z"
                  id="Path"
                  fill="#D91377"
                />
                <path
                  d="M25,26.5705058 C23.2,26.5705058 21.3,26.5705058 19.5,26.5705058 C19.3,26.5705058 19.1,26.4705058 18.9,26.3705058 C18.7,26.2705058 18.6,25.9705058 18.7,25.6705058 C18.8,25.4705058 19,25.3705058 19.2,25.1705058 C19.3,25.0705058 19.4,25.1705058 19.6,25.1705058 C23.2,25.1705058 26.8,25.1705058 30.4,25.1705058 C30.5,25.1705058 30.6,25.1705058 30.7,25.1705058 C31.1,25.1705058 31.3,25.4705058 31.3,25.7705058 C31.3,26.0705058 31,26.3705058 30.7,26.4705058 C30.6,26.4705058 30.5,26.4705058 30.4,26.4705058 C28.5,26.5705058 26.8,26.5705058 25,26.5705058 Z"
                  id="Path"
                  fill="#D91377"
                />
                <path
                  d="M18.1,28.2705058 C19.9,28.2705058 21.7,28.2705058 23.5,28.2705058 C23.7,28.2705058 24,28.3705058 24.2,28.4705058 C24.6,28.8705058 24.3,29.4705058 23.7,29.5705058 C23.6,29.5705058 23.5,29.5705058 23.4,29.5705058 C19.8,29.5705058 16.2,29.5705058 12.6,29.5705058 C12.4,29.5705058 12.2,29.5705058 12,29.4705058 C11.7,29.3705058 11.6,29.0705058 11.7,28.7705058 C11.8,28.4705058 12,28.2705058 12.3,28.2705058 C12.7,28.2705058 13.2,28.2705058 13.6,28.2705058 C15.1,28.2705058 16.6,28.2705058 18.1,28.2705058 Z"
                  id="Path"
                  fill="#DE1379"
                />
                <path
                  d="M8.7,22.2705058 C10.1,22.2705058 11.5,22.2705058 12.8,22.2705058 C12.9,22.2705058 13.1,22.2705058 13.2,22.2705058 C13.5,22.3705058 13.6,22.5705058 13.7,22.7705058 C13.7,23.0705058 13.6,23.2705058 13.4,23.3705058 C13.2,23.4705058 13,23.4705058 12.8,23.4705058 C10,23.4705058 7.3,23.4705058 4.5,23.4705058 C4.3,23.4705058 4.1,23.4705058 4,23.3705058 C3.7,23.2705058 3.6,22.9705058 3.6,22.6705058 C3.6,22.3705058 3.8,22.1705058 4.1,22.1705058 C4.2,22.1705058 4.3,22.1705058 4.5,22.1705058 C5.9,22.2705058 7.3,22.2705058 8.7,22.2705058 Z"
                  id="Path"
                  fill="#D91376"
                />
                <path
                  d="M10.7,28.8705058 C10.7,29.2705058 10.4,29.5705058 10.1,29.5705058 C9.8,29.5705058 9.4,29.2705058 9.4,28.8705058 C9.4,28.4705058 9.7,28.1705058 10,28.1705058 C10.4,28.2705058 10.7,28.5705058 10.7,28.8705058 Z"
                  id="Path"
                  fill="#D81376"
                />
                <path
                  d="M1.9,23.5705058 C1.5,23.5705058 1.2,23.2705058 1.2,22.8705058 C1.2,22.4705058 1.5,22.2705058 1.8,22.1705058 C2.2,22.1705058 2.5,22.4705058 2.5,22.7705058 C2.6,23.2705058 2.3,23.5705058 1.9,23.5705058 Z"
                  id="Path"
                  fill="#D71376"
                />
                <path
                  d="M6.4,13.9705058 C6.4,14.3705058 6.1,14.6705058 5.7,14.5705058 C5.3,14.5705058 5,14.2705058 5.1,13.8705058 C5.1,13.4705058 5.4,13.2705058 5.8,13.2705058 C6.1,13.2705058 6.4,13.5705058 6.4,13.9705058 Z"
                  id="Path"
                  fill="#D71375"
                />
                <path
                  d="M5.7,19.2705058 C6.1,19.2705058 6.4,19.5705058 6.4,19.9705058 C6.4,20.3705058 6.1,20.6705058 5.7,20.6705058 C5.3,20.6705058 5,20.3705058 5,19.9705058 C5,19.5705058 5.3,19.2705058 5.7,19.2705058 Z"
                  id="Path"
                  fill="#D81376"
                />
                <path
                  d="M16.9,26.5705058 C16.5,26.5705058 16.2,26.2705058 16.2,25.8705058 C16.2,25.4705058 16.5,25.1705058 16.9,25.2705058 C17.3,25.2705058 17.5,25.5705058 17.5,25.9705058 C17.6,26.2705058 17.3,26.5705058 16.9,26.5705058 Z"
                  id="Path"
                  fill="#D81376"
                />
              </g>
            </g>
          </g>
        </IconSvg>
      )}
    </ExternalLinkStyled>
  )
}

export const CardPotentialRewardMy = ({ pairData, playedInEpoch }: { pairData: any; playedInEpoch: boolean }) => {
  const { token1, token2 } = pairData

  const { mappedStaked, loading: loadingStaked } = useCreatedStakerPositions()

  const fighters = useMemo(() => {
    return [token1.toString(), token2.toString()]
  }, [token1, token2])

  const myStaked = useMemo(() => {
    const result: string[] = []

    if (mappedStaked[fighters[0]]) {
      result.push(fighters[0])
    }

    if (mappedStaked[fighters[1]]) {
      result.push(fighters[1])
    }
    return result
  }, [mappedStaked, fighters])

  const { votingAmountUsd, stakingAmountUsd, votingGlmrs, votingWells } = useBattlePairCurrentRewards(
    fighters,
    myStaked
  )

  const { stableCurrency } = useStableCurrency()

  const stage = useBattleStage()

  return (
    <CardMini inactive={!playedInEpoch}>
      <CardLine index={18} />
      <CardHeadBox>
        <CardHead>
          <Trans>My Rewards</Trans>
        </CardHead>
      </CardHeadBox>
      {stage !== BATTLE_STAGES.FIRST && (
        <InputLabel mt={25} alignItems="center">
          <Trans>Your rewards will appear in Stage 1</Trans>
        </InputLabel>
      )}
      <Box>
        <CardContentBox>
          <NumberText>{loadingStaked ? <Trans>Loading...</Trans> : formatDecimal(stakingAmountUsd, 2)}</NumberText>
          <InfoText>
            <Trans>{stableCurrency} Total</Trans>
          </InfoText>
        </CardContentBox>
        <CardContentBox>
          <NumberText>{loadingStaked ? <Trans>Loading...</Trans> : formatDecimal(votingAmountUsd, 2)}</NumberText>
          <InfoText>
            <Trans>ZOO Total</Trans>
          </InfoText>
        </CardContentBox>
        {votingGlmrs && (
          <CardContentBox>
            <NumberText>{loadingStaked ? <Trans>Loading...</Trans> : formatDecimal(votingGlmrs, 2)}</NumberText>
            <InfoText>
              <Trans>wGLMR Total</Trans>
            </InfoText>
          </CardContentBox>
        )}
        {votingWells && (
          <CardContentBox>
            <NumberText>{loadingStaked ? <Trans>Loading...</Trans> : formatDecimal(votingWells, 3)}</NumberText>
            <InfoText>
              <Trans>WELL Total</Trans>
            </InfoText>
          </CardContentBox>
        )}
      </Box>
    </CardMini>
  )
}

export const CardPotentialRewardTotal = ({ pairData, playedInEpoch }: { pairData: any; playedInEpoch: boolean }) => {
  const { token1, token2 } = pairData

  const { votingAmountUsd, stakingAmountUsd, votingGlmrs, votingWells, loading } = useBattlePairCurrentRewardsTotal(
    token1,
    token2
  )

  const { stableCurrency } = useStableCurrency()

  const stage = useBattleStage()

  return (
    <CardMini inactive={!playedInEpoch}>
      <CardLine index={18} />
      <CardHeadBox>
        <CardHead>
          <Trans>Total Battle Rewards</Trans>
        </CardHead>
      </CardHeadBox>
      {stage !== BATTLE_STAGES.FIRST && (
        <InputLabel mt={25} alignItems="center">
          <Trans>Rewards will appear in Stage 1</Trans>
        </InputLabel>
      )}
      <Box>
        <CardContentBox>
          <NumberText>{loading ? <Trans>Loading...</Trans> : formatDecimal(stakingAmountUsd, 2)}</NumberText>
          <InfoText>
            <Trans>{stableCurrency} Total</Trans>
          </InfoText>
        </CardContentBox>
        <CardContentBox>
          <NumberText>{loading ? <Trans>Loading...</Trans> : formatDecimal(votingAmountUsd, 2)}</NumberText>
          <InfoText>
            <Trans>ZOO Total</Trans>
          </InfoText>
        </CardContentBox>
        {votingGlmrs && (
          <CardContentBox>
            <NumberText>{loading ? <Trans>Loading...</Trans> : formatDecimal(votingGlmrs, 2)}</NumberText>
            <InfoText>
              <Trans>wGLMR Total</Trans>
            </InfoText>
          </CardContentBox>
        )}
        {votingWells && (
          <CardContentBox>
            <NumberText>{loading ? <Trans>Loading...</Trans> : formatDecimal(votingWells, 3)}</NumberText>
            <InfoText>
              <Trans>WELL Total</Trans>
            </InfoText>
          </CardContentBox>
        )}
      </Box>
    </CardMini>
  )
}

export const CardMiniForNftInfoDAI = ({
  allVotes,
  userVotes,
}: {
  allVotes: LogDescription[]
  userVotes: LogDescription[]
}) => {
  const { allDaiInvested, userDaiInvested } = useDaiVotes(allVotes, userVotes)
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()

  return (
    <CardMini>
      <CardLine index={18} />
      <CardHeadBox>
        <LogoCoin src={stableCurrencyIcon} />
        <CardHead>{stableCurrency} Staked</CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{formatDecimal(allDaiInvested)}</NumberText>
        <InfoText>
          My Staked <InfoTextNumber>{formatDecimal(userDaiInvested)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMini>
  )
}

export const CardMiniForNftInfoZOO = ({
  allVotes,
  userVotes,
  onClick,
}: {
  allVotes: LogDescription[]
  userVotes: LogDescription[]
  onClick?: () => void
}) => {
  const { allZooInvestedAmount, userZooInvestedAmount } = useDaiVotes(allVotes, userVotes)

  return (
    <CardMini onClick={onClick}>
      <CardLine index={13} />
      <CardHeadBox>
        <LogoCoin src={IconZOO} />
        <CardHead>
          <Trans>ZOO Staked</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{formatDecimal(allZooInvestedAmount)}</NumberText>
        <InfoText>
          <Trans>My Staked</Trans> <InfoTextNumber>{formatDecimal(userZooInvestedAmount)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMini>
  )
}

const useVotings = (votes: any[]) => {
  const pids = useMemo(() => {
    return votes.map((item) => item.votingPositionId.toString())
  }, [votes])

  const battleContract = useBattleArenaContract()
  const deps = useMemo(() => pids.map((pid: string) => [pid]), [pids])
  const results = useSingleContractMultipleData(battleContract, 'votingPositionsValues', deps)

  return useMemo(() => {
    return results.map((c) => c.result).filter((item) => !!item)
  }, [results])
}

const getSumBy = (arr: any, field: string) => {
  return arr.reduce((acc: BigNumber, item: any) => {
    return acc.add(item[field])
  }, ZERO)
}

export const useDaiVotes = (allVotes: LogDescription[], userVotes: LogDescription[]) => {
  const allVotesData = useVotings(allVotes)
  const userVotesData = useVotings(userVotes)

  const allDaiInvested = useMemo(() => {
    return getSumBy(allVotesData, 'daiInvested')
  }, [allVotesData])

  const userDaiInvested = useMemo(() => {
    return getSumBy(userVotesData, 'daiInvested')
  }, [userVotesData])

  const allDaiVotesAmount = useMemo(() => {
    return getSumBy(allVotesData, 'daiVotes')
  }, [allVotesData])

  const userDaiVotesAmount = useMemo(() => {
    return getSumBy(userVotesData, 'daiVotes')
  }, [userVotesData])

  const allVotesAmount = useMemo(() => {
    return getSumBy(allVotesData, 'votes')
  }, [allVotesData])

  const userVotesAmount = useMemo(() => {
    return getSumBy(userVotesData, 'votes')
  }, [userVotesData])

  const allZooInvestedAmount = useMemo(() => {
    return getSumBy(allVotesData, 'zooInvested')
  }, [allVotesData])

  const userZooInvestedAmount = useMemo(() => {
    return getSumBy(userVotesData, 'zooInvested')
  }, [userVotesData])

  return useMemo(() => {
    return {
      allDaiVotesAmount,
      userDaiVotesAmount,
      allZooVotesAmount: allVotesAmount.sub(allDaiVotesAmount),
      userZooVotesAmount: userVotesAmount.sub(userDaiVotesAmount),
      allDaiInvested,
      userDaiInvested,
      allZooInvestedAmount,
      userZooInvestedAmount,
      allVotesAmount,
      userVotesAmount,
    }
  }, [
    allDaiVotesAmount,
    userDaiVotesAmount,
    allDaiInvested,
    userDaiInvested,
    allZooInvestedAmount,
    userZooInvestedAmount,
    allVotesAmount,
    userVotesAmount,
  ])
}

export const CardDAIVotes = ({ allVotes, userVotes }: { allVotes: LogDescription[]; userVotes: LogDescription[] }) => {
  const { allDaiVotesAmount, userDaiVotesAmount } = useDaiVotes(allVotes, userVotes)
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()

  return (
    <CardMini>
      <CardLine index={18} />
      <CardHeadBox>
        <LogoCoin src={stableCurrencyIcon} />
        <CardHead>
          <Trans>{stableCurrency} Votes</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{formatDecimal(allDaiVotesAmount)}</NumberText>
        <InfoText>
          <Trans>My Votes</Trans> <InfoTextNumber>{formatDecimal(userDaiVotesAmount)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMini>
  )
}
export const CardZooVotes = ({
  allVotes,
  userVotes,
  onClick,
}: {
  allVotes: LogDescription[]
  userVotes: LogDescription[]
  onClick?: () => void
}) => {
  const { allZooVotesAmount, userZooVotesAmount } = useDaiVotes(allVotes, userVotes)

  return (
    <CardMini onClick={onClick}>
      <CardLine index={13} />
      <CardHeadBox>
        <LogoCoin src={IconZOO} />
        <CardHead>
          <Trans>ZOO Votes</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{formatDecimal(allZooVotesAmount)}</NumberText>
        <InfoText>
          <Trans>My Votes</Trans> <InfoTextNumber>{formatDecimal(userZooVotesAmount)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMini>
  )
}

enum VOTING_POWER_PARTS {
  FIRST = 0,
  SECOND = 1,
  THIRD = 3,
}

const useVotingPower = (isDai: boolean) => {
  const { epochStartDate, firstStageDuration, secondStageDuration, thirdStageDuration, fourthStageDuration } =
    useBattlesData()

  const part = useMemo(() => {
    const start = isDai
      ? epochStartDate + firstStageDuration
      : epochStartDate + firstStageDuration + secondStageDuration + thirdStageDuration

    const now = new Date().getTime() / 1000

    const current = now - start

    const target = isDai ? secondStageDuration : fourthStageDuration

    const partFirst = target / 3
    const partSecond = (target * 2) / 3

    if (current < partFirst) {
      return VOTING_POWER_PARTS.FIRST
    } else if (current < partSecond) {
      return VOTING_POWER_PARTS.SECOND
    } else {
      return VOTING_POWER_PARTS.THIRD
    }
  }, [epochStartDate, firstStageDuration, secondStageDuration, thirdStageDuration, fourthStageDuration, isDai])

  return part
}

const VotingCard = styled(CardContent)``

export const CardVotingPowerDAI = () => {
  const part = useVotingPower(true)
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()
  const stage = useBattleStage()

  return (
    <VotingCard inactive={stage !== BATTLE_STAGES.SECOND}>
      <CardMedium>
        <CardLine index={18} />
        <CardHeadTooltip>
          <MouseoverTooltip
            text={
              <Trans>{`Early ${stableCurrency} Votes carry more power. The highlighted box shows the current vote power. Missed the 1.3x multiplier? You can recompute votes to 1.3x next Battle season for maximum power.`}</Trans>
            }
          >
            <CardHeadBox>
              <LogoCoin src={stableCurrencyIcon} />
              <CardHead>
                <Trans>{stableCurrency} Voting Power</Trans>
              </CardHead>
            </CardHeadBox>
          </MouseoverTooltip>
        </CardHeadTooltip>
        <BoxPercentMax>
          <BoxPower_1 active={part === VOTING_POWER_PARTS.FIRST}>1.3x</BoxPower_1>

          <BoxPower_2 active={part === VOTING_POWER_PARTS.SECOND}>1.0x</BoxPower_2>

          <BoxPower_3 active={part === VOTING_POWER_PARTS.THIRD}>0.7x</BoxPower_3>
        </BoxPercentMax>
      </CardMedium>
    </VotingCard>
  )
}
export const CardVotingPowerZOO = () => {
  const part = useVotingPower(false)

  const stage = useBattleStage()

  return (
    <VotingCard inactive={stage !== BATTLE_STAGES.FOURTH}>
      <CardMedium>
        <CardLine index={13} />
        <CardHeadTooltip>
          <MouseoverTooltip
            text={
              <Trans>{`Early ZOO Votes carry more power. The highlighted box shows the current vote power. Missed the 1.3x multiplier? You can recompute votes to 1.3x next Battle season for maximum power.`}</Trans>
            }
          >
            <CardHeadBox>
              <LogoCoin src={IconZOO} />
              <CardHead>ZOO Voting Power</CardHead>
            </CardHeadBox>
          </MouseoverTooltip>
        </CardHeadTooltip>
        <BoxPercentMax>
          <BoxPower_1 active={part === VOTING_POWER_PARTS.FIRST}>1.3x</BoxPower_1>

          <BoxPower_2 active={part === VOTING_POWER_PARTS.SECOND}>1.0x</BoxPower_2>

          <BoxPower_3 active={part === VOTING_POWER_PARTS.THIRD}>0.7x</BoxPower_3>
        </BoxPercentMax>
      </CardMedium>
    </VotingCard>
  )
}

interface IProps {
  winData: any
  pairData: any
}

export const BoxChances = styled.div`
  margin-top: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;
`

export const BoxPercent = styled.div<{ looser?: boolean }>`
  margin-top: 24px;
  padding: 15px 25px;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  text-transform: capitalize;
  text-align: center;
  background-color: ${({ looser }) => (looser ? '#1f283c' : '#1f283c')};
  border: 2px solid ${({ looser }) => (looser ? '#1fad1f' : '#1fad1f')};
  border-radius: 18px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 24px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 28px;
    line-height: 32px;
  `};
`
export const CardHeadBoxCenter = styled(CardHeadBox)``

export const BoxPercent2 = styled(BoxPercent)`
  background-color: ${({ looser }) => (looser ? '#1f283c' : '#1f283c')};
  border: 2px solid ${({ looser }) => (looser ? '#3d7bfc' : '#3d7bfc')};
`

export const BalanceExplanation = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export const BalanceValue = styled.div`
  margin-left: 12px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 21px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

export const CardWinChances = ({ winData, pairData }: IProps) => {
  const { loadingFirst, loadingSecond, chanceA, chanceB, winner } = winData
  const { token1, token2 }: any = pairData || {}

  return (
    <ZooLoader loading={loadingFirst || loadingSecond || !(token1 && token2)}>
      <CardMedium>
        <CardLine index={12} />
        <CardHeadBoxCenter>
          <CardHead>
            <Trans>Chances to Win</Trans>
          </CardHead>
        </CardHeadBoxCenter>
        <BoxChances>
          <BoxPercent looser={isLooser(WINNER.A, winner)}>
            {winner === WINNER.A ? <Trans>Won</Trans> : chanceA.toFixed(2) + '%'}
          </BoxPercent>
          <BoxPercent2 looser={isLooser(WINNER.B, winner)}>
            {winner === WINNER.B ? <Trans>Won</Trans> : chanceB.toFixed(2) + '%'}
          </BoxPercent2>
        </BoxChances>
      </CardMedium>
    </ZooLoader>
  )
}

export const USD_OPTIONS = [
  {
    value: 'Vote in DAI',
    icon: IconDAI,
  },
]

export const CardCreateVotingPosition = ({
  positionId,
  votingsByUser,
}: {
  positionId: string
  votingsByUser: LogDescription[]
}) => {
  const daiBalance = useChainStablecoinBalance()
  const { quoteCurrency: dai } = useZooCurrencies()
  const [value, onUserInput] = useState<number | undefined>()
  const { vote: vote, pending: pendingVote } = useCreateNewVotingPosition(value, positionId)
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()
  const { chainId } = useActiveWeb3React()

  const stage = useBattleStage()

  const inactive = stage < BATTLE_STAGES.SECOND

  return (
    <CardMedium>
      <CardLine index={18} />
      <CardHeadBox>
        <LogoCoin src={stableCurrencyIcon} />
        <CardHead>
          <Trans>{stableCurrency} Voting:</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox inactive={inactive}>
        {stage === BATTLE_STAGES.SECOND || true ? (
          <>
            <BalanceExplanation>
              <InfoText>
                <Trans>My {stableCurrency} Balance:</Trans>
              </InfoText>
              &nbsp;
              <BalanceValue>{formatDecimal(daiBalance)}</BalanceValue>
            </BalanceExplanation>
            <BalanceInputWrapper
              btn={MaxButtonPurple}
              inactive={inactive}
              balance={value}
              total={daiBalance}
              setBalance={onUserInput}
            />

            <RowPicker>
              {chainId !== SupportedChainId.MOONBEAM && chainId !== SupportedChainId.MOONBEAM_ALPHA && (
                <UsdOptionPickerStyled options={USD_OPTIONS} />
              )}
              <GetDaiStyled />
            </RowPicker>
          </>
        ) : null}
        <BalanceLine>
          <BoxBtn2>
            {dai ? (
              <ApproveCheckerBattleArena currency={dai} border={value}>
                <ButtonVote disabled={pendingVote || stage !== BATTLE_STAGES.SECOND} onClick={vote}>
                  {pendingVote ? (
                    <Dots>
                      <Trans>Voting</Trans>
                    </Dots>
                  ) : (
                    <Trans>Vote</Trans>
                  )}
                </ButtonVote>
                {stage === BATTLE_STAGES.SECOND && votingsByUser && votingsByUser.length > 0 && (
                  <DaiBoostBtn votingsByUser={votingsByUser} />
                )}
              </ApproveCheckerBattleArena>
            ) : null}
          </BoxBtn2>
        </BalanceLine>
      </CardContentBox>
    </CardMedium>
  )
}

const CardMiniNoMargin = styled(CardMini)`
  width: initial;
  height: initial;
  min-height: initial;
  margin: 0;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.text2};
  margin-left: 5px;
`

const CardHeadBoxStyled = styled(CardHeadBox)`
  margin-bottom: 25px;
`

export const useAllVotesFromWinData = (winData: any) => {
  const { a: { votes: votesA } = { votes: ZERO }, b: { votes: votesB } = { votes: ZERO } } = winData

  const all = useMemo(() => {
    if (!votesA || !votesB) {
      return ZERO
    }

    return votesA.add(votesB)
  }, [votesA, votesB])

  return all
}

const VotesText = styled(ZooTextGradient)`
  display: flex;
  align-items: center;
`

export const ZooStakedTotal = ({ winData }: { winData: any }) => {
  const all = useAllVotesFromWinData(winData)

  return (
    <BoxLikeBtnMarginLeft>
      <VotesText>
        {formatDecimal(all)}
        <DopText>
          <Trans>Total votes</Trans>
        </DopText>
      </VotesText>
    </BoxLikeBtnMarginLeft>
  )
}

export const DaiStakedTotal = ({ pairData }: { pairData: any }) => {
  const { token1, token2 } = pairData

  const { allVotings: allVotings1 } = useAllBattleNftEvents(token1.toString())
  const { allVotings: allVotings2 } = useAllBattleNftEvents(token2.toString())
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()

  const { allDaiInvested: all1 } = useDaiVotes(allVotings1, [])
  const { allDaiInvested: all2 } = useDaiVotes(allVotings2, [])

  const all = useMemo(() => {
    if (!all1 || !all2) {
      return ZERO
    }

    return all2.add(all1)
  }, [all1, all2])

  return (
    <CardMiniNoMargin>
      <NumberText>{formatDecimal(all)}</NumberText>
      <CardHeadBoxStyled>
        <LogoCoin src={stableCurrencyIcon} />
        <CardHead>{stableCurrency}</CardHead>
        <SubTitle>
          <Trans>Staked total</Trans>
        </SubTitle>
      </CardHeadBoxStyled>
    </CardMiniNoMargin>
  )
}

const useCommonDaiRewards = (winData: any) => {
  const { a: { yTokensSaldo: yTokensA = ZERO } = {}, b: { yTokensSaldo: yTokensB = ZERO } = {} } = winData

  const reward = useMemo(() => {
    if (!yTokensA || !yTokensB) {
      return ZERO
    }

    return winData.winner === WINNER.A ? yTokensA : yTokensB
  }, [yTokensA, yTokensB, winData])

  return useTotalRewardsBattle(reward)
}

export const BoxLikeBtn = styled.div`
  padding: 5px 20px;
  border: 2px solid #4b4b5f;
  background-color: #27273e;
  border-radius: 50px;
  height: auto;
  display: flex;
  justify-content: space-between;
`
export const BoxLikeBtnMarginLeft = styled(BoxLikeBtn)``
export const NumberTextBattleBox = styled(NumberText)`
  color: ${({ theme }) => theme.text5};
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
`
export const DopText = styled.span`
  color: ${({ theme }) => theme.text5};
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 10px;
`

const RewardsTemplate = ({ loading, value, label }: { loading: boolean; value: BigNumber; label: any }) => {
  return (
    <BoxLikeBtn>
      <NumberTextBattleBox>
        {loading ? (
          <Dots>
            <Trans>Loading</Trans>
          </Dots>
        ) : value.isZero() ? null : (
          <>
            {formatDecimal(value, 2)} <DopText>{label}</DopText>
          </>
        )}
      </NumberTextBattleBox>
    </BoxLikeBtn>
  )
}

export const DaiTotalRewards = ({ winData }: { winData: any }) => {
  const { result: usd, loading } = useCommonDaiRewards(winData)
  const { stableCurrency } = useStableCurrency()

  if (usd.isZero()) {
    return null
  }

  return <RewardsTemplate label={<Trans>{stableCurrency}</Trans>} loading={loading} value={usd} />
}

export const WglmrWellTotalRewards = ({ epoch, winData }: { epoch: number; winData: any }) => {
  const deps = useMemo(() => [epoch], [epoch])

  const all = useAllVotesFromWinData(winData)
  const battleC = useBattleArenaContract()

  const { loading: loadingWell, result: wellRewards } = useSingleCallResult(battleC, 'wellClaimedByEpoch', deps)
  const { loading: loadingGlmr, result: glmrRewards } = useSingleCallResult(battleC, 'glmrClaimedByEpoch', deps)

  const { loading: loading, result: totalActiveVotesByEpoch } = useSingleCallResult(
    battleC,
    'totalActiveVotesByEpoch',
    deps
  )

  const wells = useMemo(() => {
    return wellRewards &&
      totalActiveVotesByEpoch &&
      totalActiveVotesByEpoch[0] &&
      !totalActiveVotesByEpoch[0].isZero() &&
      totalActiveVotesByEpoch
      ? wellRewards[0].mul(all).div(totalActiveVotesByEpoch[0])
      : ZERO
  }, [wellRewards, totalActiveVotesByEpoch, all])

  const wglmrs = useMemo(() => {
    return glmrRewards &&
      totalActiveVotesByEpoch &&
      totalActiveVotesByEpoch[0] &&
      !totalActiveVotesByEpoch[0].isZero() &&
      totalActiveVotesByEpoch
      ? glmrRewards[0].mul(all).div(totalActiveVotesByEpoch[0])
      : ZERO
  }, [glmrRewards, totalActiveVotesByEpoch, all])

  return (
    <>
      {(loading || loadingWell || !wglmrs.isZero()) && (
        <RewardsTemplate label={<Trans>wGLMR</Trans>} loading={loading || loadingWell} value={wglmrs} />
      )}
      {(loading || loadingGlmr || !wells.isZero()) && (
        <RewardsTemplate label={<Trans>WELL</Trans>} loading={loading || loadingGlmr} value={wells} />
      )}
    </>
  )
}

export const CardNFTinBattles = () => {
  const { nftsAsArray, loading } = useNftsInfo()

  return (
    <CardMiniForBattleBoard>
      <CardLine index={2} />
      <CardHeadBox>
        <CardHead>Staked NFTs</CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{loading ? <Trans>Loading...</Trans> : nftsAsArray.length}</NumberText>
        <InfoText>In Battles</InfoText>
      </CardContentBox>
    </CardMiniForBattleBoard>
  )
}
export const CardNumStakersAndVoters = () => {
  const { loading, amountVoters: amount } = useAllVotersAndStakers()

  return (
    <CardMiniForBattleBoard>
      <CardLine index={5} />
      <CardHeadBox>
        <CardHead>Voted</CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{loading ? <Trans>Loading...</Trans> : amount}</NumberText>
        <InfoText>In Battles</InfoText>
      </CardContentBox>
    </CardMiniForBattleBoard>
  )
}

const NumStage = styled(CardLine)`
  font-size: 20px;
  line-height: 25px;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontUniform};
  width: 100%;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 10px;

  ${({ theme }) => theme.mediaWidth.upToProSmall`
    margin-bottom: 0;
  `};
`
const NumStage1 = styled(NumStage)`
  color: #f56738;
`
const NumStage2 = styled(NumStage)`
  color: #f64562;
`
const NumStage3 = styled(NumStage)`
  color: #e138f5;
`
const NumStage4 = styled(NumStage)`
  color: #05b6f9;
`
const NumStage5 = styled(NumStage)`
  color: #1fad1f;
`

const DataBox = styled.div`
  margin: 10px 0;
`
const InfoTextForStage = styled(InfoText)`
  font-size: 13px !important;
  line-height: 20px !important;
  color: ${({ theme }) => theme.text2} !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 11px !important;
    line-height: normal !important;
  `};
`
const DataBoxForStage = styled(DataBox)`
  margin: 0;
`

const StageContent = ({ stage }: { stage: BATTLE_STAGES }) => {
  const currentStage = useBattleStage()
  const data = useBattleStageTime()

  const { endDate: endsDate } = useTargetBattleStageTime(stage)
  const { endDate: beginsDate } = useTargetBattleStageTime(stage - 1)

  return (
    <CardContentBox>
      <DataBoxForStage>
        {stage === currentStage ? <RenderDates data={data} showLocked={false} /> : null}
      </DataBoxForStage>
      {endsDate && beginsDate ? (
        <InfoTextForStage>
          {stage < currentStage ? <Trans>Ended</Trans> : null}
          {stage === currentStage ? <Trans>Ends</Trans> : null}
          {stage > currentStage ? <Trans>Begins</Trans> : null}
          {stage <= currentStage ? (
            <InfoTextNumber>
              {endsDate.getDate()} {getMonthLabel(endsDate.getMonth() + 1)} {endsDate.getHours()}:
              {fill(endsDate.getMinutes())}
            </InfoTextNumber>
          ) : (
            <InfoTextNumber>
              {beginsDate.getDate()} {getMonthLabel(beginsDate.getMonth() + 1)} {beginsDate.getHours()}:
              {fill(beginsDate.getMinutes())}
            </InfoTextNumber>
          )}
        </InfoTextForStage>
      ) : null}
    </CardContentBox>
  )
}

const fill = (v: number) => (v < 10 ? `0${v}` : `${v}`)

export const CardMiniStage1 = () => {
  const stage = useBattleStage()
  const gotoTab = useGoToTab(Paths.BATTLES_BOARD_STAKING, stage === BATTLE_STAGES.FIRST)

  return (
    <CardStageForBattleBoard active={stage === BATTLE_STAGES.FIRST} stage={BATTLE_STAGES.FIRST} onClick={gotoTab}>
      <Row>
        <NumStage1>Stage 1</NumStage1>
      </Row>
      <CardHeadBoxForStage>
        <CardHeadForStage>Stake NFTs</CardHeadForStage>
      </CardHeadBoxForStage>
      <StageContent stage={BATTLE_STAGES.FIRST} />
    </CardStageForBattleBoard>
  )
}

export const CardMiniStage2 = () => {
  const stage = useBattleStage()
  const gotoTab = useGoToTab(Paths.BATTLES_NFTS, stage === BATTLE_STAGES.SECOND)

  return (
    <CardStageForBattleBoard active={stage === BATTLE_STAGES.SECOND} stage={BATTLE_STAGES.SECOND} onClick={gotoTab}>
      <Row>
        <NumStage2>Stage 2</NumStage2>
      </Row>
      <CardHeadBoxForStage>
        <CardHeadForStage>Vote with FRAX</CardHeadForStage>
      </CardHeadBoxForStage>
      <StageContent stage={BATTLE_STAGES.SECOND} />
    </CardStageForBattleBoard>
  )
}
export const CardMiniStage3 = () => {
  const stage = useBattleStage()
  const gotoTab = useGoToTab(Paths.BATTLES_NFTS, stage === BATTLE_STAGES.THIRD)

  return (
    <CardStageForBattleBoard active={stage === BATTLE_STAGES.THIRD} stage={BATTLE_STAGES.THIRD} onClick={gotoTab}>
      <Row>
        <NumStage3>Stage 3</NumStage3>
      </Row>
      <CardHeadBoxForStage>
        <CardHeadForStage>NFT Pairing</CardHeadForStage>
      </CardHeadBoxForStage>
      <StageContent stage={BATTLE_STAGES.THIRD} />
    </CardStageForBattleBoard>
  )
}
export const CardMiniStage4 = () => {
  const stage = useBattleStage()
  const gotoTab = useGoToTab(Paths.BATTLES_PAIRS, stage === BATTLE_STAGES.FOURTH)

  return (
    <CardStageForBattleBoard active={stage === BATTLE_STAGES.FOURTH} stage={BATTLE_STAGES.FOURTH} onClick={gotoTab}>
      <Row>
        <NumStage4>Stage 4</NumStage4>
      </Row>
      <CardHeadBoxForStage>
        <CardHeadForStage>Battles</CardHeadForStage>
      </CardHeadBoxForStage>
      <StageContent stage={BATTLE_STAGES.FOURTH} />
    </CardStageForBattleBoard>
  )
}
export const CardMiniStage5 = () => {
  const stage = useBattleStage()
  const gotoTab = useGoToTab(Paths.BATTLES_PAIRS, stage === BATTLE_STAGES.FIFTH)

  return (
    <CardStageForBattleBoard active={stage === BATTLE_STAGES.FIFTH} stage={BATTLE_STAGES.FIFTH} onClick={gotoTab}>
      <Row>
        <NumStage5>Stage 5</NumStage5>
      </Row>
      <CardHeadBoxForStage>
        <CardHeadForStage>Battle Results</CardHeadForStage>
      </CardHeadBoxForStage>
      <StageContent stage={BATTLE_STAGES.FIFTH} />
    </CardStageForBattleBoard>
  )
}

export const CardMiniForNftInfoDAI2 = ({
  allVotes,
  userVotes,
  loading,
}: {
  allVotes: LogDescription[]
  userVotes: LogDescription[]
  loading?: boolean
}) => {
  const { allDaiInvested, userDaiInvested } = useDaiVotes(allVotes, userVotes)
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()

  return (
    <CardMiniForBattleBoard>
      <CardLine index={18} />
      <CardHeadBox>
        <LogoCoin src={stableCurrencyIcon} />
        <CardHead>{stableCurrency} Staked</CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{loading ? <Dots>Loading</Dots> : formatDecimal(allDaiInvested)}</NumberText>
        <InfoText>
          My Votes <InfoTextNumber>{loading ? <Dots>Loading</Dots> : formatDecimal(userDaiInvested)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMiniForBattleBoard>
  )
}

const ZooTextGradientStyled = styled(ZooTextGradient)`
  font-weight: 700;
  font-size: 37px;
  line-height: 38px;
  margin-bottom: 10px;

  display: flex;
`
export const UpForGrabs = () => {
  return (
    <CardMiniForBattleBoard style={{ maxHeight: 240 }}>
      <CardLine index={13} />
      <CardHeadBox>
        <CardHead>
          <Trans>Up for grabs!</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>
          <ZooTextGradientStyled>
            <Trans>3,000 $USD</Trans>
          </ZooTextGradientStyled>
        </NumberText>
        <InfoText>
          <Info>
            <Trans>Every season, across all battles!</Trans>
          </Info>
        </InfoText>
      </CardContentBox>
    </CardMiniForBattleBoard>
  )
}

export const CardMiniForNftInfoZOO2 = ({
  allVotes,
  userVotes,
  loading,
}: {
  allVotes: LogDescription[]
  userVotes: LogDescription[]
  loading?: boolean
}) => {
  const { allZooInvestedAmount, userZooInvestedAmount } = useDaiVotes(allVotes, userVotes)

  return (
    <CardMiniForBattleBoard>
      <CardLine index={13} />
      <CardHeadBox>
        <LogoCoin src={IconZOO} />
        <CardHead>
          <Trans>ZOO Staked</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox>
        <NumberText>{loading ? <Dots>Loading</Dots> : formatDecimal(allZooInvestedAmount)}</NumberText>
        <InfoText>
          <Trans>My Votes</Trans>
          <InfoTextNumber>{loading ? <Dots>Loading</Dots> : formatDecimal(userZooInvestedAmount)}</InfoTextNumber>
        </InfoText>
      </CardContentBox>
    </CardMiniForBattleBoard>
  )
}
