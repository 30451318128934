import { useCallback } from 'react'
import { Container, TypeButton } from './styles'
import { Trans } from '@lingui/macro'

interface IButtonPanel {
  onChange: (type: IPanelVariant) => void
  type: IPanelVariant
}

export enum IPanelVariant {
  INCREASE = 'Increase',
  UNLOCK = 'Unlock',
}

const ButtonPanel = ({ onChange, type }: IButtonPanel) => {
  const handleButtonClick = useCallback(
    (type: IPanelVariant) => {
      onChange(type)
    },
    [onChange]
  )

  return (
    <Container>
      <TypeButton onClick={() => handleButtonClick(IPanelVariant.INCREASE)} $active={IPanelVariant.INCREASE === type}>
        <Trans>Extend Lock</Trans>
      </TypeButton>
      <TypeButton onClick={() => handleButtonClick(IPanelVariant.UNLOCK)} $active={IPanelVariant.UNLOCK === type}>
        <Trans>Withdraw</Trans>
      </TypeButton>
    </Container>
  )
}

export default ButtonPanel
