import { Trans } from '@lingui/macro'
import { useState } from 'react'
import { PaddedColumn, Separator } from './styleds'
import { RowBetween } from 'components/Row'
import { ArrowLeft } from 'react-feather'
import styled from 'styled-components/macro'
import { Token } from '@uniswap/sdk-core'
import { ManageLists } from './ManageLists'
import ManageTokens from './ManageTokens'
import { TokenList } from '@uniswap/token-lists'
import { CurrencyModalView } from './CurrencySearchModal'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  // padding-bottom: 80px;
`

const ToggleWrapper = styled(RowBetween)`
  background-color: #33334b;
  border-radius: 50px;
  padding: 2px;
  margin-left: 25px;
`

const RowArrowList = styled(RowBetween)`
  padding: 0;
`

const ToggleOption = styled.div<{ active?: boolean }>`
  width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  background-color: ${({ active }) => (active ? '33334b' : '#f56738')};
  color: ${({ active }) => (active ? '#fff' : '#fff')};
  user-select: none;

  :hover {
    cursor: pointer;
    // opacity: 0.7;
  }
`

export default function Manage({
  setModalView,
  setImportList,
  setImportToken,
  setListUrl,
  showListsEnabled = false,
  onSelectToken,
}: {
  onDismissHandler?: () => void
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  setImportList: (list: TokenList) => void
  setListUrl: (url: string) => void
  showListsEnabled: boolean
  onSelectToken: (token: Token) => void
}) {
  // toggle between tokens and lists
  const [showLists, setShowLists] = useState(showListsEnabled)

  return (
    <Wrapper>
      <PaddedColumn>
        <RowArrowList>
          <ArrowLeft style={{ cursor: 'pointer' }} onClick={() => setModalView(CurrencyModalView.search)} />
          <ToggleWrapper>
            <ToggleOption onClick={() => setShowLists(!showLists)} active={showLists}>
              <Trans>Lists</Trans>
            </ToggleOption>
            <ToggleOption onClick={() => setShowLists(!showLists)} active={!showLists}>
              <Trans>Tokens</Trans>
            </ToggleOption>
          </ToggleWrapper>
        </RowArrowList>
      </PaddedColumn>
      <Separator />
      {/* <PaddedColumn style={{ padding: 0 }}>
      </PaddedColumn> */}
      {showLists ? (
        <ManageLists
          setModalView={setModalView}
          setImportList={setImportList}
          setListUrl={setListUrl}
          showSuggestions={false}
        />
      ) : (
        <ManageTokens setModalView={setModalView} setImportToken={setImportToken} onSelectToken={onSelectToken} />
      )}
    </Wrapper>
  )
}
