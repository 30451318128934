import { MouseoverTooltip } from 'components/Tooltip'
import { Trans } from '@lingui/macro'
import { useBattleArenaAddress, useBattleYiernContract, useVeModelContract, useZooRealToken } from 'constants/zoodao'
import { useAPYFrax } from 'hooks/gql-moonwell'
import { toNumber, useBattleRewards, useTotalRewardsBattle } from 'pages/NftBattlesPage/hooks'
import { Dots } from 'pages/Pool/styleds'
import { useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import styled from 'styled-components/macro'
import { CardMiniForBattleBoard, CardHeadBox, CardHead, CardContentBox, NumberText } from './CardMiniForNftInfo'
import { CardLine } from './styleds'
import { ZERO } from 'utils/isZero'
import { useBalance } from 'hooks/zoodao/useBalance'
import { BigNumber } from 'ethers'
import { BNZooPrice, PRICE_MULTIPLIER } from 'pages/Dashboard/InfoCards/DashboardCards'
import { fromWei } from 'utils/fromWei'

const Rewards = styled.div`
  padding: 12px;
`

export const useIncentivesAPY = () => {
  const { stakersRewards, votersRewards, loading } = useBattleRewards()

  const sum = useMemo(() => {
    return stakersRewards.add(votersRewards)
  }, [stakersRewards, votersRewards])

  const arenaEpochRewards = useEndEpochOfIncentiveRewards()

  const incentives = useMemo(() => {
    return sum.mul(arenaEpochRewards)
  }, [sum, arenaEpochRewards])

  const contract = useZooRealToken()

  const battleContractAddress = useBattleArenaAddress()

  const zooInBattles = useBalance(contract, battleContractAddress)

  const convertToZooUsd = (v: BigNumber) => v.mul(BNZooPrice).div(PRICE_MULTIPLIER)

  const vault = useBattleYiernContract()
  const vaultStablecoinStaked = useBalance(vault, battleContractAddress)

  const { result: fraxInBattles, loading: loadingFrax } = useTotalRewardsBattle(vaultStablecoinStaked)
  const apy: number = useMemo(() => {
    return zooInBattles.isZero() && fraxInBattles.isZero()
      ? 0
      : ((+fromWei(convertToZooUsd(incentives)) / +fromWei(convertToZooUsd(zooInBattles).add(fraxInBattles))) *
          365 *
          100) /
          84
  }, [incentives, zooInBattles, fraxInBattles])

  return {
    loading: loading || loadingFrax,
    apy,
  }
}

export const useEndEpochOfIncentiveRewards = () => {
  const contract = useVeModelContract()
  return toNumber(useSingleCallResult(contract, 'endEpochOfIncentiveRewards')?.result?.[0] || ZERO) - 1
}

export const CardBattleAPY = () => {
  const { frax, loading } = useAPYFrax()

  const apy = useMemo(() => {
    if (!frax) {
      return 0
    }

    return +frax.supplyRewardProtocol + +frax.supplyRewardNative + +frax.supplyRate
  }, [frax])

  return (
    <CardMiniForBattleBoard>
      <CardLine index={9} />
      <CardHeadBox>
        <CardHead>
          <Trans>Battles APY</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBox>
        <MouseoverTooltip
          placement="top"
          noPadding
          text={
            frax && (
              <Rewards>
                <div>Supply APY: {(+frax.supplyRate).toFixed(2)}%</div>
                <div>+ GLMR Rewards: {(+frax.supplyRewardNative).toFixed(2)}%</div>
                <div>+ WELL Rewards: {(+frax.supplyRewardProtocol).toFixed(2)}%</div>
              </Rewards>
            )
          }
        >
          <a href="https://moonwell.fi/artemis/FRAX" target="_blank" rel="noopener noreferrer">
            <NumberText>
              {loading ? (
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              ) : (
                <>{apy.toFixed(2)} %</>
              )}
            </NumberText>
          </a>
        </MouseoverTooltip>
      </CardContentBox>
    </CardMiniForBattleBoard>
  )
}
