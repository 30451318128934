import { useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
import { stakingPoolAbiExported, useChainStartBlock, useStakingSlpContract } from 'constants/zoodao'
import { TransactionResponse } from '@ethersproject/providers'
import { ZERO } from 'utils/isZero'
import { useActiveWeb3React } from 'hooks/web3'
import { useTransactionAdder, useHasPendingNftAction } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { getBigNumberValue } from 'pages/NftBattlesPage/hooks'
import { useSingleCallResult } from 'state/multicall/hooks'
import { LogDescription } from 'ethers/lib/utils'
import { utils } from 'ethers'
import { useBalance } from 'hooks/zoodao/useBalance'
import { useBlockNumber } from 'state/application/hooks'
import { fromWei } from 'utils/fromWei'

const iface = new utils.Interface(stakingPoolAbiExported)

export const bnFrom18 = (b: BigNumber) => b.div(1e9).div(1e9)

export const formatBNToNumber = (b: BigNumber) => +fromWei(b)

export const useStakeSLP = (amount: number) => {
  const contract = useStakingSlpContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `$stake_lp_${amount}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onStake() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account && amount > 0) {
      const txData = await contract.populateTransaction.stake(getBigNumberValue(amount))

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Stake ${amount} SLP tokens`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    onStake,
    pending,
  }
}

export const useUnStakeSLP = (amount: number) => {
  const contract = useStakingSlpContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `$unstake_lp_${amount}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onUnStake() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account && amount > 0) {
      const txData = await contract.populateTransaction.withdraw(getBigNumberValue(amount))

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Unstaked ${amount} SLP tokens`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    onUnStake,
    pending,
  }
}

export const useHarvestSLPRewards = () => {
  const contract = useStakingSlpContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `$harvest_rewards`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onHarvest() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const txData = await contract.populateTransaction.getReward()

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Harvest ZOO rewards`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    onHarvest,
    pending,
  }
}

export const useClaimableRewards = () => {
  const contract = useStakingSlpContract()

  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [account || ''], [account])
  return useSingleCallResult(contract, 'earned', deps)?.result?.[0] || ZERO
}

export const useUnstakeBalanceSLP = () => {
  const contract = useStakingSlpContract()
  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}

export const useTotalClaimedFromStakingPool = (account: string | null | undefined = undefined) => {
  const contract = useStakingSlpContract()

  const [rewards, setRewards] = useState<BigNumber>(ZERO)

  const [loading, setLoading] = useState<boolean>(true)

  const block = useBlockNumber()

  const b = useChainStartBlock()

  useEffect(() => {
    const fetch = async () => {
      if (contract && account && block) {
        const harvested = contract.filters.RewardPaid(account as any)

        setLoading(true)

        const harvestedD = await contract.queryFilter(
          {
            topics: harvested.topics,
          },
          b
        )

        const parsed = harvestedD.map((d) => ({ ...iface.parseLog(d), transactionHash: d.transactionHash }))

        const sum = parsed.reduce((acc: BigNumber, item: LogDescription) => {
          return acc.add(item.args.reward)
        }, ZERO)

        setRewards(sum)

        setLoading(false)
      }
    }

    fetch()
  }, [contract, account, b, block])

  return {
    rewards,
    loading,
  }
}
