import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useBattleStakerPositionInfo } from '../../NftBattlesPage/hooks'
import { Paths } from '../../../constants/paths'
import { OverviewCard } from './NftOverviewCard'

interface IProps {
  positionId: string
  stage?: number
  hideZeroVotes?: boolean
}

export const useCardLoading = (positionId: string) => {
  const history = useHistory()

  const path = useMemo(() => Paths.BATTLES_STAKED.replace(':positionId', positionId), [positionId])
  const onClick = (e: any) => {
    if (e) {
      e.stopPropagation()
    }
    history.push(path)
  }

  return {
    onClick,
    path,
  }
}

export const MyStakedCardForPool = ({ positionId, hideZeroVotes }: IProps) => {
  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  const stakingPositionId = useMemo(() => {
    return positionId ? positionId.toString() : ''
  }, [positionId])

  const { onClick } = useCardLoading(stakingPositionId)

  return (
    <OverviewCard
      positionInfo={positionInfo}
      onClick={onClick}
      positionId={stakingPositionId}
      isOwner={true}
      hideZeroVotes={hideZeroVotes}
    />
  )
}

export const StakedCardForPool = ({ positionId, hideZeroVotes }: IProps) => {
  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  const stakingPositionId = useMemo(() => {
    return positionId.toString()
  }, [positionId])

  const { onClick } = useCardLoading(stakingPositionId)

  return (
    <OverviewCard
      positionInfo={positionInfo}
      onClick={onClick}
      positionId={stakingPositionId}
      hideZeroVotes={hideZeroVotes}
    />
  )
}
