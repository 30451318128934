import { BigNumber } from 'ethers'

export interface ISelectedPortfolioNfts {
  [pid: string]: {
    zooInvested: BigNumber
    daiInvested: BigNumber
    stakingPositionId: string
    votingPositionId?: BigNumber
  }
}

export function getSelectedKey(stakingPositionId: string, votingPositionId: BigNumber | undefined) {
  return `${stakingPositionId}_${votingPositionId?.toString()}`
}
