import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { ButtonStake } from '../Button'
import { Dots } from '../swap/styleds'
import {
  BATTLE_STAGES,
  useAvailablePairChecking,
  useBattleStage,
  useNftPairing,
} from '../../pages/NftBattlesPage/hooks'
import { useNftBattlePairingInfo } from '../../hooks/zoodao/useBattlesEventsData'
import { GetLinkPairs } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'

const IconPair = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
`

const ContainerWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`

const ContainerWrapperWarning = styled(ContainerWrapper)`
  justify-content: flex-start;
`

interface IProps {
  stakingPositionId: string
  el?: any
  showWarning?: boolean
  className?: string
  disabled?: boolean
}
export const PairedTemplate = () => (
  <GetLinkPairs>
    <Trans>NFT Paired</Trans>
    <IconPair></IconPair>
  </GetLinkPairs>
)

const PairNft = ({
  stakingPositionId,
  className,
  showWarning = true,
  disabled = false,
  el: El = ButtonStake,
}: IProps) => {
  const { onCreatePair, pending } = useNftPairing(stakingPositionId)
  const available = useAvailablePairChecking()
  const stage = useBattleStage()

  const { paired, loading } = useNftBattlePairingInfo(stakingPositionId)

  if (paired) {
    if (!showWarning) {
      return null
    }

    return <PairedTemplate />
  }

  if (!available) {
    if (!showWarning) {
      return null
    }

    return (
      <ContainerWrapperWarning>
        <Trans>No opponents available</Trans>
      </ContainerWrapperWarning>
    )
  }

  if (stage !== BATTLE_STAGES.THIRD) {
    return (
      <ContainerWrapperWarning>
        <Trans>NFT Pairing available in Stage 3</Trans>
      </ContainerWrapperWarning>
    )
  }

  return (
    <ContainerWrapper className={className}>
      <El disabled={pending || loading || disabled} onClick={onCreatePair}>
        {pending ? (
          <Dots>
            <Trans>Pairing</Trans>
          </Dots>
        ) : (
          <Trans>Pair NFT</Trans>
        )}
      </El>
    </ContainerWrapper>
  )
}

export default PairNft
