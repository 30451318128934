import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { IDashCard, CardLine, CardHeader, CardContent, CardDescription, CardText, DashboardCardFlex } from '../styleds'
import { Trans } from '@lingui/macro'
import { formatDecimal } from '../../../utils/numberWithCommas'
import IconZOO from 'assets/svg/token.svg'
import IconDAI from 'assets/images/dai.png'
import IconSLP from 'assets/images/logo-sushi.png'
import { ButtonStake } from 'components/Button'
import { CardHead, LinkToMax } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { useChainStablecoinBalance, useZooTokenBalance } from 'hooks/zoodao/zooToken'
import {
  useChainStartBlock,
  useStakingSLPAddress,
  useStakingSlpContract,
  useZooDaiSlpContract,
  useZooDaoPair,
} from '../../../constants/zoodao'
import { useState, useEffect } from 'react'
import sushibg from 'assets/images/bg-sushi.jpg'
import { TextConnect } from 'components/WarningWrapper/WarningWrapper'
import { Tabs, Tab } from 'pages/NftBattlesPageV2/styleds'
import { Arrow, GetLinkSLP, GetZOO } from 'components/GetStablecoin'
import { useBalance } from 'hooks/zoodao/useBalance'
import { useActiveWeb3React } from 'hooks/web3'
import {
  useClaimableRewards,
  useHarvestSLPRewards,
  useStakeSLP,
  useTotalClaimedFromStakingPool,
  useUnstakeBalanceSLP,
  useUnStakeSLP,
} from './hooks'
import { fromWei } from 'utils/fromWei'
import { Dots } from 'pages/Pool/styleds'
import { ApproveCheckerSLPStaking } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import StakedXZOO from 'pages/XYield/Panels/StakedXZOO'
import { NumericalInputStyled } from 'components/NumericalInput'
import { useXZooParticipantsAmount } from 'hooks/gql'
import { BigNumber } from 'ethers'

export const CardTextStyled = styled(CardText)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`
const CardDescriptionSushiFarm = styled(CardDescription)`
  margin-top: 0px;
  display: flex;
  align-items: center;
`

const FirstForm = styled.div<{ inactive?: boolean }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.4' : '1')};
  padding: 25px;
  background-color: #27273f;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  width: 100%;
`
const SushiFarmBox = styled(FirstForm)`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1)), url(${sushibg}) 50% 50% / cover no-repeat;
`

const BoxZOO = styled.div`
  height: auto;
`
const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  margin: 12px 0;
`
export const CardHeadContribution = styled(CardHead)`
  align-items: center;
  line-height: 30px;
  width: 100%;
`

const Closed = styled.div`
  font-size: 24px;

  color: ${({ theme }) => theme.red1};
  margin-bottom: 24px;
  font-weight: bold;
`

export const LogoCoinClaimable = styled.img`
  border-radius: 100%;
  margin-right: 10px;

  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-left: 0px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 38px;
    height: 38px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 35px;
    height: 35px;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 22px;
    height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 35px;
    height: 35px;
  `}
`
const CardContentBox = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 34px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 22px;
    line-height: 24px;
  `}
`
const LineClaimNum = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  width: 100%;
  align-items: baseline;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #fff;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`
const Btns = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InfoTextNumber = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-left: 5px;
  display: inline-block;
`

export const ClaimRewards = () => {
  const { onHarvest, pending } = useHarvestSLPRewards()

  const { account } = useActiveWeb3React()
  const claimable = useClaimableRewards()

  const { rewards, loading } = useTotalClaimedFromStakingPool(account)

  return (
    <FirstForm>
      <CardLine index={13} />
      <BoxZOO>
        <CardHeadBox>
          <CardHeadContribution>
            <LogoCoinClaimable src={IconZOO} />
            <Trans>Claimable ZOO Rewards</Trans>
          </CardHeadContribution>
        </CardHeadBox>
        <CardContentBox>
          <LineClaimNum>{formatDecimal(claimable)}</LineClaimNum>
          <Btns>
            <CardDescription>
              <Trans>My Rewards Claimed:</Trans>
              <InfoTextNumber>
                {loading ? (
                  <Dots>
                    <Trans>Loading</Trans>
                  </Dots>
                ) : (
                  formatDecimal(rewards)
                )}
              </InfoTextNumber>
            </CardDescription>
            <ButtonStake disabled={pending || !claimable || claimable.isZero()} onClick={onHarvest}>
              {pending ? (
                <Dots>
                  <Trans>Claiming</Trans>
                </Dots>
              ) : (
                <>
                  <Trans>Claim</Trans>
                  <Arrow></Arrow>
                </>
              )}
            </ButtonStake>
          </Btns>
        </CardContentBox>
      </BoxZOO>
    </FirstForm>
  )
}
export const SushiFarm = ({ className }: { className?: string }) => {
  return (
    <SushiFarmBox className={className}>
      <CardLine index={16} />
      <BoxZOO>
        <CardHeadBox>
          <CardHeadContribution>
            <LogoCoinClaimable src={IconSLP} />
            <Trans>Sushi Farm</Trans>
            &nbsp;
          </CardHeadContribution>
        </CardHeadBox>
      </BoxZOO>
    </SushiFarmBox>
  )
}

export const GetSLP = ({ el: El = GetLinkSLP }: { el?: any }) => {
  const { dai, zoo } = useZooDaoPair()
  const link = `https://app.sushi.com/add/${dai}/${zoo}`
  return (
    <El onClick={() => window.open(link, '_blank')}>
      <Trans>
        Get SLP
        <Arrow></Arrow>
      </Trans>
    </El>
  )
}

export const DAIBalance = () => {
  const daiBalance = useChainStablecoinBalance()

  return (
    <DashboardCardFlex>
      <CardLine index={17} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>
            <LogoCoinClaimable src={IconDAI} />
            USD Balance
          </Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>{formatDecimal(daiBalance)}</CardTextStyled>
        <CardDescription>My Balance</CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}
export const ZOOBalance = () => {
  const zooBalance = useZooTokenBalance()

  return (
    <DashboardCardFlex>
      <CardLine index={13} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>
            <LogoCoinClaimable src={IconZOO} />
            ZOO Balance
          </Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>{formatDecimal(zooBalance)}</CardTextStyled>
        <CardDescription>My Balance</CardDescription>
      </CardContent>
      {false && <GetZOO />}
    </DashboardCardFlex>
  )
}

const useSlpTokenBalance = () => {
  const farm = useZooDaiSlpContract()
  const { account } = useActiveWeb3React()
  const balance = useBalance(farm, account)
  return balance
}

export const SLPBalance = () => {
  const balance = useSlpTokenBalance()

  return (
    <DashboardCardFlex>
      <CardLine index={16} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>
            <LogoCoinClaimable src={IconSLP} />
            SLP Balance
          </Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>{formatDecimal(balance)}</CardTextStyled>
        <CardDescription>My Balance</CardDescription>
      </CardContent>
      <GetSLP></GetSLP>
    </DashboardCardFlex>
  )
}
export const SLPStaked = () => {
  const rewardToken = useZooDaiSlpContract()
  const stakingAddress = useStakingSLPAddress()

  const balance = useBalance(rewardToken, stakingAddress)

  return (
    <DashboardCardFlex>
      <CardLine index={16} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>
            <LogoCoinClaimable src={IconSLP} />
            SLP Staked
          </Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>{formatDecimal(balance)}</CardTextStyled>
        <CardDescription>For all users</CardDescription>
      </CardContent>
      <GetSLP></GetSLP>
    </DashboardCardFlex>
  )
}
export const IconLogoAPY = styled.div`
  border-radius: 100%;
  margin-right: 10px;

  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-left: 0px;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(90.34deg, #1fad1f, #3d6dfc);
  color: #fff;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
`

const BoxForPercents = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`
const LeftColumnPercents = styled.div`
  color: #1fad1f;
`
const RightColumnPercents = styled.div`
  color: #3d6dfc;
`
const CardDescriptionLeft = styled(CardDescription)`
  color: #1fad1f;
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 600;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`
const CardDescriptionRight = styled(CardDescriptionLeft)`
  color: #3d6dfc;
`

export const ZOO_PRICE = 0.07
export const PRICE_MULTIPLIER = 100
export const BNZooPrice = BigNumber.from(Math.floor(ZOO_PRICE * PRICE_MULTIPLIER))

export const CurrentAPY = () => {
  const apy = 0

  return (
    <DashboardCardFlex>
      <CardLine index={12} />
      <Closed>
        <Trans>Yield Farm Currently Closed</Trans>
      </Closed>

      <CardHeadBox>
        <CardHeadContribution>
          <IconLogoAPY></IconLogoAPY>
          <Trans>Current APR (ZOO + FEES)</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        {!apy ? (
          <CardTextStyled>
            <Dots>Loading</Dots>
          </CardTextStyled>
        ) : (
          <BoxForPercents>
            <LeftColumnPercents>
              <CardDescriptionLeft>Weekly</CardDescriptionLeft>
              <CardTextStyled>{(apy / 52).toFixed(2)}%</CardTextStyled>
            </LeftColumnPercents>
            <RightColumnPercents>
              <CardDescriptionRight>Yearly</CardDescriptionRight>
              <CardTextStyled>{(+apy).toFixed(2)}%</CardTextStyled>
            </RightColumnPercents>
          </BoxForPercents>
        )}

        <CardDescription>
          <Trans>Based on a value of {ZOO_PRICE}$/ZOO</Trans>
        </CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}

export const NumberParticipants = ({ index }: IDashCard) => {
  const farm = useStakingSlpContract()
  const [count, setCount] = useState<number | undefined>()

  const b = useChainStartBlock()

  useEffect(() => {
    const fetch = async () => {
      if (farm) {
        const topics = farm.filters.Staked()

        const data = await farm.queryFilter(
          {
            topics: topics.topics,
          },
          b
        )

        setCount(data.length)
      }
    }

    fetch()
  }, [farm, b])

  return (
    <DashboardCardFlex>
      <CardLine index={index} />
      <CardHeader>
        <Trans>Participants</Trans>
      </CardHeader>

      <CardContent>
        <CardTextStyled>{count ?? <Trans>Loading...</Trans>}</CardTextStyled>
        <CardDescription>
          <Trans>Total</Trans>
        </CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}

export const ParticipantsXZOO = ({ index }: IDashCard) => {
  const { totalCount, loading } = useXZooParticipantsAmount()

  return (
    <DashboardCardFlex>
      <CardLine index={index} />
      <CardHeader>
        <Trans>Participants</Trans>
      </CardHeader>

      <CardContent>
        <CardTextStyled>{loading ? <Trans>Loading...</Trans> : totalCount}</CardTextStyled>
        <CardDescription>
          <Trans>Total</Trans>
        </CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}

const CardContentContribution = styled(CardContent)`
  width: 100%;
  max-width: initial;
  height: 100%;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 25px;
  align-self: center;
  min-height: 240px;
  height: 100%;
  flex-grow: 1;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: initial;
    min-height: 180px;
  `}
`
const CardContribution = styled(Card)`
  width: 100%;
  max-width: initial;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1)), url(${sushibg}) 45% 50% / cover no-repeat;
`
const CardContributionXZOO = styled(Card)`
  width: 100%;
  max-width: initial;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  background: #33334b;
`
const BoxSLP = styled.div`
  height: auto;
`
const LogoCoin = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 38px;
    height: 38px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 22px;
    height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
`
const LogoCoinContribution = styled(LogoCoin)`
  width: 35px;
  height: 35px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 35px;
    height: 35px;
  `}
`
export const InputLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-top: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 18px;
  `}
`

export const CardHeadTitle = styled(CardHead)`
  font-size: 36px;
  line-height: 42px;
  font-weight: 900;
`
const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const TextConnectFarming = styled(TextConnect)`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0px !important;
  text-align: center;
`
const CardHeadBox2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`
const PLACEHOLDER = '0'

const STAKE_TABS = {
  STAKE: 0,
  UNSTAKE: 1,
}

export const FarmingSushiBigBox = () => {
  const [tab, setTab] = useState(STAKE_TABS.STAKE)

  return (
    <CardContentContribution>
      <CardContribution>
        <BoxHeader>
          <CardHeadTitle>Sushi farming</CardHeadTitle>
          <CardHeadTitle>ZOO/DAI SLP</CardHeadTitle>
        </BoxHeader>
        <TextConnectFarming>
          <Trans>Stake your SLP tokens to receive ZOO rewards.</Trans>
          <br></br>
          <Trans>You can get SLP tokens by providing liquidity on Sushi.</Trans>
        </TextConnectFarming>
        <FirstForm>
          <BoxSLP>
            <CardLine index={16} />
            <CardHeadBox>
              <CardHeadContribution>
                <LogoCoinContribution src={IconSLP} />
                <Trans>SLP Staking</Trans>
              </CardHeadContribution>
              <CardHeadBox2>
                <Tabs>
                  <Tab active={tab === STAKE_TABS.STAKE} onClick={() => setTab(STAKE_TABS.STAKE)}>
                    <Trans>Stake</Trans>
                  </Tab>
                  <Tab active={tab === STAKE_TABS.UNSTAKE} onClick={() => setTab(STAKE_TABS.UNSTAKE)}>
                    <Trans>Unstake</Trans>
                  </Tab>
                </Tabs>
              </CardHeadBox2>
            </CardHeadBox>
            {tab === STAKE_TABS.STAKE && <StakeBlock />}
            {tab === STAKE_TABS.UNSTAKE && <UnStakeBlock />}
          </BoxSLP>
        </FirstForm>
      </CardContribution>
    </CardContentContribution>
  )
}
export const InfoTextBoxXZOO = () => {
  return (
    <CardContentContribution>
      <CardContributionXZOO>
        <CardHeadTitle>Stake ZOO in the Vault</CardHeadTitle>
        <TextConnectFarming>
          <Trans>
            The Vault is a utility for the ZOO token separate from Battles and veZOO.
            <br></br>
            <Trans>
              The xZOO Vault earns 1.5% of the yield across all Battles and returns it to ZOO stakers. Vault rewards can
              be claimed in eligible stablecoins.
            </Trans>
          </Trans>
        </TextConnectFarming>
        <StakedXZOO />
      </CardContributionXZOO>
    </CardContentContribution>
  )
}

const StakeBlock = () => {
  const [value, setValue] = useState(0)

  const balance = useSlpTokenBalance()

  const setMax = useCallback(() => {
    const val = +fromWei(balance)
    setValue(val)
  }, [setValue, balance])

  const { onStake, pending } = useStakeSLP(value)

  return (
    <CardContentBox>
      <InputLine>
        <NumericalInputStyled
          value={value}
          onUserInput={(val) => {
            setValue(+val)
          }}
          placeholder={PLACEHOLDER}
          max={balance}
        />
        <LinkToMax onClick={setMax}>
          <Trans>+Max</Trans>
        </LinkToMax>
      </InputLine>
      <Btns>
        <CardDescriptionSushiFarm>
          <Trans>My Balance (SLP):</Trans>
          <InfoTextNumber>{formatDecimal(balance)}</InfoTextNumber>
          <GetSLP></GetSLP>
        </CardDescriptionSushiFarm>
        <ApproveCheckerSLPStaking border={value}>
          <ButtonStake onClick={onStake} disabled={pending}>
            {pending ? <Dots>Staking</Dots> : <Trans>Stake</Trans>}
          </ButtonStake>
        </ApproveCheckerSLPStaking>
      </Btns>
    </CardContentBox>
  )
}

const UnStakeBlock = () => {
  const [value, setValue] = useState(0)
  const balance = useUnstakeBalanceSLP()

  const setMax = useCallback(() => {
    const val = +fromWei(balance)
    setValue(val)
  }, [setValue, balance])

  const { onUnStake, pending } = useUnStakeSLP(value)

  return (
    <CardContentBox>
      <InputLine>
        <NumericalInputStyled
          value={value}
          onUserInput={(val) => {
            setValue(+val)
          }}
          placeholder={PLACEHOLDER}
          max={balance}
        />
        <LinkToMax onClick={setMax}>
          <Trans>+Max</Trans>
        </LinkToMax>
      </InputLine>
      <Btns>
        <CardDescriptionSushiFarm>
          <Trans>My Balance (staked):</Trans>
          <InfoTextNumber>{formatDecimal(balance)}</InfoTextNumber>
        </CardDescriptionSushiFarm>
        <ButtonStake onClick={onUnStake} disabled={pending || !balance || balance.isZero()}>
          {pending ? <Dots>Unstaking</Dots> : <Trans>Unstake</Trans>}
        </ButtonStake>
      </Btns>
    </CardContentBox>
  )
}
