import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { IBridgeConfig, SwapDaiModal, SwapZooModal } from 'components/SwapWidgetModal'
import { useBridgeDaiModalToggle, useBridgeZooModalToggle } from 'state/application/hooks'
import { useChainStablecoinAddress, useZooTokenAddressReal } from 'constants/zoodao'
import { useCallback, useMemo } from 'react'
import { useStableCurrency } from '../../hooks/Tokens'

export const GetLinkZoo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f64562;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 21px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`
export const GetLinkDAI = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: end;
  margin-top: 5px;
  text-decoration: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f8ac00;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  transition: transform 0.3s ease;

  :hover {
    transform: translateX(6px);
    background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 21px;
  line-height: 24px;
`};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 16px;
  line-height: 24px;
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 13px;
  line-height: 20px;
`};

  ${({ theme }) => theme.mediaWidth.upToTablet`
  font-size: 13px;
  line-height: 20px;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 13px;
  line-height: 20px;
`};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 14px;
  line-height: 18px;
`}
`
export const GetLinkZOO = styled(GetLinkDAI)`
  color: #f64562;
`
export const GetLinkSLP = styled(GetLinkDAI)`
  color: #e138f5;
  margin-top: 0;
  margin-left: 24px;
`

export const Arrow = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
`

// need to rename the component with generic name
const GetStablecoin = ({ el: El = GetLinkDAI, className }: { el?: any; className?: string }) => {
  const toggleWalletModal = useBridgeDaiModalToggle()
  const { stableCryptoSymbol } = useStableCurrency()

  const chainStablecoinfAdd = useChainStablecoinAddress()
  const configs = useMemo(() => {
    return {
      fromAsset: chainStablecoinfAdd,
    }
  }, [chainStablecoinfAdd])

  const handleClick = useCallback(() => {
    toggleWalletModal()
  }, [toggleWalletModal])

  return (
    <>
      <El onClick={handleClick} target="_blank" className={className}>
        <Trans>
          Get {stableCryptoSymbol}
          <Arrow></Arrow>
        </Trans>
      </El>
      <SwapDaiModal configs={configs} />
    </>
  )
}

export const GetOnStellarswap = ({ el: El = GetLinkDAI, className }: { el?: any; className?: string }) => {
  const { stableCryptoSymbol } = useStableCurrency()

  return (
    <>
      <El
        onClick={() => window.open('https://app.stellaswap.com/exchange/swap', '_blank')}
        target="_blank"
        className={className}
      >
        <Trans>
          Get {stableCryptoSymbol}
          <Arrow></Arrow>
        </Trans>
      </El>
    </>
  )
}

export const GetZOO = ({ el: El = GetLinkZOO, className }: { el?: any; className?: string }) => {
  const toggleWalletModal = useBridgeZooModalToggle()
  const zoo = useZooTokenAddressReal()

  const configs = useMemo((): IBridgeConfig => {
    return {
      fromAsset: zoo,
    }
  }, [zoo])

  return (
    <>
      <El onClick={toggleWalletModal} target="_blank" className={className}>
        <Trans>
          Get ZOO
          <Arrow></Arrow>
        </Trans>
      </El>
      <SwapZooModal configs={configs} />
    </>
  )
}

export default GetStablecoin
