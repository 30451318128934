import React, { useEffect, useMemo } from 'react'
import { shortenAddress } from '../../../../utils'
import { EtherscanIcon } from '../../CardMiniForNftInfo'
import { Container, NFTAddress, InfoValue, InfoTitle, Info, LogoIconWrapper } from './styles'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'
import { IProjectPartner, useCollectionInfo } from '../../../../hooks/zoodao/useCollectionInfo'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { SupportedChainId } from 'constants/chainsinfo'
import { useExpectedPoolWeight } from 'pages/VePie/hooks'
import { useAllVeZooVotes } from 'hooks/zoodao/veModel'
import { Dots } from 'pages/Pool/styleds'
import { formatDecimal } from 'utils/numberWithCommas'
import { BigNumber } from 'ethers'
import { getWeightPercent } from 'pages/VePie/Weights/Weights'
import { VePieRewards } from './VePieRewards'
import { useActiveWeb3React } from 'hooks/web3'
import { NftNameTemplate } from '../NftOverviewCard'
import { NftProjectLogo } from '../NftProjectLogo'

const VePieNFTOverview = ({
  className,
  collectionAddress,
  addProject,
  totalWeight,
  accumulateWeight,
  color,
}: {
  color: string
  className?: string
  collectionAddress: string
  addProject: (v: IProjectPartner) => void
  totalWeight: BigNumber
  accumulateWeight: (collection: string, value: BigNumber) => void
}) => {
  const item = useCollectionInfo(collectionAddress)
  const { banner_image_url, name } = item
  const { chainId } = useActiveWeb3React()
  const exporerLink = getExplorerLink(chainId || SupportedChainId.MAINNET, collectionAddress, ExplorerDataType.ADDRESS)

  const weight = useExpectedPoolWeight(collectionAddress)

  useEffect(() => {
    if (!weight.isZero()) {
      accumulateWeight(collectionAddress, weight)
    }
  }, [weight, collectionAddress, accumulateWeight])

  const { amount, loading } = useAllVeZooVotes(collectionAddress)

  useEffect(() => {
    if (item) {
      addProject(item)
    }
  }, [item, addProject])

  const percent = useMemo(
    () => (totalWeight.isZero() ? 0 : +getWeightPercent(weight, totalWeight).toFixed(4)),
    [totalWeight, weight]
  )

  return (
    <Container className={className} color={color}>
      <LogoIconWrapper src={banner_image_url} />

      <NftProjectLogo collection={collectionAddress} />

      <Flex flexDirection="column" px={25} pb={25} mt={20}>
        <NftNameTemplate name={name} />
        <Flex alignItems="center">
          <NFTAddress>{collectionAddress && shortenAddress(collectionAddress, 2)}</NFTAddress>
          <EtherscanIcon exporerLink={exporerLink} />
        </Flex>

        <Flex>
          <Info>
            <InfoValue>{(100 * percent).toFixed(2)}%</InfoValue>
            <InfoTitle>Weight</InfoTitle>
          </Info>

          <Info>
            <InfoValue>{loading ? <Dots>Loading</Dots> : formatDecimal(amount)}</InfoValue>
            <InfoTitle>Locked veZOO</InfoTitle>
          </Info>
        </Flex>

        <VePieRewards percent={percent} />
      </Flex>
    </Container>
  )
}

export default styled(VePieNFTOverview)``
