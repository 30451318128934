import { Trans } from '@lingui/macro'
import { useSaleUnallocatedZoo } from 'hooks/zoodao/pre-sale/community-round-b'
import { LogoCoin } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import styled from 'styled-components/macro'
import { formatDecimal } from 'utils/numberWithCommas'
import IconZOO from '../../assets/svg/token.svg'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin: 25px 0 16px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
  `}
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #4c4c77;
  border-radius: 15px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.text2};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 6px;
`

const Value = styled.div`
  margin-top: auto;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
`

const LogoCoinRate = styled(LogoCoin)`
  margin-right: 12px;
  width: 22px;
  height: 22px;
`

const AccountSaleStatsB = () => {
  const { zooAllocated, claimable, zooClaimed } = useSaleUnallocatedZoo()

  return (
    <Container>
      <Item>
        <Title>
          <Trans>Total Claimed</Trans>
        </Title>
        <Value>
          <LogoCoinRate src={IconZOO} /> {formatDecimal(zooClaimed)}
        </Value>
      </Item>
      <Item>
        <Title>
          <Trans>Claimable Tokens</Trans>
        </Title>
        <Value>
          <LogoCoinRate src={IconZOO} /> {formatDecimal(claimable)}
        </Value>
      </Item>
      <Item>
        <Title>
          <Trans>Total Purchased</Trans>
        </Title>
        <Value>
          <LogoCoinRate src={IconZOO} /> {formatDecimal(zooAllocated)}
        </Value>
      </Item>
    </Container>
  )
}

export default AccountSaleStatsB
