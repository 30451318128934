import { Pair } from '@uniswap/v2-sdk'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { useState, useCallback, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { RowBetween, RowFixed } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks/web3'
import { Trans } from '@lingui/macro'
import { Lock } from 'react-feather'
import { AutoColumn } from 'components/Column'
import { FiatValue } from './FiatValue'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import CurrencyLogo from '../CurrencyLogo'

const NumericalInputStyled = styled(NumericalInput)`
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: right;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    line-height: 23px;
  `};
`

const RowFixedStyled = styled(RowFixed)`
  width: 100%;
  flex-wrap: wrap;
  flex-wrap: wrap;
`

const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 24px;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.bg1};
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div`
  border-radius: 50px;
  // border: 2px solid #33334b;
  background: #33334b;
  width: initial;
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
`

const CurrencySelect = styled.div<{ selected: boolean; hideInput: boolean }>`
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  outline: none;
  user-select: none;
  border: none;
  width: initial;
  justify-content: space-between;
  margin-right: 16px;
  min-width: 230px;
`

const InputRow = styled.div<{ selected: boolean; hideBalance: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 0;
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 57px 40px 40px 40px;
  margin-top: auto;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 24px 16px 16px 16px;
  `};
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  background: transparent;
  letter-spacing: 0.75px;
  font-family: ${({ theme }) => theme.fontUniform};
  font-size: 24px;
  line-height: 32px;
  margin-left: 15px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    line-height: 23px;
  `};
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.red};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};
  margin-left: auto;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.75px;
  :focus {
    outline: none;
  }

  font-size: 24px;
  line-height: 32px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    line-height: 23px;
  `};
`

const BalanceStyled = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  display: inline;
  cursor: pointer;
  margin-right: 24px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 23px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    line-height: 23px;
  `};
  color: ${({ theme }) => theme.text1};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  onTokenInputChange?: (a: string) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: CurrencyAmount<Token> | null
  priceImpact?: Percent
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  placeholder?: string
  tokenName?: any
  as?: any
  inputContainer?: any
  className?: string
}

interface CurrencyInputPanelTemplateProps extends CurrencyInputPanelProps {
  onCurrencySelectHandler?: () => void
  modalOpen?: boolean
  handleDismissSearch?: () => void
  isProMode?: boolean
}

export function CurrencyInputTemplate({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  onTokenInputChange,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  placeholder,
  as: AsItem = CurrencySearchModal,
  tokenName: TokenName = StyledTokenName,

  onCurrencySelectHandler,
  modalOpen,
  handleDismissSearch,
  inputContainer: InputContainer = Container,
  isProMode,
  ...rest
}: CurrencyInputPanelTemplateProps) {
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const dim = true

  return (
    <InputPanel id={id} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <TYPE.label fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </TYPE.label>
          </AutoColumn>
        </FixedContainer>
      )}
      <InputContainer>
        <InputRow selected={!onCurrencySelect} hideBalance={hideBalance}>
          <CurrencyLogo currency={currency} size={'24px'} style={{ opacity: dim ? '0.6' : '1' }} />
          <CurrencySelect selected={!!currency} hideInput={hideInput} onClick={onCurrencySelectHandler}>
            <Aligner>
              <RowFixed>
                {pair ? (
                  <TokenName>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </TokenName>
                ) : (
                  <TokenName active={Boolean(currency && currency.name)}>
                    {currency?.name || <Trans>Enter NFT ID</Trans>}
                  </TokenName>
                )}
              </RowFixed>
            </Aligner>
          </CurrencySelect>
          <NumericalInputStyled
            value={value}
            onUserInput={(val) => {
              onUserInput(val)
            }}
            placeholder={placeholder}
          />
        </InputRow>

        {!hideBalance && (
          <FiatRow>
            <RowBetween>
              {account ? (
                <RowFixedStyled>
                  <BalanceStyled onClick={onMax}>
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <Trans>
                          Balance: {formatCurrencyAmount(selectedCurrencyBalance, 4)} {currency.symbol}
                        </Trans>
                      )
                    ) : null}
                  </BalanceStyled>
                  {showMaxButton && selectedCurrencyBalance ? (
                    <StyledBalanceMax onClick={onMax}>
                      <Trans>Max</Trans>
                    </StyledBalanceMax>
                  ) : null}
                </RowFixedStyled>
              ) : (
                <span />
              )}
              <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} />
            </RowBetween>
          </FiatRow>
        )}
      </InputContainer>
      {onCurrencySelect && (
        <AsItem
          isProMode={isProMode}
          isOpenFlag={modalOpen}
          onDismissHandler={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          onTokenInputChange={onTokenInputChange}
          showCurrencyAmount={showCurrencyAmount}
        />
      )}
    </InputPanel>
  )
}

export default function CurrencyInputPanel(props: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const { onCurrencySelect } = props

  return (
    <CurrencyInputTemplate
      onCurrencySelectHandler={() => {
        if (onCurrencySelect) {
          setModalOpen(true)
        }
      }}
      modalOpen={modalOpen}
      handleDismissSearch={handleDismissSearch}
      {...props}
    />
  )
}
