import React, { useState, useEffect, useMemo } from 'react'
import { Currency } from '@uniswap/sdk-core'
import { ButtonLight, ButtonOrangeStaking, ButtonBoost, ButtonPurpleFarm } from '../../components/Button'
import { CurrencyInputTemplate } from '../../components/CurrencyInputPanel'
import { useActiveWeb3React } from '../../hooks/web3'
import { useNftToken } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Dots, YieldSuggestion } from '../Pool/styleds'
import { Trans } from '@lingui/macro'
import { useERC721Contract, useZooDaoNFTStakingPool } from '../../constants/zoodao'
import { useSingleCallResult } from '../../state/multicall/hooks'
import StakingPoolInfo from './StakingPoolInfo/StakingPoolInfo'
import { CardContainer as Card } from '../../components/EpochBanner/EpochBanner'
import { useNftInfo } from '../../hooks/zoodao/useNftInfo'
import { CurrencySearchTemplate } from '../../components/SearchModal/CurrencySearchModal'
import styled from 'styled-components/macro'
import SuggestedNfts from './SuggestedNfts/SuggestedNfts'
import { NftsStaked } from './NftsStaked'
import { useUnstake } from './hooks'
import { Paths } from 'constants/paths'
import { useHistory } from 'react-router-dom'
import firebg from 'assets/images/bg_fire.gif'
import sushibg from 'assets/images/bg-sushi.jpg'
import { CardHeadTitle } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { ApproveStakingNfts } from 'pages/NftBattlesPage/ApproveStakingNfts'

const CardContainer = styled(Card)`
  flex-grow: 1;
  width: 100%;
  padding: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     min-height: 250px;
     padding: 15px 16px;
     min-width: initial;
     width: 100%;
     margin: 0;
  `};
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  background: transparent;
  letter-spacing: 0.75px;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-left: 15px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 14px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 22px;
  `};
`

const BoxListNFTs = styled.div`
  display: flex;
  overflow: visible !important;
`
const ContainerForStaking = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
`
const ContainerForBridge = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-wrap: wrap;
  align-items: center;

  display: grid;
  grid-template-columns: auto 4fr;
  grid-gap: 25px;
`

const BoxForBtns = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  min-height: 50px;
`

const FireAlarmBox = styled.div`
  width: 100%;
  padding: 25px;
  border-radius: 24px;
  padding: 50px 100px;
  background: url(${sushibg}) 50% 20% / cover no-repeat;
  image-rendering: pixelated;
  box-shadow: 5px 10px 20px rgb(0, 0, 0, 0.2);
`
const FireAlarmBox2 = styled.div`
  width: 100%;
  padding: 25px;
  border-radius: 24px;
  padding: 50px 100px;
  background: url(${firebg}) 50% 80% / cover no-repeat;
  image-rendering: pixelated;
  box-shadow: 5px 10px 20px rgb(0, 0, 0, 0.2);
`
const FireHeadTitle = styled(CardHeadTitle)`
  font-family: Uniform Pro;
  letter-spacing: 0.75px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 900;
  text-transform: initial;
  justify-content: center;
  text-align: center;
  margin-bottom: 25px;
`
const FireTextLine = styled.div`
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  text-shadow: 5px 10px 20px rgb(0, 0, 0, 0.2);
  text-transform: initial;
  justify-content: center;
  text-align: center;
`
const BoxForBtn = styled.div`
  margin-top: 25px;
  display: grid;
  justify-content: center;
  grid-gap: 25px;
  grid-template-columns: auto auto;
`

export function useUnstakePossibilityFarmNfts(tokenId: any, nftContractAddress: string | undefined) {
  const nftPoolContract = useZooDaoNFTStakingPool()
  const [canUnstake, setCanUnstake] = useState(false)

  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [nftContractAddress, tokenId], [nftContractAddress, tokenId])
  const target = useSingleCallResult(nftPoolContract, 'ownerOf', deps)?.result?.[0]

  useEffect(() => {
    setCanUnstake(target === account)
  }, [target, account])

  return canUnstake
}

interface IProps {
  selectedNftAddress: string
  setSelectedNftAddress: (a: string) => void
}

export function NftStakingInner({ selectedNftAddress, setSelectedNftAddress }: IProps) {
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const [tokenId, setTokenId] = useState<string | undefined>()

  const nftContract = useERC721Contract(selectedNftAddress)

  const nftPoolContract = useZooDaoNFTStakingPool()

  const baseCurrency = useNftToken(selectedNftAddress)

  const onFieldAInput = (v: string) => {
    if (v) {
      setTokenId(v)
    }
  }

  const onCurrencySelect = (v: Currency) => {
    if (v) {
      // @ts-ignore
      setSelectedNftAddress(v.address)
    }
  }

  const canUnstake = useUnstakePossibilityFarmNfts(tokenId, nftContract?.address)

  // check whether the user has approved the router on the tokens
  const { onUnstake, pending: pendingUnstaking } = useUnstake(selectedNftAddress, tokenId)

  const Buttons = () =>
    !account ? (
      <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
        <Trans>Connect Wallet</Trans>
      </ButtonLight>
    ) : (
      <BoxForBtns>
        {nftPoolContract && (
          <ApproveStakingNfts nftAddress={selectedNftAddress} contract={nftPoolContract} tokenId={tokenId} />
        )}

        {canUnstake && (
          <ButtonBoost onClick={onUnstake} disabled={pendingUnstaking}>
            {pendingUnstaking ? (
              <Dots>
                <Trans>Unstaking</Trans>
              </Dots>
            ) : (
              <Trans>Unstake</Trans>
            )}
          </ButtonBoost>
        )}
      </BoxForBtns>
    )

  const isParticipated = useNftInfo()
  const history = useHistory()
  const openCatalogue = () => {
    window.open('https://zoodao.com/catalogue', '_blank')
  }
  return (
    <ContainerForStaking>
      {isParticipated ? (
        <>
          <FireAlarmBox2>
            <FireHeadTitle>NFT Rewards</FireHeadTitle>
            <FireTextLine>
              Unlock ZooDAO Fire Cards by staking
              <br></br>
              any NFT in the Staking Pool.
            </FireTextLine>
            <BoxForBtn>
              <ButtonOrangeStaking onClick={openCatalogue}>
                <Trans>Catalogue</Trans>
              </ButtonOrangeStaking>
            </BoxForBtn>
          </FireAlarmBox2>
          <CardContainer>
            <Header>
              <CardHeadTitle>
                <Trans>Stake NFTs</Trans>
              </CardHeadTitle>
            </Header>
            <CurrencyInputTemplate
              as={CurrencySearchTemplate}
              value={tokenId ? `${tokenId}` : ''}
              showCommonBases={false}
              onUserInput={onFieldAInput}
              showMaxButton={false}
              currency={baseCurrency}
              id="add-liquidity-input-tokena"
              onCurrencySelect={onCurrencySelect}
              hideBalance
              placeholder={'Paste NFT ID here'}
              tokenName={StyledTokenName}
            />
            <Buttons />
          </CardContainer>
          <BoxListNFTs>
            {nftPoolContract && (
              <SuggestedNfts
                contract={nftPoolContract}
                onSelect={(address, tokenId) => {
                  setSelectedNftAddress(address)
                  setTokenId(tokenId)
                }}
              />
            )}
          </BoxListNFTs>

          <NftsStaked />
        </>
      ) : (
        <CannotStake warningMessage={<Trans>YOU CANNOT STAKE YOUR NFT BEFORE FARM</Trans>}>
          <FireAlarmBox>
            <FireHeadTitle>Please stake SLP tokens before NFT</FireHeadTitle>
            <FireTextLine>
              Stake your SLP tokens and any NFT in the Staking Pool
              <br></br>
              to receive ZOO rewards together with ZooDAO Fire Cards.
            </FireTextLine>
            <BoxForBtn>
              <ButtonPurpleFarm onClick={() => history.push(Paths.FARMING)}>
                <Trans>Sushi Farming</Trans>
              </ButtonPurpleFarm>
            </BoxForBtn>
          </FireAlarmBox>
        </CannotStake>
      )}

      <StakingPoolInfo />
    </ContainerForStaking>
  )
}

export interface IBridgeNfts {
  onSelectAddress: (a: string | undefined) => void
  onSelectTokenId: (a: string | undefined) => void
  tokenId: string
  token: string
}

export function NftStakingInnerBridge({ token, onSelectAddress, onSelectTokenId, tokenId }: IBridgeNfts) {
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const baseCurrency = useNftToken(token)

  const onCurrencySelect = (v: Currency) => {
    if (v) {
      // @ts-ignore
      onSelectAddress(v.address)
    }
  }
  const Buttons = () =>
    !account ? (
      <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'} marginTop={24}>
        <Trans>Connect Wallet</Trans>
      </ButtonLight>
    ) : null

  return (
    <ContainerForBridge>
      <CardContainer>
        <CurrencyInputTemplate
          as={CurrencySearchTemplate}
          value={tokenId ? `${tokenId}` : ''}
          showCommonBases={false}
          onUserInput={onSelectTokenId}
          showMaxButton={false}
          currency={baseCurrency}
          id="nft-id-input"
          onCurrencySelect={onCurrencySelect}
          hideBalance
          placeholder={'Paste NFT ID here'}
          tokenName={StyledTokenName}
        />
        <Buttons />
      </CardContainer>
    </ContainerForBridge>
  )
}

const CannoStakeContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 18px;
`

export const CannotStake = ({ children }: { children?: any; warningMessage: any }) => {
  return (
    <>
      {/* {warningMessage && <Confirmation>{warningMessage}</Confirmation>} */}
      <CannoStakeContainer>
        <YieldSuggestion>{children}</YieldSuggestion>
      </CannoStakeContainer>
    </>
  )
}
