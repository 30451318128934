import { useState, useEffect, useMemo, useCallback } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useBlockNumber } from '../../../state/application/hooks'
import { getSourceByNetwork, useBattleStakerContract } from '../../../constants/zoodao'
import { FarmStakingNftOverview, StakingNftOverview, useCDNUrl } from '../../NftBattlesPageV2/cards/NftOverviewCard'

import logoCryptokitties from './../../../assets/images/logo-co/logo-crypto-kitties.svg'
import logoCryptopunks from './../../../assets/images/logo-co/logo-cryptopunks.png'
import logoCoolCats from './../../../assets/images/logo-co/logo-coolcats.png'
import logoHashmasks from './../../../assets/images/logo-co/logo-hashmasks.png'
import logoMeebits from './../../../assets/images/logo-co/logo-meebits.png'
import logoPolymorphs from './../../../assets/images/logo-co/logo-polymorphs.png'
import logoPudgyPenguins from './../../../assets/images/logo-co/logo-pp.png'
import logoBoredApesYachtClub from './../../../assets/images/logo-co/logo-bayc.png'
import logoDeviousDemonDudes from './../../../assets/images/logo-co/logo-ddd.png'
import logoTheAlienBoy from './../../../assets/images/logo-co/logo-alienboy.png'
import logoLuchadores from './../../../assets/images/logo-co/logo-luchadores.png'
import logoFLUF from './../../../assets/images/logo-co/logo-fluf.png'
import logoMetheors from './../../../assets/images/logo-co/logo-metheors.png'
import logoMetatravelers from './../../../assets/images/logo-co/logo-metatravelers.png'
import logoElven from './../../../assets/images/logo-co/logo-elven.png'
import logoMooncats from './../../../assets/images/logo-co/logo-mooncats.png'
import logoRandoMice from './../../../assets/images/logo-co/logo-randomice.png'
import logoRomanPunks from './../../../assets/images/logo-co/logo-romanpunks.png'
import logoLobbyLobsters from './../../../assets/images/logo-co/logo-ll.png'
import logoPixaWizards from './../../../assets/images/logo-co/logo-pixelwizards.png'
import logoCupcats from './../../../assets/images/logo-co/logo-cupcats.png'
import logoHOTM from './../../../assets/images/logo-co/logo-humans.png'
import logoPurrEvil from './../../../assets/images/logo-co/logo-purrevil.png'
import logoHolyCows from './../../../assets/images/logo-co/logo-holycows.png'
import logoSurreals from './../../../assets/images/logo-co/logo-surreals.png'
import logoDivineAnarchy from './../../../assets/images/logo-co/logo-da.png'
import useENSAddress from 'hooks/useENSAddress'
import { useUnstake } from '../hooks'
import { ButtonClaim, ButtonUnstake } from 'components/Button'
import { Dots } from 'pages/Pool/styleds'
import { useUnstakePossibilityFarmNfts } from '../NftStaking'
import { SupportedChainId } from 'constants/chainsinfo'
import { useSuggestedNFTSCANNfts } from 'hooks/nft-scan'
import { IBattleStakingTabsVariant, StakingTabs } from 'pages/VePie/Panels/VeModelTabs'
import { useJackpots, useStakerPositions } from 'hooks/gql'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import { BATTLE_STAGES, useBattleStage, useBattleStakerPositionInfo } from 'pages/NftBattlesPage/hooks'
import { useUnstakeContract } from 'components/UnstakeBattleNft/UnstakeBattleNft'
import { NoNftsEligble, NoNftsStaked } from 'pages/NftBattlesPageV2/NoNfts'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'
import { InJackpotsIcon } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { Contract } from 'ethers'
import { MouseoverTooltip } from 'components/Tooltip'
import { useNftPartnersLogos } from 'pages/VePie/useNftPartnersLogos'

export const SupportedNftsTypes = {
  CRYPTOKITTIES: 'CRYPTOKITTIES',
  CRYPTOPUNKS: 'CRYPTOPUNKS',
  COOL_CATS: 'COOL_CATS',
  HASHMASKS: 'HASHMASKS',
  MEEBITS: 'MEEBITS',
  POLYMORHS: 'POLYMORHS',
  PUDGY_PENGUINS: 'PUDGY_PENGUINS',
  BORED_APES: 'BORED_APES',
  DEVIOUS_DEMON_DUDES: 'DEVIOUS_DEMON_DUDES',
  THE_ALIEN_BOY: 'THE_ALIEN_BOY',
  LUCHADORES: 'LUCHADORES',
  FLUF: 'FLUF',
  METHEORS: 'METHEORS',
  METATRAVELERS: 'METATRAVELERS',
  ELVEN: 'ELVEN',
  MOONCATS: 'MOONCATS',
  RANDOMICE: 'RANDOMICE',
  ROMAN_PUNKS: 'ROMAN_PUNKS',
  LOBBY_LOBSTERS: 'LOBBY_LOBSTERS',
  PIXAWIZARDS: 'PIXAWIZARDS',
  CUPCATS: 'CUPCATS',
  HOTM: 'HOTM',
  PURR_EVIL: 'PURR_EVIL',
  HOLY_COWS: 'HOLY_COWS',
  SURREALS: 'SURREALS',
  DIVINE_ANARCHY: 'DIVINE_ANARCHY',
  // THE_SANDBOX: 'THE_SANDBOX',
  // SUPERFARM: 'SUPERFARM',
}

export const SupportedNftsLabels: { [name: string]: string } = {
  CRYPTOKITTIES: 'CryptoKitties',
  CRYPTOPUNKS: 'Cryptopunks',
  COOL_CATS: 'Cool Cats',
  HASHMASKS: 'Hashmasks',
  MEEBITS: 'Meebits',
  POLYMORHS: 'Polymorphs',
  PUDGY_PENGUINS: 'Pudgy Penguins',
  BORED_APES: 'Bored Ape Yacht Club',
  DEVIOUS_DEMON_DUDES: 'Devious Demon Dudes',
  THE_ALIEN_BOY: 'The Alien Boy',
  LUCHADORES: 'Luchadores',
  FLUF: 'FLUF World',
  METHEORS: 'Metheors',
  METATRAVELERS: 'Metatravelers',
  ELVEN: 'Elven',
  MOONCATS: 'Mooncats',
  RANDOMICE: 'RandoMice',
  ROMAN_PUNKS: 'RomanPunks',
  LOBBY_LOBSTERS: 'Lobby Lobsters',
  PIXAWIZARDS: 'PixaWizards',
  CUPCATS: 'Cupcats',
  HOTM: 'Humans of the Metaverse',
  PURR_EVIL: 'Purr Evil',
  HOLY_COWS: 'Holy Cows',
  SURREALS: 'Surreals',
  DIVINE_ANARCHY: 'Divine Anarchy',
  // THE_SANDBOX: 'The Sandbox',
  // SUPERFARM: 'Superfarm',
}

export const SupportedNftsImages = {
  [SupportedNftsTypes.CRYPTOKITTIES]: logoCryptokitties,
  [SupportedNftsTypes.CRYPTOPUNKS]: logoCryptopunks,
  [SupportedNftsTypes.COOL_CATS]: logoCoolCats,
  [SupportedNftsTypes.HASHMASKS]: logoHashmasks,
  [SupportedNftsTypes.MEEBITS]: logoMeebits,
  [SupportedNftsTypes.POLYMORHS]: logoPolymorphs,
  [SupportedNftsTypes.PUDGY_PENGUINS]: logoPudgyPenguins,
  [SupportedNftsTypes.BORED_APES]: logoBoredApesYachtClub,
  [SupportedNftsTypes.DEVIOUS_DEMON_DUDES]: logoDeviousDemonDudes,
  [SupportedNftsTypes.THE_ALIEN_BOY]: logoTheAlienBoy,
  [SupportedNftsTypes.LUCHADORES]: logoLuchadores,
  [SupportedNftsTypes.FLUF]: logoFLUF,
  [SupportedNftsTypes.METHEORS]: logoMetheors,
  [SupportedNftsTypes.METATRAVELERS]: logoMetatravelers,
  [SupportedNftsTypes.ELVEN]: logoElven,
  [SupportedNftsTypes.MOONCATS]: logoMooncats,
  [SupportedNftsTypes.RANDOMICE]: logoRandoMice,
  [SupportedNftsTypes.ROMAN_PUNKS]: logoRomanPunks,
  [SupportedNftsTypes.LOBBY_LOBSTERS]: logoLobbyLobsters,
  [SupportedNftsTypes.PIXAWIZARDS]: logoPixaWizards,
  [SupportedNftsTypes.CUPCATS]: logoCupcats,
  [SupportedNftsTypes.HOTM]: logoHOTM,
  [SupportedNftsTypes.PURR_EVIL]: logoPurrEvil,
  [SupportedNftsTypes.HOLY_COWS]: logoHolyCows,
  [SupportedNftsTypes.SURREALS]: logoSurreals,
  [SupportedNftsTypes.DIVINE_ANARCHY]: logoDivineAnarchy,
  // [SupportedNftsTypes.THE_SANDBOX]: sandboxSvg,
  // [SupportedNftsTypes.SUPERFARM]: superfarmSvg,
}

export const useSupportedNfts = () => {
  const { chainId } = useActiveWeb3React()

  const suggestedNfts = useMemo(() => {
    return {
      [SupportedNftsTypes.CRYPTOKITTIES]: getSourceByNetwork(chainId, '0x06012c8cf97bead5deae237070f9587f8e7a266d'),
      [SupportedNftsTypes.CRYPTOPUNKS]: getSourceByNetwork(chainId, '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb'),
      [SupportedNftsTypes.COOL_CATS]: getSourceByNetwork(chainId, '0x114f1388fab456c4ba31b1850b244eedcd024136'),
      [SupportedNftsTypes.HASHMASKS]: getSourceByNetwork(chainId, '0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e'),
      [SupportedNftsTypes.MEEBITS]: getSourceByNetwork(chainId, '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7'),
      [SupportedNftsTypes.POLYMORHS]: getSourceByNetwork(chainId, '0x1cbb182322aee8ce9f4f1f98d7460173ee30af1f'),
      [SupportedNftsTypes.PUDGY_PENGUINS]: getSourceByNetwork(chainId, '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'),
      [SupportedNftsTypes.BORED_APES]: getSourceByNetwork(chainId, '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'),
      [SupportedNftsTypes.DEVIOUS_DEMON_DUDES]: getSourceByNetwork(
        chainId,
        '0x6Ac645FdA81299c8eB0da31fc773953752112E82'
      ),
      [SupportedNftsTypes.THE_ALIEN_BOY]: getSourceByNetwork(chainId, '0x4581649aF66BCCAeE81eebaE3DDc0511FE4C5312'),
      [SupportedNftsTypes.LUCHADORES]: getSourceByNetwork(chainId, '0x8b4616926705Fb61E9C4eeAc07cd946a5D4b0760'),
      [SupportedNftsTypes.FLUF]: getSourceByNetwork(chainId, '0xCcc441ac31f02cD96C153DB6fd5Fe0a2F4e6A68d'),
      [SupportedNftsTypes.METHEORS]: getSourceByNetwork(chainId, '0xf172057796C0a46174441C63156cDB4db6021fE8'),
      [SupportedNftsTypes.METATRAVELERS]: getSourceByNetwork(chainId, '0x5372f926b34Be60aC1436372107C3eE8c6e056E5'),
      [SupportedNftsTypes.ELVEN]: getSourceByNetwork(chainId, '0x3E2803e5d68dFCfacfbC9Be836E24d24FCA674B8'),
      [SupportedNftsTypes.MOONCATS]: getSourceByNetwork(chainId, '0xc3f733ca98E0daD0386979Eb96fb1722A1A05E69'),
      [SupportedNftsTypes.RANDOMICE]: getSourceByNetwork(chainId, '0x091561EB5B0fc1dA32A0F8842512A994B0Fdc37B'),
      [SupportedNftsTypes.ROMAN_PUNKS]: getSourceByNetwork(chainId, '0x495f947276749Ce646f68AC8c248420045cb7b5e'),
      [SupportedNftsTypes.LOBBY_LOBSTERS]: getSourceByNetwork(chainId, '0xc0Cb81c1F89Ab0873653f67EEa42652F13CD8416'),
      [SupportedNftsTypes.PIXAWIZARDS]: getSourceByNetwork(chainId, '0xc6b0B290176AaaB958441Dcb0c64ad057cBc39a0'),
      [SupportedNftsTypes.CUPCATS]: getSourceByNetwork(chainId, '0x8Cd8155e1af6AD31dd9Eec2cEd37e04145aCFCb3'),
      [SupportedNftsTypes.HOTM]: getSourceByNetwork(chainId, '0x8a9ECe9d8806eB0CdE56Ac89cCB23a36E2C718cf'),
      [SupportedNftsTypes.PURR_EVIL]: getSourceByNetwork(chainId, '0x495f947276749Ce646f68AC8c248420045cb7b5e'),
      [SupportedNftsTypes.HOLY_COWS]: getSourceByNetwork(chainId, '0xc196aE96170C58Eb7B78c917298064CE7192F046'),
      [SupportedNftsTypes.SURREALS]: getSourceByNetwork(chainId, '0xa406489360A47Af2C74fc1004316A64e469646A5'),
      [SupportedNftsTypes.DIVINE_ANARCHY]: getSourceByNetwork(chainId, '0xc631164B6CB1340B5123c9162f8558c866dE1926'),
    }
  }, [chainId])

  const byAddress = useMemo(() => {
    return Object.keys(suggestedNfts).reduce((acc, key) => {
      const addr = suggestedNfts[key]
      if (addr) {
        acc[addr] = key
      }
      return acc
    }, {} as any)
  }, [suggestedNfts])

  return {
    suggestedNfts,
    byAddress,
  }
}

export const useSuggestedOpenseaNfts = () => {
  const { account, chainId } = useActiveWeb3React() //
  const [nfts, setNfts] = useState<INftInfo[]>([])

  const block = useBlockNumber()

  const { address, loading } = useENSAddress(account)
  useEffect(() => {
    const fetchNfts = async () => {
      const URL = `https://api.opensea.io/api/v1/assets?owner=${address}&order_direction=desc&offset=0&limit=50`

      try {
        fetch(URL)
          .then((result) => result.json())
          .then((data: any) => {
            setNfts(data.assets)
          })
          .catch(() => {
            console.log('OpenSea error')
          })
      } catch (e) {
        console.log(e)
      }
    }

    if (!loading && chainId === SupportedChainId.MAINNET) fetchNfts()
  }, [block, account, loading, address, chainId])

  return nfts
}

export interface INftInfo {
  contract: {
    image_url: string
    address: string
  }
  id: string
  image_url: string
  token_id: string
  chain_id: SupportedChainId
}

interface IProps {
  onSelect: (address: string, tokenId: string) => void
  contract: Contract
}
const BattleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: visible !important;
  overflow-y: auto;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-template-columns: 1fr;
  `};
`

const ZooLoaderStyled = styled(ZooLoader)`
  width: 100%;
  height: 200px;
  margin: 24px 0;
`

const BattleContainerStyled = styled(BattleContainer)`
  max-height: 800px;
  overflow-y: auto !important;
  margin-top: 12px;
  padding: 12px 25px 25px 25px;
`

export const SuggestedBattlesNfts = ({ contract, onSelect }: IProps) => {
  const { nfts, loading: loadingN } = useSuggestedNFTSCANNfts()

  const [tab, setTab] = useState<IBattleStakingTabsVariant>(IBattleStakingTabsVariant.MyNfts)

  const changeTab = useCallback(
    (value: IBattleStakingTabsVariant) => {
      setTab(value)
    },
    [setTab]
  )
  const { account } = useActiveWeb3React()

  const { positions: staked, loading: loadingP } = useStakerPositions(undefined, account)

  const { jackpots: jackpotsA, loading: loadingA } = useJackpots(account, IPanelJackpotsVariant.JackpotA)

  const amount = useMemo(() => {
    if (tab === IBattleStakingTabsVariant.MyNfts) {
      return nfts.length
    } else if (tab === IBattleStakingTabsVariant.Staked) {
      return staked.length + jackpotsA.length
    } else {
      throw Error('Not existing projects amount')
    }
  }, [staked, tab, nfts, jackpotsA])

  return (
    <>
      <StakingTabs type={tab} onChange={changeTab} amount={amount || 0} />
      <ZooLoaderStyled loading={(nfts.length === 0 && loadingN) || loadingP || loadingA}>
        <BattleContainerStyled>
          {tab === IBattleStakingTabsVariant.MyNfts && (
            <>
              {nfts.length === 0 && <NoNftsEligble />}
              {nfts.map((item: INftInfo) => {
                const address = item.contract.address
                const tokenId = item.token_id

                return (
                  <StakingNftOverview
                    key={item.id}
                    onClick={() => {
                      onSelect(address, tokenId)
                    }}
                    contract={contract}
                    token={address}
                    tokenId={tokenId}
                    collectionThumbnail={item.contract.image_url}
                    tokenImage={item.image_url}
                    isStakeVisible={true}
                  />
                )
              })}
            </>
          )}
          {tab === IBattleStakingTabsVariant.Staked && (
            <>
              {staked.length === 0 && jackpotsA.length === 0 && <NoNftsStaked />}
              {jackpotsA.map((item: any) => {
                return (
                  <StakedItemWrapper
                    contract={contract}
                    key={item.positionId}
                    stakingPositionId={item.positionId}
                    isJackpot={true}
                  />
                )
              })}
              {staked.map((item: any) => {
                return (
                  <StakedItemWrapper
                    contract={contract}
                    key={item.stakingPositionId}
                    stakingPositionId={item.stakingPositionId}
                  />
                )
              })}
            </>
          )}
        </BattleContainerStyled>
      </ZooLoaderStyled>
    </>
  )
}

const InJackpotsIconStyled = styled(InJackpotsIcon)`
  margin-top: 25px;
`

const StakedItemWrapper = ({
  stakingPositionId,
  isJackpot = false,
  contract,
}: {
  contract: Contract
  stakingPositionId: string
  isJackpot?: boolean
}) => {
  const { positionInfo, loading } = useBattleStakerPositionInfo(stakingPositionId)

  const { id, token } = positionInfo || {}

  const collectionThumbnail = useNftPartnersLogos(token)

  const tokenImage = useCDNUrl(token, id)

  return (
    <StakingNftOverview
      contract={contract}
      disabled={loading}
      token={token}
      tokenId={id}
      collectionThumbnail={collectionThumbnail}
      tokenImage={tokenImage}
      isStakeVisible={false}
    >
      {isJackpot ? <InJackpotsIconStyled /> : <UnStakeBtn disabled={loading} stakingPositionId={stakingPositionId} />}
    </StakingNftOverview>
  )
}

const SuggestedNfts = ({ onSelect, contract }: IProps) => {
  const { nfts: userNfts } = useSuggestedNFTSCANNfts()

  if (!userNfts || userNfts.length === 0) {
    return null
  }

  return (
    <BattleContainer>
      {userNfts.map((item: any) => {
        const address = item.contract.address
        const tokenId = item.token_id

        return (
          <StakingNftOverview
            contract={contract}
            key={item.id}
            onClick={() => onSelect(address, tokenId)}
            token={address}
            tokenId={tokenId}
            collectionThumbnail={item.collectionThumbnail}
            tokenImage={item.tokenImage}
            isStakeVisible={true}
          />
        )
      })}
    </BattleContainer>
  )
}

export const FarmNfts = ({ list, canUnstake = false }: { list: any[]; canUnstake: boolean }) => {
  if (!list || list.length === 0) {
    return null
  }

  return (
    <BattleContainer>
      {list.map((item: any) => {
        const { id, token } = item.args
        return <Overview key={`${token}_${id.toString()}`} item={item} canUnstake={canUnstake} />
      })}
    </BattleContainer>
  )
}

const ButtonUnstakeStyled = styled(ButtonUnstake)`
  width: 100%;
  margin-top: 16px;
`

const Overview = ({ item, canUnstake }: { item: any; canUnstake: boolean }) => {
  const { id, token } = item.args
  const { onUnstake, pending: pendingUnstaking } = useUnstake(token, id.toString())

  const possibleToUnstake = useUnstakePossibilityFarmNfts(id, token)

  return (
    <FarmStakingNftOverview key={`${token}_${id.toString()}`} tokenId={id} token={token}>
      {canUnstake ||
        (possibleToUnstake && (
          <ButtonUnstakeStyled onClick={onUnstake}>
            {pendingUnstaking ? (
              <Dots>
                <Trans>Unstaking</Trans>
              </Dots>
            ) : (
              <Trans>Unstake</Trans>
            )}
          </ButtonUnstakeStyled>
        ))}
    </FarmStakingNftOverview>
  )
}

const BoxBtnForStakingPage = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`

const ButtonStakeFullSize = styled(ButtonClaim)`
  width: 100%;
  font-size: 15px;
`

export const UnStakeBtn = ({ stakingPositionId, disabled }: { stakingPositionId: string; disabled?: boolean }) => {
  const stage = useBattleStage()

  const nftPoolContract = useBattleStakerContract()
  const { onUnstake, pending: pendingUnstaking } = useUnstakeContract(nftPoolContract, stakingPositionId)

  return (
    <BoxBtnForStakingPage>
      <MouseoverTooltip
        placement="top"
        text={stage === BATTLE_STAGES.FIRST ? null : <Trans>Unstaking is possible during Stage 1</Trans>}
      >
        <ButtonStakeFullSize
          onClick={onUnstake}
          disabled={stage !== BATTLE_STAGES.FIRST || pendingUnstaking || disabled}
        >
          <Trans>Unstake</Trans>
        </ButtonStakeFullSize>
      </MouseoverTooltip>
    </BoxBtnForStakingPage>
  )
}
export default SuggestedNfts
