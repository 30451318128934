import styled from 'styled-components/macro'
import { Flex } from 'rebass/styled-components'
import { ButtonGradient, ButtonBurgundy } from '../../../components/Button'
import Dropdown from 'react-dropdown'
import IconArrowDown from '../../../assets/svg/angle-down-solid.svg'
import IconArrowDownTransform from '../../../assets/svg/angle-up-solid.svg'
import LogoIcon from 'pages/NftBattlesPageV2/cards/LogoIcon/LogoIcon'

export const LogoCoin = styled(LogoIcon)`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 30px;
  height: 30px;
  `}
`

export const InputLabel = styled(Flex)`
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
`

export const TxtBalance = styled.div`
  color: ${({ theme }) => theme.text1};
`

export const LockButton = styled(ButtonGradient)`
  width: 180px;
`

export const PickerLabel = styled(Flex).attrs({
  alignItems: 'center',
})``

export const MaxButtonRed = styled(ButtonBurgundy)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: none;
  width: 100px;
  height: 48px;
  padding: 0;
  border-radius: 24px;
  font-weight: 700;
`

export const CalendarIcon = styled.div`
  font-family: 'Font Awesome 6 Pro';
  margin-right: 25px;
`

export const Picker = styled(Dropdown)`
  margin-top: 25px;

  .Dropdown-control {
    background-color: #33334b;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 25px;
    border: 0px;
    border-radius: 25px;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    min-width: 250px;
    text-align: center;

    transition: background-color 0.3s;
    font-family: Uniform Pro;
    font-weight: 600;
    line-height: 18px;
    font-size: 18px;
    color: #fff;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 12px 40px 12px 14px;
      font-size: 16px;
      line-height: 16px;
    `};

    &:hover {
      color: ${({ theme }) => theme.text1};
    }
  }
  .Dropdown-arrow {
    top: 15px;
    right: 25px;
    background: url(${IconArrowDown}) 50% 50% / cover no-repeat;
    color: #fff;
    height: 18px;
    width: 18px;
    border: 0 !important;

    .is-open .Dropdown-arrow {
      background: url(${IconArrowDownTransform}) 50% 50% / cover no-repeat !important;
    }
  }

  &.is-open .Dropdown-arrow {
    background: url(${IconArrowDownTransform}) 50% 50% / cover no-repeat !important;
  }

  .Dropdown-menu {
    margin-top: 10px;
    font-size: 25px;
    line-height: 30px;
    background-color: #4c4c66;
    border: none;
    cursor: pointer;
    top: 105%;
    border-radius: 24px;
    font-family: Uniform Pro;
    font-weight: 600;
    line-height: 18px;
    font-size: 18px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 25%);
    color: #fff;

    transition: all 0.5s ease-in-out;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      font-size: 16px;
      line-height: 16px;
    `};
  }

  .Dropdown-option {
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    color: #fff;
    background-color: #27273e;
    color: ${({ theme }) => theme.text2};
    &:hover {
      background: #33334b;
      color: #fff;
    }
  }

  .Dropdown-option.is-selected {
    background: #33334b;
    color: #fff;
    ::after {
      height: 10px;
      width: 10px;
      background: #2fd9c6;
      border-radius: 10px;
      content: '';
      position: absolute;
      right: 30px;
    }
  }

  .Dropdown-placeholder {
    text-align: left;
  }
`
