import React, { useState } from 'react'
import { Picker } from './styles'

import IconFRAX from '../../../assets/images/frax-icon.png'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { LogoCoin, PickerLabel } from './styles'

const LogoCoinMini = styled(LogoCoin)`
  width: 30px;
  height: 30px;
`

export interface IOption {
  value: string
  icon: any
}

const useUsdOptions = (options: IOption[]) => {
  return useMemo(() => {
    return options.map(({ value, icon }) => ({
      value: `${value}`,
      label: (
        <PickerLabel>
          <LogoCoinMini src={icon} />
          {value}
        </PickerLabel>
      ),
    }))
  }, [options])
}

const USD_OPTIONS: IOption[] = [
  {
    value: 'Claim in FRAX',
    icon: IconFRAX,
  },
]

export const UsdOptionPicker = ({
  options: defaultOptions,
  className,
}: {
  options?: IOption[]
  className?: string
}) => {
  const options = useUsdOptions(defaultOptions || USD_OPTIONS)
  const [duration, setDuration] = useState(() => (defaultOptions ? defaultOptions[0] : USD_OPTIONS[0]).value)

  return (
    <Picker
      className={className}
      options={options}
      value={duration}
      onChange={({ value }: any) => {
        setDuration(value)
      }}
    />
  )
}
