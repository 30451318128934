import React, { useEffect, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { shortenAddress } from '../../../../utils'
import { EtherscanIcon } from '../../CardMiniForNftInfo'
import { Container, NFTAddress, VoteButton, InfoValue, InfoTitle, Info, LogoIconWrapper } from './styles'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { SupportedChainId } from 'constants/chainsinfo'
import { useExpectedPoolWeight } from 'pages/VePie/hooks'
import { Dots } from 'pages/Pool/styleds'
import { useCollectionInfo } from '../../../../hooks/zoodao/useCollectionInfo'
import { formatDecimal } from 'utils/numberWithCommas'
import { BigNumber } from 'ethers'
import { getWeightPercent } from 'pages/VePie/Weights/Weights'
import { VePieRewards } from './VePieRewards'
import { DurationExplanation } from 'pages/VePie/Panels/VotingPanel'
import { useIsOwner } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import { useVeModelContract } from 'constants/zoodao'
import { AlarmText } from '../JackpotsNFTOverview/styles'
import { VeModelPositionDetails } from 'pages/VePie/NFTList'
import { useSingleCallResult } from 'state/multicall/hooks'
import { NftNameTemplate } from '../NftOverviewCard'
import { NftProjectLogo } from '../NftProjectLogo'

export const isEqualVePositions = (selected: VeModelPositionDetails | null, a: VeModelPositionDetails) =>
  selected && selected.collection === a.collection && selected.positionId === a.positionId

export const getVoteKey = (payout_address: string, tokenId?: string | BigNumber) =>
  tokenId ? `${payout_address}_${tokenId?.toString()}` : payout_address

export const useVotePositionInfo = (pid: string | undefined) => {
  const contract = useVeModelContract()

  const deps = useMemo(() => [pid], [pid])
  const res = useSingleCallResult(contract, 'vePositions', deps)

  return useMemo(() => {
    return {
      info: res?.result,
      loading: res?.loading,
    }
  }, [res])
}

const VePieVoteNFTOverview = ({
  className,
  item,
  selected,
  setSelected,
  totalWeight,
  accumulateWeight,
  color,
}: {
  color: string
  className?: string
  item: VeModelPositionDetails
  selected: VeModelPositionDetails | null
  setSelected: (p: VeModelPositionDetails) => void
  totalWeight: BigNumber
  accumulateWeight: (collection: string, value: BigNumber) => void
}) => {
  const { collection, positionId: tokenId } = item
  const { name, banner_image_url, payout_address } = useCollectionInfo(collection)

  const exporerLink = getExplorerLink(SupportedChainId.MAINNET, payout_address, ExplorerDataType.ADDRESS)

  const weight = useExpectedPoolWeight(collection)

  const contract = useVeModelContract()
  const isOwner = useIsOwner(tokenId, contract)

  useEffect(() => {
    if (!weight.isZero()) {
      const key = getVoteKey(payout_address, tokenId)

      accumulateWeight(key, weight)
    }
  }, [weight, accumulateWeight, payout_address, tokenId])

  const percent = useMemo(
    () => (totalWeight.isZero() ? 0 : +getWeightPercent(weight, totalWeight).toFixed(4)),
    [totalWeight, weight]
  )

  const { info, loading } = useVotePositionInfo(tokenId)

  return (
    <Container className={className} inactive={!isOwner} color={color}>
      <LogoIconWrapper src={banner_image_url} />

      <NftProjectLogo collection={collection} />

      <Flex flexDirection="column" px={25} pb={25} mt={20}>
        <NftNameTemplate name={name} />

        <Flex alignItems="center">
          <NFTAddress>{payout_address && shortenAddress(payout_address, 2)}</NFTAddress>
          <EtherscanIcon exporerLink={exporerLink} />
        </Flex>

        <Flex>
          <Info>
            <InfoValue>{(100 * percent).toFixed(2)}%</InfoValue>
            <InfoTitle>Weight</InfoTitle>
          </Info>

          <Info>
            <InfoValue>
              {loading || !info ? (
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              ) : (
                formatDecimal(info.zooLocked)
              )}
            </InfoValue>
            <InfoTitle>Locked veZOO</InfoTitle>
          </Info>
        </Flex>

        <VePieRewards percent={percent} />

        {info && (
          <Flex>
            <DurationExplanation info={info} />
          </Flex>
        )}

        {isOwner ? (
          <>
            {collection ? (
              <VoteButton onClick={() => setSelected(item)}>
                {selected && isEqualVePositions(selected, item) ? <Trans>Selected</Trans> : <Trans>Choose</Trans>}
              </VoteButton>
            ) : (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            )}
          </>
        ) : (
          <AlarmText style={{ marginTop: 25 }}>Unlocked</AlarmText>
        )}
      </Flex>
    </Container>
  )
}

export default styled(VePieVoteNFTOverview)``
