import { isSvg, useNftScanImages } from 'hooks/nft-scan'
import { useCallback, useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import IconTemplate from '../../../../assets/images/battles/zero-nft.png'
import { BigNumber } from 'ethers'

export const ImgParticipants = styled.img`
  border-radius: 24px;
  border-radius: 24px 24px 0 0;
  width: 100%;
`

interface UseAssetIconProps {
  icons?: (string | undefined)[]
}

interface UseAssetIconRes {
  icon: string | undefined
  handleImageError: () => void
}

export const useAssetIcon = ({ icons }: UseAssetIconProps): UseAssetIconRes => {
  const getNextAvailableIndex = useCallback(
    (index: number) => {
      if (!icons) {
        return 0
      }
      let result = index + 1
      while (result < icons.length && !icons[result]) {
        result = result + 1
      }
      return result
    },
    [icons]
  )

  const [iconIndex, setIconIndex] = useState(getNextAvailableIndex(-1))

  const icon = useMemo(() => {
    return icons && icons[iconIndex]
  }, [iconIndex, icons])

  const handleImageError = useCallback(() => {
    setIconIndex(getNextAvailableIndex(iconIndex))
  }, [getNextAvailableIndex, iconIndex])
  return {
    icon,
    handleImageError,
  }
}

const LogoIcon = ({
  src,
  el: El = ImgParticipants,
  asBg = false,
  handleError,
  ...rest
}: {
  src: string | string[]
  el?: any
  asBg?: boolean
  handleError?: (v: boolean) => void
}) => {
  const { icon, handleImageError } = useAssetIcon({ icons: Array.isArray(src) ? src : [src] })

  const isSvgIcon = icon && isSvg(icon)

  const handleErrorCallback = useCallback(() => {
    handleImageError()
    handleError && handleError(true)
  }, [handleError, handleImageError])

  if (isSvgIcon && icon) {
    return <El {...rest} src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`} />
  }

  if (asBg) {
    const bgImgStyles = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
    }
    if (icon) {
      return (
        <El
          {...rest}
          style={{
            background: `url(${icon})`,
            ...bgImgStyles,
          }}
          onError={handleErrorCallback}
        />
      )
    } else {
      return (
        <El
          {...rest}
          style={{
            background: `url(${IconTemplate})`,
            ...bgImgStyles,
          }}
        />
      )
    }
  }

  if (icon) {
    return <El {...rest} src={icon} onError={handleErrorCallback} />
  } else {
    return <El {...rest} src={IconTemplate} />
  }
}

export const LogoIconCDN = ({
  src,
  el: El = ImgParticipants,
  asBg = false,
  token,
  id,
  ...rest
}: {
  src: string | string[]
  el?: any
  asBg?: boolean
  token: string
  id: BigNumber
}) => {
  const [error, setError] = useState(false)

  return error ? (
    <ErrorCdnIcon el={El} asBg={asBg} {...rest} token={token} id={id} />
  ) : (
    <LogoIcon src={src} el={El} asBg={asBg} {...rest} handleError={setError} />
  )
}

const ErrorCdnIcon = ({
  el: El = ImgParticipants,
  asBg = false,
  token,
  id,
  ...rest
}: {
  el?: any
  asBg?: boolean
  token: string
  id: BigNumber
}) => {
  const { tokenImage } = useNftScanImages(token, id)

  return <LogoIcon src={tokenImage} el={El} asBg={asBg} {...rest} />
}

export default LogoIcon
