import Modal from 'components/Modal'
import { DaiReVoting } from 'components/VoteForBattle/VoteForBattle'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen, useRevotingBoostToggle } from 'state/application/hooks'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { ButtonBoost } from 'components/Button'
import { useBattleEpoch } from 'pages/NftBattlesPage/hooks'
import { useMemo } from 'react'

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.75px;
  color: ${({ theme }) => theme.grayscale};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 20px;
  line-height: 24px;
  `};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`

const ModalStyled = styled(Modal)`
  width: auto;
  max-width: 600px !important;
`

export const Arrow = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
`
const ButtonBoostStyled = styled(ButtonBoost)`
  margin-left: 16px;
`

const DaiReVotingStyled = styled(DaiReVoting)`
  margin-top: 16px;
`

export const DaiBoostBtn = ({ votingsByUser }: { votingsByUser: any[] }) => {
  const toggleWalletModal = useRevotingBoostToggle()
  const walletModalOpen = useModalOpen(ApplicationModal.BOOST_BY_REVOTING)

  const epoch = useBattleEpoch()

  const availableVotings = useMemo(() => {
    return votingsByUser.filter((i) => i.currentEpoch.toString() !== epoch.toString())
  }, [votingsByUser, epoch])

  if (availableVotings.length === 0) {
    return null
  }

  return (
    <>
      <ModalStyled isOpenFlag={walletModalOpen} onDismissHandler={toggleWalletModal} minHeight={false} maxHeight={600}>
        <Container>
          <Title>
            <Trans>Recompute positions from previous Seasons</Trans>
          </Title>
          {availableVotings.map((item) => {
            const vpId = item.votingPositionId.toString()
            const positionEpoch = item.currentEpoch.toString()

            if (positionEpoch === epoch.toString()) {
              return null
            }

            return <DaiReVotingStyled votingPositionId={vpId} key={vpId} />
          })}
        </Container>
      </ModalStyled>

      <ButtonBoostStyled onClick={toggleWalletModal} target="_blank">
        <Trans>
          Boost Votes
          <Arrow></Arrow>
        </Trans>
      </ButtonBoostStyled>
    </>
  )
}
