import { useCallback, useMemo } from 'react'
import { BACKEND_SERVER_HOST } from '../../constants/config'
import useSWR from 'swr'
import { useActiveWeb3React } from 'hooks/web3'
import { SupportedChainId } from 'constants/chainsinfo'

import { COMMON_BASES } from 'constants/routing'

const fetchAllSlugs = async (endpoint: string, chainId?: SupportedChainId) => {
  if (chainId === SupportedChainId.RINKEBY) {
    return TESTNET_MOCKS_RINKEBY
  }
  if (chainId === SupportedChainId.GOERLI) {
    return TESTNET_MOCKS_GOERLI
  }
  if (chainId === SupportedChainId.MOONBEAM_ALPHA) {
    return []
  }
  if (chainId === SupportedChainId.MOONBEAM) {
    return COMMON_BASES[chainId].map((item) => {
      return {
        id: item.wrapped.address,
        address: item.wrapped.address,
        slug: item.symbol,
        name: item.name,
      }
    })
  }
  const url = `${BACKEND_SERVER_HOST}${endpoint}/${chainId}`
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((response) => response.json())
}

const TESTNET_MOCKS_RINKEBY: IApiSlug[] = [
  { id: '0x49081f292c2516533f31708c4b9ac854bf9ce0a6', address: '0x49081f292c2516533f31708c4b9ac854bf9ce0a6' },
  { id: '0xBf282A14B9C572CeE0eef60f328496849B53a194', address: '0xBf282A14B9C572CeE0eef60f328496849B53a194' },
  { id: '0xf03CB04759C55A47cf5A88ddd7CA6AE2374845DB', address: '0xf03CB04759C55A47cf5A88ddd7CA6AE2374845DB' },
  { id: '0x8e89A140596D73573cCF99A012E11Ccb6b89Ea3d', address: '0x8e89A140596D73573cCF99A012E11Ccb6b89Ea3d' },
  { id: '0x816Ff6Aa5d0e4c8720505e6887A7EC03E48a10Ca', address: '0x816Ff6Aa5d0e4c8720505e6887A7EC03E48a10Ca' },
]

const TESTNET_MOCKS_GOERLI: IApiSlug[] = []

export interface IApiSlug {
  id: string
  slug?: string
  name?: string
  address: string
  url?: string
}

const useNftPartnersList = () => {
  const { chainId } = useActiveWeb3React()
  const loader = useCallback((endpoint: string) => fetchAllSlugs(endpoint, chainId), [chainId])
  const result = useSWR('partners', loader)

  return useMemo(() => {
    const { data, error } = result
    return {
      loading: !error && !data,
      allCollectionSlugs: (data || []) as IApiSlug[],
    }
  }, [result])
}

export { useNftPartnersList }
