import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PitchSvg from './pitch.svg'
import TelegramSvg from './telegram.svg'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { CardContent, CardLine } from '../NftBattlesPageV2/styleds'
import IconFRAX from '../../assets/images/frax-icon.png'
import IconZOO from '../../assets/svg/token.svg'
import { RenderDates } from '../NftBattlesPageV2/BattleClosing'
import { DEFAULT, getDates, ILeftDays, SECOND } from '../../components/EpochBanner/EpochBanner'
import { LinkToMax } from '../NftBattlesPageV2/CardMiniForNftInfo'
import { useChainSaleStablecoinBalance, useZooSaleBalanceTest } from '../../hooks/zoodao/zooToken'
import { useSaleZooCurrencies } from '../../hooks/useZooCurrencies'
import { fromWei } from '../../utils/fromWei'
import { ApproveCheckerSaleA } from '../NftBattlesPage/ApproveCheckerBattleArena'
import { Dots } from '../../components/swap/styleds'
import {
  ANGELS_PHASES,
  useRoundACrowdsaleDates,
  useRoundASaleData,
  useRoundAUnallocatedZoo,
} from '../../hooks/zoodao/pre-sale/community-round-a'
import { StyledRow, ContentStyled, TextConnect, Header } from '../../components/WarningWrapper/WarningWrapper'
import stripsBg from './../../assets/images/strips.svg'
import { formatDecimal } from 'utils/numberWithCommas'
import { NumericalInputStyled } from 'components/NumericalInput'
import { useRoundAClaim, useRoundBSale } from 'pages/NftBattlesPage/hooks'
import { Base, ButtonBoost, ButtonStake } from '../../components/Button'
import { Frax } from 'components/Sidebar/BuyBlock'
import { darken } from 'polished'
import { ZooTextGradient } from 'components/ZooText/ZooText'
import AccountSaleStatsA from './AccountSaleStatsA'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 16px;
  `}
`
const ContainerBottom = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  grid-template-columns: 1fr ;
  `}
`
const PreSaleStyledRow = styled(StyledRow)`
  padding: 50px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0
  `};
`
const PreSaleContentStyled = styled(ContentStyled)`
  padding: 35px 50px;
`
const PreSaleHeader = styled(Header)`
  margin-bottom: 24px;
  color: #007bff;
  user-select: none !important;
`
const PreSaleTextConnect = styled(TextConnect)`
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 15px !important;
  text-align: center;

  font-size: 18px !important;
  line-height: 26px !important;
  user-select: none !important;
`

const Left = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 16px;
  `}
`

const LeftBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 16px;
    grid-template-columns: 1fr;
  `}
`

const Right = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 16px;
  `}
`

const RightBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 16px;
  `}
`

const LogoCoin = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 38px;
    height: 38px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 22px;
    height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
`

const CardHead = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: 0.75px;
  display: flex;
  word-break: break-word;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 20px;
    line-height: 26px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 17px;
    line-height: 26px;
  `};
`

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 25px;
  align-self: center;
  min-height: 240px;
  height: 100%;
  flex-grow: 1;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: initial;
    min-height: 180px;
  `}
`
const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
`

const CardContentBox = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 34px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 22px;
    line-height: 24px;
  `}
`

const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  align-items: baseline;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
`

const Description = styled.div`
  margin-top: 5px;
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  line-height: 24px;
  width: 100%;
`

//https://t.me/JoshZooDAO
//https://docsend.com/view/fsapxk3ypj49hp6f

const BtnLink = styled(Base)`
  border: 1px solid #d9dbe9;
  border-radius: 50px;
  padding: 15px 30px;
  background: transparent;

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
`

const Img = styled.img`
  margin-right: 8px;
`

const SaleBtns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 28px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  grid-template-columns: 1fr;
    `};
`

const CommunityRoundA = () => {
  return (
    <>
      <Container>
        <Left>
          <PreSaleStyledRow>
            <PreSaleContentStyled>
              <>
                <PreSaleHeader>ZooDAO Community round A</PreSaleHeader>
                <PreSaleTextConnect>
                  <Trans>
                    The ZooDAO Community Round enables supporters of ZooDAO to purchase $ZOO tokens at a fixed rate
                    prior to launch.
                  </Trans>
                </PreSaleTextConnect>
                <PreSaleTextConnect>
                  <Trans>
                    These tokens will be locked for 6 months and then released over a 6-month vesting schedule.
                  </Trans>
                </PreSaleTextConnect>
                <PreSaleTextConnect style={{ fontWeight: 'bold' }}>
                  <Trans>Need GLMR for gas fees? Message us on Telegram! </Trans>
                </PreSaleTextConnect>

                <SaleBtns>
                  <BtnLink
                    onClick={() => window.open('https://dapp.zoodao.com/guides/ZooDAO_Pitch_Deck_v0.pdf', '_blank')}
                  >
                    <Img src={PitchSvg} />
                    <Trans>Pitch Deck</Trans>
                  </BtnLink>
                  <BtnLink onClick={() => window.open('https://t.me/JoshZooDAO', '_blank')}>
                    <Img src={TelegramSvg} />
                    <Trans>Get in touch</Trans>
                  </BtnLink>
                </SaleBtns>
              </>
            </PreSaleContentStyled>
          </PreSaleStyledRow>
        </Left>

        <Right>
          <LeftBottom>
            <ZooMax />
            <DaiMax />
          </LeftBottom>
          <LeftBottom>
            <CardRate />
            <Contributed />
          </LeftBottom>
        </Right>
      </Container>
      <ContainerBottom>
        <Left>
          <Claimable />
        </Left>
        <RightBottom>
          <Contribution />
        </RightBottom>
      </ContainerBottom>
    </>
  )
}
const LogoCoinRate = styled(LogoCoin)`
  margin: 5px 10px 8px;
  width: 25px;
  height: 25px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 25px;
    height: 25px;
  `};
`

const LineRate = styled(Line)`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 17px;
    line-height: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 32px;
  `};
`

const FRAX_PER_ZOO = 0.065

const CardRate = () => {
  return (
    <CardContent>
      <Card>
        <CardLine index={15} />
        <CardHeadBox>
          <CardHead>
            <Trans>Rate</Trans>
          </CardHead>
        </CardHeadBox>
        <CardContentBox>
          <LineRate>
            {1} <LogoCoinRate src={IconZOO} /> = {FRAX_PER_ZOO} <LogoCoinRate src={IconFRAX} />
          </LineRate>
        </CardContentBox>
      </Card>
    </CardContent>
  )
}

const RenderDatesStyled = styled(RenderDates)`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
`

const usePreSaleTime = () => {
  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const { idoStart, currentPhase, vestingPhaseDuration, lockPhaseDuration, salePhaseDuration } =
    useRoundACrowdsaleDates()

  const calculateBorder = useCallback((): ILeftDays => {
    let duration = salePhaseDuration

    if (currentPhase === ANGELS_PHASES.LockPhase) {
      duration += lockPhaseDuration
    }

    if (currentPhase === ANGELS_PHASES.VestingPhase) {
      duration += lockPhaseDuration + vestingPhaseDuration
    }

    if (currentPhase === ANGELS_PHASES.UnlockPhase) {
      duration += lockPhaseDuration + vestingPhaseDuration
    }

    return getDates(new Date(idoStart * 1000), duration * 1000)
  }, [idoStart, lockPhaseDuration, salePhaseDuration, vestingPhaseDuration, currentPhase])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  return border
}

const RemainingTime = () => {
  const data = usePreSaleTime()

  const { currentPhase } = useRoundACrowdsaleDates()

  return (
    <CardContent>
      <Card>
        <CardLine index={3} />
        <CardHeadBox>
          <CardHead>
            {currentPhase === ANGELS_PHASES.SalePhase ? <Trans>Remaining Sale Time</Trans> : null}
           
            {currentPhase === ANGELS_PHASES.UnlockPhase ? <Trans>Unlocking Time</Trans> : null}
          </CardHead>
        </CardHeadBox>
        <CardContentBox>
          <RenderDatesStyled forceShow showLabels showLocked={false} data={data} />
        </CardContentBox>
      </Card>
    </CardContent>
  )
}

const DaiMax = () => {
  const balance = useChainSaleStablecoinBalance()

  return (
    <CardContent>
      <Card>
        <CardLine index={10} />
        <CardHeadBox>
          <CardHead>
            <LogoCoin src={IconFRAX} /> <Trans>FRAX Balance</Trans>
          </CardHead>
        </CardHeadBox>
        <CardContentBox>
          <Line>{formatDecimal(balance)}</Line>
        </CardContentBox>
      </Card>
    </CardContent>
  )
}

const Contributed = () => {
  const { zooAllocated } = useRoundAUnallocatedZoo()

  return (
    <CardContent>
      <Card>
        <CardLine index={12} />
        <CardHeadBox>
          <CardHead>
            <LogoCoin src={IconFRAX} /> <Trans>FRAX Contributed</Trans>
          </CardHead>
        </CardHeadBox>
        <CardContentBox>
          <Line>{formatDecimal(zooAllocated.mul(FRAX_PER_ZOO * 1000).div(1000))}</Line>
        </CardContentBox>
      </Card>
    </CardContent>
  )
}

const ZooMax = () => {
  const zooBalance = useZooSaleBalanceTest()

  return (
    <CardContent>
      <Card>
        <CardLine index={1} />
        <CardHeadBox>
          <CardHead>
            <LogoCoin src={IconZOO} /> <Trans>ZOO Balance</Trans>
          </CardHead>
        </CardHeadBox>
        <CardContentBox>
          <Line>{formatDecimal(zooBalance)}</Line>
        </CardContentBox>
      </Card>
    </CardContent>
  )
}

const PLACEHOLDER = '0'

const Btns = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const CardContentContribution = styled(CardContent)`
  width: 100%;
  max-width: initial;
`

const CardContribution = styled(Card)`
  width: 100%;
  max-width: initial;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  background: url(${stripsBg}) 50% 50% / cover no-repeat;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding: 0
  `}
`

const InputLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-top: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 18px;
  `}
`

const CardHeadContribution = styled(CardHead)`
  margin-right: 16px;
`

const LogoCoinContribution = styled(LogoCoin)`
  width: 35px;
  height: 35px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 35px;
    height: 35px;
  `}
`
const LogoCoinClaimable = styled(LogoCoinContribution)`
  margin-left: 0px;
`

const Arrow = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: 800;
  display: inline-block;
  margin-left: 10px;
`

const Links = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  margin-top: 16px;
  `}
`

const Balance = styled.div`
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: end;
  text-decoration: none;
  margin-right: 12px;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 14px;
  line-height: 24px;
`};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 14px;
  line-height: 24px;
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 13px;
  line-height: 20px;
`};

  ${({ theme }) => theme.mediaWidth.upToTablet`
  font-size: 13px;
  line-height: 20px;
`};
`

const FraxStyled = styled(Frax)`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: end;
  text-decoration: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  transition: transform 0.3s ease;

  :hover {
    transform: translateX(6px);
    background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const GetDaiWrapper = () => {
  const stablecoinBalance = useChainSaleStablecoinBalance()

  return (
    <Links>
      <Balance>
        <Trans>Balance: {formatDecimal(stablecoinBalance)}</Trans>
      </Balance>

      <FraxStyled
        showImg={false}
        label={
          <ZooTextGradient>
            <Trans>BUY FRAX</Trans>
          </ZooTextGradient>
        }
      />
    </Links>
  )
}

const FirstForm = styled.div`
  padding: 25px;
  background-color: #27273f;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  width: 100%;
`
const BoxDAI = styled.div`
  margin-bottom: 25px;
  height: auto;
`
const BoxZOO = styled.div`
  height: auto;
`

const Contribution = () => {
  const stablecoinBalance = useChainSaleStablecoinBalance()
  const zooBalance = useZooSaleBalanceTest()
  const { quoteCurrency: stablecoin } = useSaleZooCurrencies()
  const [value, onUserInput] = useState<number | undefined>()
  const [valueZoo, onUserInputZoo] = useState<number | undefined>()

  const stablecoinAsNumber = useMemo(() => +fromWei(stablecoinBalance), [stablecoinBalance])
  const { idoStart, currentPhase } = useRoundACrowdsaleDates()

  const { action, pending } = useRoundBSale(valueZoo)

  const balanceNumberZoo = useMemo(() => {
    return +fromWei(zooBalance)
  }, [zooBalance])

  const setDai = useCallback(
    (val: number) => {
      const daiVal = Math.min(val, stablecoinAsNumber)

      onUserInput(daiVal)
      onUserInputZoo(daiVal / FRAX_PER_ZOO)
    },
    [stablecoinAsNumber]
  )

  const setMaxDai = useCallback(() => {
    setDai(stablecoinAsNumber)
  }, [setDai, stablecoinAsNumber])

  const setMaxZoo = useCallback(() => {
    const maxZoo = balanceNumberZoo
    const maxDai = maxZoo * FRAX_PER_ZOO
    setDai(maxDai)
  }, [balanceNumberZoo, setDai])

  const onUserInputZooHandle = useCallback(
    (val: number) => {
      const maxDai = val * FRAX_PER_ZOO
      setDai(maxDai)
    },
    [setDai]
  )

  const notStarted = idoStart * 1000 > new Date().getTime()

  const ended = currentPhase != ANGELS_PHASES.SalePhase

  return (
    <CardContentContribution inactive={notStarted || ended}>
      <CardContribution>
        <FirstForm>
          <CardLine index={13} />
          <BoxDAI>
            <CardHeadBox>
              <CardHeadContribution style={{ width: '100%' }}>
                <LogoCoinContribution src={IconFRAX} />
                <Trans>Contribute FRAX</Trans>
              </CardHeadContribution>
            </CardHeadBox>
            <CardContentBox>
              <InputLine>
                <NumericalInputStyled
                  value={value}
                  onUserInput={(val) => {
                    setDai(+val)
                  }}
                  placeholder={PLACEHOLDER}
                  max={stablecoinAsNumber}
                />

                <LinkToMax onClick={setMaxDai}>
                  <Trans>+Max</Trans>
                </LinkToMax>
              </InputLine>

              <GetDaiWrapper />
            </CardContentBox>
          </BoxDAI>
          <BoxZOO>
            <CardHeadBox>
              <CardHeadContribution>
                <LogoCoinContribution src={IconZOO} />
                <Trans>Get ZOO</Trans>
              </CardHeadContribution>
            </CardHeadBox>

            <CardContentBox>
              <InputLine>
                <NumericalInputStyled
                  value={valueZoo}
                  onUserInput={(val) => {
                    onUserInputZooHandle(+val)
                  }}
                  placeholder={PLACEHOLDER}
                />

                <LinkToMax onClick={setMaxZoo}>
                  <Trans>+Max</Trans>
                </LinkToMax>
              </InputLine>

              {stablecoin ? (
                <Btns style={{ marginTop: 16 }}>
                  <ApproveCheckerSaleA
                    currency={stablecoin}
                    disabled={currentPhase !== ANGELS_PHASES.SalePhase}
                    border={value}
                  >
                    <ButtonBoost disabled={pending || ended || valueZoo === 0} onClick={action}>
                      {pending ? (
                        <Dots>
                          <Trans>Contributing</Trans>
                        </Dots>
                      ) : stablecoinBalance.isZero() || ended ? (
                        <>
                          <Trans>Not available</Trans>
                          <Arrow></Arrow>
                        </>
                      ) : (
                        <>
                          <Trans>Contribute</Trans>
                          <Arrow></Arrow>
                        </>
                      )}
                    </ButtonBoost>
                  </ApproveCheckerSaleA>
                </Btns>
              ) : (
                <Btns>
                  <ButtonBoost onClick={() => window.location.reload()} style={{ marginTop: 16 }}>
                    <Trans>Please Switch to Moonbeam</Trans>
                  </ButtonBoost>
                </Btns>
              )}
            </CardContentBox>
          </BoxZOO>
        </FirstForm>
      </CardContribution>
    </CardContentContribution>
  )
}

const InfoBoxClaimWhitelist = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
  `}
`

const Claimable = () => {
  const { action, pending } = useRoundAClaim()

  const { claimable: claimableTokens } = useRoundAUnallocatedZoo()

  const { totalZooForSale } = useRoundASaleData()

  return (
    <CardContentContribution>
      <CardContribution>
        <InfoBoxClaimWhitelist>
          <CardContent>
            <Card>
              <CardLine index={2} />
              <CardHeadBox>
                <CardHead>
                  <Trans>
                    <LogoCoinContribution src={IconZOO} />
                    ZOO Remaining
                  </Trans>
                </CardHead>
              </CardHeadBox>
              <CardContentBox>
                <Line>{formatDecimal(totalZooForSale)}</Line>
                <Description>
                  <Trans>Fixed</Trans>
                </Description>
              </CardContentBox>
            </Card>
          </CardContent>

          <RemainingTime />
        </InfoBoxClaimWhitelist>

        <FirstForm>
          <CardLine index={7} />
          <BoxZOO>
            <CardHeadBox>
              <CardHeadContribution>
                <LogoCoinClaimable src={IconZOO} />
                <Trans>Claimable ZOO</Trans>
              </CardHeadContribution>
              <CardContentBox style={{ marginLeft: 'auto' }}>
                <Btns>
                  <ButtonStake disabled={pending || claimableTokens.isZero()} onClick={action}>
                    {pending ? (
                      <Dots>
                        <Trans>Claiming</Trans>
                      </Dots>
                    ) : (
                      <>
                        <Trans>Claim {formatDecimal(claimableTokens)}</Trans>
                        <Arrow></Arrow>
                      </>
                    )}
                  </ButtonStake>
                </Btns>
              </CardContentBox>
            </CardHeadBox>

            <AccountSaleStatsA />
          </BoxZOO>
        </FirstForm>
      </CardContribution>
    </CardContentContribution>
  )
}

export default CommunityRoundA
