import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { RouteComponentProps } from 'react-router-dom'
import {
  CardDAIVotes,
  CardMiniForNftInfoDAI,
  CardPotentialRewardMy,
  CardPotentialRewardTotal,
  CardVotes,
  CardZooVotes,
} from './CardMiniForNftInfo'
import { BattleClosingUntilResults, BattleClosing_2 } from './BattleClosing'
import { useAllBattleNftEvents, useBattleNftEventsForUser } from '../../hooks/zoodao/useBattlesEventsData'
import ZooLoader from '../../components/ZooLoader/ZooLoader'
import { BATTLE_STAGES, useBattleEpoch, useBattleStage, usePairInEpochInfo } from '../NftBattlesPage/hooks'
import { WinnersChoosingGif } from '../../components/ChooseWinners/ChooseWinners'
import { BattlePairsNftOverview2 } from './cards/NftOverviewCard'
import { useWinChances, WINNER } from './utils'
import { Btn } from './NftBattlesPairsPool'
import { CardsForVoting } from './CardsForVoting'

const NftBattlesContainer = styled.div`
  align-items: center;
  margin-top: 0;
  width: 100%;
`

const ContainerBox = styled.div<{ looser?: boolean }>`
  opacity: ${({ looser }) => (looser ? '0.4' : 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`

const ContainerTwoColumns = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  > div {
    margin: 0;
    width: initial;
  }
`
const ContainerSolo = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  > div {
    margin: 0;
    width: initial;
  }
`
const ContainerBox2 = styled(ContainerBox)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`

const ContainerOneColumn = styled.div`
  width: 100%;
  margin-top: 0px;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  > div {
    margin: 0;
    width: 100%;
  }
`

export const FullBox = styled.div`
  align-items: center;
`

const CardContent = styled.div`
  display: flex;
`

export const BattleLabelWrapper = styled.div`
  padding: 15px 25px;
  background-color: #33334b;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 50px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: normal;
  font-size: 44px;
  line-height: 30px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 30px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 30px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 30px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 30px;
    line-height: 30px;
  `};
`
export const BattleLabelWrapperInfo = styled.div`
  padding: 15px 25px;
  background-color: #33334b;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 50px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: normal;
  font-size: 44px;
  line-height: 30px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 40px;
    line-height: 40px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 40px;
    line-height: 40px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 30px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 36px;
    line-height: 36px;
  `};
`

const ArrowPrev = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
`

export const BattleLabel = styled.div`
  background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: ${({ theme }) => theme.fontUniform};
`

const ContainerZero2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`

const BattleRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 25px;
`

const CardData = ({ positionId, type, winData }: { positionId: string; type: WINNER; winData: any }) => {
  return (
    <ContainerBox>
      <BattlePairsNftOverview2
        type={type}
        winData={winData}
        positionId={positionId}
        showWinnerLabel={true}
        makeDisabled={true}
        showBattleIcon={false}
      />
    </ContainerBox>
  )
}
const CardData2 = ({ positionId }: { positionId: string }) => {
  const { votingsByUser } = useBattleNftEventsForUser(positionId)
  const { allVotings } = useAllBattleNftEvents(positionId)

  return (
    <ContainerBox>
      <ContainerTwoColumns>
        <CardVotes positionId={positionId} />
        <CardMiniForNftInfoDAI allVotes={allVotings} userVotes={votingsByUser} />
        <CardZooVotes allVotes={allVotings} userVotes={votingsByUser} />
        <CardDAIVotes allVotes={allVotings} userVotes={votingsByUser} />
      </ContainerTwoColumns>
    </ContainerBox>
  )
}
const CardData3 = ({ positionId }: { positionId: string }) => {
  const { votingsByUser } = useBattleNftEventsForUser(positionId)

  return (
    <ContainerBox>
      <ContainerOneColumn>
        <CardsForVoting positionId={positionId} votingsByUser={votingsByUser} hideSecondStage />
      </ContainerOneColumn>
    </ContainerBox>
  )
}

const NftCardPairInfo = ({
  match: {
    params: { pairId = '', epoch = '0' },
  },
}: RouteComponentProps<{ pairId?: string; epoch?: string }>) => {
  const { data, loading } = usePairInEpochInfo(+epoch, pairId)

  const { token1, token2 }: any = data || {}

  const history = useHistory()

  const onClick = useCallback(() => {
    history.goBack()
  }, [history])

  if (!pairId) {
    return null
  }

  return (
    <NftBattlesContainer>
      <ContainerZero2>
        <Btn onClick={onClick}>
          <ArrowPrev></ArrowPrev>
          <Trans>Back to Battles</Trans>
        </Btn>
      </ContainerZero2>
      <CardContent>
        <ZooLoader loading={loading || !token1 || !token2 || !data}>
          <WrapperPairData data={data} pairId={pairId} epoch={+epoch} />
        </ZooLoader>
      </CardContent>
    </NftBattlesContainer>
  )
}

const WrapperPairData = ({ data, epoch, pairId }: { data: any; epoch: number; pairId: string }) => {
  const { token1, token2 }: any = data || {}

  const winData = useWinChances(data, epoch)

  const { loadingSecond, loadingFirst } = winData

  return (
    <ContainerBox2>
      <BattleRow>
        {token1 ? <CardData type={WINNER.A} winData={winData} positionId={token1.toString()} /> : null}
        <ZooLoader loading={loadingSecond || loadingFirst}>
          <CenterData pairId={pairId} epoch={epoch} />
        </ZooLoader>
        {token2 ? <CardData type={WINNER.B} winData={winData} positionId={token2.toString()} /> : null}
      </BattleRow>

      <BattleRow>
        {token1 ? <CardData2 positionId={token1.toString()} /> : null}
        <ZooLoader loading={loadingSecond || loadingFirst}>
          <CenterData2 winData={winData} pairId={pairId} pairData={data} />
        </ZooLoader>
        {token2 ? <CardData2 positionId={token2.toString()} /> : null}
      </BattleRow>

      <BattleRow>
        {token1 ? <CardData3 positionId={token1.toString()} /> : null}
        <div></div>
        {token2 ? <CardData3 positionId={token2.toString()} /> : null}
      </BattleRow>
    </ContainerBox2>
  )
}

const CenterData = ({ pairId, epoch }: { pairId: string; epoch: number }) => {
  const battleID = parseInt(pairId) + 1

  const currentEpoch = useBattleEpoch()

  return (
    <Center>
      <BattleLabelWrapperInfo>
        <BattleLabel>
          <Trans>Battle #</Trans>
          {battleID}
        </BattleLabel>
      </BattleLabelWrapperInfo>

      <WinnersChoosingGif positionIndex={pairId} />
      {+epoch === +currentEpoch && <BattleClosingUntilResults />}
    </Center>
  )
}
const CenterData2 = ({ pairId, pairData }: { winData: any; pairId: string; pairData: any }) => {
  const { playedInEpoch }: any = pairData || {}

  const stage = useBattleStage()

  return (
    <Center>
      <ContainerBox2>
        <ContainerOneColumn>
          {/* {stage === BATTLE_STAGES.FOURTH ? <BattleClosing_2 positionId={pairId} playedInEpoch={playedInEpoch} /> : null} */}
          {stage === BATTLE_STAGES.FIFTH ? <BattleClosing_2 positionId={pairId} playedInEpoch={playedInEpoch} /> : null}
          {/* <>{pairData && winData ? <CardWinChances winData={winData} pairData={pairData} /> : null}</> */}
        </ContainerOneColumn>
        <ContainerSolo>
          <CardPotentialRewardMy pairData={pairData} playedInEpoch={playedInEpoch} />
          <CardPotentialRewardTotal pairData={pairData} playedInEpoch={playedInEpoch} />
        </ContainerSolo>
      </ContainerBox2>
    </Center>
  )
}

export default NftCardPairInfo
