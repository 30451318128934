import { useNftPartnersLogos } from 'pages/VePie/useNftPartnersLogos'
import { Flex } from 'rebass'
import { NFTImage } from './PortfolioNFTOverview/styles'

export const NftProjectLogo = ({ collection }: { collection: string }) => {
  const logo = useNftPartnersLogos(collection)

  return (
    <Flex justifyContent="center" mt={-38}>
      <NFTImage src={logo} />
    </Flex>
  )
}
