import React, { useEffect, useCallback, useState, KeyboardEvent } from 'react'
import styled from 'styled-components/macro'
import { CardPairsPool } from './CardPairsPool'
import { useBattleEpoch, useNftsPairsInEpoch } from '../NftBattlesPage/hooks'
import { Trans } from '@lingui/macro'
import ZooLoader from '../../components/ZooLoader/ZooLoader'
import { ButtonVote } from '../../components/Button'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteScrollProps } from './NftBattlesNfts'
import { ContentStyled, Header, StyledRow, TextConnect } from 'components/WarningWrapper/WarningWrapper'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from 'constants/paths'
import useDebounce from 'hooks/useDebounce'
import { SearchInput } from 'components/SearchModal/styleds'

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`

const NftBattlesContainer = styled.div`
  margin-top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;

  .infinite-scroll-component__outerdiv {
    width: 100%;
    height: 100%;
    overflow: visible !important;

    .infinite-scroll-component {
      width: 100%;
      height: 100% !important;
      overflow: visible !important;
      display: flex;
      flex-direction: column;
    }
  }
`

export const Btn = styled(ButtonVote)`
  padding: 15px 25px;
  background-color: #33334b;
  color: #fff;
  border: 2px solid transparent;
  transition: all 0.3s;

  &:hover {
    background-color: #33334b;
    border: 2px solid transparent;
    color: #fff;
    transform: translateX(-10px);
    transition: all 0.3s;
  }
  &:focus {
    background-color: #33334b;
    color: #fff;
    border: 2px solid transparent;
    transition: all 0.3s;
  }
`
const BtnNext = styled(Btn)`
  &:hover {
    transform: translateX(10px);
  }
`
const BtnSoon = styled(BtnNext)`
  background-color: #202136 !important;
  border: 2px solid #33334b !important;
  &:hover {
    transform: translateX(0px);
  }
  cursor: default !important;
`

const Selector = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
`

export const Epoch = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #33334b;
  background-color: #202136;
  padding: 15px 25px;
  border-radius: 50px;

  ${({ theme }) => theme.mediaWidth.upToProSmall`
    font-size: 16px;
  `};
`

const ArrowPrev = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
`
const ArrowNext = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: 600;
  display: inline-block;
  margin-left: 10px;
`

const SearchInputStyled = styled(SearchInput)`
  margin: 0 0 24px 0;
`

const NftCardPairsPool = () => {
  const { id: currentEpoch }: any = useParams()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const { pairs, loading } = useNftsPairsInEpoch(currentEpoch, searchQuery)

  const debouncedQuery = useDebounce(searchQuery, 200)

  const handleInput = useCallback(
    (event: any) => {
      const input = event.target.value
      setSearchQuery(input)
    },
    [setSearchQuery]
  )

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setSearchQuery(debouncedQuery)
      }
    },
    [debouncedQuery, setSearchQuery]
  )

  return (
    <NftBattlesContainer>
      <EpochSelector url={Paths.BATTLES_PAIRS} />

      <SearchInputStyled
        type="text"
        id="nfts-search-input"
        placeholder={`Search by NFT project name`}
        autoComplete="off"
        value={searchQuery}
        onChange={handleInput}
        onKeyDown={handleEnter}
      />

      <ZooLoader loading={loading}>
        {pairs.length > 0 ? <InfiniteScrollListStyled itemsTotal={pairs} currentEpoch={currentEpoch} /> : <NoPairs />}
      </ZooLoader>
    </NftBattlesContainer>
  )
}

export const EpochSelector = ({ url }: { url: string }) => {
  const limit = useBattleEpoch()
  const { id: currentEpoch }: any = useParams()
  const history = useHistory()

  const setEpoch = useCallback(
    (newEpoch: number) => {
      history.push(url + '/' + newEpoch)
    },
    [history, url]
  )

  useEffect(() => {
    if (limit && !currentEpoch) {
      setEpoch(limit)
    }
  }, [limit, currentEpoch, setEpoch])

  function onPrev() {
    if (currentEpoch > 1) {
      setEpoch(+currentEpoch - 1)
    }
  }

  function onNext() {
    if (currentEpoch < limit) {
      setEpoch(+currentEpoch + 1)
    }
  }

  const epoch = useBattleEpoch()
  return (
    <Selector>
      {currentEpoch > 1 ? (
        <Btn onClick={onPrev}>
          <ArrowPrev></ArrowPrev>
          Prev
        </Btn>
      ) : (
        <div style={{ width: '125px' }}>&nbsp;</div>
      )}
      <Epoch>{+epoch === +currentEpoch ? <Trans>Current Season</Trans> : <Trans>Season {currentEpoch}</Trans>}</Epoch>
      {limit > currentEpoch ? (
        <BtnNext onClick={onNext}>
          Next
          <ArrowNext></ArrowNext>
        </BtnNext>
      ) : (
        <BtnSoon onClick={onNext}>
          Soon
          <ArrowNext></ArrowNext>
        </BtnSoon>
      )}
    </Selector>
  )
}
interface IScrollProps {
  itemsTotal: any[]
  currentEpoch: number
  className?: string
}

const ZooLoaderStyled = styled(ZooLoader)`
  width: 100%;
  height: 200px;
`

const PER_PAGE = 10

const InfiniteScrollList = ({ className, itemsTotal, currentEpoch }: IScrollProps) => {
  const { currentList, hasMore, fetchData } = useInfiniteScrollProps(itemsTotal, PER_PAGE)

  return (
    <InfiniteScroll
      className={className}
      dataLength={currentList.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<ZooLoaderStyled loading={true} />}
      scrollableTarget="body_over"
    >
      <List>
        {currentList.map((item, index) => (
          <CardPairsPool positionIndex={item.pairIndex} key={index} epoch={currentEpoch} />
        ))}
        {currentList.length === 0 ? <NoPairs /> : null}
      </List>
    </InfiniteScroll>
  )
}

const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  overflow: visible !important;
`

const TextConnectBattles = styled(TextConnect)`
  margin-bottom: 0px;
  text-align: center;
`

const NoPairs = () => {
  return (
    <StyledRow>
      <ContentStyled>
        <Header>There are no Battles</Header>
        <TextConnectBattles>
          Battles will appear after NFTs
          <br></br>
          have been paired
        </TextConnectBattles>
        {/* <Web3StatusStyled text={<Trans>Let&#8217;s Start</Trans>} /> */}
      </ContentStyled>
    </StyledRow>
  )
}

const NftCardPairsPoolWrapped = () => {
  return <NftCardPairsPool />
}

export default NftCardPairsPoolWrapped
