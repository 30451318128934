import usePrevious from 'hooks/usePrevious'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface IBridgeContext {
  targetToken?: string
  toggle?: (targetToken: string) => void
}

export const WarningCtx = createContext<IBridgeContext>({})

const useBridgeModeToggle = () => {
  const [targetToken, setMode] = useState<string>('')

  const toggle = useCallback(
    (token: string) => {
      setMode(token)
    },
    [setMode]
  )

  return { targetToken, toggle }
}

export const WarningModes = ({ children }: { children: any }) => {
  const { toggle, targetToken } = useBridgeModeToggle()
  return <WarningCtx.Provider value={{ toggle, targetToken }}>{children}</WarningCtx.Provider>
}

export const useActiveBridge = () => {
  const { targetToken, toggle } = useContext(WarningCtx)

  const { pathname } = useLocation()

  const prev = usePrevious(pathname)

  useEffect(() => {
    if (prev && pathname && pathname !== prev) {
      toggle && toggle('')
    }
  }, [toggle, prev, pathname])

  return {
    toggle,
    targetToken: targetToken,
  }
}
