import { Trans } from '@lingui/macro'
import { useActiveWeb3React } from '../../../../hooks/web3'
import { ExplorerDataType, getExplorerLink } from '../../../../utils/getExplorerLink'
import { shortenAddress } from '../../../../utils'
import { ExternalLink } from '../../../../theme'
import { EtherscanIcon, InJackpotsIcon, useDaiVotes } from '../../CardMiniForNftInfo'
import { Container, VoteButton, NFTAddress, InfoValue, InfoTitle, Info } from './styles'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'
import { SupportedChainId } from '../../../../constants/chainsinfo'
import {
  useBattleStakerPositionInfo,
  useBattleVotingPositionInfo,
  useYTokensUsdValue,
} from 'pages/NftBattlesPage/hooks'
import { useCardLoading } from '../CardForPool'
import { useStakerYTokensRewards } from 'components/ClaimRewardForStakers/ClaimRewardForStakers'
import { id } from 'date-fns/locale'
import { useBattleNftEventsForUser, useAllBattleNftEvents } from 'hooks/zoodao/useBattlesEventsData'
import { formatDecimal } from 'utils/numberWithCommas'
import { Dots } from 'pages/Pool/styleds'
import { getSelectedKey } from 'pages/Portfolio/utils'
import { useContext, useEffect, useMemo } from 'react'
import { ButtonPurple } from 'components/Button'
import { ZERO } from 'utils/isZero'
import { BigNumber } from 'ethers'
import { useVoterUsdRewards } from 'components/ClaimRewardForVoter/ClaimRewardForVoter'
import { PORTFOLIO_TABS } from 'pages/NftBattlesPageV2/NftBattlesNfts'
import { ImgParticipantsBox, NftName, useCDNUrl } from '../NftOverviewCard'
import { useIncentiveStakerZooRewards } from 'components/ClaimRewardForStakers/ClaimIncentiveForStaker'
import { useIncentiveVoterZooRewards } from 'components/ClaimRewardForVoter/ClaimIncentiveForVoter'
import { useStableCurrency } from '../../../../hooks/Tokens'
import { useStakingPositionByVoting } from '../JackpotsNFTOverview/PositionsView'
import { AlarmText } from '../JackpotsNFTOverview/styles'
import { SelectedPositionsContext } from 'pages/Portfolio/Portfolio'
import { MouseoverTooltip } from 'components/Tooltip'
import LogoIcon from '../LogoIcon/LogoIcon'

const ImgParticipantsBoxStyled = styled(ImgParticipantsBox)`
  aspect-ratio: 1;
  object-fit: cover;
`

const RemoveBtn = styled(ButtonPurple)`
  margin-top: 25px;
`

export const PortfolioJackpotyNFTOverview = ({ type, ...rest }: IProps) => {
  if (type === PORTFOLIO_TABS.STAKED) {
    return <PortfolipNFTOverview type={type} {...rest} />
  }

  return <PortfolioJackpotVotingNFTOverview type={type} {...rest} />
}

const PortfolioJackpotVotingNFTOverview = (rest: IProps) => {
  const stakingPositionId = useStakingPositionByVoting(rest.votingPositionId)

  return <PortfolipNFTOverview {...rest} stakingPositionId={stakingPositionId} />
}

interface IProps {
  className?: string
  stakingPositionId: string
  votingPositionId: BigNumber
  type: string
  toggle?: (
    isUpdate: boolean,
    pid: string,
    daiInvested: BigNumber,
    zooInvested: BigNumber,
    votingPositionId?: BigNumber
  ) => void
  isDeleted?: boolean
}

const PortfolipNFTOverview = ({ className, stakingPositionId, votingPositionId, type, toggle, isDeleted }: IProps) => {
  const isStakingPositionsChecking = type === PORTFOLIO_TABS.STAKED
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const selected = useContext(SelectedPositionsContext)

  const { positionInfo } = useBattleStakerPositionInfo(stakingPositionId)
  const { stableCurrency } = useStableCurrency()

  const { onClick } = useCardLoading(stakingPositionId)

  const { id: tokenId, token } = positionInfo || {}

  const { votingsByUser } = useBattleNftEventsForUser(stakingPositionId)
  const { allVotings } = useAllBattleNftEvents(stakingPositionId)

  const { info, loading } = useBattleVotingPositionInfo(votingPositionId)

  const { allDaiInvested, allZooInvestedAmount } = useDaiVotes(allVotings, votingsByUser)
  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  const image_original_url = useCDNUrl(token, tokenId)

  const investedZoo = useMemo(() => {
    switch (type) {
      case PORTFOLIO_TABS.STAKED: {
        return allZooInvestedAmount
      }

      case PORTFOLIO_TABS.VOTES: {
        return loading || !info ? ZERO : info.zooInvested
      }
      default: {
        throw 'Not handled case ZOO'
      }
    }
  }, [allZooInvestedAmount, type, info, loading])

  const investedDai = useMemo(() => {
    switch (type) {
      case PORTFOLIO_TABS.STAKED: {
        return allDaiInvested
      }

      case PORTFOLIO_TABS.VOTES: {
        return loading || !info ? ZERO : info.daiInvested
      }

      default: {
        throw 'Not handled case USD'
      }
    }
  }, [allDaiInvested, type, info, loading])

  useEffect(() => {
    if (selected && selected[stakingPositionId]) {
      toggle && toggle(true, investedDai, investedZoo, votingPositionId)
    }
  }, [selected, toggle, investedZoo, investedDai, votingPositionId, stakingPositionId])

  if (!id) {
    return null
  }

  const key = getSelectedKey(stakingPositionId, votingPositionId)

  return (
    <Container className={className} isDeleted={isDeleted}>
      <ImgParticipantsBoxStyled onClick={onClick}>
        <LogoIcon src={image_original_url} />
      </ImgParticipantsBoxStyled>

      <Flex flexDirection="column" px={25} pb={25} mt={20}>
        <NftName token={token} tokenId={tokenId} />
        <ExternalLink href={exporerLink}>
          <Flex alignItems="center">
            {token && <NFTAddress>{shortenAddress(token, 2)}</NFTAddress>}
            <EtherscanIcon exporerLink={exporerLink} />
          </Flex>
        </ExternalLink>
        <Flex>
          <Info>
            <InfoValue>
              {loading ? (
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              ) : (
                formatDecimal(investedDai)
              )}
            </InfoValue>
            <InfoTitle>
              <Trans>Staked {stableCurrency}</Trans>
            </InfoTitle>
          </Info>

          <Info>
            <InfoValue>
              {loading ? (
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              ) : (
                formatDecimal(investedZoo)
              )}
            </InfoValue>
            <InfoTitle>
              <Trans>Staked ZOO</Trans>
            </InfoTitle>
          </Info>
        </Flex>

        {isStakingPositionsChecking
          ? stakingPositionId && <StakingPanelRewards stakingPositionId={stakingPositionId} />
          : votingPositionId && <StakingVotingRewards votingPositionId={votingPositionId} />}

        {isDeleted && <AlarmText>Liquidated Position</AlarmText>}

        {toggle && selected ? (
          <>
            {!selected[key] ? (
              <VoteButton onClick={() => toggle(false, stakingPositionId, investedDai, investedZoo, votingPositionId)}>
                <Trans>Select</Trans>
              </VoteButton>
            ) : (
              <RemoveBtn onClick={() => toggle(false, stakingPositionId, ZERO, ZERO, votingPositionId)}>
                <Trans>Unselect</Trans>
              </RemoveBtn>
            )}
          </>
        ) : (
          <Flex flexDirection="column">
            <MouseoverTooltip
              text={<Trans>You must unstake from Jackpots to claim Battle rewards</Trans>}
              placement="top"
            >
              <InJackpotsIcon />
            </MouseoverTooltip>
          </Flex>
        )}
      </Flex>
    </Container>
  )
}

const StakingPanelRewards = ({ stakingPositionId }: { stakingPositionId: string }) => {
  const { rewards: rStaker, loading: loadingStakerRewards } = useStakerYTokensRewards(stakingPositionId)
  const { rewards: rStakerInc, loading: loadingIncStakerRewards } = useIncentiveStakerZooRewards(stakingPositionId)

  const { usd: rewardsUsd, loading: loadingUsd } = useYTokensUsdValue(rStaker)

  const { stableCurrency } = useStableCurrency()

  return (
    <Flex style={{ marginBottom: '16px' }}>
      <Info>
        <InfoValue>
          {loadingStakerRewards || loadingUsd ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            formatDecimal(rewardsUsd, 2)
          )}
        </InfoValue>
        <InfoTitle>
          <Trans>Claimable {stableCurrency}</Trans>
        </InfoTitle>
      </Info>
      <Info>
        <InfoValue>
          {loadingIncStakerRewards ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            formatDecimal(rStakerInc)
          )}
        </InfoValue>
        <InfoTitle>
          <Trans>Claimable ZOO</Trans>
        </InfoTitle>
      </Info>
    </Flex>
  )
}

const StakingVotingRewards = ({ votingPositionId }: { votingPositionId: BigNumber }) => {
  const { rewardsUsd, rewardsWell, rewardsGlmr, loading: loadingVoterRewards } = useVoterUsdRewards(votingPositionId)

  const { rewards: rVoterInc, loading: loadingIncVoterRewards } = useIncentiveVoterZooRewards(votingPositionId)

  const { stableCurrency } = useStableCurrency()

  return (
    <>
      <Flex>
        <Info>
          <InfoValue>
            {loadingVoterRewards ? (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            ) : (
              formatDecimal(rewardsUsd, 2)
            )}
          </InfoValue>
          <InfoTitle>
            <Trans>Claimable {stableCurrency}</Trans>
          </InfoTitle>
        </Info>
        <Info>
          <InfoValue>
            {loadingIncVoterRewards ? (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            ) : (
              formatDecimal(rVoterInc)
            )}
          </InfoValue>
          <InfoTitle>
            <Trans>Claimable ZOO</Trans>
          </InfoTitle>
        </Info>
      </Flex>

      <Flex style={{ marginBottom: '16px' }}>
        <Info>
          <InfoValue>
            {loadingVoterRewards ? (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            ) : (
              formatDecimal(rewardsWell, 2)
            )}
          </InfoValue>
          <InfoTitle>
            <Trans>Claimable WELL</Trans>
          </InfoTitle>
        </Info>
        <Info>
          <InfoValue>
            {loadingVoterRewards ? (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            ) : (
              formatDecimal(rewardsGlmr, 2)
            )}
          </InfoValue>
          <InfoTitle>
            <Trans>Claimable GLMR</Trans>
          </InfoTitle>
        </Info>
      </Flex>
    </>
  )
}
export default styled(PortfolipNFTOverview)``
