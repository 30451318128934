import { IDashCard, CardLine, DashboardCard, CardHeader, CardContent, CardText, CardDescription } from '../styleds'
import { Trans } from '@lingui/macro'
import { formatDecimal } from 'utils/numberWithCommas'
import styled from 'styled-components/macro'
import { useTotalClaimedFromStakingPool } from './hooks'
import { Dots } from 'pages/Pool/styleds'

export const CardTextStyled = styled(CardText)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

const ClaimedTotal = ({ index }: IDashCard) => {
  const { rewards, loading } = useTotalClaimedFromStakingPool()

  return (
    <DashboardCard>
      <CardLine index={index} />
      <CardHeader>
        <Trans>Claimed Total</Trans>
      </CardHeader>

      <CardContent>
        <CardTextStyled>{loading ? <Dots>Loading</Dots> : formatDecimal(rewards)}</CardTextStyled>
        <CardDescription>
          <Trans>For all users</Trans>
        </CardDescription>
      </CardContent>
    </DashboardCard>
  )
}

export default ClaimedTotal
