import { Dots } from 'pages/Pool/styleds'
import { Trans } from '@lingui/macro'

const Loading = ({ children, loading }: { children: any; loading?: boolean }) => {
  if (!loading) {
    return children
  } else {
    return (
      <Dots>
        <Trans>Loading</Trans>
      </Dots>
    )
  }
}

export default Loading
