import React, { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { RouteComponentProps } from 'react-router-dom'
import {
  CardDAIVotes,
  CardMiniForNftInfoDAI,
  CardMiniForNftInfoZOO,
  CardPairNft,
  CardVotes,
  CardVotingPowerDAI,
  CardVotingPowerZOO,
  CardZooVotes,
} from './CardMiniForNftInfo'
import { BattleNftTransactions } from './BattleStakingTransactions'
import { useAllBattleNftEvents, useBattleNftEventsForUser } from '../../hooks/zoodao/useBattlesEventsData'
import { BATTLE_STAGES, useBattleStage, useBattleStakerPositionInfo } from '../NftBattlesPage/hooks'
import ZooLoader from '../../components/ZooLoader/ZooLoader'
import { Btn } from './NftBattlesPairsPool'
import { Paths } from 'constants/paths'
import { CardsForVoting } from './CardsForVoting'
import { SimpleNftOverview } from './cards/NftOverviewCard'

const NftBattlesContainer = styled.div`
  margin-top: 0px;
  width: 100%;
`
const CardNftForNftInfoFull = styled(SimpleNftOverview)`
  height: 100%;
`
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;
`
const ZooLoaderBox = styled(ZooLoader)`
  margin-top: 125px;
`
const ContainerZero = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContainerFirst = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

const ContainerSecond = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  width: 100%;
`
const ArrowPrev = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
`

const NextColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;
`

const TxsContainer = styled.div`
  margin-top: 25px;
`

const NftBattleStaked = ({
  match: {
    params: { positionId },
  },
}: RouteComponentProps<{ positionId: string }>) => {
  const { votingsByUser, myEvents } = useBattleNftEventsForUser(positionId)

  const { allEvents, allVotings, loading: loadingAll } = useAllBattleNftEvents(positionId)

  const stage = useBattleStage()

  const history = useHistory()
  const zooBoostRef = useRef<HTMLDivElement>(null)

  const onClick = useCallback(() => {
    history.push(Paths.BATTLES_NFTS)
  }, [history])

  const handleZooBoxClicked = useCallback(() => {
    if (zooBoostRef && zooBoostRef.current) {
      zooBoostRef.current.scrollIntoView()
    }
  }, [])

  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  if (!positionId) {
    return null
  }

  return (
    <NftBattlesContainer>
      <ZooLoaderBox loading={loadingAll}>
        <Container>
          <ContainerZero>
            <Btn onClick={onClick}>
              <ArrowPrev></ArrowPrev>
              Back To Arena
            </Btn>
          </ContainerZero>
          <ContainerFirst>
            <CardNftForNftInfoFull positionId={positionId} />
            <NextColumns>
              <CardVotes positionId={positionId} />
              <CardPairNft positionId={positionId} disabled={allVotings && allVotings.length === 0} />
            </NextColumns>
            <NextColumns>
              <CardDAIVotes allVotes={allVotings} userVotes={votingsByUser} />
              <CardMiniForNftInfoDAI allVotes={allVotings} userVotes={votingsByUser} />
            </NextColumns>
            <NextColumns>
              <CardZooVotes allVotes={allVotings} userVotes={votingsByUser} onClick={handleZooBoxClicked} />
              <CardMiniForNftInfoZOO allVotes={allVotings} userVotes={votingsByUser} onClick={handleZooBoxClicked} />
            </NextColumns>
          </ContainerFirst>
          {positionInfo && !positionInfo.isDeleted && (
            <ContainerSecond>
              {stage == BATTLE_STAGES.SECOND ? <CardVotingPowerDAI /> : null}
              {stage == BATTLE_STAGES.FOURTH ? <CardVotingPowerZOO /> : null}
              <CardsForVoting positionId={positionId} votingsByUser={votingsByUser} zooBoostRef={zooBoostRef} />
            </ContainerSecond>
          )}
        </Container>
      </ZooLoaderBox>
      <TxsContainer>
        <BattleNftTransactions myEvents={myEvents} allEvents={allEvents} />
      </TxsContainer>
    </NftBattlesContainer>
  )
}

export default NftBattleStaked
