import React, { useMemo, useState } from 'react'
import { DashboardCardFlex } from 'pages/Dashboard/styleds'
import { Box } from 'components/MUI'
import { InputLabel, LockButton, LogoCoin, Picker, TxtBalance } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import IconVeZOO from '../../../assets/svg/logo_veZOO_token.svg'
import { formatDecimal } from 'utils/numberWithCommas'
import styled from 'styled-components/macro'
import { Dots } from 'pages/Pool/styleds'
import ButtonPanel, { IPanelVariant } from './ButtonPanel'
import { useVeModelProlongate, useVeModelUnLock } from 'hooks/zoodao/veModel'
import { useLockOptions } from './LockPanel'
import { useCollectionInfo } from 'hooks/zoodao/useCollectionInfo'
import { ZERO } from 'utils/isZero'
import { toNumber } from 'pages/NftBattlesPage/hooks'
import { usePositionTimer } from '../Weights/Weights'
import { InfoValue, Info, InfoTitle } from 'pages/NftBattlesPageV2/cards/VePieNFTOverview/styles'
import { VeModelPositionDetails } from '../NFTList'
import { useVotePositionInfo } from 'pages/NftBattlesPageV2/cards/VePieNFTOverview/VePieVoteNFTOverview'

const PickerStyled = styled(Picker)``

const Box2 = styled(Box)`
  color: ${({ theme }) => theme.text1};
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  display: flex;
  word-break: break-word;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 20px;
    line-height: 26px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 18px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

const VotingPanel = ({ selected }: { selected: VeModelPositionDetails | null }) => {
  const [type, setType] = useState(IPanelVariant.INCREASE)

  const { lockOptions, duration, setDuration, durationNumber } = useLockOptions()
  const { name } = useCollectionInfo(selected?.collection)

  const { info, loading } = useVotePositionInfo(selected?.positionId)

  const { action: unlock, pending: pendingUnlock } = useVeModelUnLock(selected?.positionId)
  const { action: prolongate, pending: pendingProlongate } = useVeModelProlongate(selected?.positionId, durationNumber)

  const isAllowed = useMemo(() => {
    return new Date().getTime() / 1000 > toNumber(info?.expirationDate || ZERO)
  }, [info])

  return (
    <DashboardCardFlex inactive={!selected}>
      <Box flexDirection="row" alignItems="center">
        <Box2 flexDirection="row" alignItems="center">
          <LogoCoin src={IconVeZOO} />
          {type === IPanelVariant.INCREASE ? <Trans>Manage veZOO</Trans> : <Trans>Take veZOO</Trans>}
        </Box2>
        <Box flex={1} />
        <ButtonPanel onChange={setType} type={type} />
      </Box>

      <InputLabel mt={35} alignItems="center">
        <Trans>Vote Position:</Trans>&nbsp;
        <TxtBalance>{selected?.positionId?.toString() || '#'}</TxtBalance>
        <Flex flex={1} />
        {name && (
          <>
            <Trans>Project:</Trans>&nbsp;
            <TxtBalance>{name || <Trans>Please select project</Trans>}</TxtBalance>
          </>
        )}
      </InputLabel>

      {type === IPanelVariant.INCREASE ? (
        <PickerStyled
          options={lockOptions}
          value={`${duration}`}
          onChange={({ value }: any) => {
            setDuration(value)
          }}
        />
      ) : null}

      <Flex alignItems="center" justifyContent="space-between" mt={25}>
        <InputLabel>
          <Trans>Your veZOO locked:</Trans>&nbsp;
          <TxtBalance>
            {loading || !info ? (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            ) : (
              formatDecimal(info?.zooLocked || ZERO)
            )}
          </TxtBalance>
        </InputLabel>
        <LockButton
          onClick={() => (type === IPanelVariant.INCREASE ? prolongate() : unlock())}
          disabled={pendingUnlock || pendingProlongate || !selected || (type === IPanelVariant.UNLOCK && !isAllowed)}
        >
          {pendingUnlock || pendingProlongate ? (
            pendingProlongate ? (
              <>
                <Dots>Increasing</Dots>
              </>
            ) : (
              <>
                <Dots>Unlocking</Dots>
              </>
            )
          ) : type === IPanelVariant.INCREASE ? (
            <Trans>Increase</Trans>
          ) : (
            <Trans>Take</Trans>
          )}
        </LockButton>
      </Flex>
    </DashboardCardFlex>
  )
}

const fill = (v: number) => (v < 10 ? `0${v}` : `${v}`)

export const DurationExplanation = ({ info }: { info: any }) => {
  const { expirationDate } = info

  const { days, hours, minutes, seconds } = usePositionTimer(expirationDate.toNumber())

  return (
    <Info>
      <InfoValue>
        <>
          {fill(days)} : {fill(hours)} : {fill(minutes)} : {fill(seconds)}
        </>
      </InfoValue>
      <InfoTitle>Duration Left</InfoTitle>
    </Info>
  )
}

/**
 */

export default VotingPanel
