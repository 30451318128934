import { useCallback } from 'react'
import { Container, TypeButton } from './styles'
import { Trans } from '@lingui/macro'

interface IButtonPanel {
  onChange: (type: IPanelJackpotsVariant) => void
  type: IPanelJackpotsVariant
}

export enum IPanelJackpotsVariant {
  JackpotA = 'A',
  JackpotB = 'B',
}

const ButtonPanel = ({ onChange, type }: IButtonPanel) => {
  const handleButtonClick = useCallback(
    (type: IPanelJackpotsVariant) => {
      onChange(type)
    },
    [onChange]
  )

  return (
    <Container>
      <TypeButton
        onClick={() => handleButtonClick(IPanelJackpotsVariant.JackpotA)}
        $active={IPanelJackpotsVariant.JackpotA === type}
      >
        <Trans>Jackpot A</Trans>
      </TypeButton>
      <TypeButton
        onClick={() => handleButtonClick(IPanelJackpotsVariant.JackpotB)}
        $active={IPanelJackpotsVariant.JackpotB === type}
      >
        <Trans>Jackpot B</Trans>
      </TypeButton>
    </Container>
  )
}

export default ButtonPanel
