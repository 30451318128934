import {
  useBattleVotingPositionInfo,
  useWithdrawDaiFromVotingPosition,
  useWithdrawZooFromVotingPosition,
} from '../../pages/NftBattlesPage/hooks'
import { ButtonUnstake } from '../Button'
import { Dots } from '../../pages/Pool/styleds'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { useCallback, useMemo, useState } from 'react'
import { fromWei } from 'utils/fromWei'
import { ZERO } from 'utils/isZero'
import { NumericalInputStyled } from 'components/NumericalInput'

const Container = styled.div``

export const InputLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-bottom: 12px;
`
export const LinkToMax = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
  justify-content: end;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #f64562;
  cursor: pointer;
`

const PLACEHOLDER = '0'

export const LiquidateZooVoting = ({
  votingPositionId,
  className,
  disabled,
}: {
  votingPositionId: string
  className?: string
  disabled?: boolean
}) => {
  const [amount, setAmount] = useState<number | undefined>()
  const { info, loading } = useBattleVotingPositionInfo(votingPositionId)

  const balance = useMemo(() => {
    return info?.zooInvested || ZERO
  }, [info])

  const { pending, withdraw } = useWithdrawZooFromVotingPosition(votingPositionId, amount)

  const setMax = useCallback(() => {
    const val = +fromWei(balance)
    setAmount(val)
  }, [setAmount, balance])

  return (
    <Container>
      <InputLine>
        <NumericalInputStyled
          value={amount}
          onUserInput={(val) => {
            setAmount(+val)
          }}
          placeholder={PLACEHOLDER}
          max={balance}
          disabled={loading}
        />
        <LinkToMax onClick={setMax}>
          <Trans>+Max</Trans>
        </LinkToMax>
      </InputLine>

      <ButtonUnstake
        disabled={pending || disabled || loading || !amount || amount <= 0}
        onClick={withdraw}
        className={className}
      >
        {pending ? (
          <Dots>
            <Trans>Unstaking</Trans>
          </Dots>
        ) : (
          <Trans>Unstake</Trans>
        )}
      </ButtonUnstake>
    </Container>
  )
}

export const LiquidateDaiVoting = ({
  votingPositionId,
  className,
  disabled,
}: {
  votingPositionId: string
  className?: string
  disabled?: boolean
}) => {
  const [amount, setAmount] = useState<number | undefined>()
  const { info, loading } = useBattleVotingPositionInfo(votingPositionId)

  const balance = useMemo(() => {
    return info?.daiInvested || ZERO
  }, [info])

  const { pending, withdraw } = useWithdrawDaiFromVotingPosition(votingPositionId, amount || 0)

  const setMax = useCallback(() => {
    const val = +fromWei(balance)
    setAmount(val)
  }, [setAmount, balance])

  return (
    <Container>
      <InputLine>
        <NumericalInputStyled
          value={amount}
          onUserInput={(val) => {
            setAmount(+val)
          }}
          placeholder={PLACEHOLDER}
          max={balance}
          disabled={loading}
        />
        <LinkToMax onClick={setMax}>
          <Trans>+Max</Trans>
        </LinkToMax>
      </InputLine>

      <ButtonUnstake
        disabled={pending || disabled || loading || !amount || amount <= 0}
        onClick={withdraw}
        className={className}
      >
        {pending ? (
          <Dots>
            <Trans>Unstaking</Trans>
          </Dots>
        ) : (
          <Trans>Unstake</Trans>
        )}
      </ButtonUnstake>
    </Container>
  )
}
