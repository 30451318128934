import { useMemo } from 'react'
import {
  useBattleArenaContract,
  useBattleStakerContract,
  useWellAddress,
  useWGlmrAddress,
} from '../../constants/zoodao'
import { useActiveWeb3React } from '../web3'

import { BigNumber, utils } from 'ethers'
import { useBattleEpoch, usePricePerShare, useTotalRewardsBattle } from '../../pages/NftBattlesPage/hooks'

import { CallState, useSingleContractMultipleData } from './../../state/multicall/hooks'
import { fromWei } from 'utils/fromWei'
import { BN_1E18, ZERO } from 'utils/isZero'

import {
  useAllLogs,
  useChosenWinners,
  useClaimedRewards,
  usePairedNfts,
  useStakerPositions,
  useTransferRewards,
  useTransition,
  useVoterPositions,
  useXZooTotalClaimed,
} from './../gql'
import { useJackpotsClaimed } from 'pages/Jackpots/Panels/InfoCards/ClaimedTotal'

export type LD = utils.LogDescription
export interface LogDescription extends LD {
  transactionHash: string
}

export function sortByIndex(a: any, b: any) {
  if (a.blockNumber === b.blockNumber) {
    return b.transactionIndex - a.transactionIndex
  }

  return b.blockNumber - a.blockNumber
}

function sortByVotes(a: any, b: any) {
  return b.numberVotes - a.numberVotes
}

const mapVotes = ({ result }: any) => {
  return result ? { ...result, numberVotes: +fromWei(result.votes) } : {}
}

export const CreatedStakerPosition = 'CreatedStakerPosition'
export const RemovedStakerPosition = 'RemovedStakerPosition'
export const CreatedVotingPosition = 'CreatedVotingPosition'
export const LiquidatedVotingPosition = 'LiquidatedVotingPosition'
export const AddedZooToVoting = 'AddedZooToVoting'
export const AddedDaiToVoting = 'AddedDaiToVoting'
export const WithdrawedDaiFromVoting = 'WithdrawedDaiFromVoting'
export const WithdrawedZooFromVoting = 'WithdrawedZooFromVoting'

export const useLastCreatedEvent = (positionId: string) => {
  const { loading, positions } = useStakerPositions(positionId)

  return useMemo(() => ({ position: positions[0], loading }), [positions, loading])
}

export const useAllVotersAndStakers = () => {
  const { positions: voted, loading: loadingV } = useVoterPositions()

  return useMemo(
    () => ({
      amountVoters: voted.length,
      loading: loadingV,
    }),
    [voted, loadingV]
  )
}

export const useCreatedStakerPositions = (isMy = true, search?: string, isAll = false) => {
  const { account } = useActiveWeb3React()
  const { positions: staked, loading } = useStakerPositions(undefined, isMy ? account : undefined, search, isAll)

  const staker = useBattleStakerContract()
  const map = useMemo(() => staked.map(({ stakingPositionId }: any) => [stakingPositionId]), [staked])
  const results = useSingleContractMultipleData(staker, 'positions', map)

  const isLoading = useMemo(() => {
    return loading || results.some((i) => i.loading || !i.result)
  }, [results, loading])

  const data = useMemo(() => {
    if (isLoading) {
      return {
        loading: true,
        staked: [],
        mappedStaked: {},
      }
    }

    const mapped = staked.reduce((acc: any, item: any) => {
      acc[item.stakingPositionId.toString()] = true
      return acc
    }, {} as any)

    const fullInfo = staked
      .filter((item: any, index: number) => !!results[index])
      .map((item: any, index: number) => ({
        ...item,
        ...results[index].result,
      }))

    return {
      staked: fullInfo,
      mappedStaked: mapped,
      loading: isLoading,
    }
  }, [staked, results, isLoading])

  return useTransition(data)
}

export const useUserBattleEvents = (search?: string, isAll = false, asAuthor = true) => {
  const { account } = useActiveWeb3React()
  const { positions: votings, loading } = useVoterPositions(undefined, undefined, account, search, isAll, asAuthor)

  return useMemo(
    () => ({
      votings,
      loading,
    }),
    [votings, loading]
  )
}

export const useBattleNftEventsForUser = (positionId: string | null) => {
  const { account } = useActiveWeb3React()

  const { positions: voted, loading: loadingV } = useVoterPositions(positionId, undefined, account)

  const { logs, loading: loadingL } = useAllLogs(account, positionId)

  return {
    votingsByUser: voted,
    loading: loadingV || loadingL,
    myEvents: logs,
  }
}
export const useAllBattleNftEvents = (positionId: string | null) => {
  const { positions: voted, loading: loadingV } = useVoterPositions(positionId)
  const { logs, loading: loadingL } = useAllLogs(null, positionId)

  return useMemo(() => {
    return {
      allEvents: logs,
      allVotings: voted,
      loading: loadingV || loadingL,
    }
  }, [voted, logs, loadingL, loadingV])
}

export const useNftBattlePairingInfo = (positionId: string) => {
  const epoch = useBattleEpoch()

  const { nfts: first, loading: loadingF } = usePairedNfts(positionId, null, epoch)
  const { nfts: second, loading: loadingS } = usePairedNfts(null, positionId, epoch)

  const data = useMemo(() => {
    const pairs = [...first, ...second]

    let pair = undefined
    if (pairs.length > 0) {
      pair = pairs[0]
    }

    return {
      loading: loadingS && loadingF,
      paired: !!pair,
      pair: pair,
    }
  }, [first, second, loadingF, loadingS])
  return useTransition(data)
}

export const useBattleNftIsStakedByMe = (positionId: string) => {
  const { account } = useActiveWeb3React()
  const { positions: staked, loading } = useStakerPositions(positionId, account)

  return {
    loading,
    isMy: staked.length > 0,
  }
}

export const useMostPopularNfts = () => {
  const epoch = useBattleEpoch()
  const pairs = useAllPaired(epoch ? epoch - 5 : undefined)

  const arena = useBattleArenaContract()

  const pairsDeps1 = useMemo(() => pairs.map(({ fighter1, currentEpoch }: any) => [fighter1, currentEpoch]), [pairs])
  const results = useSingleContractMultipleData(arena, 'rewardsForEpoch', pairsDeps1)

  const pairsDeps2 = useMemo(() => pairs.map(({ fighter2, currentEpoch }: any) => [fighter2, currentEpoch]), [pairs])
  const resultsSecond = useSingleContractMultipleData(arena, 'rewardsForEpoch', pairsDeps2)

  const { mappedStaked: stakedActivePositions, loading } = useCreatedStakerPositions(false)

  return useMemo(() => {
    if (loading || Object.keys(stakedActivePositions).length == 0) {
      return {
        loading: loading,
        nfts: [],
      }
    }

    const all = [
      ...results
        .filter(({ result }, index) => !!result && !!pairs[index])
        .map(mapVotes)
        .map((i, index) => ({
          ...i,
          stakingPositionId: pairs[index].fighter1.toString(),
          epoch: pairs[index].currentEpoch.toString(),
        })),
      ...resultsSecond
        .filter(({ result }, index) => !!result && !!pairs[index])
        .map(mapVotes)
        .map((i, index) => ({
          ...i,
          stakingPositionId: pairs[index].fighter2.toString(),
          epoch: pairs[index].currentEpoch.toString(),
        })),
    ]

    const loadingResults = isLoadingResults(all)

    if (loadingResults) {
      return {
        loading: true,
        nfts: [],
      }
    }

    const nfts = all.filter((x) => stakedActivePositions[x.stakingPositionId.toString()]).sort(sortByVotes)

    const items = []
    const unique = {} as any
    for (let i = 0; i < nfts.length; i++) {
      const item = nfts[i]

      if (!unique[item.stakingPositionId]) {
        items.push(item)
        unique[item.stakingPositionId] = true
      }
    }

    return {
      loading: false,
      nfts: items,
    }
  }, [stakedActivePositions, loading, pairs, results, resultsSecond])
}

const useAllPaired = (fromEpoch?: number) => {
  const { nfts } = usePairedNfts(undefined, undefined, undefined, undefined, fromEpoch)

  return nfts
}

export const useSumRewards = (rewards: any[]) => {
  const amount = useMemo(() => {
    return rewards.reduce((acc: BigNumber, item: any) => acc.add(item.daiReward), ZERO)
  }, [rewards])

  return amount
}

// FIXME:  and add ZOO + GLMR + WELL rewards
export const useDistributedRewards = () => {
  const { rewards, loading } = useClaimedRewards()

  const { value: xZooUsd, loading: loadingXZoo } = useXZooTotalClaimed()
  const { loading: loadingJackpots, value: jackpotsUsd } = useJackpotsClaimed()

  const wglmr = useWGlmrAddress()
  const well = useWellAddress()

  const { value: valueWglmr, loading: loadingWglmr } = useTransferRewards(undefined, wglmr)
  const { value: valueWell, loading: loadingWell } = useTransferRewards(undefined, well)

  const amountUsd = useSumRewards(rewards)

  return {
    loading: loading || loadingWell || loadingWglmr || loadingXZoo || loadingJackpots,
    amountUsd: amountUsd.add(xZooUsd).add(jackpotsUsd),
    wells: valueWell,
    wglmrs: valueWglmr,
  }
}

const useCurrentEpochWinnersList = () => {
  const epoch = useBattleEpoch()
  const { winners } = useChosenWinners(epoch)

  return useMemo(() => {
    const ids = winners.reduce((acc: any, i: any) => {
      if (i.winner) {
        acc[i.fighter1.toString()] = true
      } else {
        acc[i.fighter2.toString()] = true
      }

      return acc
    }, {} as any)

    return { stakingIds: Object.keys(ids), epoch }
  }, [winners, epoch])
}

const useAllAccountActivePositionsAtEpoch = (account: string | undefined | null, staked: string[]) => {
  const { positions: voted, loading } = useVoterPositions(null, undefined, account)

  return useMemo(() => {
    if (staked.length === 0 || !account) {
      return {
        stakings: [],
        votings: [],
      }
    }

    const stakingMap = staked.reduce((acc, i) => {
      acc[i] = true
      return acc
    }, {} as any)

    const data = voted.filter((i: any) => {
      return stakingMap[i.stakingPositionId.toString()]
    })

    return {
      stakings: staked,
      votings: data,
      loading,
    }
  }, [voted, account, loading, staked])
}

export const useAllExpectedRewards = () => {
  const { stakingIds, epoch } = useCurrentEpochWinnersList()

  const arena = useBattleArenaContract()

  const params = useMemo(() => {
    return stakingIds.map((ids) => [ids, epoch])
  }, [stakingIds, epoch])

  const results = useSingleContractMultipleData(arena, 'rewardsForEpoch', params)

  const sum = useMemo(() => {
    const loading: boolean = isLoadingResults(results)

    if (loading) {
      return ZERO
    }

    return results
      .filter((r) => r?.result?.yTokensSaldo.gte(ZERO))
      .reduce((acc, r) => {
        if (r.result) {
          acc.add(r.result.yTokensSaldo)
        }
        return acc
      }, ZERO)
  }, [results])

  const { result: usd, loading } = useTotalRewardsBattle(sum)

  return {
    usd,
    loading,
  }
}

export const useBattlePairCurrentRewardsTotal = (stakingPositionA: string, stakingPositionB: string) => {
  const { positions: votedA, loading: loadingA } = useVoterPositions(stakingPositionA)
  const { positions: votedB, loading: loadingB } = useVoterPositions(stakingPositionB)

  const allVotings = useMemo(() => {
    return [...votedA, ...votedB]
  }, [votedA, votedB])
  const votingPositions = useVotingPositions(allVotings)

  const stakingIds = useMemo(() => {
    return [stakingPositionA, stakingPositionB]
  }, [stakingPositionA, stakingPositionB])

  const data = useTotalUserRewards(votingPositions, stakingIds)
  return {
    ...data,
    loading: data.loading || loadingA || loadingB,
  }
}

export const useBattlePairCurrentRewards = (fihgters: string[], stakingPositionsIds: string[]) => {
  const { account } = useActiveWeb3React()
  const { votings } = useAllAccountActivePositionsAtEpoch(account, fihgters)

  const votingPositions = useVotingPositions(votings)

  return useTotalUserRewards(votingPositions, stakingPositionsIds)
}

const DEFAULT_ARR: any[] = []

export const useUserStakingRewards = (myStakingPositionsIds: any[]) => {
  return useTotalUserRewards(DEFAULT_ARR, myStakingPositionsIds)
}

const useVotingPositions = (votingObjs: any[]) => {
  const contract = useBattleArenaContract()
  const votIds = useMemo(() => {
    return votingObjs.map((i) => [i.votingPositionId])
  }, [votingObjs])
  const positions = useSingleContractMultipleData(contract, 'votingPositionsValues', votIds)

  return useMemo(() => {
    const isLoading = positions.some((item) => item.loading)
    if (isLoading) {
      return []
    } else {
      return positions
        .filter((_, index) => !!votingObjs[index])
        .map((item, index) => ({
          ...item.result,
          votingPositionId: votingObjs[index].votingPositionId,
        }))
    }
  }, [positions, votingObjs])
}
export const useUserVotingRewards = (votingObjs: any[]) => {
  const votingPositions = useVotingPositions(votingObjs)

  return useTotalUserRewards(votingPositions, DEFAULT_ARR)
}

export const toUsdByShare = (pricePerShare: BigNumber, share: BigNumber) => pricePerShare.mul(share).div(BN_1E18)

const useTotalUserRewards = (votings: any[], stakingPositions: string[]) => {
  const arena = useBattleArenaContract()

  const votersMap = useMemo(() => {
    return votings.map((i) => [i.votingPositionId])
  }, [votings])

  const stakersMap = useMemo(() => {
    return stakingPositions.map((i) => [i])
  }, [stakingPositions])

  const stakerRewards = useSingleContractMultipleData(arena, 'getPendingStakerReward', stakersMap)
  const voterRewards = useSingleContractMultipleData(arena, 'getPendingVoterReward', votersMap)
  const debts = useSingleContractMultipleData(arena, 'debtOfPosition', votersMap)

  const pricePerShare = usePricePerShare()

  return useMemo(() => {
    const loadingVoters = isLoadingResults(voterRewards)
    const loadingStakers = isLoadingResults(stakerRewards)
    const loadingDebts = isLoadingResults(debts)

    if (loadingVoters || loadingStakers || loadingDebts || pricePerShare.loading) {
      return {
        loading: true,
        total: ZERO,
        stakingRewards: [],
        votingRewards: [],
        stakingAmountUsd: ZERO,
        votingAmountUsd: ZERO,
        votingWells: ZERO,
        votingGlmrs: ZERO,
      }
    }

    const staking = stakerRewards.map((item, index) => {
      return {
        loading: false,
        amount: toUsdByShare(pricePerShare.result, item?.result?.[0] || ZERO),
        stakingPositionId: stakingPositions[index],
      }
    })

    const voting = voterRewards
      .filter((_, index) => !!votings[index])
      .map((item, index) => {
        const debtY = votings[index].yTokensRewardDebt

        const debt: any = debts[index]

        return {
          amountUsd: toUsdByShare(pricePerShare.result, (item?.result?.yTokens || ZERO).add(debtY)),
          glmrs: (item?.result?.glmrs || ZERO).add(debt.results?.glmrs || ZERO),
          wells: (item?.result?.wells || ZERO).add(debt.results?.wells || ZERO),
          votingPositionId: votings[index].votingPositionId,
        }
      })

    const votingAmountUsd = voting.reduce((acc: BigNumber, item) => acc.add(item.amountUsd), ZERO)
    const votingWells = voting.reduce((acc: BigNumber, item) => (item.wells ? acc.add(item.wells) : acc), ZERO)
    const votingGlmrs = voting.reduce((acc: BigNumber, item) => (item.glmrs ? acc.add(item.glmrs) : acc), ZERO)
    const stakingAmountUsd = staking.reduce((acc: BigNumber, item) => acc.add(item.amount), ZERO)

    return {
      total: votingAmountUsd.add(stakingAmountUsd),

      stakingRewards: staking,
      stakingAmountUsd,
      votingAmountUsd,
      votingWells,
      votingGlmrs,

      loading: false,
    }
  }, [voterRewards, debts, pricePerShare, stakerRewards, votings, stakingPositions])
}

function isLoadingResults(results: CallState[]): boolean {
  return results.some(({ loading }) => loading)
}
