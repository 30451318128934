import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import { SupportedChainId } from '../../constants/chainsinfo'
import { shortenAddress, shortenTx } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import {
  ExternalLinkStyled,
  TdId,
  Tr,
  Th,
  Td,
  TheadStyled,
  TbodyStyled,
  TableContainer,
  Table,
  CardContainerTable,
} from './styleds'
import { TableTransactionsForInfiniteScroll, TABS_FOR_TABLE } from './TableTemplate'
import moment from 'moment'
import { Dots } from 'pages/Pool/styleds'
import { useBattleStakerPositionInfo } from 'pages/NftBattlesPage/hooks'
import styled from 'styled-components/macro'
import { useInfiniteScrollProps } from './NftBattlesNfts'
import InfiniteScroll from 'react-infinite-scroll-component'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import { formatDecimal } from 'utils/numberWithCommas'
import { useContractName } from 'hooks/useContractName'
import { useJackpotsLogs, useStakingLogs } from 'hooks/gql'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'

const useBattleTxs = (tab: string) => {
  const { account } = useActiveWeb3React()

  const target = useMemo(() => (!tab || tab === TABS_FOR_TABLE.ALL ? undefined : account), [tab, account])

  const { logs, loading } = useStakingLogs(target)

  return useMemo(() => {
    return {
      events: logs,
      loading: loading,
    }
  }, [logs, loading])
}

/* INIFINTE SCROLL START */

const ZooLoaderStyled = styled(ZooLoader)`
  width: 100%;
  height: 200px;
`

const PER_PAGE = 15

interface IScrollProps {
  itemsTotal: any[]
  renderItem: (item: any, index: number) => any
  className?: string
  contentBuilder: (content: any) => any
}

const InfiniteScrollList = ({ className, itemsTotal, renderItem, contentBuilder }: IScrollProps) => {
  const { currentList, hasMore, fetchData } = useInfiniteScrollProps(itemsTotal, PER_PAGE)

  const content = useMemo(
    () => currentList.map((item: any, index: number) => renderItem(item, index)),
    [currentList, renderItem]
  )

  return (
    <InfiniteScroll
      className={className}
      dataLength={currentList.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<ZooLoaderStyled loading={true} />}
      scrollableTarget="body_over"
    >
      {contentBuilder(content)}
    </InfiniteScroll>
  )
}

const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  overflow: visible !important;
`

/* INFINITE SCROLL END */

export const JackpotsTransactions = ({ type }: { type: IPanelJackpotsVariant }) => {
  const { logs } = useJackpotsLogs(type)

  return <InfiniteScrollJackpotsTemplate events={logs} />
}

const InfiniteScrollJackpotsTemplate = ({ events }: { events: any }) => (
  <CardContainerTable>
    <InfiniteScrollListStyled
      itemsTotal={events}
      renderItem={(item: any) => <ListItemJackpot e={item} key={item.id} />}
      contentBuilder={(content: any) => (
        <TableContainer>
          <Table>
            <TheadStyled>
              <Tr>
                <Th>
                  <Trans>Timestamp</Trans>
                </Th>
                <Th>
                  <Trans>Epoch</Trans>
                </Th>
                <Th>
                  <Trans>Action</Trans>
                </Th>
                <Th>
                  <Trans>Transaction</Trans>
                </Th>
              </Tr>
            </TheadStyled>
            <TbodyStyled>{content}</TbodyStyled>
          </Table>
        </TableContainer>
      )}
    />
  </CardContainerTable>
)

const InfiniteScrollTemplate = ({ events }: { events: any }) => (
  <InfiniteScrollListStyled
    itemsTotal={events}
    renderItem={(item: any) => <ListItem e={item} key={item.id} />}
    contentBuilder={(content: any) => (
      <TableContainer>
        <Table>
          <TheadStyled>
            <Tr>
              <Th>
                <Trans>Timestamp</Trans>
              </Th>
              <Th>
                <Trans>Action</Trans>
              </Th>
              <Th>
                <Trans>Collection</Trans>
              </Th>
              <Th>
                <Trans>Author</Trans>
              </Th>
              <Th>
                <Trans>Transaction</Trans>
              </Th>
            </Tr>
          </TheadStyled>
          <TbodyStyled>{content}</TbodyStyled>
        </Table>
      </TableContainer>
    )}
  />
)

export const BattleStakingTransactions = () => {
  return <TableTransactionsForInfiniteScroll el={BattleStakingTransactionsInner} />
}

export const BattleStakingTransactionsInner = ({ tab }: { tab: string }) => {
  const { events } = useBattleTxs(tab)

  return <InfiniteScrollTemplate events={events} />
}

export const BattleNftTransactions = ({ myEvents, allEvents }: { myEvents: any[]; allEvents: any[] }) => {
  return (
    <TableTransactionsForInfiniteScroll
      el={({ tab }) => <BattleNftTransactionsInner tab={tab} myEvents={myEvents} allEvents={allEvents} />}
    />
  )
}

export const BattleNftTransactionsInner = ({
  tab,
  myEvents,
  allEvents,
}: {
  tab: string
  myEvents: any[]
  allEvents: any[]
}) => {
  const events = useMemo(() => {
    return tab === TABS_FOR_TABLE.MY ? myEvents : allEvents
  }, [tab, myEvents, allEvents])

  return <InfiniteScrollTemplate events={events} />
}

const ListItem = ({ e }: { e: any }) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const {
    currentEpoch,
    staker,
    author,
    voter,
    beneficiary,
    stakingPositionId,
    daiNumber,
    daiAmount,
    daiReceived,
    zooReturned,
    zooNumber,
    amount,
    timestamp,
    transactionHash,

    isStaked,
    isVoted,
    isLiquidated,
    isRemoved,
    isAddedZoo,
    isAddedDai,
    isRemovedZoo,
    isRemovedDai,
  } = e

  const { positionInfo, loading } = useBattleStakerPositionInfo(stakingPositionId)

  const { name: contractName } = useContractName(positionInfo.token)

  const isPositive = isStaked || isVoted || isAddedZoo || isAddedDai

  const amountValue = daiNumber || zooNumber || amount || daiAmount

  return (
    <Tr key={transactionHash}>
      <Td>
        <Trans>Epoch #</Trans>
        {currentEpoch.toString()}
        &nbsp;,&nbsp;
        {moment(new Date(timestamp)).format('MMMM Do YYYY, h:mm a')}
      </Td>
      <TdId positive={isPositive}>
        {isStaked && <Trans>Staked NFT</Trans>}
        {isRemoved && <Trans>Unstaked NFT</Trans>}
        {isLiquidated && (
          <>
            <Trans>Liquidated Voting Position</Trans>
            <br />
            {isRemovedZoo && (
              <>
                {formatDecimal(amountValue || zooReturned)}&nbsp;<Trans>ZOO</Trans>
              </>
            )}
            ,&nbsp;&nbsp;
            {isRemovedDai && (
              <>
                {formatDecimal(amountValue || daiReceived)}&nbsp;<Trans>USD</Trans>
              </>
            )}
          </>
        )}
        {(isAddedDai || isVoted) && (
          <>
            <Trans>Staked</Trans>&nbsp;{formatDecimal(amountValue)}&nbsp;<Trans>USD</Trans>
          </>
        )}
        {isAddedZoo && (
          <>
            <Trans>Staked</Trans>&nbsp;{formatDecimal(amountValue)}&nbsp;<Trans>ZOO</Trans>
          </>
        )}
        {!isLiquidated && isRemovedZoo && (
          <>
            <Trans>Unstaked</Trans>&nbsp;{formatDecimal(amountValue || zooReturned)}&nbsp;<Trans>ZOO</Trans>
          </>
        )}
        {!isLiquidated && isRemovedDai && (
          <>
            <Trans>Unstaked</Trans>&nbsp;{formatDecimal(amountValue || daiReceived)}&nbsp;<Trans>USD</Trans>
          </>
        )}
      </TdId>
      <Td>
        {loading || !positionInfo ? (
          <Dots>Loading</Dots>
        ) : (
          <>
            {contractName} #{positionInfo ? positionInfo?.id?.toString() : null}
          </>
        )}
      </Td>
      <Td>
        <ExternalLinkStyled
          href={getExplorerLink(chainId, author || staker || voter || beneficiary, ExplorerDataType.ADDRESS)}
        >
          {shortenAddress(author || staker || voter || beneficiary)}
        </ExternalLinkStyled>
      </Td>
      <Td>
        <ExternalLinkStyled href={getExplorerLink(chainId, transactionHash, ExplorerDataType.TRANSACTION)}>
          {shortenTx(transactionHash, 10)}
        </ExternalLinkStyled>
      </Td>
    </Tr>
  )
}

const ListItemJackpot = ({ e }: { e: any }) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { epoch, transactionHash, timestamp, rewards, winner } = e

  return (
    <Tr key={transactionHash}>
      <Td>{moment(new Date(timestamp)).format('MMMM Do YYYY, h:mm a')}</Td>
      <Td>
        <Trans>Epoch #</Trans>
        {epoch.toString()}
      </Td>
      <TdId positive={!!rewards} purple={!!winner}>
        {rewards && (
          <>
            <Trans>Claimed {formatDecimal(rewards, 8)} Rewards</Trans>
          </>
        )}
        {winner && (
          <>
            <Trans>Chose Winner</Trans>
          </>
        )}
      </TdId>
      <Td>
        <ExternalLinkStyled href={getExplorerLink(chainId, transactionHash, ExplorerDataType.TRANSACTION)}>
          {shortenTx(transactionHash, 10)}
        </ExternalLinkStyled>
      </Td>
    </Tr>
  )
}
