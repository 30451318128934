import { createTheme } from '@mui/material/styles'

const muiTheme = () =>
  createTheme({
    spacing: 1,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            padding: '0',
            lineHeight: 'normal',
          },
        },
      },
    },
  })

export default muiTheme