import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'
import { CardText, IDashCard } from '../Dashboard/styleds'
import { Table as ITable, Tr as ITr, Th as ITh, Td as ITd, Thead, Tbody } from 'react-super-responsive-table'
import { CardContainer } from 'components/EpochBanner/EpochBanner'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

export const TheadStyled = styled(Thead)``
export const TbodyStyled = styled(Tbody)``

export const StageContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-gap: 35px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
    grid-template-columns: 2fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
    grid-template-columns: 2fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-template-columns: 1fr;
  `};
`

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const CardTextStyled = styled(CardText)`
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 34px;
    line-height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 24px;
    line-height: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    font-size: 18px;
    line-height: 24px;
  `};
`

export const CardLine = styled.div<IDashCard>`
  width: 38px;
  min-height: 6px;
  background: ${({ index, theme }) => {
    switch (index) {
      case 0: {
        return theme.orange
      }
      case 1: {
        return '#F64562'
      }
      case 2: {
        return '#e138f5'
      }
      case 3: {
        return '#FE01B0'
      }
      case 4: {
        return '#5e2fdd'
      }
      case 5: {
        return '#3D6DFC'
      }
      case 6: {
        return '#05b6f9'
      }
      case 7: {
        return '#1fad1f'
      }
      case 8: {
        return '#1fad1f'
      }
      case 9: {
        return '#effe04'
      }
      case 10: {
        return '#f8ac00'
      }
      case 11: {
        return '#fff'
      }
      case 12: {
        return 'linear-gradient(90deg, #1fad1f, #3d6dfc)'
      }
      case 13: {
        return 'linear-gradient(90deg, #FE5E00 12.38%, #F64562 51.8%, #C22DE1 91.43%)'
      }
      case 14: {
        return '#C627CD'
      }
      case 15: {
        return 'linear-gradient(90deg, #f8ac00 12.38%, #F64562 91.43%)'
      }
      case 16: {
        return 'linear-gradient(90deg, #016eda, #d900c0);'
      }
      case 17: {
        return 'linear-gradient(90deg, #fbc856, #f9ab0f);'
      }
      case 18: {
        return 'linear-gradient(90deg, #f4b731 15%, #54ae94 50%, #2775ca 85%);'
      }
      case 19: {
        return 'linear-gradient(114.44deg, #7433FF 0%, #FFA3FD 100%);'
      }
    }

    return theme.bg2
  }};

  border-radius: 24px;
  margin-bottom: 15px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    margin-bottom: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-bottom: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    margin-bottom: 15px;
    display: none;
  `};
`

export const CardContent = styled.div<{ inactive?: boolean }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.5' : '1')};
  display: flex;
  justify-content: space-between;
  position: relative;
`

export const NftBattlesContainer = styled.div`
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const CardMini = styled.div<{ inactive?: boolean; onClick?: () => void }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.4' : '1')};
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 25px;
  align-self: center;
  min-height: 240px;
  width: 100%;
  flex-grow: 1;
  height: 100%;

  ${({ onClick }) => onClick && `cursor: pointer;`}

  ${({ theme }) => theme.mediaWidth.upToMedium`
  min-height: 215px;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  min-height: 215px;
`};
`

export const CardMedium = styled(CardMini)`
  height: 100%;
`

export const CardContainerTable = styled(CardContainer)`
  overflow: auto;
  background: rgb(51, 51, 75);
  width: 100%;
`

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 30px;
  `};

  margin-bottom: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    margin-bottom: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-bottom: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    margin-bottom: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 15px;
  `};
`

export const Tab = styled.div<{ active: boolean }>`
  display: flex;
  font-size: 18px;
  line-height: 24px;

  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
  cursor: pointer;
  margin-right: 15px;
  text-transform: capitalize;

  color: ${({ theme, active }) => (active ? '#f64562' : theme.text2)};

  border-bottom: ${({ active }) => (active ? '6px solid #f64562' : 'none')};
  box-sizing: border-box;
  margin-bottom: ${({ active }) => (active ? '-6px' : 'none')};
  border-radius: 6px;
  padding-bottom: 6px;

  &:last-child {
    margin-right: 0px;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontUniform};
  color: ${({ theme }) => theme.grayscale};
  font-size: 37px;
  line-height: 47px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 37px;
    line-height: 47px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 27px;
    line-height: 37px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 18px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 18px;
    line-height: 28px;
  `};
`

export const TableContainer = styled.div``

export const ExternalLinkStyled = styled(ExternalLink)`
  color: ${({ theme }) => theme.text5};
`

export const Table = styled(ITable)`
  width: 100%;
  height: 100%;
  border-spacing: 0 10px;
  padding-right: 5px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 50px;
  `};
`

export const Th = styled(ITh)`
  white-space: nowrap;
  background-color: #202136;
  border: 2px solid #202136;
  padding: 25px;
  font-family: ${({ theme }) => theme.fontUniform};
  font-size: 18px;
  line-height: 22px;
  box-sizing: border-box;
  text-align: left;

  &:first-child {
    border-radius: 24px 0 0 24px;
  }
  &:last-child {
    border-radius: 0 24px 24px 0;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 26px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

export const Td = styled(ITd)`
  vertical-align: top;
  padding: 25px;
  font-family: ${({ theme }) => theme.fontCarmen};
  font-size: 21px;
  line-height: 29px;
  box-sizing: border-box;
  text-align: left;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  &:first-child {
    border-radius: 24px 0 0 24px;
    border-left: 2px solid transparent;
  }
  &:last-child {
    border-radius: 0 24px 24px 0;
    border-right: 2px solid transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 21px;
    line-height: 29px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 14px;
    line-height: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
    line-height: 16px;
  `};
`

export const TdId = styled(Td)<{ positive?: boolean; purple?: boolean }>`
  color: ${({ theme, positive, purple }) => (positive ? theme.green : purple ? theme.purple : theme.red)};
`

export const Tr = styled(ITr)`
  box-sizing: border-box;

  border: none !important;
  background-color: #27263f;

  &:nth-child(even) {
    background-color: #202136;
  }
  &:hover {
    Td {
      background-color: #2d2137;
      border-top: 2px solid #f64562;
      border-bottom: 2px solid #f64562;
      &:first-child {
        border-left: 2px solid #f64562;
      }
      &:last-child {
        border-right: 2px solid #f64562;
      }
    }
  }
`

export const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
`
