import React, { useState, useCallback, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { CardContainer, DEFAULT, getDates, ILeftDays, SECOND } from '../../components/EpochBanner/EpochBanner'
import { BATTLE_STAGES, useBattlesData, useBattleStage, useUpdateEpoch } from '../NftBattlesPage/hooks'
import { CardRow } from './styleds'
import { ButtonGradient } from '../../components/Button'
import { Dots } from '../Pool/styleds'
import { RenderDates } from './BattleClosing'

export const useBattleStageTime = () => {
  const stage = useBattleStage()

  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const {
    epochStartDate,
    firstStageDuration,
    secondStageDuration,
    thirdStageDuration,
    fourthStageDuration,
    fifthStageDuration,
  } = useBattlesData()

  const calculateBorder = useCallback((): ILeftDays => {
    let duration = 0

    if (stage === BATTLE_STAGES.FIRST) {
      duration += firstStageDuration
    } else if (stage === BATTLE_STAGES.SECOND) {
      duration += firstStageDuration + secondStageDuration
    } else if (stage === BATTLE_STAGES.THIRD) {
      duration += firstStageDuration + secondStageDuration + thirdStageDuration
    } else if (stage === BATTLE_STAGES.FOURTH) {
      duration += firstStageDuration + secondStageDuration + thirdStageDuration + fourthStageDuration
    } else if (stage === BATTLE_STAGES.FIFTH) {
      duration +=
        firstStageDuration + secondStageDuration + thirdStageDuration + fourthStageDuration + fifthStageDuration
    }

    const date = new Date(epochStartDate * 1000)

    return getDates(date, duration * 1000)
  }, [
    epochStartDate,
    stage,
    firstStageDuration,
    secondStageDuration,
    thirdStageDuration,
    fourthStageDuration,
    fifthStageDuration,
  ])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  return border
}

export const useTargetBattleStageTime = (stage: BATTLE_STAGES) => {
  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const {
    epochStartDate,
    firstStageDuration,
    secondStageDuration,
    thirdStageDuration,
    fourthStageDuration,
    epochDuration,
  } = useBattlesData()

  const calculateBorder = useCallback((): ILeftDays => {
    let duration = 0

    if (stage === BATTLE_STAGES.FIRST) {
      duration += firstStageDuration
    } else if (stage === BATTLE_STAGES.SECOND) {
      duration += firstStageDuration + secondStageDuration
    } else if (stage === BATTLE_STAGES.THIRD) {
      duration += firstStageDuration + secondStageDuration + thirdStageDuration
    } else if (stage === BATTLE_STAGES.FOURTH) {
      duration += firstStageDuration + secondStageDuration + thirdStageDuration + fourthStageDuration
    } else if (stage === BATTLE_STAGES.FIFTH) {
      duration += epochDuration
    }

    const date = new Date(epochStartDate * 1000)

    return getDates(date, duration * 1000)
  }, [
    epochStartDate,
    stage,
    firstStageDuration,
    secondStageDuration,
    thirdStageDuration,
    fourthStageDuration,
    epochDuration,
  ])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  return border
}

const RenderDatesStyled = styled(RenderDates)`
  margin-left: 24px;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    margin-left: 12px;
  `};
`

const Header = styled.div<{ stage: number }>`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f56738;
  display: flex;

  ${({ stage }) => (stage === BATTLE_STAGES.FIRST ? 'background: #f56738;' : null)};
  ${({ stage }) => (stage === BATTLE_STAGES.SECOND ? 'background: #F64562;' : null)};
  ${({ stage }) => (stage === BATTLE_STAGES.THIRD ? 'background: #e138f5;' : null)};
  ${({ stage }) => (stage === BATTLE_STAGES.FOURTH ? 'background: #05b6f9' : null)};
  ${({ stage }) => (stage === BATTLE_STAGES.FIFTH ? 'background: #1fad1f;' : null)};

  -webkit-background-clip: text;
  color: transparent;

  font-size: 32px;
  line-height: 50px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
  `};
`

const CardContainerStyled = styled(CardContainer)`
  padding: 0;
  min-width: initial;
  border-radius: 0px;
  background: none;
  box-shadow: none;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: start;
`

export const EpochUpdater = () => {
  const { onUpdate, pending } = useUpdateEpoch()
  const currentStage = useBattleStage()

  if (currentStage <= BATTLE_STAGES.FOURTH) {
    return null
  }

  return (
    <ButtonGradient disabled={pending} onClick={onUpdate} style={{ marginLeft: '25px' }}>
      {pending ? (
        <Dots>
          <Trans>Starting</Trans>
        </Dots>
      ) : (
        <>
          <Trans>Start New Season</Trans>
        </>
      )}
    </ButtonGradient>
  )
}

export const StageIndicator = () => {
  const currentStage = useBattleStage()

  const data = useBattleStageTime()

  return (
    <CardContainerStyled style={{ width: 'initial' }}>
      <CardRow>
        <Header stage={currentStage}>
          <Trans>Stage</Trans> {currentStage + 1}
        </Header>

        <RenderDatesStyled showLocked={false} data={data} showSeconds />
        {data.noTime && <EpochUpdater />}
      </CardRow>
    </CardContainerStyled>
  )
}
