import { IDashCard, CardLine, DashboardCard, CardHeader, CardContent, CardText } from '../../styleds'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { Dots } from 'pages/Pool/styleds'
import { useJackpotsWinners } from 'hooks/gql'
import { IPanelJackpotsVariant } from '../ButtonPanel'
import { formatDecimal } from 'utils/numberWithCommas'
import { usePendingJackpotRewards } from 'pages/NftBattlesPageV2/cards/JackpotsNFTOverview/hooks'
import { ClaimJackpot } from 'pages/NftBattlesPageV2/cards/JackpotsNFTOverview/JackpotsNFTOverview'
import { useIsOwner } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import { useJackpotContract } from 'constants/zoodao'

export const CardTextStyled = styled(CardText)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

interface IProps extends IDashCard {
  type: IPanelJackpotsVariant
}

const ClaimableTotal = ({ index, type }: IProps) => {
  const { data, loading } = useJackpotsWinners(type)

  return (
    <DashboardCard>
      <CardLine index={index} />
      <CardHeader>
        <Trans>Your Wins</Trans>
      </CardHeader>

      <CardContent>
        <CardTextStyled>{loading ? <Dots>Loading</Dots> : null}</CardTextStyled>

        {data.length === 0 && (
          <Row>
            <Trans>No claimable rewards</Trans>
          </Row>
        )}
        {data.map((item: any) => (
          <ClaimRow key={item.id} item={item} />
        ))}
      </CardContent>
    </DashboardCard>
  )
}

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 12px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 18px;

  &:last-child {
    margin: 0;
  }
`

const RewardsStyled = styled.div`
  font-size: 16px;
`

const ClaimRow = ({ item }: { item: any }) => {
  const contract = useJackpotContract(item.type)

  const isOwner = useIsOwner(item.winner, contract)

  if (!isOwner) {
    return null
  }

  return (
    <Row>
      <Trans>Season {item.epoch}</Trans>

      <RewardsBlock item={item} />
    </Row>
  )
}

const RewardsBlock = ({ item }: { item: any }) => {
  const { loading, rewards } = usePendingJackpotRewards(item.type, item.winner, item.epoch)

  const noRewards = rewards.isZero()

  return (
    <>
      <RewardsStyled>
        {loading ? (
          <Dots>
            <Trans>Loading</Trans>
          </Dots>
        ) : noRewards ? (
          <Trans>Rewards Were Claimed</Trans>
        ) : (
          <Trans>Rewards {formatDecimal(rewards, 2)}</Trans>
        )}
      </RewardsStyled>

      <ClaimJackpot panelType={item.type} jackpotPositionId={item.winner} epoch={item.epoch} noRewards={noRewards} />
    </>
  )
}
export default ClaimableTotal
