import { Trans } from '@lingui/macro'
import { Token } from '@uniswap/sdk-core'
import { Text } from 'rebass'
import { Currency } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import { COMMON_BASES } from '../../constants/routing'
import { currencyId } from '../../utils/currencyId'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { useActiveWeb3React } from 'hooks/web3'
import { useMemo } from 'react'
import { SupportedChainId } from 'constants/chainsinfo'
import { useNftPartnersList } from 'pages/VePie/useNftPartnersList'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  color: ${({ theme, disable }) => disable && theme.text3};
  background-color: ${({ theme, disable }) => disable && theme.bg3};
  filter: ${({ disable }) => disable && 'grayscale(1)'};
`

export const useCommonBases = () => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const partners = useNftPartnersList()

  return useMemo(() => {
    // FIXME on PRODUCTION
    if (chainId === SupportedChainId.MAINNET && !partners.loading) {
      return partners
        ? partners.allCollectionSlugs.map((item) => new Token(chainId, item.address, 0, item.slug, item.slug))
        : []
    }
    const list = COMMON_BASES[chainId]
    return list
  }, [chainId, partners])
}

export const useAllSuggestedNFTTokens = () => {
  const list = useCommonBases()

  return useMemo(() => {
    return !list
      ? []
      : list.map((item) => {
          return new WrappedTokenInfo(
            {
              name: '',
              symbol: '',
              ...item.wrapped,
            },
            {} as any
          )
        })
  }, [list])
}

export default function CommonBases({
  onSelect,
  selectedCurrency,
}: {
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const bases = useCommonBases()

  return bases.length > 0 ? (
    <MobileWrapper gap="md">
      <AutoRow>
        <Text fontWeight={500} fontSize={14} marginTop={24}>
          <Trans>Suggestions</Trans>
        </Text>
      </AutoRow>
      <AutoRow gap="4px">
        {bases.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)
          return (
            <BaseWrapper
              onClick={() => !isSelected && onSelect(currency)}
              disable={isSelected}
              key={currencyId(currency)}
            >
              <CurrencyLogo currency={currency} style={{ marginRight: 8 }} />
              <Text fontWeight={500} fontSize={16}>
                {currency.symbol}
              </Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </MobileWrapper>
  ) : null
}
