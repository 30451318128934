import { Trans } from '@lingui/macro'
import { useState } from 'react'
import { Table } from 'react-super-responsive-table'
import { CardContainerTable, Tabs, Tab, TableContainer } from './styleds'

interface IProps {
  el: (e: any) => any
}

export const TABS_FOR_TABLE = {
  ALL: 'ALL',
  MY: 'MY',
}

export const TableTransactions = ({ el: El }: IProps) => {
  const [tab, setTab] = useState(TABS_FOR_TABLE.ALL)

  return (
    <CardContainerTable>
      <Tabs>
        <Tab onClick={() => setTab(TABS_FOR_TABLE.ALL)} active={tab === TABS_FOR_TABLE.ALL}>
          <Trans>All Transactions</Trans>
        </Tab>
        <Tab onClick={() => setTab(TABS_FOR_TABLE.MY)} active={tab === TABS_FOR_TABLE.MY}>
          <Trans>My Transactions</Trans>
        </Tab>
      </Tabs>

      <TableContainer>
        <Table>
          <El tab={tab} />
        </Table>
      </TableContainer>
    </CardContainerTable>
  )
}

export const TableTransactionsForInfiniteScroll = ({ el: El }: IProps) => {
  const [tab, setTab] = useState(TABS_FOR_TABLE.ALL)

  return (
    <CardContainerTable>
      <Tabs>
        <Tab onClick={() => setTab(TABS_FOR_TABLE.ALL)} active={tab === TABS_FOR_TABLE.ALL}>
          <Trans>All Transactions</Trans>
        </Tab>
        <Tab onClick={() => setTab(TABS_FOR_TABLE.MY)} active={tab === TABS_FOR_TABLE.MY}>
          <Trans>My Transactions</Trans>
        </Tab>
      </Tabs>

      <El tab={tab} />
    </CardContainerTable>
  )
}
