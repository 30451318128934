import { DashboardCardFlex, CardLine } from 'pages/Dashboard/styleds'
import { CardHeadBox } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import IconXZOO from 'assets/svg/logo_xZOO_token.svg'
import { LogoCoin, TxtBalance, InputLabel, VoteButton } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import { useClaimRewardsXZoo, useTargetPositionForUser, useXZooPendingRewards } from './hooks'
import { formatDecimal } from 'utils/numberWithCommas'
import { Dots } from 'pages/Pool/styleds'
import { useXZooTotalClaimedByUser } from 'hooks/gql'
import { useMemo } from 'react'
import { ZERO } from 'utils/isZero'

const ClaimableRewardsXZOO = () => {
  const { position, loading: loadingPositions } = useTargetPositionForUser()

  const { tokenId: pid } = position || {}
  const { rewards, loading } = useXZooPendingRewards(pid)

  const { action, pending } = useClaimRewardsXZoo(pid)

  const { claimed, loading: loadingClaimed } = useXZooTotalClaimedByUser()

  const claimedTotal = useMemo(() => {
    return claimed.reduce((acc, item) => {
      return acc.add(item.amount)
    }, ZERO)
  }, [claimed])

  return (
    <DashboardCardFlex inactive={!pid || loadingPositions}>
      <CardLine index={19} />

      <CardHeadBox>
        <CardHeadContribution>
          <LogoCoin src={IconXZOO} />
          <Trans>Claimable xZOO Rewards</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <InputLabel mt={25}>
        <Trans>My USD rewards:</Trans>&nbsp;
        {loading ? <Dots>Loading</Dots> : <TxtBalance>{formatDecimal(rewards, 10)}</TxtBalance>}
      </InputLabel>

      <Flex alignItems="center" justifyContent="space-between" mt={25}>
        <InputLabel>
          <Trans>My rewards claimed:</Trans>&nbsp;
          <TxtBalance>
            {loadingClaimed ? (
              <Dots>
                <Trans>Loading</Trans>
              </Dots>
            ) : (
              formatDecimal(claimedTotal, 10)
            )}
          </TxtBalance>
        </InputLabel>
        <VoteButton onClick={action} disabled={pending || rewards.isZero()}>
          {pending ? (
            <Dots>
              <Trans>Claiming</Trans>
            </Dots>
          ) : (
            <Trans>Claim</Trans>
          )}
        </VoteButton>
      </Flex>
    </DashboardCardFlex>
  )
}

export default ClaimableRewardsXZOO
