import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { useZooFarmData } from '../../hooks/zoodao/useZooFarmData'

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 25px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0;
  align-self: start;
`

const RighItem = styled.div`
  position: absolute;
  width: 228px;
  height: 217px;
  top: -94px;
  background: linear-gradient(135deg, #fe6100 15.71%, #e13b95 74.66%);
  transform: rotate(-90deg);
  border-radius: 50%;
  right: -44px;
`

const Title = styled.div`
  font-size: 36px;
  line-height: 41px;
  color: ${({ theme }) => theme.text1};
  font-family: ${({ theme }) => theme.fontUniform};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 20px;
  `};
`

const Description = styled.div`
  margin-top: 27px;
  color: ${({ theme }) => theme.text2};
`

const Times = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  margin-top: 20px;
`

const TimeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const TimeBlockTitle = styled.div`
  font-size: 37px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: 800;

  color: ${({ theme }) => theme.text1};
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 28px;
  `};
`

const TimeBlockValue = styled.div`
  margin-top: 21px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 11px;
  background: #ffbcbc;
  font-size: 64px;
  color: ${({ theme }) => theme.text1Reverse};
  font-family: ${({ theme }) => theme.fontUniform};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 28px;
  `};
`

const SecondTimeBlockValue = styled(TimeBlockValue)`
  background: #febcff;
`

const ThirdTimeBlockValue = styled(TimeBlockValue)`
  background: #cdbcff;
`

export const SECOND = 1000
export const MINUTE = SECOND * 60
export const HOUR = MINUTE * 60
export const DAY = HOUR * 24
export const ONE_WEEK = 7 * DAY

export interface ILeftDays {
  days: number
  hours: number
  minutes: number
  seconds: number
  endDate?: Date
  isValid: boolean
  noTime?: boolean
}

export const formatEpochItem = (v: number, isValid: boolean) => (v < 0 || !isValid ? '--' : v >= 10 ? v : `0${v}`)

export const DEFAULT: ILeftDays = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  isValid: false,
  noTime: false,
}

export const getDates = (date: Date, duration: number) => {
  const endDate = new Date(date).setTime(date.getTime() + duration)
  const diff = endDate - new Date().getTime()

  const days = Math.max(0, Math.floor(diff / DAY))

  const hours = Math.max(0, Math.floor((diff - days * DAY) / HOUR))

  const minutes = Math.max(0, Math.floor((diff - days * DAY - hours * HOUR) / MINUTE))

  const seconds = Math.max(0, Math.floor((diff - days * DAY - hours * HOUR - minutes * MINUTE) / SECOND))

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    endDate: new Date(endDate),
    isValid: days >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0,
    noTime: diff < 0 || (days === 0 && hours === 0 && minutes === 0 && seconds === 0),
  }
}

export const useEpoch = () => {
  const { lastUpdateDate } = useZooFarmData()
  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const calculateBorder = useCallback((): ILeftDays => {
    const date = new Date(lastUpdateDate * 1000)

    return getDates(date, ONE_WEEK)
  }, [lastUpdateDate])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  const { days, hours, minutes } = border

  const isValid = days >= 0 && hours >= 0 && minutes >= 0

  return {
    isValid,
    days,
    hours,
    minutes,
    lastUpdateDate,
  }
}

interface IProps {
  className?: string
}

const EpochBanner = ({ className }: IProps) => {
  const { days, hours, minutes, isValid } = useEpoch()

  return (
    <CardContainer className={className}>
      <RighItem />

      <Title>
        <Trans>Until</Trans>
        <br />
        <Trans>Next Epoch</Trans>
      </Title>

      <Description>
        <Trans>We give out awards once a week. Until the next hand is left ...</Trans>
      </Description>

      <Times>
        <TimeBlock>
          <TimeBlockTitle>d</TimeBlockTitle>
          <TimeBlockValue>{formatEpochItem(days, isValid)}</TimeBlockValue>
        </TimeBlock>

        <TimeBlock>
          <TimeBlockTitle>h</TimeBlockTitle>
          <SecondTimeBlockValue>{formatEpochItem(hours, isValid)}</SecondTimeBlockValue>
        </TimeBlock>

        <TimeBlock>
          <TimeBlockTitle>m</TimeBlockTitle>
          <ThirdTimeBlockValue>{formatEpochItem(minutes, isValid)}</ThirdTimeBlockValue>
        </TimeBlock>
      </Times>
    </CardContainer>
  )
}

export default EpochBanner
