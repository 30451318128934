import { SupportedChainId } from 'constants/chainsinfo'
import { COMMON_BASES } from 'constants/routing'
import { BigNumber } from 'ethers'
import { useNftPartnersLogos } from 'pages/VePie/useNftPartnersLogos'
import { useMemo } from 'react'
import { useTransition, useSWR as useSWRTransition, useNftScanImg } from './gql'
import useENSAddress from './useENSAddress'
import { useActiveWeb3React } from './web3'
import { getOpenseaFixtures, IconNftTemplate, INftImgInfo, NFT_DEFAULT } from './zoodao/useNftInfo'

export const X_API_KEY = 'rQB9CURvvzljLfntrYixcWH0'

const getMoonbeamNftsAPI = (account: string | null) =>
  `https://moonbeamapi.nftscan.com/api/v2/account/own/all/${account}?erc_type=erc721`

const getArbitrumNftsAPI = (account: string | null) =>
  `https://arbitrumapi.nftscan.com/api/v2/account/own/${account}?erc_type=erc721`

const getETHNftsAPI = (account: string | null) =>
  `https://restapi.nftscan.com/api/v2/account/own/all/${account}?erc_type=erc721`

interface INftScanNft {
  amount: number
  attributes: string | null
  content_type: string
  content_uri: string // img
  contract_address: string
  contract_name: string
  contract_token_id: string
  erc_type: string
  external_link: string
  image_uri: string // img
  latest_trade_price: string | null
  latest_trade_symbol: string | null
  latest_trade_timestamp: string | null
  metadata_json: string
  mint_price: number
  mint_timestamp: number
  mint_transaction_hash: string
  minter: string
  name: string
  nftscan_id: string
  nftscan_uri: string | null
  owner: string
  token_id: string
  token_uri: string
}

interface INftScanNftMap {
  code: number
  data: {
    contract_name: string
    assets: INftScanNft[]
  }[]
}

export const fetchNftScan = (url: string) =>
  fetch(url, {
    method: 'GET',
    headers: {
      'x-api-key': X_API_KEY,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((result) => result.json())

export const useRestricted = () => {
  return useMemo(() => new Set(['zVoterPosition', 'veZoo', 'zStakerPosition', 'xZoo', 'Jackpot A', 'Jackpot B']), [])
}

export const useSuggestedNFTSCANNfts = (chainTarget?: SupportedChainId) => {
  const { account, chainId = SupportedChainId.MAINNET } = useActiveWeb3React() //
  const { address, loading: loadingEns } = useENSAddress(account)

  const restricted = useRestricted()

  const url = useMemo(() => {
    if (account && !loadingEns) {
      switch (chainTarget || chainId) {
        case SupportedChainId.MOONBEAM: {
          return getMoonbeamNftsAPI(address || account)
        }
        case SupportedChainId.ARBITRUM_ONE: {
          return getArbitrumNftsAPI(address || account)
        }
        case SupportedChainId.MAINNET: {
          return getETHNftsAPI(address || account)
        }
      }
    }
    return ''
  }, [address, chainId, chainTarget, account, loadingEns])

  const { data: loadedData } = useSWRTransition(url, fetchNftScan)

  const transformed = useMemo(() => {
    const data = loadedData as INftScanNftMap
    if (!data || data.code !== 200) {
      return []
    }
    const values: any[] = data.data || []

    const filtered = values.filter((item) => !restricted.has(item.contract_name))

    const mapped = filtered
      .reduce((acc: any[], item) => {
        return [...acc, ...item.assets]
      }, [])
      .map((item) => {
        const { tokenImage, contractImage } = getParsedImg(item, '')

        const isCanary = item.contract_address.toLowerCase() === CANARY_MOONBEAM
        return {
          contract: {
            image_url: contractImage,
            address: item.contract_address,
          },
          id: item.nftscan_id,
          token_id: item.token_id,
          image_url: isCanary ? getCanaryUrl(item.token_id) : tokenImage,
          chain_id: chainTarget || chainId,
        }
      })
      .reduce((acc: any[], nft: any) => {
        const found = COMMON_BASES[chainId]?.some(
          (item) => item.wrapped.address.toLowerCase() === nft.contract.address.toLowerCase()
        )
        return found ? [nft, ...acc] : [...acc, nft]
      }, [])

    return mapped
  }, [loadedData, restricted, chainId, chainTarget])

  const transitionedNfts = useTransition(transformed)

  return useMemo(() => ({ nfts: transitionedNfts || [], loading: !loadedData }), [transitionedNfts, loadedData])
}

interface INftScanNft {
  content_type: string
  content_uri: string
  contract_address: string
  contract_name: string
  contract_token_id: string
  erc_type: string
  external_link: string
  image_uri: string
  latest_trade_price: string | null
  latest_trade_symbol: string | null
  latest_trade_timestamp: string | null
  mint_price: number
  mint_timestamp: number
  mint_transaction_hash: string
  minter: string
  name: string
  nftscan_id: string
  nftscan_uri: string | null // img
  owner: string
  token_id: string
  token_uri: string // meta
}

const CANARY_MOONBEAM = '0x139e9ba28d64da245ddb4cf9943aa34f6d5abfc5'.toLowerCase()

export const useNftScanMetaInfo = (address: string, tokenId: BigNumber, fallbackImg?: string) => {
  const { chainId } = useActiveWeb3React()
  const { targetAddress, id } = useMemo(
    // FIXME: remove it for production usage
    () => getOpenseaFixtures(chainId, address, tokenId),
    [chainId, address, tokenId]
  )

  const isCanary = targetAddress.toLowerCase() === CANARY_MOONBEAM

  const { nftScanTokens, error } = useNftScanImg(targetAddress, id)

  return useMemo(() => {
    if (!nftScanTokens || nftScanTokens.length === 0 || !nftScanTokens[0].meta) {
      return NFT_DEFAULT(fallbackImg)
    }
    const data = JSON.parse(nftScanTokens[0].meta)

    const result = !error && typeof data === 'object' ? convertToNFTInfo(data, fallbackImg) : NFT_DEFAULT(fallbackImg)

    if (isCanary) {
      const image = getCanaryUrl(id)

      return {
        ...result,
        tokenImage: image,
        tokenThumbnail: image,
      }
    }
    return result
  }, [nftScanTokens, error, id, isCanary, fallbackImg])
}

export const useNftScanImages = (token: string, id: BigNumber, fallbackImg: string = IconNftTemplate) => {
  const data = useNftScanMetaInfo(token, id, fallbackImg)

  const collectionThumbnail = useNftPartnersLogos(token)

  return useMemo(() => {
    const tokenImageUrl = data.tokenImage || data.tokenThumbnail
    const {
      collection: { collectionThumbnail: fallbackTmb },
    } = data

    return {
      tokenImage: tokenImageUrl || fallbackImg,
      collectionThumbnail: collectionThumbnail || fallbackTmb || fallbackImg,
    }
  }, [data, fallbackImg, collectionThumbnail])
}

function convertToNFTInfo(data: INftScanNft, fallbackImg: string = IconNftTemplate): INftImgInfo {
  const { tokenImage, contractImage } = getParsedImg(data, fallbackImg)
  return {
    tokenImage: tokenImage,
    tokenThumbnail: tokenImage,

    collection: {
      collectionThumbnail: contractImage,
    },
  }
}

function getParsedImg(data: INftScanNft, fallbackImg: string) {
  if (data.nftscan_uri) {
    return {
      tokenImage: data.nftscan_uri,
      contractImage: data.nftscan_uri || fallbackImg,
    }
  }

  if (data.metadata_json) {
    try {
      const parsed = JSON.parse(data.metadata_json)
      if (parsed && parsed.image_small) {
        return {
          tokenImage: parsed.image_small,
          contractImage: parsed.image_small || fallbackImg,
        }
      }
      if (parsed && parsed.image && parsed.image.indexOf('https') !== -1) {
        return {
          tokenImage: parsed.image,
          contractImage: parsed.image || fallbackImg,
        }
      }
    } catch (e) {
      console.log('Error for NftScan NFT', data)
    }
  }

  const tImage = data.content_uri ? buildContentUri(data.content_uri) : fallbackImg

  return {
    tokenImage: tImage,
    contractImage: tImage,
  }
}

export const isSvg = (url: string) => url.startsWith('<svg')

const buildContentUri = (content_uri: string) => {
  return isSvg(content_uri) || content_uri.startsWith('https://') ? content_uri : `https://ipfs.io/ipfs/${content_uri}`
}

export function getCanaryUrl(id: string) {
  return `https://cna-api.eu-4.evennode.com/${id}`
}
