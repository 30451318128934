import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { TransactionResponse } from '@ethersproject/providers'
import { useBattleArenaContract, useBattleVoterAddress, useBattleVoterContract } from '../../constants/zoodao'
import { ButtonStake } from '../Button'
import { useActiveWeb3React } from '../../hooks/web3'
import { useHasPendingNftAction, useTransactionAdder } from '../../state/transactions/hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { Dots } from '../swap/styleds'
import { ZERO } from '../../utils/isZero'
import { formatDecimal } from '../../utils/numberWithCommas'
import { BigNumber } from 'ethers'
import { useCallStaticMethod } from 'pages/VePie/hooks'

export const useIncentiveVoterZooRewards = (pid: string | BigNumber) => {
  const contract = useBattleArenaContract(false)

  const voter = useBattleVoterAddress()

  const deps = useMemo(() => [pid], [pid])
  const params = useMemo(
    () => ({
      from: voter,
    }),
    [voter]
  )
  const res = useCallStaticMethod(contract, 'calculateIncentiveRewardForVoter', deps, params)

  return useMemo(() => {
    return {
      rewards: res?.result || ZERO,
      loading: res?.loading,
    }
  }, [res])
}

const useIncentiveClaimForVoter = (positionId: BigNumber) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()

  const farmContract = useBattleVoterContract()

  const actionType = `claimIncentiveVoterReward_${positionId.toString()}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onClaim() {
    if (!chainId || !library || !account || !account || !farmContract) return

    if (account && deadline) {
      const txData = await farmContract.populateTransaction.claimIncentiveVoterReward(positionId, account)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Claim incentive voter rewards for ${positionId}`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return { onClaim, pending }
}

interface IProps {
  votingPositionId: BigNumber
  className?: string
}

const ClaimIncentiveForVoter = ({ votingPositionId, className }: IProps) => {
  const { onClaim, pending } = useIncentiveClaimForVoter(votingPositionId)

  const { rewards, loading } = useIncentiveVoterZooRewards(votingPositionId)

  if (rewards.isZero()) {
    return null
  }

  return (
    <ButtonStake disabled={pending || loading} onClick={onClaim} className={className}>
      {pending ? (
        <Dots>
          <Trans>Claiming Incentive Rewards</Trans>
        </Dots>
      ) : (
        <>
          <Trans>Claim ZOO</Trans>
          &nbsp;
          {formatDecimal(rewards)}
        </>
      )}
    </ButtonStake>
  )
}

export default ClaimIncentiveForVoter
