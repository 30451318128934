import React, { useCallback, useMemo } from 'react'
import { DashboardCardFlex } from 'pages/Dashboard/styleds'
import { CardHeadBox } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import { LogoCoin, TxtBalance, InputLabel, LockButton } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import IconZOO from '../../../assets/svg/token.svg'
import { ZERO } from 'utils/isZero'
import { BigNumber } from 'ethers'
import {
  useBattleArenaContract,
  useBattleStakerAddress,
  useBattleStakerContract,
  useBattleVoterAddress,
  useBattleVoterContract,
} from 'constants/zoodao'
import { useActiveWeb3React } from 'hooks/web3'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { formatDecimal } from 'utils/numberWithCommas'
import { Dots } from 'pages/Pool/styleds'
import { useCallStaticMultiMethod } from 'pages/VePie/hooks'
import { useClaimedIncentiveRewards } from 'hooks/gql'
import { MouseoverTooltip } from 'components/Tooltip'
import { BATTLE_STAGES, useBattleStage } from 'pages/NftBattlesPage/hooks'

const useBatchClaimFromIncentiveStakings = (vPids: BigNumber[] | string[]) => {
  const farmContract = useBattleStakerContract()
  const { account } = useActiveWeb3React()
  const dataFunc = useCallback(async () => {
    return await farmContract?.populateTransaction.batchClaimIncentiveStakerReward(vPids, account || '')
  }, [farmContract, vPids, account])

  return useTxTemplate(`use_batch_claim_inc_for_staking_${vPids.join('_')}`, 'Claim rewards for staked NFTs', dataFunc)
}

const useBatchClaimFromIncentiveVotings = (vPids: BigNumber[]) => {
  const farmContract = useBattleVoterContract()
  const { account } = useActiveWeb3React()
  const dataFunc = useCallback(async () => {
    return await farmContract?.populateTransaction.batchClaimIncentiveVoterReward(vPids, account || '')
  }, [farmContract, vPids, account])

  return useTxTemplate(`use_batch_claim_inc_for_votings_${vPids.join('_')}`, 'Claim rewards for votings', dataFunc)
}

const useTotalIncentiveStakerRewards = (pids: string[]) => {
  const contract = useBattleArenaContract(false)

  const staker = useBattleStakerAddress()

  const params = useMemo(() => {
    return { from: staker }
  }, [staker])

  const pidsMulti = useMemo(() => pids.map((item) => [item]), [pids])
  const res = useCallStaticMultiMethod(contract, 'calculateIncentiveRewardForStaker', pidsMulti, params)

  return useMemo(() => {
    return {
      rewards: res.result.reduce((acc, item) => {
        return acc.add(item || ZERO)
      }, ZERO),
      loading: res.loading,
    }
  }, [res])
}

const useTotalIncentiveVoterRewards = (pids: BigNumber[]) => {
  const contract = useBattleArenaContract(false)

  const voter = useBattleVoterAddress()

  const params = useMemo(() => {
    return { from: voter }
  }, [voter])

  const pidsMulti = useMemo(() => pids.map((item) => [item]), [pids])
  const res = useCallStaticMultiMethod(contract, 'calculateIncentiveRewardForVoter', pidsMulti, params)

  return useMemo(() => {
    return {
      rewards: res.result.reduce((acc, item) => {
        return acc.add(item || ZERO)
      }, ZERO),
      loading: res.loading,
    }
  }, [res])
}

export const ZOOClaimableStaking = ({ pids }: { pids: string[] }) => {
  const { loading, rewards } = useTotalIncentiveStakerRewards(pids)
  const { action, pending } = useBatchClaimFromIncentiveStakings(pids)

  const { claimed } = useClaimedIncentiveRewards(true, pids)

  return (
    <ZOOBatchClaimTemplate
      disabled={pids.length === 0}
      loadingRewards={loading}
      availableRewards={rewards}
      action={action}
      pending={pending}
      claimed={claimed}
    />
  )
}

export const ZOOClaimableVoting = ({ pids }: { pids: BigNumber[] }) => {
  const { loading, rewards } = useTotalIncentiveVoterRewards(pids)
  const { action, pending } = useBatchClaimFromIncentiveVotings(pids)
  const { claimed } = useClaimedIncentiveRewards(false, pids)

  return (
    <ZOOBatchClaimTemplate
      disabled={pids.length === 0}
      loadingRewards={loading}
      availableRewards={rewards}
      action={action}
      pending={pending}
      claimed={claimed}
    />
  )
}

const ZOOBatchClaimTemplate = ({
  availableRewards,
  action,
  disabled,
  loadingRewards,
  pending,
  claimed,
}: {
  availableRewards: BigNumber
  action: () => void
  disabled: boolean
  pending: boolean
  loadingRewards: boolean
  claimed: BigNumber
}) => {
  const stage = useBattleStage()

  return (
    <DashboardCardFlex inactive={disabled}>
      <CardHeadBox>
        <CardHeadContribution>
          <LogoCoin src={IconZOO} />
          <Trans>Claimable ZOO Rewards</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <InputLabel mt={'25px'} mb={'6px'} alignItems="center">
        <Trans>Rewards from selected NFTs:</Trans>
        &nbsp;
        {loadingRewards ? (
          <Dots>
            <Trans>Loading</Trans>
          </Dots>
        ) : (
          <TxtBalance>{formatDecimal(availableRewards)}</TxtBalance>
        )}
      </InputLabel>
      <InputLabel fontSize={'12px !important'} mt={'6px'} mb={'10px'} alignItems="center">
        <Trans>They will appear soon</Trans>
      </InputLabel>

      <Flex alignItems="center" justifyContent="space-between" mt={25}>
        <InputLabel>
          <Trans>
            My rewards claimed:&nbsp;
            <TxtBalance>{formatDecimal(claimed, 2)}</TxtBalance>
          </Trans>
        </InputLabel>

        <MouseoverTooltip
          placement="bottom"
          hidden={stage !== BATTLE_STAGES.FIRST}
          text={
            availableRewards.isZero() ? (
              <Trans>No claimable rewards</Trans>
            ) : (
              <Trans>You may claim rewards during Stage 1</Trans>
            )
          }
        >
          <LockButton
            onClick={action}
            disabled={disabled || pending || availableRewards.isZero() || stage !== BATTLE_STAGES.FIRST}
          >
            {pending ? (
              <Dots>
                <Trans>Claiming</Trans>
              </Dots>
            ) : (
              <Trans>Claim Rewards</Trans>
            )}
          </LockButton>
        </MouseoverTooltip>
      </Flex>
    </DashboardCardFlex>
  )
}
