import React, { useContext, useMemo } from 'react'
import Logo from '../../assets/svg/logo.svg'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { SquidWidget } from '@0xsquid/widget'
import FraxImg from './frax.svg'
import GlmrImg from './glmr.svg'
import { SupportedChainId } from 'constants/chainsinfo'
import Modal from 'components/Modal'
import { FRAX_MOONBEAM, GLMR_MOONBEAM, useChainStablecoinAddress, useGlmrAddress } from 'constants/zoodao'
import { WarningCtx } from './BridgeMode'
import { ZooTextGradient } from 'components/ZooText/ZooText'

const Container = styled.div`
  background: ${({ theme }) => theme.bg2};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0px 24px 24px 0px;
  min-height: 110px;
  display: flex;
  max-width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100%;
  `};

  @media (max-height: 800px) {
    min-height: 80px;
  }
`

const Text = styled(ZooTextGradient)`
  font-size: 24px;
  text-align: center;
  font-weight: bold;

  @media (max-height: 800px) {
    font-size: 13px;
    line-height: normal;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px 4px 15px;
  color: ${({ theme }) => theme.text1};

  border: 1px solid #575983;
  border-radius: 40px;
  margin-top: 18px;

  @media (max-height: 800px) {
    margin-top: 7px;
  }
`

export const BuyBlock = () => {
  return (
    <Container>
      <Text>
        <Trans>Get Assets</Trans>
      </Text>

      <Row>
        <Glmr />
        <Divider />
        <Frax />
      </Row>
    </Container>
  )
}

const Divider = styled.div`
  border: 1px solid #575983;
  height: 100%;
  margin: 0 12px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 7px;
`

const Item = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.text1};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    font-weight: bold;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
  `};
`

export const Glmr = ({ label }: { label?: any }) => {
  const token = useGlmrAddress()
  const { toggle } = useContext(WarningCtx)

  if (label) {
    return <Item onClick={() => toggle && toggle(token)}>{label}</Item>
  }

  return (
    <Item onClick={() => toggle && toggle(token)}>
      <Icon src={GlmrImg} />
      <Trans>GLMR</Trans>
    </Item>
  )
}

export const Frax = ({
  className,
  label = 'FRAX',
  showImg = true,
}: {
  label?: any
  showImg?: boolean
  className?: string
}) => {
  const token = useChainStablecoinAddress()
  const { toggle } = useContext(WarningCtx)

  return (
    <>
      <Item onClick={() => toggle && toggle(token)} className={className}>
        {showImg && <Icon src={FraxImg} />}
        <>{label}</>
      </Item>
    </>
  )
}

const ModalStyled = styled(Modal)`
  width: auto;
`

const USDT_MAINNET = {
  address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  chainId: 1,
}

const FRAX_MOONBEAM_TOKEN = {
  address: FRAX_MOONBEAM,
  chainId: 1284, // Chain ID for Moonbeam
}

const GLMR_MOONBEAM_TOKEN = {
  address: GLMR_MOONBEAM,
  chainId: 1284, // Chain ID for Moonbeam
}

export const WidgetSquid = () => {
  const { targetToken, toggle } = useContext(WarningCtx)

  //const { chainId: toChain = SupportedChainId.MAINNET } = useActiveWeb3React()
  //const { toChain: fromChain } = useBridgeTargetChain(toChain)

  const tokenMoonbeam = useMemo(() => {
    if (targetToken === GLMR_MOONBEAM) {
      return GLMR_MOONBEAM_TOKEN
    }

    return FRAX_MOONBEAM_TOKEN
  }, [targetToken])

  const config: any = useMemo(() => {
    return {
      initialFromChainId: SupportedChainId.MAINNET, // Ethereum
      initialToChainId: SupportedChainId.MOONBEAM, // Moonbeam
      companyName: 'ZooDAO',
      style: {
        neutralContent: '#9DA7B1',
        baseContent: '#FFFDFD',
        base100: '#434565',
        base200: '#202230',
        base300: '#161522',
        error: '#ED6A5E',
        warning: '#FFB155',
        success: '#62C555',
        primary: '#AB67CB',
        secondary: '#37394C',
        secondaryContent: '#B2BCD3',
        neutral: '#383A4C',
        roundedBtn: '24px',
        roundedBox: '20px',
        roundedDropDown: '0px',
        displayDivider: false,
      },
      slippage: 1.5,
      infiniteApproval: true,
      instantExec: false,
      apiUrl: 'https://api.squidrouter.com',
      mainLogoUrl: Logo,
      titles: {
        swap: 'Convert',
        settings: 'Settings',
        wallets: 'Wallets',
        tokens: 'Tokens',
        chains: 'Chains',
        history: 'History',
        transaction: 'Transaction',
        destination: 'Destination address',
      },
      favTokens: [tokenMoonbeam, USDT_MAINNET],
      defaultTokens: [tokenMoonbeam, USDT_MAINNET],
      priceImpactWarnings: {
        warning: 3,
        critical: 5,
      },
    }
  }, [tokenMoonbeam])

  return (
    <ModalStyled
      isOpenFlag={!!targetToken}
      onDismissHandler={() => {
        toggle && toggle('')
      }}
      minHeight={false}
      maxHeight={600}
    >
      {!!targetToken && <SquidWidget config={config} />}
    </ModalStyled>
  )
}
