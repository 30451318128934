import { Dots } from '../../pages/Pool/styleds'
import styled from 'styled-components/macro'
import { Contract } from '@ethersproject/contracts'
import { useBattleStakerContract, useERC721Contract } from '../../constants/zoodao'
import { useActiveWeb3React } from '../../hooks/web3'
import { useHasPendingNftAction, useTransactionAdder } from '../../state/transactions/hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { Trans } from '@lingui/macro'
import { TransactionResponse } from '@ethersproject/providers'
import { ButtonUnstake } from '../Button'
import { useUnstakePossibility } from '../../pages/NftBattlesPage/NftBattleStaking'
import { BATTLE_STAGES, useBattleStage } from '../../pages/NftBattlesPage/hooks'

export const ButtonUnstakeFull = styled(ButtonUnstake)`
  width: 100%;
`
export const useUnstakeContract = (nftPoolContract: Contract | null, stakingPositionId: string) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline() // custom from users settings

  const actionType = `$unstakin_${stakingPositionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onUnstake() {
    if (!chainId || !library || !account || !nftPoolContract) return

    if (account && deadline) {
      const txData = await nftPoolContract.populateTransaction.unstakeNft(stakingPositionId)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'Withdraw NFT from staking pool',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return { onUnstake, pending }
}

interface IProps {
  stakingPositionId: string
  tokenId: string
  tokenAddress: string
  className?: string
  disabled?: boolean
}

export const UnstakeBattleNft = ({ stakingPositionId, tokenId, tokenAddress, className, disabled = false }: IProps) => {
  const nftPoolContract = useBattleStakerContract()
  const { onUnstake, pending: pendingUnstaking } = useUnstakeContract(nftPoolContract, stakingPositionId)

  const nftContract = useERC721Contract(tokenAddress)
  const canUnstake = useUnstakePossibility(tokenId, nftContract)

  const stage = useBattleStage()

  return (
    <ButtonUnstake
      className={className}
      onClick={onUnstake}
      disabled={disabled || !canUnstake || pendingUnstaking || stage !== BATTLE_STAGES.FIRST}
    >
      {pendingUnstaking ? (
        <Dots>
          <Trans>Unstaking</Trans>
        </Dots>
      ) : (
        <Trans>Unstake</Trans>
      )}
    </ButtonUnstake>
  )
}
