import { useActiveWeb3React } from '../web3'
import { Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import { useHasPendingNftAction, useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { BigNumber, PopulatedTransaction } from 'ethers'
import { useAddPopup } from 'state/application/hooks'

type AsyncFunc = () => Promise<PopulatedTransaction | undefined>

export const useTxTemplate = (
  actionType: string,
  successMsg: string,
  funcTxData: AsyncFunc,
  failMsg?: any,
  txCallback?: (tx: TransactionResponse) => void,
  manualGazLimit?: BigNumber
) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [disabled, setDisabled] = useState(false)

  const pending = useHasPendingNftAction('', '', actionType)

  const addPopup = useAddPopup()

  const action = useCallback(async () => {
    if (!chainId || !library || !account) return

    if (account) {
      const txData = await funcTxData()

      const txn = {
        ...txData,
        value: txData?.value || '0x0',
      }

      let estimatedCost = undefined

      try {
        estimatedCost = await library.getSigner().estimateGas(txn)
      } catch (error) {
        addPopup({
          msg: {
            success: false,
            title: <Trans>Transaction Error</Trans>,
            description: 'Can not estimate gas usage for transaction or not enough balance',
          },
        })
        console.error('Failed to estimate transaction', error)
      }

      try {
        const newTxn = {
          ...txn,
          gasLimit: estimatedCost ? calculateGasMargin(chainId, estimatedCost) : manualGazLimit,
        }

        return library
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            txCallback && txCallback(response)
            addTransaction(response, {
              summary: successMsg,
              nftAction: {
                nftAddress: '',
                tokenId: '',
                type: actionType,
              },
            })
          })
      } catch (error) {
        console.error('Failed to send transaction', error)

        setDisabled(true)

        failMsg &&
          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: failMsg,
            },
          })

        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      }
    } else {
      return
    }
  }, [
    successMsg,
    manualGazLimit,
    actionType,
    funcTxData,
    account,
    addTransaction,
    chainId,
    library,
    addPopup,
    failMsg,
    txCallback,
  ])

  return {
    action,
    pending,
    disabled,
  }
}

/**
 */
