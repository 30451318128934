import { DashboardCard } from '../styleds'
import { X } from 'react-feather'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useDegenModalToggle } from 'state/application/hooks'
import { useContext } from 'react'
import { WarningCtx } from 'components/Sidebar/BridgeMode'
import { useGlmrAddress } from 'constants/zoodao'
import { useUserMode } from 'components/Header/UserMode'

const CloseIcon = styled(X)`
  height: 32px;
  width: 32px;

  position: absolute;
  right: 20px;
  top: 20px;

  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const DashboardCardStyled = styled(DashboardCard)`
  padding: 0;
  flex-direction: row;
  display: flex;
  position: relative;
`

const Header = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;

  letter-spacing: 0.75px;
  font-feature-settings: 'salt' on;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
`

const Text = styled.div`
  margin-top: 4px;
`

const Texts = styled.div`
  margin-top: 14px;
`

const DegenLabel = styled.span`
  color: #f64562;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-underline-offset: 3px;
`

const ExplanationCard = () => {
  const [show, setShow] = useLocalStorage('SHOW_EXPLANATION_CARD', true)

  const toggleWalletModal = useDegenModalToggle()

  const { isDegen } = useUserMode()

  const { toggle: openWidget } = useContext(WarningCtx)

  const token = useGlmrAddress()

  if (!show) {
    return null
  }

  return (
    <DashboardCardStyled>
      <Block>
        <Header>
          <Trans>Welcome to ZooDAO!</Trans>
        </Header>

        <Texts>
          <Text style={{ marginBottom: '12px' }}>
            <Trans>Use FRAX to vote on Staked NFTs for yield returns.</Trans>
          </Text>
          <Text>
            <Trans>Check out the Battleboard for Battle Stage and their associated actions.</Trans>
          </Text>
          <Text>
            <Trans>
              <DegenLabel onClick={() => openWidget && openWidget(token)}>Cross-chain swaps</DegenLabel> are available
              for users coming from Ethereum.
            </Trans>
          </Text>
          {!isDegen && (
            <Text>
              <Trans>
                Experienced users can try <DegenLabel onClick={toggleWalletModal}>Degen Mode</DegenLabel>.
              </Trans>
            </Text>
          )}
        </Texts>
      </Block>
      <CloseIcon onClick={() => setShow(false)} />
    </DashboardCardStyled>
  )
}

export default ExplanationCard
