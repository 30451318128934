import styled from 'styled-components/macro'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

export const TextDot = styled.div`
  height: 3px;
  width: 3px;
  background-color: ${({ theme }) => theme.text2};
  border-radius: 50%;
`

export const Checkbox = styled.input`
  border: 1px solid ${({ theme }) => theme.red3};
  height: 20px;
  margin: 0;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 0px;
  margin: 25px 0;
`

export const MenuItem = styled(RowBetween)`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.bg2};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 10px 25px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  border-radius: 50px;
  color: #fff;
  border-style: solid;
  border: 2px solid transparent;
  -webkit-appearance: none;
  text-align: left;
  background-color: #33334b;
  padding-left: 50px;

  ::placeholder {
    color: #9998b8;
  }
  transition: border 100ms;
  :focus {
    border: 2px solid #f64562;
    outline: none;
  }
  font-size: 24px;
  line-height: 24px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 24px;s
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 14px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 22px;
    margin-right: 0px;
  `};
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`
