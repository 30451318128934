import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { TransactionResponse } from '@ethersproject/providers'
import { useBattleArenaContract, useBattleStakerContract } from '../../constants/zoodao'
import { ButtonStake } from '../Button'
import { useActiveWeb3React } from '../../hooks/web3'
import { useHasPendingNftAction, useTransactionAdder } from '../../state/transactions/hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { Dots } from '../swap/styleds'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { ZERO } from '../../utils/isZero'
import { formatDecimal } from '../../utils/numberWithCommas'
import { BATTLE_STAGES, useBattleStage, useYTokensUsdValue } from '../../pages/NftBattlesPage/hooks'

export const useStakerYTokensRewards = (stakingPositionId: string) => {
  const contract = useBattleArenaContract()

  const deps = useMemo(() => [stakingPositionId], [stakingPositionId])
  const res = useSingleCallResult(contract, 'getPendingStakerReward', deps)

  return useMemo(() => {
    return {
      rewards: res?.result?.[0] || ZERO,
      loading: res?.loading,
    }
  }, [res])
}

const useClaimFromStakers = (positionId: string) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()

  const farmContract = useBattleStakerContract()

  const actionType = `use_claim_for_stakers_${positionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onClaim() {
    if (!chainId || !library || !account || !account || !farmContract) return

    if (account && deadline) {
      const txData = await farmContract.populateTransaction.claimRewardFromStaking(positionId, account)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'Claim rewards for stakers',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return { onClaim, pending }
}

interface IProps {
  stakingPositionId: string
  showAmount?: boolean
  hide?: boolean
  className?: string
}

const ClaimRewardForStakers = ({ stakingPositionId, showAmount = true, hide = true, className }: IProps) => {
  const { onClaim, pending } = useClaimFromStakers(stakingPositionId)

  const { rewards, loading } = useStakerYTokensRewards(stakingPositionId)
  const stage = useBattleStage()

  const disabled = stage !== BATTLE_STAGES.FIRST || rewards.isZero()

  const { usd: rewardsUsd, loading: loadingUsd } = useYTokensUsdValue(rewards)

  if (disabled) {
    if (hide) {
      return null
    }
  }

  return (
    <ButtonStake disabled={pending || loading || disabled || loadingUsd} onClick={onClaim} className={className}>
      {pending ? (
        <Dots>
          <Trans>Claiming Staker Rewards</Trans>
        </Dots>
      ) : showAmount ? (
        <>
          <Trans>Claim</Trans>
          {formatDecimal(rewardsUsd)}
        </>
      ) : (
        <Trans>Claim</Trans>
      )}
    </ButtonStake>
  )
}

export default ClaimRewardForStakers
