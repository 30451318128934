import { Trans } from '@lingui/macro'
import { useJackpotsParticipantsAmount } from 'hooks/gql'
import { DashboardCardFlex, CardLine, CardDescription } from 'pages/Dashboard/styleds'
import { CardTextStyled } from './DashboardCards'
import { CardContent, CardHeader, IDashCard } from 'pages/Jackpots/styleds'

export const JackpotsParticipants = ({ index }: IDashCard) => {
  const { totalCount, loading } = useJackpotsParticipantsAmount()

  return (
    <DashboardCardFlex>
      <CardLine index={index} />
      <CardHeader>
        <Trans> Participants</Trans>
      </CardHeader>

      <CardContent>
        <CardTextStyled>{loading ? <Trans>Loading...</Trans> : totalCount}</CardTextStyled>
        <CardDescription>
          <Trans>Total</Trans>
        </CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}
