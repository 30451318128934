import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useBattleArenaContract, useBattleVoterContract } from '../../constants/zoodao'
import { ButtonStake } from '../Button'
import { useActiveWeb3React } from '../../hooks/web3'
import { useHasPendingNftAction, useTransactionAdder } from '../../state/transactions/hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { Dots } from '../swap/styleds'
import { ZERO } from '../../utils/isZero'
import { formatDecimal } from '../../utils/numberWithCommas'
import {
  BATTLE_STAGES,
  useBattleStage,
  useBattleVotingPositionInfo,
  usePricePerShare,
} from '../../pages/NftBattlesPage/hooks'
import styled from 'styled-components/macro'
import { useSingleCallResult } from 'state/multicall/hooks'
import { toUsdByShare } from 'hooks/zoodao/useBattlesEventsData'

export const IconClaim = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-right: 0px;
  margin-left: 10px;
`

export const useVoterUsdRewards = (votingPositionId: BigNumber | string = '') => {
  const contract = useBattleArenaContract()

  const deps = useMemo(() => [votingPositionId], [votingPositionId])

  const position = useBattleVotingPositionInfo(votingPositionId)
  const voterRewards = useSingleCallResult(contract, 'getPendingVoterReward', deps)
  const debts = useSingleCallResult(contract, 'debtOfPosition', deps)

  const pricePerShare = usePricePerShare()

  return useMemo(() => {
    const loading = position?.loading || voterRewards.loading || debts.loading || pricePerShare.loading

    return {
      rewardsUsd: loading
        ? ZERO
        : toUsdByShare(pricePerShare.result, position?.info?.yTokensRewardDebt.add(voterRewards.result?.yTokens)),
      rewardsWell: loading ? ZERO : (voterRewards.result?.wells || ZERO).add(debts.result?.wells || ZERO) || ZERO,
      rewardsGlmr: loading ? ZERO : (voterRewards.result?.glmrs || ZERO).add(debts.result?.glmrs || ZERO) || ZERO,
      loading: loading,
    }
  }, [position, voterRewards, pricePerShare, debts])
}

const useClaimFroVoter = (positionId: string) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()

  const farmContract = useBattleVoterContract()

  const actionType = `use_claim_for_voter_${positionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onClaim() {
    if (!chainId || !library || !account || !account || !farmContract) return

    if (account && deadline) {
      const txData = await farmContract.populateTransaction.claimRewardFromVoting(positionId, account)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'Claim rewards for voter',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return { onClaim, pending }
}

interface IProps {
  votingPositionId: string
  showAmount?: boolean
  hide?: boolean
  className?: string
}

const ClaimRewardForVoter = ({ votingPositionId, showAmount = true, hide = true, className }: IProps) => {
  const { onClaim, pending } = useClaimFroVoter(votingPositionId)

  const { rewardsUsd, loading } = useVoterUsdRewards(votingPositionId)

  const stage = useBattleStage()

  const disabled = stage !== BATTLE_STAGES.FIRST || rewardsUsd.isZero()

  if (disabled) {
    if (hide) {
      return null
    }
  }

  return (
    <ButtonStake disabled={pending || disabled || loading} onClick={onClaim} className={className}>
      {pending ? (
        <Dots>
          <Trans>Claiming</Trans>
        </Dots>
      ) : showAmount ? (
        <>
          <Trans>Claim Voter Rewards</Trans>
          {formatDecimal(rewardsUsd)}
        </>
      ) : (
        <Trans>Claim</Trans>
      )}
    </ButtonStake>
  )
}

export default ClaimRewardForVoter
