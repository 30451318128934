import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { createContext, useCallback, useContext } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import Dropdown from 'react-dropdown'
import IconArrowDown from './arrow.svg'
import { DegenModal } from './DegenModal'
import { useDegenModalToggle, useModalOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/actions'

const StyledDd = styled(Dropdown)`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .Dropdown-control {
    border: 0px;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    margin: 0 25px;
    background: none;
    font-weight: 400;

    transition: background-color 0.3s;
    font-family: Uniform Pro;
    line-height: 18px;
    font-size: 18px;
    color: ${({ theme }) => theme.text5};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        font-size: 16px;
        line-height: 16px;
    `};

    &:hover {
      color: ${({ theme }) => theme.text1};
    }
  }

  .Dropdown-arrow {
    background: url(${IconArrowDown}) 50% 50% / cover no-repeat;
    border: 0 !important;

    top: 15px;
    right: 58px;
    color: #fff;
    height: 7px;
    width: 11px;

    .is-open .Dropdown-arrow {
      transform: rotate(90deg) !important;
    }
  }

  &.is-open .Dropdown-arrow {
    transform: rotate(180deg) !important;
  }

  .Dropdown-menu {
    font-size: 25px;
    line-height: 30px;
    background-color: ${({ theme }) => theme.bg2};
    border: none;
    cursor: pointer;
    top: 105%;
    border-radius: 18px;
    font-family: Uniform Pro;
    line-height: 18px;
    font-size: 18px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        font-size: 16px;
        line-height: 16px;
    `};
    box-shadow: 0px 15px 15px rgb(0 0 0 / 25%);
  }

  .Dropdown-option {
    text-align: center;
    padding: 15px 25px;
    color: ${({ theme }) => theme.text2};
    &:hover {
      background: #9998b8;
      color: #202136;
    }
  }

  .Dropdown-option.is-selected {
    color: #fff;
    background: #f64562;
  }

  .Dropdown-placeholder {
    margin-right: 32px;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.text5};
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 26px;
    line-height: 26px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px;
    line-height: 24px;
  `};
`

export enum USER_MODES {
  DEGEN = '1',
  LIGHT = '2',
}

const USER_MODES_KEY = 'USER_MODES_KEY'

export const UserModesContext = createContext<{
  toggle?: (m: USER_MODES) => void
  mode?: USER_MODES
}>({})

export const useUserMode = () => {
  const { mode } = useContext(UserModesContext)

  return {
    isDegen: mode === USER_MODES.DEGEN,
    isLight: mode === USER_MODES.LIGHT,
  }
}

const useUserModeToggle = () => {
  const [mode, setMode] = useLocalStorage(USER_MODES_KEY, USER_MODES.LIGHT)

  const toggle = (mode: USER_MODES) => {
    setMode(mode)
  }

  return { mode, toggle }
}

export const UserModes = ({ children }: { children: any }) => {
  const { toggle, mode } = useUserModeToggle()
  return <UserModesContext.Provider value={{ toggle, mode }}>{children}</UserModesContext.Provider>
}

const OPTIONS = [
  {
    value: USER_MODES.LIGHT,
    label: (
      <Label>
        <Trans>Lite</Trans>
      </Label>
    ),
  },
  {
    value: USER_MODES.DEGEN,
    label: (
      <Label>
        <Trans>Degen</Trans>
      </Label>
    ),
  },
]

export const UserModesSelector = () => {
  const { mode, toggle } = useContext(UserModesContext)

  const toggleWalletModal = useDegenModalToggle()

  const toggleWrapper = useCallback(
    (m: USER_MODES) => {
      if (m === USER_MODES.DEGEN) {
        toggleWalletModal()
      } else {
        toggle && toggle(m)
      }
    },
    [toggleWalletModal, toggle]
  )

  const isModalOpen = useModalOpen(ApplicationModal.DEGEN_MODE)

  return (
    <>
      {!isModalOpen && (
        <StyledDd
          options={OPTIONS}
          value={mode}
          onChange={({ value }: any) => {
            toggleWrapper(value)
          }}
        />
      )}
      <DegenModal />
    </>
  )
}
