import styled from 'styled-components/macro'

export const DashboardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column-reverse;
  `};
`
