import { Trans } from '@lingui/macro'
import { useLocation } from 'react-router'
import useScrollPosition from '@react-hook/window-scroll'
import { Flex, Text } from 'rebass'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import Logo from '../../assets/svg/logo.svg'
import { useActiveWeb3React } from '../../hooks/web3'
import Web3Status from '../Web3Status'
import { AddZooToken } from '../AddToken/AddToken'
import { useZooTokenBalance } from '../../hooks/zoodao/zooToken'
import { formatDecimal } from '../../utils/numberWithCommas'
import { Paths } from '../../constants/paths'
import { StageIndicator } from '../../pages/NftBattlesPageV2/StageIndicator'
import { useMemo } from 'react'
import NetworkSelector from './NetworkSelector'
import { FaucetNfts } from 'pages/NftBattlesPageV2/BattlesFaucet'
import { AlmostHere, isTestnet, useWarningFlag } from 'components/WarningWrapper/WarningWrapper'
import { SupportedChainId } from 'constants/chainsinfo'
import { UserModesSelector } from './UserMode'
import { isMobile, isMobileOnly } from 'react-device-detect'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: ${({ showBackground }) => (showBackground ? 'none' : 'grid')};
  display: grid;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 21;
  position: fixed;
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  background-color: #27273f;
  border-radius: 0 0 24px 24px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
  padding: 10px 25px;

  grid-template-columns: 280px 1fr auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 240px 1fr auto;
  `};
  ${({ theme }) => theme.mediaWidth.upToTablet`
    padding-right: 18px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-right: 15px;
    grid-template-columns:  1fr 0fr 4fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: flex-end;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ active }) => (!active ? '#33334b' : '#33334b')};
  border-radius: 50px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const BalanceText = styled(Text)`
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  padding: 15px 25px;
  min-width: auto !important;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    line-height: 16px;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: auto;
  text-decoration: none !important;

  :hover {
    cursor: pointer;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s;

  width: 50px;
  height: 50px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 40px;
    height: 40px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 36px;
    height: 36px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  width: 36px;
  height: 36px;
  `};
`

const LogoImg = styled.img`
  width: 40px;
  height: 40px;
  image-rendering: pixelated;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 40px;
    height: 40px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 36px;
    height: 36px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 42px;
    height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 24px;
    height: 24px;
    padding: 0;
  `};
`

const ZooTokenWrapper = styled.div`
  margin-right: 20px;
`

export const useIsBridge = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return pathname && pathname.indexOf(Paths.BATTLES_BRIDGE) >= 0
  }, [pathname])
}

export const useIsPreSale = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return pathname && pathname.indexOf(Paths.PRE_SALE) >= 0
  }, [pathname])
}

export const useIsCommunityRound = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return (
      pathname && (pathname.indexOf(Paths.COMMUNITY_ROUND_A) >= 0 || pathname.indexOf(Paths.COMMUNITY_ROUND_B) >= 0)
    )
  }, [pathname])
}

export const useIsYieldFarm = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return pathname && pathname.indexOf(Paths.YIELD_DASHBOARD) >= 0
  }, [pathname])
}

export const useIsBattles = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return pathname && pathname.indexOf(Paths.BATTLES) >= 0
  }, [pathname])
}

export const useIsYield = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return pathname && pathname.indexOf(Paths.YIELD) >= 0
  }, [pathname])
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const [darkMode] = useDarkModeManager()

  const zooBalance = useZooTokenBalance()

  const scrollY = useScrollPosition()

  const isBattles = useIsBattles()

  const isBridge = useIsBridge()
  const isPresale = useIsPreSale()
  const isAnglesRound = useIsCommunityRound()
  const isYieldFarm = useIsYieldFarm()

  const { notSupportedChain } = useWarningFlag()

  const isValid = !notSupportedChain

  const showAlmost = account && notSupportedChain && !isMobile

  return (
    <>
      {showAlmost ? (
        <AlmostHere />
      ) : (
        <HeaderFrame showBackground={scrollY > 16}>
          <Flex>
            <Title href={'https://zoodao.com'} target="_blank">
              <Icon>
                <LogoImg width={'auto'} src={isBattles ? Logo : darkMode ? Logo : Logo} alt="logo" />
              </Icon>
            </Title>
            {isValid && !isPresale && !isAnglesRound && !isMobileOnly && <UserModesSelector />}
          </Flex>

          <>
            {!isBridge && !isAnglesRound && !isYieldFarm && !isMobile ? (
              <Flex>
                <StageIndicator />
                {(isTestnet(chainId) || chainId === SupportedChainId.MOONBEAM) && false && <FaucetNfts />}
              </Flex>
            ) : (
              <div />
            )}
          </>

          <HeaderControls>
            {isValid && <ZooTokenWrapper>{account ? <AddZooToken /> : null}</ZooTokenWrapper>}
            {account && <NetworkSelector />}
            <HeaderElement>
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                {account && zooBalance && isValid ? (
                  <BalanceText>
                    {formatDecimal(zooBalance)}
                    &nbsp;
                    <Trans>ZOO</Trans>
                  </BalanceText>
                ) : null}
                <Web3Status />
              </AccountElement>
            </HeaderElement>
          </HeaderControls>
        </HeaderFrame>
      )}
    </>
  )
}
