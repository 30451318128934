import { Paths } from 'constants/paths'
import { useRewardsFor } from 'pages/NftBattlesPage/hooks'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { fromWei } from 'utils/fromWei'

export enum WINNER {
  A = 'A',
  B = 'B',
  UNKNOWN = 'UNKNOWN',
}

export const isLooser = (participant: WINNER, selected?: WINNER) => {
  return selected !== WINNER.UNKNOWN && selected !== participant
}

export const useWinChances = (pairData: any, epoch: number) => {
  const { token1, token2, playedInEpoch, win }: any = pairData || {}

  const { loading: loadingFirst, data: first } = useRewardsFor(token1, epoch)
  const { loading: loadingSecond, data: second } = useRewardsFor(token2, epoch)

  const { chanceA, chanceB } = useMemo(() => {
    const DEFAULT = {
      chanceA: 0,
      chanceB: 0,
    }
    if (loadingFirst || loadingSecond || !first || !second) {
      return DEFAULT
    }
    const { votes: firstVotes } = first
    const { votes: secondVotes } = second

    const a = +fromWei(firstVotes)
    const b = +fromWei(secondVotes)

    const sum = a + b

    if (sum === 0) {
      return DEFAULT
    }

    return {
      chanceA: (100 * a) / sum,
      chanceB: (100 * b) / sum,
    }
  }, [first, second, loadingFirst, loadingSecond])

  return {
    chanceA,
    chanceB,
    playedInEpoch,
    win,
    loadingSecond,
    loadingFirst,
    winner: playedInEpoch ? (win ? WINNER.A : WINNER.B) : WINNER.UNKNOWN,
    a: first,
    b: second,
  }
}

export const useGoToBattle = (positionIndex: string, epoch: number) => {
  const history = useHistory()

  return useCallback(() => {
    history.push(
      Paths.BATTLES_PAIR_INFO.replace(':pairId', positionIndex.toString()).replace(':epoch', `${epoch.toString()}`)
    )
  }, [positionIndex, epoch, history])
}

export const useGoToJackpots = () => {
  const history = useHistory()

  return useCallback(() => {
    history.push(Paths.BATTLES_JACKPOTS)
  }, [history])
}

export const useGoToTab = (tabPath: string, active: boolean) => {
  const history = useHistory()

  return useCallback(() => {
    if (active) {
      history.push(tabPath)
    }
  }, [active, history, tabPath])
}