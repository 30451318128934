import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DashboardCardFlex } from 'pages/Dashboard/styleds'
import { CalendarIcon, InputLabel, LockButton, LogoCoin, MaxButtonRed, Picker, PickerLabel, TxtBalance } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import IconZOO from '../../../assets/svg/token.svg'
import { GetZOO } from '../../../components/GetStablecoin'
import { BalanceInputWrapper } from 'pages/Portfolio/Panels/BalanceInput'
import { useZooBalanceTest } from 'hooks/zoodao/zooToken'
import { formatDecimal } from 'utils/numberWithCommas'
import styled from 'styled-components/macro'
import { Dots } from 'pages/Pool/styleds'
import { DAY } from 'components/EpochBanner/EpochBanner'
import { useAllVeZooVotes, useVeModelLock } from 'hooks/zoodao/veModel'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import { IProjectPartner } from 'hooks/zoodao/useCollectionInfo'
import { useZooCurrencies } from 'hooks/useZooCurrencies'
import { ApproveCheckerVeModel } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import { useVeModelInfo } from '../hooks'
import { useActiveWeb3React } from 'hooks/web3'
import SearchDropdown from 'components/SearchDropdown/SearchDropdown'
import LogoIcon from 'pages/NftBattlesPageV2/cards/LogoIcon/LogoIcon'
import { useNftPartnersLogos } from '../useNftPartnersLogos'
import { CardHeadBox } from 'pages/NftBattlesPageV2/styleds'

interface ILockOption {
  label: string
  value: number
}

const OPTIONS: ILockOption[] = [
  {
    label: '21 days',
    value: (DAY * 21) / 1000,
  },
  {
    label: '42 days',
    value: (DAY * 21 * 2) / 1000,
  },
  {
    label: '63 days',
    value: (DAY * 21 * 3) / 1000,
  },
  {
    label: '84 days',
    value: (DAY * 21 * 4) / 1000,
  },
]

const getLockOption = (item: ILockOption) => ({
  value: `${item.label}`,
  label: (
    <PickerLabel>
      <CalendarIcon></CalendarIcon>
      {item.label}
    </PickerLabel>
  ),
})

const getOption = (p: IProjectPartner) => ({
  value: p.payout_address,
  name: p.name,
  data: p,
})

const PickerStyled = styled(Picker)``

const BalanceInputWrapperStyled = styled(BalanceInputWrapper)`
  height: 46px;
  margin-top: 25px;
`

export const useLockOptions = () => {
  const { minTimelock, maxTimelock } = useVeModelInfo()

  const lockOptions = useMemo(() => {
    return OPTIONS.filter((item) => item.value >= minTimelock && item.value <= maxTimelock).map(getLockOption)
  }, [minTimelock, maxTimelock])

  const [duration, setDuration] = useState<string>('')

  useEffect(() => {
    lockOptions && lockOptions[0] && setDuration(lockOptions[0].value)
  }, [lockOptions])

  const durationNumber = useMemo(() => {
    return OPTIONS.find((item) => item.label === duration)?.value || 0
  }, [duration])

  return { lockOptions, duration, setDuration, durationNumber }
}

function compare(a: IProjectPartner, b: IProjectPartner) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}
const PickerBtn = styled.button.attrs({
  alignItems: 'center',
})`
  display: flex;
  align-items: center;
  font-size: inherit;
  width: 100%;
  background: transparent;
  color: inherit;
  border: none;
  padding: 15px 25px;
  cursor: pointer;
`
const LogoProject = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 30px;
    height: 30px;
  `}
`

const RenderOption = (domProps: any, p: any) => {
  const icon = useNftPartnersLogos(p.data.payout_address)

  return (
    <PickerBtn {...domProps} type="button">
      <LogoIcon src={icon} el={LogoProject} asBg />
      {p.data.name}
    </PickerBtn>
  )
}
const LockPanel = ({ projectsArr }: { projectsArr: IProjectPartner[] }) => {
  const [balance, setBalance] = useState<number | undefined>()

  const { account = '' } = useActiveWeb3React()
  const zooBalance = useZooBalanceTest()
  const { amount, loading } = useAllVeZooVotes(null, account)

  const OPTIONS_PROJECTS = useMemo(() => {
    return projectsArr.sort(compare).map(getOption)
  }, [projectsArr])

  const [payout_address, setPayoutAddress] = useState<string>('')

  const onChange = useCallback(
    (name: string) => {
      setPayoutAddress(name)
    },
    [setPayoutAddress]
  )
  const { lockOptions, duration, setDuration, durationNumber } = useLockOptions()
  const { action, pending } = useVeModelLock(payout_address || '', balance, durationNumber)

  const { baseCurrency: zoo } = useZooCurrencies()

  return (
    <DashboardCardFlex>
      <CardHeadBox style={{ marginBottom: '16px' }}>
        <CardHeadContribution>
          <LogoCoin src={IconZOO} />
          <Trans>Project Voting</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <SearchDropdown
        options={OPTIONS_PROJECTS}
        value={payout_address}
        onChange={(selectedValue: any) => {
          onChange(selectedValue)
        }}
        renderOption={RenderOption}
      />

      <PickerStyled
        options={lockOptions}
        value={`${duration}`}
        onChange={({ value }: any) => {
          setDuration(value)
        }}
      />

      <BalanceInputWrapperStyled total={zooBalance} balance={balance} btn={MaxButtonRed} setBalance={setBalance} />

      <InputLabel mt={15} alignItems="center">
        <Trans>My ZOO Balance:</Trans>&nbsp;
        <TxtBalance>{formatDecimal(zooBalance)}</TxtBalance>
        <Flex flex={1} />
        {false && <GetZOO />}
      </InputLabel>
      <Flex alignItems="center" justifyContent="space-between" mt={25}>
        <InputLabel>
          <Trans>My veZOO:</Trans>&nbsp;
          <TxtBalance>{loading ? <Dots>Loading</Dots> : formatDecimal(amount)}</TxtBalance>
        </InputLabel>
        {zoo && (
          <ApproveCheckerVeModel currency={zoo} border={balance}>
            <LockButton onClick={() => action()} disabled={pending || !balance || +balance === 0}>
              {pending ? (
                <>
                  <Dots>Voting</Dots>
                </>
              ) : (
                <Trans>Vote</Trans>
              )}
            </LockButton>
          </ApproveCheckerVeModel>
        )}
      </Flex>
    </DashboardCardFlex>
  )
}

/**
 */

export default LockPanel
