import { useState, useEffect, useMemo } from 'react'
import { utils } from 'ethers'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useBlockNumber } from '../../../state/application/hooks'
import { nftStakingAbiExported, useChainStartBlock, useZooDaoNFTStakingPool } from 'constants/zoodao'
import { sortByIndex } from 'hooks/zoodao/useBattlesEventsData'
import { TABS_FOR_TABLE } from 'pages/NftBattlesPageV2/TableTemplate'

const iface = new utils.Interface(nftStakingAbiExported)

export const useNftStakingTxs = (tab: string) => {
  const contract = useZooDaoNFTStakingPool()
  const { account } = useActiveWeb3React()
  const latestBlock = useBlockNumber()
  const [loading, setLoading] = useState<boolean>(false)

  const [events, setEvents] = useState<LogDescription[]>([])
  const b = useChainStartBlock()
  useEffect(() => {
    const fetch = async () => {
      if (contract && account) {
        setEvents([])
        const target = tab === TABS_FOR_TABLE.ALL ? undefined : account

        const staked = contract.filters.StakedNft(target as any)
        const withdraw = contract.filters.UnstakedNft(target as any)

        const stakedD = await contract.queryFilter(
          {
            topics: staked.topics,
          },
          b
        )
        const withdrawD = await contract.queryFilter(
          {
            topics: withdraw.topics,
          },
          b
        )

        const parsed = [...stakedD, ...withdrawD]
          .sort(sortByIndex)
          .map((d) => ({ ...iface.parseLog(d), transactionHash: d.transactionHash }))

        setEvents(parsed)
        setLoading(false)
      }
    }

    fetch()
  }, [contract, b, account, latestBlock, tab])

  return {
    events,
    loading,
  }
}

const UnstakedNft = 'UnstakedNft'
const StakedNft = 'StakedNft'

const getKey = (args: any) => `${args.staker}_${args.token}_${args.id.toString()}`

function getUnstaked(parsed: LD[]): IMap {
  return parsed
    .filter((d) => d.name === UnstakedNft)
    .reduce((acc, item) => {
      acc[getKey(item.args)] = true
      return acc
    }, {} as any)
}

interface IMap {
  [positioNId: string]: boolean
}

function getStaked(parsed: LD[], unstaked: IMap) {
  return parsed.filter(({ name, args }) => name === StakedNft && !unstaked[getKey(args)])
}

export type LD = utils.LogDescription
export interface LogDescription extends LD {
  transactionHash: string
}

export const useStakedForFarmingNfts = (isMy = false) => {
  const nftPoolContract = useZooDaoNFTStakingPool()
  const { account } = useActiveWeb3React()

  const [staked, setStaked] = useState<LogDescription[]>([])

  const [loading, setLoading] = useState<boolean>(true)

  const b = useChainStartBlock()

  useEffect(() => {
    const fetch = async () => {
      if (nftPoolContract && (isMy ? account : true)) {
        const target = isMy ? account : undefined

        const staked = nftPoolContract.filters.StakedNft(target)
        const unStaked = nftPoolContract.filters.UnstakedNft(target)

        setLoading(true)

        const stakedData = await nftPoolContract.queryFilter(
          {
            topics: staked.topics,
          },
          b
        )
        const unStakedData = await nftPoolContract.queryFilter(
          {
            topics: unStaked.topics,
          },
          b
        )

        const data = [...stakedData, ...unStakedData]

        const parsed = data.sort(sortByIndex).map((d) => iface.parseLog(d))

        const unstaked = getUnstaked(parsed)
        const activeStaked = getStaked(parsed, unstaked)

        setStaked(activeStaked as any[])

        setLoading(false)
      }
    }

    fetch()
  }, [nftPoolContract, b, account, isMy])

  return useMemo(() => {
    const mapped = staked.reduce((acc, item) => {
      acc[getKey(item.args)] = true
      return acc
    }, {} as any)
    return {
      staked,
      mappedStaked: mapped,
      loading,
    }
  }, [staked, loading])
}
