import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { ButtonBlue } from '../Button'
import { Dots } from '../swap/styleds'
import {
  BATTLE_STAGES,
  useBattleEpoch,
  useBattleStage,
  useBattleVotingPositionInfo,
  useMaxZooValueConverter,
  useRecomputeMethod,
} from '../../pages/NftBattlesPage/hooks'
import { useZooCurrencies } from '../../hooks/useZooCurrencies'
import { ApproveCheckerBattleArena } from '../../pages/NftBattlesPage/ApproveCheckerBattleArena'
import { BigNumber } from '@ethersproject/bignumber'
import { ZERO } from '../../utils/isZero'

export const Warning = styled.div`
  color: #e138f5;
  font-weight: bold;
  margin: 16px 0;
  display: flex;
  word-break: break-word;
  width: 100%;
`

const Line = styled.div`
  display: flex;
  align-items: center;
`

export const DaiReVoting = ({ votingPositionId, className }: { votingPositionId: string; className?: string }) => {
  const { pending, onRecompute, isExecutedAlready, error } = useRecomputeMethod(votingPositionId, 'recomputeDaiVotes')

  return (
    <ButtonBlue disabled={pending || isExecutedAlready || error} className={className} onClick={onRecompute}>
      {pending ? (
        <Dots>
          <Trans>Recomputing</Trans>
        </Dots>
      ) : (
        <Line>
          <Trans>Recompute</Trans>
        </Line>
      )}
    </ButtonBlue>
  )
}
const ButtonBlue2 = styled(ButtonBlue)`
  width: 100%;
`
const BoxRecompute = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ZooReVoting = ({ votingPositionId }: { votingPositionId: string }) => {
  const { pending, onRecompute, isExecutedAlready, error } = useRecomputeMethod(votingPositionId, 'recomputeZooVotes')

  const { info, loading } = useBattleVotingPositionInfo(votingPositionId)

  const currentEpoch = useBattleEpoch()

  if (!info || loading || (info && info.startEpoch && info.startEpoch.toNumber() === currentEpoch)) {
    return null
  }

  return (
    <BoxRecompute>
      <ButtonBlue2 disabled={pending || isExecutedAlready || error} onClick={onRecompute}>
        {pending ? (
          <Dots>
            <Trans>Recomputing</Trans>
          </Dots>
        ) : (
          <Line>
            <Trans>Recompute</Trans>
          </Line>
        )}
      </ButtonBlue2>
    </BoxRecompute>
  )
}

export const useMaxZooValue = (max: BigNumber) => {
  const maxZooValue = useMaxZooValueConverter(max)

  return useMemo(() => {
    return maxZooValue ? maxZooValue : ZERO
  }, [maxZooValue])
}

interface IRecomputeProps {
  votingPositionId: string
}

export const RecomputeForStageSecond = ({ votingPositionId }: IRecomputeProps) => {
  const currentStage = useBattleStage()
  const { quoteCurrency: dai } = useZooCurrencies()

  if (currentStage !== BATTLE_STAGES.SECOND) {
    return null
  }

  return (
    <>
      {dai ? <ApproveCheckerBattleArena currency={dai} /> : null}
      <DaiReVoting votingPositionId={votingPositionId} />
    </>
  )
}

export const RecomputeForStageFourth = ({ votingPositionId }: IRecomputeProps) => {
  const currentStage = useBattleStage()
  const { baseCurrency: zoo } = useZooCurrencies()

  if (currentStage !== BATTLE_STAGES.FOURTH) {
    return null
  }
  return (
    <>
      {zoo ? <ApproveCheckerBattleArena currency={zoo} /> : null}
      <ZooReVoting votingPositionId={votingPositionId} />
    </>
  )
}
