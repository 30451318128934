import styled from 'styled-components/macro'
import { Box, Button } from '../../../../components/MUI'

export const Container = styled(Box)`
  flex-direction: row;
  align-items: center;
  background-color: #33334b;
  border-radius: 25px;
  height: 38px;
`

export const TypeButton = styled(Button)<{ $active: boolean }>`
  width: 129px;
  height: 38px;
  border-radius: 25px;
  color: white;
  font-family: ${({ theme }) => theme.fontUniform};
  font-size: 18px;
  font-weight: 400;

  ${({ $active }) =>
    $active &&
    ` &, &:hover {
        background-color: #C22DE1;
      }`}
`
