import { useVeModelContract } from '../../constants/zoodao'
import { useCallback, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { ZERO } from 'utils/isZero'
import { getBigNumberValue, useBattleRewards } from 'pages/NftBattlesPage/hooks'
import { useTxTemplate } from './tx-template'
import { formatDecimal } from 'utils/numberWithCommas'
import { shortenString } from 'utils'
import { useVotedForCollection } from 'hooks/gql'

const sum = (arr: BigNumber[]) => arr.reduce((acc, item) => acc.add(item), ZERO)

export const useAllVeZooVotes = (collection: string | null = null, account: null | string = null) => {
  const { voted, loading: loadingV } = useVotedForCollection(account, collection)

  return useMemo(() => {
    const locked = sum(voted.map((item: any) => item.amount))

    return {
      amount: locked,
      loading: loadingV,
    }
  }, [voted, loadingV])
}

export const useVeModelLock = (collection = '', amount: number | undefined, lockTime: number) => {
  const contract = useVeModelContract()

  const value = useMemo(() => (amount ? getBigNumberValue(amount) : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.voteForNftCollection(collection, value, lockTime)
  }, [contract, lockTime, collection, value])

  return useTxTemplate(
    `$voteForNftCollection_${collection}`,
    `Lock ${formatDecimal(value)} ZOO for ${shortenString(collection)}`,
    dataFunc
  )
}

export const useVeModelUnLock = (positionId?: BigNumber | string) => {
  const contract = useVeModelContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.unlockZoo(positionId || '')
  }, [contract, positionId])

  return useTxTemplate(
    `$unlockZoo_${positionId?.toString()}`,
    `UnLock ZOO from position ${positionId?.toString()}`,
    dataFunc
  )
}

export const useVeModelProlongate = (positionId?: BigNumber | string, lockTime = 0) => {
  const contract = useVeModelContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.prolongate(positionId || '', lockTime)
  }, [contract, positionId, lockTime])

  return useTxTemplate(
    `$prolongate_${positionId?.toString()}`,
    `Prolongate locking ZOO position ${positionId?.toString()}`,
    dataFunc
  )
}

export const useExpectedCollectionRewards = (percent: number) => {
  const { stakersRewards, votersRewards, loading } = useBattleRewards()

  return useMemo(() => {
    const fixed = (percent * 100).toFixed(0)
    return {
      stakersRewards: !percent ? ZERO : stakersRewards.mul(BigNumber.from(fixed)).div(100),
      votersRewards: !percent ? ZERO : votersRewards.mul(BigNumber.from(fixed)).div(100),
      loading,
    }
  }, [stakersRewards, votersRewards, loading, percent])
}
