import styled from 'styled-components/macro'
import { ShadowCard } from '../../../../components/Card'
import { Text } from 'rebass/styled-components'

export const Container = styled(ShadowCard)<{ inactive?: boolean }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.4' : '1')};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%
  `};

  background-color: #27273f;
  overflow: hidden;
  border-radius: 24px;

  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  :hover {
    box-shadow: 6px 12px 24px rgba(0, 0, 0, 0.25);
    transform: scale3d(1.015, 1.015, 1.015);
  }
`

export const NFTAddress = styled(Text)`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: white;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
  `};
`

export const RewardsLabel = styled(Text)`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
  `};
`

export const LogoIcon = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`

export const AlarmText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  word-break: break-word;
  margin-top: auto;

  transition: background-color 0.3s;
  box-sizing: border-box;

  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.75px;
  border-radius: 50px;
  width: auto;
  padding: 15px 25px;
  background-color: rgb(248, 172, 0, 0.05);
  border: 2px solid #f8ac00;
  color: #f8ac00;
`
