import { useMemo } from 'react'
import { useActiveWeb3React } from '../hooks/web3'
import { SupportedChainId } from './chainsinfo'
import { useContract } from '../hooks/useContract'
import nftStakingAbi from './../abis/zoodao_nft_staking_pool_abi.json'
import zooDaoTokenAbi from './../abis/zoodao_token_abi.json'
import zooDaoFarmAbi from './../abis/zoodao_farm_abi.json'
import erc721Abi from './../abis/erc721.json'
import erc20Abi from './../abis/erc20.json'
import xZooAbi from './../abis/xZoo.json'
import jackpotsAbi from './../abis/jackpot.json'
import layerZeroAbi from './../abis/layer-zero.json'
import layerZeroResolverAbi from './../abis/layer-zero-resolver.json'
import {
  YiernBattleAbiMoonbeam,
  BattleArenaAbi,
  BattleStakerAbi,
  BattleVoterAbi,
  CrowdsaleAbi,
  SlpStakingAbi,
  BattleFaucetAbi,
  MultichainAbi,
  VeModelAbi,
  XZoo,
  Jackpot,
  AngelsRoundAbi,
  LayerZero,
  LayerZeroResolver,
  WmaticglpAbiArbitrum,
} from '../abis/types'
import crowdSaleAbi from './../abis/crowdsale-abi.json'
import slpPairAbi from './../abis/slp-pair-abi.json'
import slpStakingABI from './../abis/slp-staking-abi.json'
import multichainABI from './../abis/multichain-abi.json'
import yiernBattleABI from './../abis/yiern-battle-abi-moonbeam.json'
import wmaticglpABI from './../abis/wmaticglp-abi-arbitrum.json'
import veModelABI from './../abis/ve-model-abi.json'
import angelsRoundABI from './../abis/angels-round-abi.json'

import battleFaucetAbi from './../abis/battle-faucet-abi.json'

import battleArenaZooFunctionsAbi from './../abis/nft_battle_arena_functions_abi.json'

import battleStakerAbi from './../abis/battle-staker-abi.json'
import battleVoterAbi from './../abis/battle-voter-abi.json'
import battleArenaAbi from './../abis/battle-arena-abi.json'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'

const BATTLE_STAKER_MOONBEAM = '0x193ee2003583fe3430Cc9C5ECf260eDFA8Da5B31'
const BATTLE_VOTER_MOONBEAM = '0x54F37fFF3C9652bBEe3Ca3dd72ab619152F1b2D9'
const BATTLE_ARENA_MOONBEAM = '0x0ADeb5A930875606F325e114FD5147148e042828'

const BATTLE_STAKER_ARBITRUM = '0x7f2F4B005C6b0A8B64cbD28C4f9640C08d6C20B4'
const BATTLE_VOTER_ARBITRUM = '0xa6E90F0997afE9F0Bf8d2193D58C1C23993349E7'
const BATTLE_ARENA_ARBITRUM = '0xC9458cA68AB4Ba27f02455fe42F2612723c5830D'

const BATTLE_YIERN_MOONBEAM = '0x1C55649f73CDA2f72CEf3DD6C5CA3d49EFcF484C'
const BATTLE_YIERN_ARBITRUM = '0xdDbdeda15C2Df67ee5F10782679dA93722d3189B'

const BATTLE_FAUCET_MOONBEAM = '0x043aBF0E3658464c100d80A68343f1Af161d0727'

const NFT_BATTLE_ZOO_FUNCTIONS_MOONBEAM = '0x4510AB2E7ACD55bF792c15F0537f8a25661D0bb5'
const NFT_BATTLE_ZOO_FUNCTIONS_ARBITRUM = '0xf2921C91E92F5BFc76B54b9D1aa40B9787D7e156'

const VE_MODEL_MOONBEAM = '0x1BD77C71568f723D6906EA80FEE45f1F52834c15'
const VE_MODEL_ARBITRUM = '0x97F8F6270f085fbBc900A5D64920D3E13f2cF65f'

const X_ZOO_MOONBEAM = '0x1C535C24f911a8741018Cf09F3030aB6e1b910Cf'
const X_ZOO_ARBITRUM = '0x9F4507f0eB9CD9820aa69e67762814Bab7C15417'

const BATTLE_FAUCET_ARBITRUM = ''

const JACKPOT_A_MOONBEAM = '0x47391EB997f432F019463dF31e3cc09CbE3f4aED'
const JACKPOT_B_MOONBEAM = '0x1a13136996d4B3e6CD4C89128bA8B5836338463E'

const JACKPOT_A_ARBITRUM = '0xaFe8F5E03AE217c61665FEC7239fb93039E9806b'
const JACKPOT_B_ARBITRUM = '0xD4c744bc66840D3E0255f7508BECaa4257CAEAbb'

export const ZOO_TOKEN_MAINNET = '0x09F098B155D561Fc9F7BcCc97038b7e3d20bAF74'
const ZOO_TOKEN_MOONBEAM_REAL = '0x7cd3e6e1A69409deF0D78D17a492e8e143F40eC5' //'0x3907e6ff436e2b2b05d6b929fb05f14c0ee18d90'

const WELL_MOONBEAM = '0x511ab53f793683763e5a8829738301368a2411e3'
const wGLMR_MOONBEAM = '0xacc15dc74880c9944775448304b263d191c6077f'

export const fsGLP = '0x1aDDD80E6039594eE970E5872D247bf0414C8903'

export const GLMR_MOONBEAM = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export const battleArenaAbiExported = battleArenaAbi
export const battleStakerAbiExported = battleStakerAbi
export const battleVoterAbiExported = battleVoterAbi
export const stakingPoolAbiExported = slpStakingABI
export const nftStakingAbiExported = nftStakingAbi

export const DAI_MAINNET = '0x6b175474e89094c44da98b954eedeac495271d0f'

export const FRAX_MOONBEAM = '0x322e86852e492a7ee17f28a78c663da38fb33bfb'

// 0x9de882a68616fa96622ca5d032cb7f7416823b0c
const CROWDSALE_MAINNET = '0x38dda02B4B8EDE3E4BC17675c3C6af98d462bcCf'
const SALE_MOONBEAM_A = '0xb07cD2547AD45F7759f47DBD44C1E07A87537DeD'
const SALE_MOONBEAM_B = '0x0517af138e98AdA5659fFD2836E71b2bC676d1a6'

const SLP_ZOO_DAI_MAINNET = '0x8496e5f9bfb467841427e4f3183181c2e8dc162b'

const SLP_STAKING_MAINNET = '0xF43De6A8b74dA89231F5aa91900E5d07b1d57046'

const SLP_REWARDS_MAINNET = ZOO_TOKEN_MAINNET

const LAYER_ZERO_ENDPOINTS = {
  hardhat: '0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675',
  ethereum: '0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675',
  bsc: '0x3c2269811836af69497E5F486A85D7316753cf62',
  avalanche: '0x3c2269811836af69497E5F486A85D7316753cf62',
  polygon: '0x3c2269811836af69497E5F486A85D7316753cf62',
  arbitrum: '0x3c2269811836af69497E5F486A85D7316753cf62',
  optimism: '0x3c2269811836af69497E5F486A85D7316753cf62',
  fantom: '0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7',
  goerli: '0xbfD2135BFfbb0B5378b56643c2Df8a87552Bfa23',
  'bsc-testnet': '0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1',
  fuji: '0x93f54D755A063cE7bB9e6Ac47Eccc8e33411d706',
  mumbai: '0xf69186dfBa60DdB133E91E9A4B5673624293d8F8',
  'arbitrum-goerli': '0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab',
  'optimism-goerli': '0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1',
  'fantom-testnet': '0x7dcAD72640F835B0FA36EFD3D6d3ec902C7E5acf',
  moonbase: '0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1',
  moonbeam: '0x9740FF91F1985D8d2B71494aE1A2f723bb3Ed9E4',
}

const useLayerZeroResolverAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, LAYER_ZERO_ENDPOINTS.ethereum, LAYER_ZERO_ENDPOINTS.moonbeam)
  }, [chainId])
}

export const useLayerZeroAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(
      chainId,
      '0x34B282440C13104fc752e6B3FB407497fD761d97',
      '0x82aE1Dd43A75e58b75f9F039D848C691C2D8E7b5',
      '0x9D42C819B743ae8F1C677Fb4f774FD9AC4442ECd'
    )
  }, [chainId])
}

export const useLayerZeroContract = () => {
  const address = useLayerZeroAddress()
  return useContract<LayerZero>(address, layerZeroAbi)
}

export const useLayerZeroResolverContract = () => {
  const address = useLayerZeroResolverAddress()
  return useContract<LayerZeroResolver>(address, layerZeroResolverAbi)
}

export const useSlpRewardsAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, SLP_REWARDS_MAINNET)
  }, [chainId])
}

export const useSlpRewardsToken = () => {
  const address = useSlpRewardsAddress()
  return useContract(address, erc20Abi)
}

export const useZooDaiSLPAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, SLP_ZOO_DAI_MAINNET)
  }, [chainId])
}

export const useWellAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, '', WELL_MOONBEAM)
  }, [chainId])
}

export const useWGlmrAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, '', wGLMR_MOONBEAM)
  }, [chainId])
}

export const useGlmrAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, '', GLMR_MOONBEAM)
  }, [chainId])
}

export const useStakingSLPAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, SLP_STAKING_MAINNET)
  }, [chainId])
}

export const useStakingSlpContract = () => {
  const address = useStakingSLPAddress()

  return useContract<SlpStakingAbi>(address, slpStakingABI)
}

export const useZooDaiSlpContract = () => {
  const address = useZooDaiSLPAddress()

  return useContract(address, slpPairAbi)
}

export const useBattleArenaNftDefaultForStaking = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId)
  }, [chainId])
}

export const useDefaultBattleParticipant = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId)
  }, [chainId])
}

export const getSourceByNetwork = (chainId: number | undefined, mainnet = '', moonbeam = '', arbitrum = ''): string => {
  if (chainId === SupportedChainId.MAINNET) {
    return mainnet || ''
  } else if (chainId === SupportedChainId.MOONBEAM) {
    return moonbeam
  } else if (chainId === SupportedChainId.ARBITRUM_ONE) {
    return arbitrum
  } else {
    return mainnet
  }
}

export const useZooDaoPair = () => {
  const zoo = useZooTokenAddressReal()
  const dai = useChainStablecoinAddress()

  return useMemo(
    () => ({
      zoo,
      dai,
    }),
    [dai, zoo]
  )
}

export const useZooDaoSalePair = () => {
  const zoo = useZooTokenAddressReal()
  const stablecoin = useChainStablecoinAddress()

  return useMemo(
    () => ({
      zoo,
      stablecoin,
    }),
    [stablecoin, zoo]
  )
}

export const useERC721Contract = (address: string | undefined) => {
  return useContract(address, erc721Abi)
}

export const useZooDaoCrowdsaleContract = () => {
  const address = useCrowdsaleAddress()

  return useContract<CrowdsaleAbi>(address, crowdSaleAbi, true)
}

export const useZooDaoRoundAContract = () => {
  const address = useAngelsRoundAAddress()

  return useContract<AngelsRoundAbi>(address, angelsRoundABI, true)
}
export const useZooDaoRoundBContract = () => {
  const address = useAngelsRoundBAddress()

  return useContract<AngelsRoundAbi>(address, angelsRoundABI, true)
}

export const useZooDaoNFTStakingPool = () => {
  const { chainId } = useActiveWeb3React()

  const address = useMemo(() => {
    return getSourceByNetwork(chainId)
  }, [chainId])

  return useContract(address, nftStakingAbi, true)
}

export const useBattleStakerContract = (withSignerIfPossible = true): BattleStakerAbi | null => {
  const address = useBattleStakerAddress()

  return useContract<BattleStakerAbi>(address, battleStakerAbi, withSignerIfPossible)
}

export const useBattleStakerAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', BATTLE_STAKER_MOONBEAM, BATTLE_STAKER_ARBITRUM)
  }, [chainId])
}
export const useBattleArenaAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', BATTLE_ARENA_MOONBEAM, BATTLE_ARENA_ARBITRUM)
  }, [chainId])
}

export const useBattleYiernAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', BATTLE_YIERN_MOONBEAM, BATTLE_YIERN_ARBITRUM)
  }, [chainId])
}

export const useVeModelAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', VE_MODEL_MOONBEAM, VE_MODEL_ARBITRUM)
  }, [chainId])
}

export const useBattleFaucetAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', BATTLE_FAUCET_MOONBEAM, BATTLE_FAUCET_ARBITRUM)
  }, [chainId])
}

export const useVeModelContract = (): VeModelAbi | null => {
  const address = useVeModelAddress()

  return useContract<VeModelAbi>(address, veModelABI, true)
}

export const useBattleFaucetContract = (): BattleFaucetAbi | null => {
  const address = useBattleFaucetAddress()

  return useContract<BattleFaucetAbi>(address, battleFaucetAbi, true)
}

export const useBattleYiernContract = (): YiernBattleAbiMoonbeam | WmaticglpAbiArbitrum | null => {
  const address = useBattleYiernAddress()

  const arbitrumContract = useContract<YiernBattleAbiMoonbeam>(address, yiernBattleABI, true)
  const moonbeamContract = useContract<WmaticglpAbiArbitrum>(address, wmaticglpABI, true)

  const { chainId } = useActiveWeb3React()

  switch (chainId) {
    case SupportedChainId.MOONBEAM: {
      return moonbeamContract
    }
    case SupportedChainId.ARBITRUM_ONE: {
      return arbitrumContract
    }
    default: {
      return null
    }
  }
}

export const useBattleArenaContract = (withSignerIfPossible = true): BattleArenaAbi | null => {
  const address = useBattleArenaAddress()

  return useContract<BattleArenaAbi>(address, battleArenaAbi, withSignerIfPossible)
}

export const useBattleVoterContract = (withSignerIfPossible = true): BattleVoterAbi | null => {
  const address = useBattleVoterAddress()

  return useContract<BattleVoterAbi>(address, battleVoterAbi, withSignerIfPossible)
}

export const useBattleVoterAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', BATTLE_VOTER_MOONBEAM, BATTLE_VOTER_ARBITRUM)
  }, [chainId])
}

export const useCrowdsaleAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, CROWDSALE_MAINNET)
  }, [chainId])
}

export const useAngelsRoundAAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', SALE_MOONBEAM_A)
  }, [chainId])
}
export const useAngelsRoundBAddress = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    return getSourceByNetwork(chainId, '', SALE_MOONBEAM_B)
  }, [chainId])
}

export const useBattleArenaFunctions = () => {
  const address = useBattleArenaZooFunctionsAddress()

  return useContract(address, battleArenaZooFunctionsAbi, true)
}

export const useBattleArenaZooFunctionsAddress = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, '', NFT_BATTLE_ZOO_FUNCTIONS_MOONBEAM, NFT_BATTLE_ZOO_FUNCTIONS_ARBITRUM)
  }, [chainId])
}

export const useZooSaleToken = () => {
  const address = useZooTokenAddressReal()
  return useContract(address, zooDaoTokenAbi, true)
}

export const useZooRealToken = () => {
  const address = useZooTokenAddressReal()
  return useContract(address, zooDaoTokenAbi, true)
}

export const useProjectStablecoinForCurrentChain = () => {
  const address = useChainStablecoinAddress()
  return useContract(address, zooDaoTokenAbi, true)
}

export const useSaleStablecoinForCurrentChain = () => {
  const address = useChainStablecoinAddress()
  return useContract(address, zooDaoTokenAbi, true)
}

export const useZooTokenAddressReal = () => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getSourceByNetwork(chainId, ZOO_TOKEN_MAINNET, ZOO_TOKEN_MOONBEAM_REAL)
  }, [chainId])
}

export const useChainStablecoinAddress = () => {
  const { chainId } = useActiveWeb3React()
  const address = useMemo(() => {
    return getSourceByNetwork(chainId, DAI_MAINNET, FRAX_MOONBEAM)
  }, [chainId])

  return address
}

export const useXZooTokenAddress = () => {
  const { chainId } = useActiveWeb3React()
  const address = useMemo(() => {
    return getSourceByNetwork(chainId, '', X_ZOO_MOONBEAM, X_ZOO_ARBITRUM)
  }, [chainId])

  return address
}

export const useZooDaoFarm = () => {
  const address = useZooDaoFarmAddress()

  return useContract(address, zooDaoFarmAbi, true)
}

export const useXZooContract = () => {
  const address = useXZooTokenAddress()

  return useContract<XZoo>(address, xZooAbi, true)
}

export const useZooDaoFarmAddress = () => {
  const { chainId } = useActiveWeb3React()

  const address = useMemo(() => {
    return getSourceByNetwork(chainId)
  }, [chainId])

  return address
}

export const useZooDaiContracts = () => {
  const { zoo, dai } = useZooDaoPair()

  const zooContract = useContract(zoo, erc20Abi)
  const daiContract = useContract(dai, erc20Abi)

  return {
    zooContract,
    daiContract,
  }
}

export const useMultichainRouter = (address: string) => {
  return useContract<MultichainAbi>(address, multichainABI, true)
}

export const getChainStartBlock = (chainId?: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MOONBEAM: {
      return 1887167
    }
    case SupportedChainId.MOONBEAM_ALPHA: {
      return 2826073
    }

    default: {
      return undefined
    }
  }
}

export const useChainStartBlock = () => {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => getChainStartBlock(chainId), [chainId])
}

export const useJackpotAAddress = () => {
  const { chainId } = useActiveWeb3React()

  const address = useMemo(() => {
    return getSourceByNetwork(chainId, '', JACKPOT_A_MOONBEAM, JACKPOT_A_ARBITRUM)
  }, [chainId])

  return address
}

export const useJackpotBAddress = () => {
  const { chainId } = useActiveWeb3React()

  const address = useMemo(() => {
    return getSourceByNetwork(chainId, '', JACKPOT_B_MOONBEAM, JACKPOT_B_ARBITRUM)
  }, [chainId])

  return address
}

export const useJackpotAContract = () => {
  const address = useJackpotAAddress()

  return useContract<Jackpot>(address, jackpotsAbi, true)
}

export const useJackpotBContract = () => {
  const address = useJackpotBAddress()

  return useContract<Jackpot>(address, jackpotsAbi, true)
}

export const useJackpotContract = (type: IPanelJackpotsVariant) => {
  const a = useJackpotAAddress()
  const b = useJackpotBAddress()

  return useContract<Jackpot>(type === IPanelJackpotsVariant.JackpotA ? a : b, jackpotsAbi, true)
}
