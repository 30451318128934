import { BigNumber } from 'ethers'
import request from 'graphql-request'
import gql from 'graphql-tag'
import { isEqual } from 'lodash'
import { DocumentNode } from 'graphql/language/ast'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'
import { useEffect, useMemo, useState } from 'react'
import useSWRWrapper from 'swr'
import { ZERO } from 'utils/isZero'
import { useActiveWeb3React } from './web3'
// import { useBlockNumber } from 'state/application/hooks'

const HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://squid.subsquid.io/zoodao/v/v18/graphql'
    : 'https://squid.subsquid.io/zoodao/v/v18/graphql'

const CHECKING_TIMEOUT = 30000

const useBlockNumber = () => {
  const [tId, setId] = useState(Date.now())

  useEffect(() => {
    const id = setInterval(() => {
      setId((v) => ++v)
    }, CHECKING_TIMEOUT)

    return () => clearInterval(id)
  }, [setId])

  return tId
}

export const useSWR = (key: string, fetcher: any) => {
  const { data: d, error } = useSWRWrapper(key, fetcher)

  const data = useTransition(d)

  return useMemo(() => {
    return { data, error }
  }, [data, error])
}

export const useTransition = (data: any) => {
  const [prev, setPrev] = useState(() => data)

  useEffect(() => {
    if (data) {
      setPrev((state: any) => {
        if (state && data && isEqual(data, state)) {
          return state
        }

        return data
      })
    }
  }, [data])

  return prev
}

const LIMITS = 500
const LIMITLOGS = 100

const createdStakerPositionsQuery = gql`
  query createdStakerPositions($stakingPid: BigInt,$staker: String, $search: String, $isDeleted: Boolean) {
    createdStakerPositions(limit: ${LIMITS}, 
      where: {isDeleted_eq: $isDeleted, stakingPositionId_eq: $stakingPid, staker_eq: $staker, project: {OR: {name_containsInsensitive: $search, OR: {address_containsInsensitive: $search, OR: {symbol_containsInsensitive: $search}}}}},
       orderBy: timestamp_DESC) {
      id
      stakingPositionId
      staker
      currentEpoch
      isDeleted
      timestamp
      transactionHash
    }
  }
`
const removedStakerPositionsQuery = gql`
  query removedStakerPositions($stakingPid: BigInt, $staker: String) {
    removedStakerPositions(limit: ${LIMITS}, where: { stakingPositionId_eq: $stakingPid, staker_eq: $staker }) {
      id
      currentEpoch
      staker
      stakingPositionId
      timestamp
      transactionHash
    }
  }
`
const createdVotingPositionsQuery = gql`
  query createdVotingPositions($stakingPid: BigInt, $votingPid: BigInt, $voter: String, $author: String, $search: String, $isDeleted: Boolean) {
    createdVotingPositions(
      limit: ${LIMITS}
      where: {isDeleted_eq: $isDeleted, stakingPositionId_eq: $stakingPid, votingPositionId_eq: $votingPid, voter_eq: $voter, author_eq: $author, project: {OR: {name_containsInsensitive: $search, OR: {address_containsInsensitive: $search, OR: {symbol_containsInsensitive: $search}}}}},
      orderBy: timestamp_DESC
    ) {
      id
      voter
      author
      votes
      currentEpoch
      daiAmount
      votingPositionId
      stakingPositionId
      timestamp
      isDeleted
      transactionHash
    }
  }
`

const pairedNftsQuery = gql`
  query pairedNfts($fighter1: BigInt, $fighter2: BigInt, $search: String, $currentEpoch: BigInt, $fromEpoch:BigInt) {
    pairedNfts(
        limit: ${LIMITS}, 
        where: { 
          currentEpoch_gte:$fromEpoch,
          currentEpoch_eq: $currentEpoch, AND: {
            fighter1_eq: $fighter1,
            OR: {
              fighter2_eq: $fighter2, 
              OR:  {
                project1: {name_containsInsensitive: $search, 
                  OR: {address_containsInsensitive: $search, 
                    OR: {symbol_containsInsensitive: $search}
                  }
                }
                , OR: {project2: {name_containsInsensitive: $search, OR: {address_containsInsensitive: $search, OR: {symbol_containsInsensitive: $search}}}} 
              }
            }
          }
        }, 
        orderBy: timestamp_ASC) {
          id
          currentEpoch
          fighter1
          fighter2
          pairIndex
    }
  }
`

const chosenWinnerQuery = gql`
  query chosenWinners($currentEpoch: BigInt) {
    chosenWinners(limit: ${LIMITS}, where: { currentEpoch_eq: $currentEpoch }) {
      currentEpoch
      fighter1
      fighter2
      id
      pairIndex
      playedPairsAmount
      winner
    }
  }
`

const claimedRewardsQuery = gql`
  query claimedRewards($beneficiary: String) {
    claimedRewardFromStakings(limit: ${LIMITS}, where: { beneficiary_eq: $beneficiary }) {
      daiReward
    }
    claimedRewardFromVotings(limit: ${LIMITS}, where: { beneficiary_eq: $beneficiary }) {
      daiReward
    }
  }
`

const claimedRewardsStakingQuery = gql`
  query claimedRewards($pids: [BigInt!], $beneficiary: String) {
    claimedRewardFromStakings(limit: ${LIMITS}, where: {stakingPositionId_in: $pids, beneficiary_eq: $beneficiary }) {
      daiReward
    }
  }
`

const claimedRewardsVotingQuery = gql`
  query claimedRewards($pids: [BigInt!], $beneficiary: String) {
    claimedRewardFromVotings(limit: ${LIMITS}, where: {votingPositionId_in: $pids, beneficiary_eq: $beneficiary }) {
      daiReward
    }
  }
`

const zooUnlockedQuery = gql`
  query zooUnlockeds($collection: String, $voter: String) {
    zooUnlockeds(where: { collection_eq: $collection, voter_eq: $voter }, limit: ${LIMITS}) {
      amount
    }
  }
`

const votedForCollectionsQuery = gql`
  query votedForCollections($collection: String, $voter: String) {
    votedForCollections(limit: ${LIMITS}, where: { collection_eq: $collection, voter_eq: $voter, isDeleted_eq: false }) {
      amount
    }
  }
`

const votedForCollectionsListQuery = gql`
  query votedForCollectionsList($voter: String) {
    votedForCollections(where: { voter_eq: $voter, isDeleted_eq: false }, limit: ${LIMITS}, orderBy: positionId_DESC) {
      id
      isDeleted
      positionId
      timestamp
      transactionHash
      voter
      amount
      collection
    }
  }
`

const xZooLogsQuery = gql`
  query xZooLogs($account: String) {
    xZooClaimeds(limit: ${LIMITLOGS}, where: { staker_eq: $account }) {
      id
      amount
      beneficiary
      positionId,
      staker
      timestamp
      transactionHash
    }
    xZooStakeds(limit: ${LIMITLOGS}, where: { staker_eq: $account }) {
      id
      amount
      beneficiary
      positionId,
      staker
      timestamp
      transactionHash
    }
    xZooWithdraweds(limit: ${LIMITLOGS}, where: { staker_eq: $account }) {
      id
      amount
      beneficiary
      positionId,
      staker
      timestamp
      transactionHash
    }
  }
`

const stakingLogsQuery = gql`
  query stakingLogsQuery($account: String) {
    createdStakerPositions(limit: ${LIMITLOGS}, where: { staker_eq: $account }) {
      id
      currentEpoch
      isDeleted
      staker
      stakingPositionId
      timestamp
      transactionHash
      author
    }
    removedStakerPositions(limit: ${LIMITLOGS}, where: {staker_eq: $account }) {
      id
      staker
      stakingPositionId
      timestamp
      currentEpoch
      transactionHash
    }
  }
`

const jackpotsLogsQuery = gql`
  query jackpotsLogs($type: String) {
    jackpotClaimeds(limit: ${LIMITLOGS}, where: {type_eq: $type}) {
      id
      owner
      epoch
      beneficiary
      type
      transactionHash
      timestamp
      rewards
      positionId
    }
    jackpotWinnerChooseds(limit: ${LIMITLOGS}, where: {type_eq: $type}) {
      epoch
      id
      timestamp
      transactionHash
      type
      winner
    }
  }
`
const jackpotsWinnersQuery = gql`
  query jackpotsWinners($type: String) {
    jackpotWinnerChooseds(limit: ${LIMITS}, where: {type_eq: $type}) {
      epoch
      id
      timestamp
      transactionHash
      type
      winner
    }
  }
`

const allLogsQuery = gql`
  query logsQuery($account: String, $stakingPositionId: BigInt) {
    createdStakerPositions(limit: ${LIMITLOGS}, where: { stakingPositionId_eq: $stakingPositionId, staker_eq: $account }) {
      id
      currentEpoch
      isDeleted
      staker
      stakingPositionId
      timestamp
      transactionHash
      author
    }
    createdVotingPositions(
      limit: ${LIMITLOGS}
      where: { stakingPositionId_eq: $stakingPositionId, voter_eq: $account },
      orderBy: timestamp_DESC
    ) {
      id
      voter
      votes
      currentEpoch
      daiAmount
      votingPositionId
      stakingPositionId
      timestamp
      transactionHash
      author
    }
    addedDaiToVotings(limit: ${LIMITLOGS}, where: { stakingPositionId_eq: $stakingPositionId, voter_eq: $account }) {
      id
      currentEpoch
      amount
      stakingPositionId
      timestamp
      voter
      votes
      votingPositionId
      transactionHash
    }
    addedZooToVotings(limit: ${LIMITLOGS}, where: { stakingPositionId_eq: $stakingPositionId, voter_eq: $account }) {
      id
      currentEpoch
      amount
      stakingPositionId
      timestamp
      voter
      votes
      votingPositionId
      transactionHash
    }
    withdrawedDaiFromVotings(limit: ${LIMITLOGS}, where: { voter_eq: $account, stakingPositionId_eq: $stakingPositionId }) {
      id
      daiNumber
      currentEpoch
      beneficiary
      stakingPositionId
      timestamp
      votingPositionId
      voter
      transactionHash
    }
    withdrawedZooFromVotings(limit: ${LIMITLOGS}, where: { voter_eq: $account, stakingPositionId_eq: $stakingPositionId }) {
      id
      stakingPositionId
      timestamp
      voter
      votingPositionId
      zooNumber
      currentEpoch
      beneficiary
      transactionHash
    }
    removedStakerPositions(limit: ${LIMITLOGS}, where: { stakingPositionId_eq: $stakingPositionId, staker_eq: $account }) {
      id
      staker
      stakingPositionId
      timestamp
      currentEpoch
      transactionHash
    }
    liquidatedVotingPositions(limit: ${LIMITLOGS}, where: {stakingPositionId_eq: $stakingPositionId, beneficiary_eq: $account}) {
      id
      stakingPositionId
      timestamp
      transactionHash
      voter
      votingPositionId
      zooReturned
      daiReceived
      currentEpoch
      beneficiary
    }
  }
`

const tokensGivenQuery = gql`
  query faucetZooGivens($user: String) {
    faucetZooGivens(limit: ${LIMITS}, where: { user_eq: $user }) {
      id
      user
      timestamp
    }
  }
`

const xZooParticipantsAmountCountQuery = gql`
  query xZooStakedsConnection {
    xZooStakedsConnection(orderBy: id_ASC) {
      totalCount
    }
  }
`

const jackpotsStakedAmountQuery = gql`
  query jackpotStakedsConnection {
    jackpotStakedsConnection(orderBy: id_ASC) {
      totalCount
    }
  }
`

const statsQuery = gql`
  query Stats($key: String) {
    stats(where: { id_eq: $key }) {
      id
      updatedAt
      value
    }
  }
`

const transfersErc20Rewards = gql`
  query transferErc20s($contract: String, $to: String) {
    transferErc20s(limit: ${LIMITS}, where: { contract_eq: $contract, to_eq: $to }) {
      from
      id
      amount
      contract
      to
      transactionHash
    }
  }
`

const xZooClaimedsByUserQuery = gql`
  query xZooClaimedsByUser($staker: String) {
    xZooClaimeds(where: {staker_eq: $staker}, limit: ${LIMITS}) {
      id
      positionId
      staker
      timestamp
      transactionHash
      beneficiary
      amount
    }
  }
`

const jackpotsQuery = gql`
  query jackpotStakeds($beneficiary: String, $type: String) {
    jackpotStakeds(limit: ${LIMITS}, where: {isDeleted_eq: false, beneficiary_eq: $beneficiary, type_eq: $type}) {
      id
      beneficiary
      jackpotPositionId
      positionId
      timestamp
      transactionHash
      type
    }
  }
`
const nftScanTokensQuery = gql`
  query nftScanTokens($contract: String!, $tokenId: BigInt!) {
    nftScanTokens(limit: ${LIMITS}, where: {contract_eq: $contract, tokenId_eq: $tokenId}) {
      id
      contract
      tokenId
      meta
    }
  }
`

const claimedIncentiveRewardsVotingQuery = gql`
  query claimedIncentiveRewardFromVotings($votings: [BigInt!], $voter: String) {
    claimedIncentiveRewardFromVotings(limit: ${LIMITS}, where: {votingPositionId_in: $votings, voter_eq: $voter}) {
      id
      zooReward
    }
  }
`
const claimedIncentiveRewardsStakingQuery = gql`
  query claimedIncentiveRewardFromVotings($stakings:[BigInt!], $staker: String) {
    claimedIncentiveRewardFromVotings(limit: ${LIMITS}, where: {stakingPositionId_in: $stakings,  staker_eq: $staker}) {
      id
      zooReward
    }
  }
`
const query = (query: DocumentNode, variables: any) => request(`${HOST}`, query, variables)

const getIncentiveRewards = async (isStakings: boolean, variables: any) => {
  return query(isStakings ? claimedIncentiveRewardsStakingQuery : claimedIncentiveRewardsVotingQuery, variables)
}

const getJackpots = async (variables: any) => {
  return query(jackpotsQuery, variables)
}

const getNftScanTokens = async (variables: any) => query(nftScanTokensQuery, variables)

const getXZooClaimedByUser = async (variables: any) => {
  return query(xZooClaimedsByUserQuery, variables)
}

const getTransferErc20Rewards = async (vars: any) => query(transfersErc20Rewards, vars)

const getStats = async (variables: any) => {
  return query(statsQuery, variables)
}

const getXZooParticipantsAmount = async (variables: any) => {
  return query(xZooParticipantsAmountCountQuery, variables)
}

const getJackpotsAmountStaked = async (variables: any) => {
  return query(jackpotsStakedAmountQuery, variables)
}

const getTokensGiven = async (variables: any) => {
  return query(tokensGivenQuery, variables)
}

const getAllLogs = async (variables: any) => {
  return query(allLogsQuery, variables)
}

const getJackpotsLogs = async (variables: any) => {
  return query(jackpotsLogsQuery, variables)
}

const getJackpotsWinners = async (variables: any) => {
  return query(jackpotsWinnersQuery, variables)
}

const getStakingLogs = async (variables: any) => {
  return query(stakingLogsQuery, variables)
}

const getXZooLogs = async (variables: any) => {
  return query(xZooLogsQuery, variables)
}

const getCreatedStakerPositions = async (variables: any) => {
  return query(createdStakerPositionsQuery, variables)
}

const getRemovedStakerPositions = async (variables: any) => {
  return query(removedStakerPositionsQuery, variables)
}

const getCreatedVoterPositions = async (variables: any) => {
  return query(createdVotingPositionsQuery, variables)
}

const getPairedNfts = async (variables: any) => {
  return query(pairedNftsQuery, variables)
}

const getChoosenWinner = async (variables: any) => {
  return query(chosenWinnerQuery, variables)
}

const getClaimedRewards = async (variables: any) => {
  return query(claimedRewardsQuery, variables)
}

const getClaimedVotingRewards = async (variables: any) => {
  return query(claimedRewardsVotingQuery, variables)
}

const getClaimedStakingRewards = async (variables: any) => {
  return query(claimedRewardsStakingQuery, variables)
}

const getZooUnlockedAmount = async (variables: any) => {
  return query(zooUnlockedQuery, variables)
}

const getVotedForCollection = async (variables: any) => {
  return query(votedForCollectionsQuery, variables)
}

const getVotedForCollectionsList = async (variables: any) => {
  return query(votedForCollectionsListQuery, variables)
}

export function useStakerPositions(
  stakingPositionId?: string | BigNumber | null,
  account?: string | null,
  search?: string,
  isAll = false
) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (stakingPositionId) {
      vars.stakingPid = stakingPositionId?.toString()
    }
    if (account) {
      vars.staker = account.toLowerCase()
    }
    if (search) {
      vars.search = search
    }

    if (!isAll) {
      vars.isDeleted = false
    }
    return vars
  }, [stakingPositionId, isAll, account, search, block])

  const { data, error } = useSWR(`getCreatedStakerPositions_${JSON.stringify(vars)}`, () => {
    return getCreatedStakerPositions({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      positions: data ? data.createdStakerPositions : [],
      loading: !data,
      error,
    }
  }, [error, data])
}
export function useUnstakedPositions(stakingPositionId?: string | BigNumber | null, account?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (stakingPositionId) {
      vars.stakingPid = stakingPositionId?.toString()
    }
    if (account) {
      vars.staker = account.toLowerCase()
    }
    return vars
  }, [stakingPositionId, account, block])

  const { data, error } = useSWR(`getRemovedStakerPositions_${JSON.stringify(vars)}`, () => {
    return getRemovedStakerPositions({
      ...vars,
    })
  })

  return {
    positions: data ? data.removedStakerPositions : [],
    loading: !data,
    error,
  }
}

export function useVoterPositions(
  stakingPositionId?: string | BigNumber | null,
  votingPositionId?: string | BigNumber | null,
  account?: string | null,
  search?: string,
  isAll = false,
  asAuthor = true
) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (stakingPositionId) {
      vars.stakingPid = stakingPositionId?.toString()
    }
    if (account) {
      // TODO: it is not voter!
      if (asAuthor) {
        vars.author = account.toLowerCase()
      } else {
        vars.voter = account.toLowerCase()
      }
    }
    if (search) {
      vars.search = search
    }
    if (votingPositionId) {
      vars.votingPid = votingPositionId.toString()
    }
    if (!isAll) {
      vars.isDeleted = false
    }
    return vars
  }, [stakingPositionId, votingPositionId, asAuthor, isAll, account, search, block])

  const { data, error } = useSWR(`getCreatedVoterPositions_${JSON.stringify(vars)}`, () => {
    return getCreatedVoterPositions({
      ...vars,
    })
  })

  return useMemo(
    () => ({
      positions: data ? data.createdVotingPositions : [],
      loading: !data,
      error,
    }),
    [data, error]
  )
}
export function usePairedNfts(
  fighter1?: string | BigNumber | null,
  fighter2?: string | BigNumber | null,
  currentEpoch?: string | BigNumber | null | number,
  searchQuery?: string,
  fromEpoch?: number
) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (fighter1) {
      vars.fighter1 = fighter1.toString()
    }
    if (fighter2) {
      vars.fighter2 = fighter2.toString()
    }
    if (currentEpoch) {
      vars.currentEpoch = currentEpoch
    }
    if (searchQuery) {
      vars.search = searchQuery
    }
    if (fromEpoch) {
      vars.fromEpoch = fromEpoch
    }
    return vars
  }, [fighter1, fighter2, searchQuery, currentEpoch, fromEpoch, block])

  const { data, error } = useSWR(`getPairedNfts_${JSON.stringify(vars)}`, () => getPairedNfts(vars))

  return useMemo(() => {
    return {
      nfts: data ? data.pairedNfts : [],
      loading: !data,
      error,
    }
  }, [data, error])
}

export function useChosenWinners(currentEpoch?: string | BigNumber | null | number) {
  const block = useBlockNumber()

  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (currentEpoch) {
      vars.currentEpoch = currentEpoch
    }
    return vars
  }, [currentEpoch, block])

  const { data, error } = useSWR(`getChoosenWinner_${JSON.stringify(vars)}`, () => {
    return getChoosenWinner({
      ...vars,
    })
  })

  return {
    winners: data ? data.chosenWinners : [],
    loading: !data,
    error,
  }
}

export function useClaimedRewards(beneficiary?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (beneficiary) {
      vars.beneficiary = beneficiary.toLowerCase()
    }
    return vars
  }, [beneficiary, block])

  const { data, error } = useSWR(`getClaimedRewards_${JSON.stringify(vars)}`, () => {
    return getClaimedRewards({
      ...vars,
    })
  })

  return {
    rewards: data ? [...data.claimedRewardFromStakings, ...data.claimedRewardFromVotings] : [],
    loading: !data,
    error,
  }
}

export function useStakingClaimedRewards(isStaking: boolean, beneficiary?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (beneficiary) {
      vars.beneficiary = beneficiary.toLowerCase()
    }
    return vars
  }, [beneficiary, block])

  const { data, error } = useSWR(`get${isStaking ? 'Staking' : 'Voting'}ClaimedRewards_${JSON.stringify(vars)}`, () => {
    if (isStaking)
      return getClaimedStakingRewards({
        ...vars,
      })
    else
      return getClaimedVotingRewards({
        ...vars,
      })
  })

  return {
    rewards: data ? (isStaking ? data.claimedRewardFromStakings : data.claimedRewardFromVotings) : [],
    loading: !data,
    error,
  }
}

export function useZooUnlockedAmount(voter?: string | null, collection?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (voter) {
      vars.voter = voter.toLowerCase()
    }
    if (collection) {
      vars.collection = collection.toLowerCase()
    }
    return vars
  }, [voter, collection, block])

  const { data, error } = useSWR(`getZooUnlockedAmount_${JSON.stringify(vars)}`, () => {
    return getZooUnlockedAmount({
      ...vars,
    })
  })

  return {
    unlockeds: data ? data.zooUnlockeds : [],
    loading: !data,
    error,
  }
}
export function useTokensGiven(user?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (user) {
      vars.user = user.toLowerCase()
    }
    return vars
  }, [user, block])

  const { data, error } = useSWR(`getTokensGiven_${JSON.stringify(vars)}`, () => {
    return getTokensGiven({
      ...vars,
    })
  })

  return {
    faucetZooGivens: data ? data.faucetZooGivens : [],
    loading: !data,
    error,
  }
}

export function useXZooParticipantsAmount() {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    return vars
  }, [block])

  const { data, error } = useSWR(`getXZooParticipants_${JSON.stringify(vars)}`, () => {
    return getXZooParticipantsAmount({
      ...vars,
    })
  })

  return {
    totalCount: data && data.xZooStakedsConnection ? data.xZooStakedsConnection.totalCount : 0,
    loading: !data,
    error,
  }
}

export function useJackpotsParticipantsAmount() {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    return vars
  }, [block])

  const { data, error } = useSWR(`getJackpotsParticipants_${JSON.stringify(vars)}`, () => {
    return getJackpotsAmountStaked({
      ...vars,
    })
  })

  return {
    totalCount: data && data.jackpotStakedsConnection ? data.jackpotStakedsConnection.totalCount : 0,
    loading: !data,
    error,
  }
}

export function useXZooTotalClaimed() {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { block, key: 'X_ZOO_CLAIMED_TOTAL_KEY' }
    return vars
  }, [block])

  const { data, error } = useSWR(`getStats_xZooClaimed_${JSON.stringify(vars)}`, () => {
    return getStats({
      ...vars,
    })
  })

  return {
    value: data && data.stats && data.stats[0] ? data.stats[0].value : 0,
    loading: !data,
    error,
  }
}

export function useTransferRewards(to: string | null | undefined, token: string) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (to) {
      vars.to = to.toLowerCase()
    }
    if (token) {
      vars.contract = token.toLowerCase()
    }
    return vars
  }, [block, to, token])

  const { data, error } = useSWR(`getErc20Rewards_${JSON.stringify(vars)}`, () => {
    return getTransferErc20Rewards({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      value: data ? data.transferErc20s.reduce((acc: BigNumber, item: any) => acc.add(item.amount), ZERO) : 0,
      loading: !data,
      error,
    }
  }, [data, error])
}

export function useXZooTotalClaimedByUser() {
  const block = useBlockNumber()
  const { account } = useActiveWeb3React()
  const vars = useMemo(() => {
    const vars: any = { block, staker: account?.toLowerCase() }
    return vars
  }, [block, account])

  const { data, error } = useSWR(`getXZooClaimedByUser_${JSON.stringify(vars)}`, () => {
    return getXZooClaimedByUser({
      ...vars,
    })
  })

  return {
    claimed: data && data.xZooClaimeds ? (data.xZooClaimeds as any[]) : [],
    loading: !data,
    error,
  }
}

const JACKPOTS_CLAIMED_TOTAL_KEY = 'JACKPOTS_CLAIMED_TOTAL'

export function useJackpotsTotalClaimed(type: IPanelJackpotsVariant) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { block, key: `${type}_${JACKPOTS_CLAIMED_TOTAL_KEY}` }
    return vars
  }, [block, type])

  const { data, error } = useSWR(`getStats_jackpotsClaimed_${JSON.stringify(vars)}`, () => {
    return getStats({
      ...vars,
    })
  })

  return {
    value: data && data.stats && data.stats[0] ? data.stats[0].value : 0,
    loading: !data,
    error,
  }
}

export function useVotedForCollection(voter?: string | null, collection?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (voter) {
      vars.voter = voter.toLowerCase()
    }
    if (collection) {
      vars.collection = collection.toLowerCase()
    }
    return vars
  }, [collection, voter, block])

  const { data, error } = useSWR(`getVotedForCollection_${JSON.stringify(vars)}`, () => {
    return getVotedForCollection({
      ...vars,
    })
  })

  return {
    voted: data ? data.votedForCollections : [],
    loading: !data,
    error,
  }
}
export function useVotedForCollectionsList(voter?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (voter) {
      vars.voter = voter.toLowerCase()
    }

    return vars
  }, [voter, block])

  const { data, error } = useSWR(`getVotedForCollectionList_${JSON.stringify(vars)}`, () => {
    return getVotedForCollectionsList({
      ...vars,
    })
  })

  return {
    list: data ? data.votedForCollections : [],
    loading: !data,
    error,
  }
}

export function useAllLogs(account?: string | null, stakingPositionId?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (account) {
      vars.account = account.toLowerCase()
    }
    if (stakingPositionId) {
      vars.stakingPositionId = stakingPositionId
    }
    return vars
  }, [account, stakingPositionId, block])

  const { data, error } = useSWR(`getAllLogs_${JSON.stringify(vars)}`, () => {
    return getAllLogs({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      logs: data
        ? [
            ...data.createdStakerPositions.map((i: any) => ({ ...i, isStaked: true })),
            ...data.createdVotingPositions.map((i: any) => ({ ...i, isAddedDai: true })),
            ...data.removedStakerPositions.map((i: any) => ({ ...i, isRemoved: true })),
            ...data.addedDaiToVotings.map((i: any) => ({ ...i, isAddedDai: true })),
            ...data.addedZooToVotings.map((i: any) => ({ ...i, isAddedZoo: true })),
            ...data.withdrawedDaiFromVotings.map((i: any) => ({ ...i, isRemovedDai: true })),
            ...data.withdrawedZooFromVotings.map((i: any) => ({ ...i, isRemovedZoo: true })),
            ...data.liquidatedVotingPositions.map((i: any) => ({
              ...i,
              isLiquidated: true,
              isRemovedZoo: true,
              isRemovedDai: true,
            })),
          ].sort(sortByTimestamp)
        : [],
      loading: !data,
      error,
    }
  }, [error, data])
}

export function useJackpotsLogs(type: IPanelJackpotsVariant) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (type) {
      vars.type = type
    }

    return vars
  }, [block, type])

  const { data, error } = useSWR(`getJackpotsLogs_${JSON.stringify(vars)}`, () => {
    return getJackpotsLogs({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      logs: data ? [...data.jackpotClaimeds, ...data.jackpotWinnerChooseds].sort(sortByTimestamp) : [],
      loading: !data,
      error,
    }
  }, [error, data])
}

export function useJackpotsWinners(type: IPanelJackpotsVariant) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    if (type) {
      vars.type = type
    }

    return vars
  }, [block, type])

  const { data, error } = useSWR(`getJackpotsWinners_${JSON.stringify(vars)}`, () => {
    return getJackpotsWinners({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      data: data ? data.jackpotWinnerChooseds : [],
      loading: !data,
      error,
    }
  }, [error, data])
}

export function useStakingLogs(account?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (account) {
      vars.account = account.toLowerCase()
    }
    return vars
  }, [account, block])

  const { data, error } = useSWR(`getStakingLogs_${JSON.stringify(vars)}`, () => {
    return getStakingLogs({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      logs: data
        ? [
            ...data.createdStakerPositions.map((i: any) => ({ ...i, isStaked: true })),
            ...data.removedStakerPositions.map((i: any) => ({ ...i, isRemoved: true })),
          ].sort(sortByTimestamp)
        : [],
      loading: !data,
      error,
    }
  }, [error, data])
}

export function useXZooLogs(account?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (account) {
      vars.account = account.toLowerCase()
    }
    return vars
  }, [account, block])

  const { data, error } = useSWR(`getXZooLogs_${JSON.stringify(vars)}`, () => {
    return getXZooLogs({
      ...vars,
    })
  })

  return useMemo(() => {
    return {
      logs: data
        ? [
            ...data.xZooClaimeds.map((i: any) => ({ ...i, isClaimed: true })),
            ...data.xZooStakeds.map((i: any) => ({ ...i, isStaked: true })),
            ...data.xZooWithdraweds.map((i: any) => ({ ...i, isWithdrawed: true })),
          ].sort(sortByTimestamp)
        : [],
      loading: !data,
      error,
    }
  }, [error, data])
}

export function sortByTimestamp(a: any, b: any) {
  return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
}

export function useJackpots(beneficiary?: string | null, type?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (beneficiary) {
      vars.beneficiary = beneficiary.toLowerCase()
    }
    if (type) {
      vars.type = type
    }
    return vars
  }, [type, beneficiary, block])

  const { data, error } = useSWR(`getJackpots_${JSON.stringify(vars)}`, () => {
    return getJackpots({
      ...vars,
    })
  })

  return {
    jackpots: data ? data.jackpotStakeds : [],
    loading: !data,
    error,
  }
}

export function useNftScanImg(contract?: string | null, tokenId?: string | null) {
  const block = useBlockNumber()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }
    if (contract) {
      vars.contract = contract.toLowerCase()
    }
    if (contract) {
      vars.contract = contract.toLowerCase()
    }
    if (tokenId) {
      vars.tokenId = tokenId
    }
    return vars
  }, [contract, tokenId, block])

  const { data, error } = useSWR(`getNftScan_Api_${JSON.stringify(vars)}`, () => {
    return getNftScanTokens({
      ...vars,
    })
  })

  return {
    nftScanTokens: data ? data.nftScanTokens : [],
    loading: !data,
    error,
  }
}

export function useClaimedIncentiveRewards(isStaking: boolean, pids: string[] | BigNumber[]) {
  const block = useBlockNumber()
  const { account = '' } = useActiveWeb3React()
  const vars = useMemo(() => {
    const vars: any = { invalidationNumber: block }

    const user = account || ''
    if (isStaking) {
      vars.staker = user.toLowerCase()
      vars.stakings = pids.map((p) => p.toString())
    } else {
      vars.voter = user.toLowerCase()
      vars.votings = pids.map((p) => p.toString())
    }

    return vars
  }, [account, isStaking, pids, block])

  const { data, error } = useSWR(`useIncentiveRewards_${JSON.stringify(vars)}`, () => {
    return getIncentiveRewards(isStaking, {
      ...vars,
    })
  })

  return {
    claimed: data
      ? data.claimedIncentiveRewardFromVotings.reduce((acc: BigNumber, item: any) => {
          return acc.add(item.zooReward)
        }, ZERO)
      : ZERO,
    loading: !data,
    error,
  }
}
