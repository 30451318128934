import React, { useState, useMemo, useCallback, useEffect, KeyboardEvent } from 'react'
import { Trans } from '@lingui/macro'
import styled, { useTheme } from 'styled-components/macro'
import { BATTLE_STAGES, useBattleStage, useNftsInfo } from '../NftBattlesPage/hooks'
import { MyStakedCardForPool, StakedCardForPool } from './cards/CardForPool'
import ZooLoader from '../../components/ZooLoader/ZooLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NoNftsEligble, NoNftsStaked } from './NoNfts'
import { AmountDd, DropdownContainer, StyledDd } from './NftsDropdown'
import { useJackpots, useStakerPositions } from 'hooks/gql'
import { useActiveWeb3React } from 'hooks/web3'
import { Checkbox, SearchInput } from 'components/SearchModal/styleds'
import useDebounce from 'hooks/useDebounce'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'
import { TYPE } from 'theme'
import { AutoRow } from 'components/Row'
import { useLocalStorage } from 'hooks/useLocalStorage'

export const PORTFOLIO_TABS = {
  VOTES: 'VOTES',
  ALL: 'ALL',
  STAKED: 'STAKED',
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  height: 100%;

  overflow: visible;

  .infinite-scroll-component__outerdiv {
    width: 100%;
    height: 100%;
  }
`

const NftBattlesContainer = styled.div`
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const NftBattlesNfts = () => {
  const [tab, setTab] = useState(PORTFOLIO_TABS.ALL)
  const { account } = useActiveWeb3React()

  const [search, setSearch] = useState<string | undefined>()

  const { positions: staked, loading } = useStakerPositions(undefined, account, search)
  const { nftsAsArray: all, loading: loadingAll } = useNftsInfo(search)

  const { jackpots: jackpotsA, loading: loadingA } = useJackpots(account, IPanelJackpotsVariant.JackpotA)

  const theme = useTheme()

  const [hideZeroVotes, setHideZeroVotes] = useLocalStorage('HIDE_ZERO_VOTES', false)

  return (
    <NftBattlesContainer>
      <ZooLoader loading={loadingAll || loading || loadingA}>
        <BattlesDropdown
          searchQuery={search}
          setSearchQuery={setSearch}
          tab={tab}
          setTab={setTab}
          myStakedAmount={staked.length + jackpotsA.length}
          allAmount={all.length}
          options={OPTIONS}
        >
          <AutoRow
            justify="left"
            style={{ cursor: 'pointer', marginTop: '12px' }}
            onClick={() => setHideZeroVotes(!hideZeroVotes)}
          >
            <Checkbox
              name="confirmed"
              type="checkbox"
              checked={hideZeroVotes}
              onChange={() => setHideZeroVotes(!hideZeroVotes)}
            />
            <TYPE.body ml="10px" fontSize="16px" color={theme.red1} fontWeight={500}>
              <Trans>Hide Zero Votes</Trans>
            </TYPE.body>
          </AutoRow>
        </BattlesDropdown>

        <Container>
          {tab === PORTFOLIO_TABS.ALL ? <AllStaked nftsAsArray={all} hideZeroVotes={hideZeroVotes} /> : null}
          {tab === PORTFOLIO_TABS.STAKED ? (
            <MyStaked positions={staked} jackpots={jackpotsA} hideZeroVotes={hideZeroVotes} loading={loading} />
          ) : null}
        </Container>
      </ZooLoader>
    </NftBattlesContainer>
  )
}

export interface IOption {
  label: any
  value: string
}

interface IBattleDd {
  tab: string
  setTab: (t: string) => void
  allAmount?: number
  myStakedAmount: number
  votedAmount?: number
  options: IOption[]
  searchQuery: string | undefined
  setSearchQuery: (v: string | undefined) => void
  children?: any
  hideText?: boolean
}

const OPTIONS: IOption[] = [
  {
    value: PORTFOLIO_TABS.ALL,
    label: <Trans>All Staked NFTs</Trans>,
  },
  {
    value: PORTFOLIO_TABS.STAKED,
    label: <Trans>My Staked NFTs</Trans>,
  },
]

const SearchInputStyled = styled(SearchInput)`
  margin: 0 24px;
  width: 100%;
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const SearchWrapper = styled.div`
  flex: 1;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const DescriptionText = styled.div`
  margin: 5px 15px 0 5px;
  font-size: 15px;
  color: ${({ theme }) => theme.text2};
`

export const BattlesDropdown = ({
  tab,
  searchQuery,
  setSearchQuery,
  setTab,
  allAmount,
  myStakedAmount,
  votedAmount,
  options,
  children,
  hideText = false,
}: IBattleDd) => {
  const stage = useBattleStage()

  const txtDesc = useMemo(() => {
    if (hideText) {
      return null
    }
    if (stage === BATTLE_STAGES.SECOND) {
      return 'Vote on your champions with FRAX - the more votes an NFT has, the higher chance it has of winning Battles and the more yield it generates'
    }
    if (stage === BATTLE_STAGES.FOURTH) {
      return null //"Boost your votes with ZOO - you can match your FRAX votes at a 1:1 ratio with ZOO to gain even more votes"
    }
    return null
  }, [stage, hideText])

  const defaultOption = useMemo(() => {
    return options.find(({ value }) => value === tab)
  }, [tab, options])

  const debouncedQuery = useDebounce(searchQuery, 200)

  const handleInput = useCallback(
    (event: any) => {
      const input = event.target.value
      setSearchQuery(input)
    },
    [setSearchQuery]
  )

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setSearchQuery(debouncedQuery)
      }
    },
    [debouncedQuery, setSearchQuery]
  )

  return (
    <DropdownContainer>
      <SearchWrapper>
        <InputWrapper>
          <AmountDd>
            {tab === PORTFOLIO_TABS.ALL ? allAmount : null}
            {tab === PORTFOLIO_TABS.STAKED ? myStakedAmount : null}
            {tab === PORTFOLIO_TABS.VOTES ? votedAmount : null}
            &nbsp;
            <Trans>Total</Trans>
          </AmountDd>
          <SearchInputStyled
            type="text"
            id="nfts-search-input"
            placeholder={`Search by name or NFT address`}
            autoComplete="off"
            value={searchQuery}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </InputWrapper>
        {txtDesc && (
          <DescriptionText>
            <Trans>{txtDesc}</Trans>
          </DescriptionText>
        )}
      </SearchWrapper>
      <RightBlock>
        <StyledDd
          options={options}
          value={defaultOption}
          onChange={({ value }: any) => {
            setTab(value)
          }}
        />
        {children}
      </RightBlock>
    </DropdownContainer>
  )
}

interface PositionsProps {
  positions: any[]
  jackpots: any[]
  loading: boolean
  hideZeroVotes?: boolean
}

const AllStaked = ({ nftsAsArray, hideZeroVotes }: { nftsAsArray: any[]; hideZeroVotes: boolean }) => {
  if (nftsAsArray.length === 0) {
    return <NoNftsEligble />
  }
  return (
    <InfiniteScrollListStyled
      itemsTotal={nftsAsArray}
      renderItem={(item: any) => (
        <StakedCardForPool
          key={item.stakingPositionId}
          positionId={item.stakingPositionId}
          hideZeroVotes={hideZeroVotes}
        />
      )}
    />
  )
}

const MyStaked = ({ positions, jackpots, loading, hideZeroVotes }: PositionsProps) => {
  const all = useMemo(() => [...jackpots, ...positions], [jackpots, positions])

  if (!loading && all.length === 0) {
    return <NoNftsStaked />
  }

  return (
    <InfiniteScrollListStyled
      itemsTotal={all}
      renderItem={(item: any) => (
        <MyStakedCardForPool
          positionId={item.stakingPositionId || item.positionId}
          key={item.stakingPositionId || item.positionId}
          hideZeroVotes={hideZeroVotes}
        />
      )}
    />
  )
}

interface IScrollProps {
  itemsTotal: any[]
  renderItem: (item: any, index: number) => any
  className?: string
}

const ZooLoaderStyled = styled(ZooLoader)`
  width: 100%;
  height: 200px;
`

const PER_PAGE = 30

export const useInfiniteScrollProps = (itemsTotal: any[], perPage: number) => {
  const [currentList, setCurrentList] = useState<any[]>(() => (itemsTotal ? itemsTotal.slice(0, perPage) : []))

  useEffect(() => {
    setCurrentList(() => (itemsTotal ? itemsTotal.slice(0, perPage) : []))
  }, [itemsTotal, perPage])

  const fetchData = useCallback(() => {
    if (currentList.length === itemsTotal.length) {
      return
    }

    const newOffset = Math.min(currentList.length + perPage, itemsTotal.length)
    const nextList = itemsTotal.slice(0, newOffset)
    setCurrentList(nextList)
  }, [currentList, itemsTotal, perPage])

  const hasMore = currentList.length < itemsTotal.length

  return useMemo(() => ({ currentList, hasMore, fetchData }), [currentList, hasMore, fetchData])
}

const InfiniteScrollList = ({ className, itemsTotal, renderItem }: IScrollProps) => {
  const { currentList, hasMore, fetchData } = useInfiniteScrollProps(itemsTotal, PER_PAGE)

  return (
    <InfiniteScroll
      className={className}
      dataLength={currentList.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<ZooLoaderStyled loading={true} />}
      scrollableTarget="body_over"
    >
      {currentList.map((item: any, index: number) => renderItem(item, index))}
    </InfiniteScroll>
  )
}
const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  overflow: visible !important;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  width: 100%;
  padding: 0 0 25px;

  ${({ theme }) => theme.mediaWidth.upToProMedium`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

export default NftBattlesNfts
