import React, { useCallback, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { SupportedChainId } from '../../../constants/chainsinfo'
import { useActiveWeb3React } from '../../../hooks/web3'
import {
  BATTLE_STAGES,
  useAllowedForStaking,
  useBattleEpoch,
  useBattleStage,
  useBattleStakerPositionInfo,
} from '../../NftBattlesPage/hooks'
import {
  useAllBattleNftEvents,
  useBattleNftEventsForUser,
  useLastCreatedEvent,
} from '../../../hooks/zoodao/useBattlesEventsData'
import {
  BoxPercent,
  BoxPercent2,
  CardMiniForNftInfo,
  EtherscanIcon,
  InBattleIcon,
  InJackpotsIcon,
  useDaiVotes,
} from '../CardMiniForNftInfo'
import { ExplorerDataType, getExplorerLink } from '../../../utils/getExplorerLink'
import { shortenAddress, shortenString } from '../../../utils'
import { ExternalLink } from '../../../theme'
import { ButtonClaim, ButtonStake, ButtonBoost } from '../../../components/Button'
import { ZERO } from '../../../utils/isZero'
import { LiquidateZooVoting } from '../../../components/LiquidateVotingPosition/LiquidateVotingPosition'
import PairNft from '../../../components/PairNft/PairNft'
import { CardContent } from '../styleds'
import { useERC721Contract, useJackpotAAddress } from '../../../constants/zoodao'
import { useUnstakePossibility } from '../../NftBattlesPage/NftBattleStaking'
import { UnstakeBattleNft } from '../../../components/UnstakeBattleNft/UnstakeBattleNft'
import { useCardLoading } from './CardForPool'
import { isLooser, WINNER } from '../utils'
import LogoIcon, { LogoIconCDN } from './LogoIcon/LogoIcon'
import { BigNumber, Contract } from 'ethers'
import { INftInfo } from 'pages/NftStaking/SuggestedNfts/SuggestedNfts'
import { IBridgeNfts } from 'pages/NftStaking/NftStaking'
import { useHandleChainSwitch } from 'components/Header/NetworkSelector'
import { useContractName } from 'hooks/useContractName'
import { Dots } from 'pages/Pool/styleds'
import { Text } from 'rebass/styled-components'
import { useNftScanImages } from 'hooks/nft-scan'
import { AlarmText } from './JackpotsNFTOverview/styles'
import { ApproveStakingNfts } from 'pages/NftBattlesPage/ApproveStakingNfts'
import { MouseoverTooltip } from 'components/Tooltip'
import { useNftPartnersLogos } from 'pages/VePie/useNftPartnersLogos'
import { IconNftTemplate } from 'hooks/zoodao/useNftInfo'

const windowInnerWidth = window.innerWidth

let NavBarLeftWidth = 250
if (windowInnerWidth < 1600) {
  NavBarLeftWidth = 200
}

const widthWorkBox = windowInnerWidth - NavBarLeftWidth - 50 - 5 - 75
const ForBattleBoard = (widthWorkBox + 50) / 4

const LeftRight = (windowInnerWidth - NavBarLeftWidth - 50 - 5 - 25) / 2
const BattleBoxCardFighter = (LeftRight - 75) / 2

const TrioBattleInfo = (windowInnerWidth - NavBarLeftWidth - 50 - 5 - 50) / 3

const BridgeLeft = (ForBattleBoard * 2.5 - 25) / 3

const CardForPoolContainer = styled.div<{ paddingBottomEnabled?: boolean }>`
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  width: 100%;
  align-self: center;
  padding-bottom: ${({ paddingBottomEnabled }) => (paddingBottomEnabled ? '0px' : 0)};
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0;
    max-width: 100%;
  `};

  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  :hover {
    box-shadow: 6px 12px 24px rgba(0, 0, 0, 0.25);
    transform: scale3d(1.015, 1.015, 1.015);
    border-radius: 24px;
  }
`
export const ExternalLinkBigSize = styled(ExternalLink)`
  font-size: 18px;
  line-height: 28px;
`

export const ImgParticipantsBox = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  max-height: 330px;
  aspect-ratio: 1;
  object-fit: cover;
  flex-grow: 1;
  overflow: hidden;
  border: 0px;
  border-radius: 24px 24px 0 0;
`

export const ImgParticipantsBoxBattles = styled(ImgParticipantsBox)`
  max-height: ${BattleBoxCardFighter}px;

  ${({ theme }) => theme.mediaWidth.upToProMedium`
    max-height: ${BattleBoxCardFighter}px;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    max-height: ${BattleBoxCardFighter}px;
  `};
  ${({ theme }) => theme.mediaWidth.upToProSmall`
    max-height: ${BattleBoxCardFighter}px;
  `};
`
export const ImgParticipantsBoxBattlesInfo = styled(ImgParticipantsBox)`
  max-height: ${TrioBattleInfo}px;

  ${({ theme }) => theme.mediaWidth.upToProMedium`
    max-height: ${TrioBattleInfo}px;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    max-height: ${TrioBattleInfo}px;
  `};
  ${({ theme }) => theme.mediaWidth.upToProSmall`
    max-height: ${TrioBattleInfo}px;
  `};
`
export const ImgParticipantsBoxBridge = styled(ImgParticipantsBox)`
  max-height: ${BridgeLeft}px;

  ${({ theme }) => theme.mediaWidth.upToProMedium`
    max-height: ${BridgeLeft}px;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    max-height: ${BridgeLeft}px;
  `};
  ${({ theme }) => theme.mediaWidth.upToProSmall`
    max-height: 300px;
  `};
`

const CardForPoolTextBox = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 25px;
  border-radius: 0 0 24px 24px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Line = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const FirstLine = styled(Line)`
  justify-content: start;
  font-family: ${({ theme }) => theme.fontUniform};
  font-size: 20px;
  line-height: 30px;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

const SecondLine = styled(Line)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`

const LogoCo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 24px;
    height: 24px;
    margin-right: 10px;
  `};
`

const ContractText = styled.div`
  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
  color: #9998b8;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 18px;
  `};
`

export const Number = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  font-family: ${({ theme }) => theme.fontUniform};
`
const Info = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const InfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`
const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const BOTTOM_STYLES = `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 3;
  margin-top: auto;
`

const PairNftStyled = styled(PairNft)`
  ${BOTTOM_STYLES}
  width: 100%;
`

const FooterContainer = styled.div`
  ${BOTTOM_STYLES}
  width: 100%;
`
const ButtonStakeFull = styled(ButtonStake)`
  margin-top: 35px;
  width: 100%;
`

const ButtonClaimFull = styled(ButtonClaim)`
  margin-top: 35px;
  width: 100%;
`

const CardContentStyled = styled(CardContent)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%
  `};

  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  :hover {
    box-shadow: 6px 12px 24px rgba(0, 0, 0, 0.25);
    transform: scale3d(1.015, 1.015, 1.015);
    border-radius: 24px;
  }
`
const BtnDopBox = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: end;
  width: 100%;
`
const ButtonBoostFull = styled(ButtonBoost)`
  width: 100%;
`

const NFTName = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  color: white;
  display: flex;
`

const InfoRightStyled = styled(InfoRight)`
  max-height: 0px;
`

const NameLabel = styled.div``

const OnwerAddress = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`

const EtherscanIconLink = styled.div`
  margin-left: auto;
  :hover {
    cursor: pointer;
  }
`

export const OwnerUnstake = ({
  positionInfo,
  positionId,
  passChecking = true,
}: {
  positionInfo: any
  positionId: string
  passChecking?: boolean
}) => {
  const { id, token } = positionInfo

  const contract = useERC721Contract(token)
  const canUnstake = useUnstakePossibility(id, contract)

  if (!id || !token) {
    if (!passChecking) return null
  }

  return (
    <>
      {canUnstake ? (
        <UnstakeBattleNft stakingPositionId={positionId.toString()} tokenId={id} tokenAddress={token} />
      ) : null}
    </>
  )
}

const DEFAULT_EMPTY_ARRAY: any[] = []

export const BoostBtnWrapper = ({ positionId, onClick }: { positionId: string; onClick?: (e: any) => void }) => {
  const stage = useBattleStage()

  const { votingsByUser } = useBattleNftEventsForUser(positionId)
  const { userDaiVotesAmount } = useDaiVotes(DEFAULT_EMPTY_ARRAY, votingsByUser)

  if (stage !== BATTLE_STAGES.FOURTH || !userDaiVotesAmount.gt(ZERO)) {
    return null
  }

  return (
    <ButtonBoostFull onClick={onClick}>
      <Trans>Boost</Trans>
    </ButtonBoostFull>
  )
}

export const NftName = ({ token, tokenId }: { token: string; tokenId?: BigNumber | string }) => {
  const { name, loading: loadingName } = useContractName(token)

  if (!token) {
    return null
  }

  return (
    <NFTName>
      <NameLabel>
        {loadingName ? (
          <Dots>
            <Trans>Loading</Trans>
          </Dots>
        ) : (
          name
        )}
        {tokenId ? (
          <>
            <br /> #{shortenString(tokenId?.toString())}
          </>
        ) : null}
      </NameLabel>
    </NFTName>
  )
}

export const NftNameTemplate = ({ name }: { name: string }) => {
  return (
    <NFTName>
      <NameLabel>{name}</NameLabel>
    </NFTName>
  )
}

export const useInJackpotsChecker = (positionId: string) => {
  const { loading, position } = useLastCreatedEvent(positionId)

  const { staker: address = '' } = position || {}
  const jackpotAddress = useJackpotAAddress()

  const isJackpot = address && address.toLowerCase() === jackpotAddress.toLowerCase()

  return { isJackpot, loading, position }
}

export const PositionOwner = ({ positionId }: { positionId: string }) => {
  const { loading, isJackpot, position } = useInJackpotsChecker(positionId)
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  if (!loading && !position) {
    return null
  }

  const { staker: address = '' } = position || {}
  const exporerLink = getExplorerLink(chainId, address, ExplorerDataType.ADDRESS)

  if (isJackpot) {
    return <InJackpotsIcon />
  }

  return (
    <OnwerAddress>
      <MouseoverTooltip placement="top" text={<Trans>Owner</Trans>}>
        {loading ? (
          <Dots>
            <Trans>Loading</Trans>
          </Dots>
        ) : (
          <ExternalLinkBigSize href={exporerLink}>
            <>{shortenAddress(address)}</>
          </ExternalLinkBigSize>
        )}
      </MouseoverTooltip>
    </OnwerAddress>
  )
}

export const useCDNUrl = (token: string, id: BigNumber) => {
  const { chainId } = useActiveWeb3React()

  const cdnUrl = useMemo(() => {
    if (!token || !id) {
      return IconNftTemplate
    }
    return `https://cdn.zoodao.com/${chainId}_${token.toLowerCase()}_${id.toString()}_600px.jpeg`
  }, [chainId, token, id])

  return cdnUrl
}

export const NftImageWrapper = ({
  id,
  token,
  onClick,
  el: El = ImgParticipantsBox,
}: {
  el?: any
  id: BigNumber
  token: string
  onClick?: (e: any) => void
}) => {
  const cdnUrl = useCDNUrl(token, id)

  console.log('token', token, id, cdnUrl)
  return (
    <El onClick={onClick}>
      <LogoIconCDN src={cdnUrl} token={token} id={id} />
    </El>
  )
}

export const OverviewCard = ({
  positionInfo,
  onClick,
  children,
  positionId,
  votingPositionId,
  isOwner = false,
  hideZeroVotes,
}: {
  positionInfo: any
  onClick?: (e: any) => void
  children?: any
  positionId: string
  votingPositionId?: string
  isOwner?: boolean
  hideZeroVotes?: boolean
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { id, token } = positionInfo || {}

  const collectionThumbnail = useNftPartnersLogos(token)
  const stage = useBattleStage()

  const { votingsByUser, loading } = useBattleNftEventsForUser(positionId)
  const { allVotings, loading: loadingAll } = useAllBattleNftEvents(positionId)
  const { allVotesAmount, userDaiVotesAmount } = useDaiVotes(allVotings, votingsByUser)

  if (!id) {
    return null
  }

  if (hideZeroVotes && allVotings.length === 0) {
    return null
  }

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  const paddingBottom =
    stage === BATTLE_STAGES.SECOND ||
    (stage === BATTLE_STAGES.THIRD && allVotesAmount.gt(ZERO)) ||
    (stage === BATTLE_STAGES.FOURTH && userDaiVotesAmount.gt(ZERO)) ||
    (stage === BATTLE_STAGES.FIRST && isOwner && votingPositionId)

  return (
    <CardContentStyled>
      <CardForPoolContainer paddingBottomEnabled={paddingBottom}>
        <NftImageWrapper token={token} id={id} onClick={onClick} />

        {stage >= BATTLE_STAGES.THIRD ? (
          <InfoRightStyled>
            <InBattleIcon positionId={positionId} />
          </InfoRightStyled>
        ) : null}

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <LogoIcon el={LogoCo} src={collectionThumbnail} />
                <NftName token={token} tokenId={id} />
                <EtherscanIconLink>
                  <EtherscanIcon exporerLink={exporerLink} />
                </EtherscanIconLink>
              </FirstLine>
              <SecondLine>
                <PositionOwner positionId={positionId} />
              </SecondLine>
            </InfoLeft>
          </Info>

          <CardMiniForNftInfo allVotesAmount={allVotesAmount} loading={loadingAll || loading} />

          {children}

          {stage === BATTLE_STAGES.THIRD ? (
            <PairNftStyled el={ButtonStakeFull} stakingPositionId={positionId} showWarning={false} />
          ) : null}
          {stage === BATTLE_STAGES.SECOND ? (
            <FooterContainer>
              <ButtonClaimFull onClick={onClick}>
                <Trans>Vote</Trans>
              </ButtonClaimFull>
            </FooterContainer>
          ) : null}
          {stage === BATTLE_STAGES.FOURTH ? (
            <FooterContainer>
              <BtnDopBox>
                <BoostBtnWrapper positionId={positionId} onClick={onClick} />
              </BtnDopBox>
            </FooterContainer>
          ) : null}
          {stage === BATTLE_STAGES.FIRST && isOwner && votingPositionId ? (
            <FooterContainer>
              <BtnDopBox>
                {positionInfo && isOwner && stage === BATTLE_STAGES.FIRST ? (
                  <OwnerUnstake positionId={positionId} positionInfo={positionInfo} />
                ) : null}
              </BtnDopBox>
              <LiquidateZooVoting votingPositionId={votingPositionId} />
            </FooterContainer>
          ) : null}
        </CardForPoolTextBox>
      </CardForPoolContainer>
    </CardContentStyled>
  )
}

export const CardContentSimpleStyled = styled(CardContentStyled)<{ selected?: boolean }>`
  margin: 0;
  margin-bottom: auto;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  border-radius: 24px;
  ::after {
    ${({ selected }) =>
      selected
        ? 'content: ""; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; margin: -3px; border-radius: 24px; background-image: linear-gradient(90deg, #f56738 15%, #f64562 50%, #e138f5 85%);'
        : 'none'};
  }
  :hover {
    transform: scale3d(1.015, 1.015, 1.015);
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
    border-radius: 24px;
    ::after {
      ${({ selected }) =>
        selected
          ? 'transform: scale3d(1.015, 1.015, 1.015); transition: all 0.3s cubic-bezier(0, 0, 0.5, 1); border-radius: 24px;'
          : 'none'};
    }
  }
`

export const CardForPoolContainerNoPadding = styled(CardForPoolContainer)<{
  type?: WINNER
  looser?: boolean
  disabled?: boolean
}>`
  border: ${({ type, looser }) => {
    if (looser) {
      return '0px solid #F64562;'
    }

    switch (type) {
      case WINNER.A: {
        return '0px solid #1fad1f'
      }
      case WINNER.B: {
        return '0px solid #3258B4'
      }
      default: {
        return 'initial'
      }
    }

    return 'initial'
  }};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  padding: 0;
  height: inherit;
  min-width: 100%;
  max-width: 100%;
  background-color: #33334b;
`

export const SimpleNftOverview = ({
  onClick,
  children,
  positionId,
  className,
}: {
  onClick?: () => void
  children?: any
  positionId: string
  className?: string
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  const { id, token } = positionInfo || {}

  const { collectionThumbnail, tokenImage } = useNftScanImages(token, id)

  const stage = useBattleStage()

  if (!id) {
    return null
  }

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  return (
    <CardContentSimpleStyled className={className}>
      <CardForPoolContainerNoPadding>
        <ImgParticipantsBox onClick={onClick}>
          <LogoIcon src={tokenImage} />
        </ImgParticipantsBox>

        {stage >= BATTLE_STAGES.THIRD ? (
          <InfoRightStyled>
            <InBattleIcon showLabel={true} positionId={positionId} />
          </InfoRightStyled>
        ) : null}

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <LogoIcon el={LogoCo} src={collectionThumbnail} />
                <NftName token={token} tokenId={id} />
                <EtherscanIconLink>
                  <EtherscanIcon exporerLink={exporerLink} />
                </EtherscanIconLink>
              </FirstLine>
              <SecondLine>
                <PositionOwner positionId={positionId} />
              </SecondLine>
            </InfoLeft>
          </Info>

          {children}
        </CardForPoolTextBox>
      </CardForPoolContainerNoPadding>
    </CardContentSimpleStyled>
  )
}

const ButtonStakeFullSize = styled(ButtonStake)`
  width: 100%;
`
const BoxBtnForStakingPage = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`
const CardForPoolTextBoxBattles = styled(CardForPoolTextBox)`
  background-color: #33334b;
`
const InBattleContainer = styled.div`
  margin-top: -50px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: auto;

  background-color: #6b2e45;
  text-align: center;
  height: 50px;
  padding: 10px 25px;

  color: ${({ theme }) => theme.red};
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 28px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;

  background: linear-gradient(90deg, #67382e, #65314a, #552971);
`
const WinnerText = styled.div`
  background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`

export const WinnerLabel = () => {
  return (
    <InBattleContainer>
      <WinnerText>
        <Trans>Winner</Trans>
      </WinnerText>
    </InBattleContainer>
  )
}

export const BattlePairsNftOverview = ({
  children,
  positionId,
  winner,
  className,
  type,
  makeDisabled = false,
  showBattleIcon = true,
  epoch,
}: {
  children?: any
  positionId: string
  winner?: WINNER
  className?: string
  type: WINNER
  showWinnerLabel?: boolean
  makeDisabled?: boolean
  showBattleIcon?: boolean
  epoch: number
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  const { id, token } = positionInfo || {}

  const collectionThumbnail = useNftPartnersLogos(token)

  const { onClick } = useCardLoading(positionId)

  const currentEpoch = useBattleEpoch()

  if (!id) {
    return null
  }

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  const looser = isLooser(type, winner)

  return (
    <CardContentSimpleStyled className={className}>
      <CardForPoolContainerNoPadding type={type} looser={looser} disabled={makeDisabled && looser}>
        <NftImageWrapper token={token} id={id} onClick={onClick} el={ImgParticipantsBoxBattles} />

        {showBattleIcon && currentEpoch === +epoch ? (
          <InfoRightStyled>
            <InBattleIcon showLabel={false} positionId={positionId} />
          </InfoRightStyled>
        ) : null}

        <CardForPoolTextBoxBattles>
          <Info>
            <InfoLeft>
              <FirstLine>
                <LogoIcon el={LogoCo} src={collectionThumbnail} />
                <NftName token={token} tokenId={id} />
                <EtherscanIconLink>
                  <EtherscanIcon exporerLink={exporerLink} />
                </EtherscanIconLink>
              </FirstLine>
              <SecondLine>
                <PositionOwner positionId={positionId} />
              </SecondLine>
            </InfoLeft>
          </Info>

          {children}

          {/* {winner === type && showWinnerLabel ? <WinnerLabel /> : null} */}
        </CardForPoolTextBoxBattles>
      </CardForPoolContainerNoPadding>
    </CardContentSimpleStyled>
  )
}
export const BattlePairsNftOverview2 = ({
  children,
  positionId,
  className,
  type,
  winData,
  showWinnerLabel = false,
  makeDisabled = false,
  showBattleIcon = true,
}: {
  children?: any
  positionId: string
  className?: string
  type: WINNER
  showWinnerLabel?: boolean
  makeDisabled?: boolean
  showBattleIcon?: boolean
  winData: any
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { positionInfo } = useBattleStakerPositionInfo(positionId)

  const { id, token } = positionInfo || {}

  const collectionThumbnail = useNftPartnersLogos(token)

  const { onClick } = useCardLoading(positionId)

  const { chanceB, chanceA, loadingFirst, loadingSecond, winner } = winData

  if (!id) {
    return null
  }

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  const looser = isLooser(type, winner)

  return (
    <CardContentSimpleStyled className={className}>
      <CardForPoolContainerNoPadding type={type} looser={looser} disabled={makeDisabled && looser}>
        <NftImageWrapper token={token} id={id} onClick={onClick} el={ImgParticipantsBoxBattlesInfo} />

        {winner === type && showWinnerLabel ? <WinnerLabel /> : null}

        {showBattleIcon ? (
          <InfoRightStyled>
            <InBattleIcon showLabel={false} positionId={positionId} />
          </InfoRightStyled>
        ) : null}

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <LogoIcon el={LogoCo} src={collectionThumbnail} />
                <NftName token={token} tokenId={id} />
                <EtherscanIconLink>
                  <EtherscanIcon exporerLink={exporerLink} />
                </EtherscanIconLink>
              </FirstLine>
              <SecondLine>
                <PositionOwner positionId={positionId} />
              </SecondLine>
            </InfoLeft>
          </Info>

          {children}

          {loadingFirst || type !== WINNER.A ? null : (
            <BoxPercent looser={isLooser(WINNER.A, winner)}>
              {winner === WINNER.A ? <Trans>Won</Trans> : chanceA.toFixed(2) + '%'}
            </BoxPercent>
          )}

          {loadingSecond || type !== WINNER.B ? null : (
            <BoxPercent2 looser={isLooser(WINNER.B, winner)}>
              {winner === WINNER.B ? <Trans>Won</Trans> : chanceB.toFixed(2) + '%'}
            </BoxPercent2>
          )}
        </CardForPoolTextBox>
      </CardForPoolContainerNoPadding>
    </CardContentSimpleStyled>
  )
}

export const StakeBtn = ({
  onClick,
  token,
  forceAllowed,
  disabled,
  className,
  pending,
}: {
  disabled?: boolean
  pending?: boolean
  onClick: (() => void) | (() => Promise<void>)
  token: string
  forceAllowed?: boolean
  className?: string
}) => {
  const stage = useBattleStage()

  const { allowedForStaking, loading } = useAllowedForStaking(token)

  return (
    <BoxBtnForStakingPage className={className}>
      {allowedForStaking || forceAllowed ? (
        <ButtonStakeFullSize
          onClick={onClick}
          disabled={
            pending || disabled || stage !== BATTLE_STAGES.FIRST || (!forceAllowed && (loading || !allowedForStaking))
          }
        >
          {pending ? (
            <Dots>
              <Trans>Staking</Trans>
            </Dots>
          ) : (
            <Trans>Stake</Trans>
          )}
        </ButtonStakeFullSize>
      ) : (
        <AlarmText>
          <Trans>Not Eligible</Trans>
        </AlarmText>
      )}
    </BoxBtnForStakingPage>
  )
}

export const StakingNftOverview = ({
  contract,
  onClick,
  children,
  className,
  tokenImage,
  token,
  tokenId,
  disabled,
  isStakeVisible = true,
}: {
  contract: Contract
  onClick?: () => void
  children?: any
  className?: string
  tokenId: string
  token: string
  tokenImage: string
  collectionThumbnail: string
  disabled?: boolean
  isStakeVisible?: boolean
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  if (!token) {
    return null
  }

  return (
    <CardContentSimpleStyled className={className}>
      <CardForPoolContainerNoPadding>
        <ImgParticipantsBox onClick={onClick}>
          <LogoIcon src={tokenImage} />
        </ImgParticipantsBox>

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <NftName token={token} tokenId={tokenId} />
              </FirstLine>
              <ExternalLinkBigSize href={exporerLink}>
                <SecondLine>
                  <ContractText>{shortenAddress(token, 2)}</ContractText>
                  <EtherscanIcon exporerLink={exporerLink} />
                </SecondLine>
              </ExternalLinkBigSize>
            </InfoLeft>
            {isStakeVisible && (
              <InfoRight>
                <ApproveStakingNfts contract={contract} disabled={disabled} nftAddress={token} tokenId={tokenId} />
              </InfoRight>
            )}
          </Info>

          {children}
        </CardForPoolTextBox>
      </CardForPoolContainerNoPadding>
    </CardContentSimpleStyled>
  )
}

export const FarmStakingNftOverview = ({
  onClick,
  children,
  className,
  tokenId,
  token,
}: {
  onClick?: () => void
  children?: any
  className?: string
  disabled?: boolean
  token: string
  tokenId: BigNumber
}) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { collectionThumbnail, tokenImage } = useNftScanImages(token, tokenId)

  const exporerLink = getExplorerLink(chainId, token, ExplorerDataType.ADDRESS)

  return (
    <CardContentSimpleStyled className={className}>
      <CardForPoolContainerNoPadding style={{ cursor: 'initial' }}>
        <ImgParticipantsBox onClick={onClick} style={{ cursor: 'initial' }}>
          <LogoIcon src={tokenImage} />
        </ImgParticipantsBox>

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <LogoIcon el={LogoCo} src={collectionThumbnail} />

                <NftName token={token} tokenId={tokenId} />
              </FirstLine>
              <ExternalLinkBigSize href={exporerLink}>
                <SecondLine>
                  <ContractText>{shortenAddress(token, 2)}</ContractText>
                  <EtherscanIcon exporerLink={exporerLink} />
                </SecondLine>
              </ExternalLinkBigSize>
            </InfoLeft>
            <InfoRight>{onClick ? <StakeBtn token={token} onClick={onClick} forceAllowed /> : null}</InfoRight>
          </Info>

          {children}
        </CardForPoolTextBox>
      </CardForPoolContainerNoPadding>
    </CardContentSimpleStyled>
  )
}

interface IBridgeNftOverview {
  children?: any
  className?: string
  disabled?: boolean
  item: INftInfo
  selectors: IBridgeNfts
}

export const BridgeNFTOverview = ({ children, className, item, selectors }: IBridgeNftOverview) => {
  const { token: targetToken, tokenId: targetTokenId, onSelectAddress, onSelectTokenId } = selectors

  const {
    token_id: tokenId,
    contract: { address: token },
    chain_id,
    image_url: xdefiImg,
  } = item

  const { tokenImage } = useNftScanImages(token, BigNumber.from(tokenId), xdefiImg)
  const exporerLink = getExplorerLink(chain_id, token, ExplorerDataType.ADDRESS)

  const handleChainSwitch = useHandleChainSwitch()

  const onClick = useCallback(() => {
    onSelectAddress(token)
    onSelectTokenId(tokenId)
    handleChainSwitch(chain_id)
  }, [onSelectAddress, onSelectTokenId, handleChainSwitch, chain_id, token, tokenId])

  const isSelected = tokenId === targetTokenId && token === targetToken

  return (
    <CardContentSimpleStyled className={className} selected={isSelected}>
      <CardForPoolContainerNoPadding style={{ cursor: 'initial' }}>
        <ImgParticipantsBoxBridge onClick={onClick} style={{ cursor: 'initial' }}>
          <LogoIcon src={tokenImage} />
        </ImgParticipantsBoxBridge>

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <NftName token={token} tokenId={tokenId} />
              </FirstLine>
              <ExternalLinkBigSize href={exporerLink}>
                <SecondLine>
                  <ContractText>{shortenAddress(token, 2)}</ContractText>
                  <EtherscanIcon exporerLink={exporerLink} chainIdExternal={chain_id} />
                </SecondLine>
              </ExternalLinkBigSize>
            </InfoLeft>
          </Info>

          {children}
        </CardForPoolTextBox>
      </CardForPoolContainerNoPadding>
    </CardContentSimpleStyled>
  )
}

const CardForPoolContainerBoard = styled(CardForPoolContainer)`
  min-width: initial;
  max-width: initial;
`

const WinnerCardContent = styled(CardContent)`
  width: fill-available;
`

export const BattleBoardOverviewCard = ({ item: { stakingPositionId: positionId, votes } }: { item: any }) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const { isJackpot, position } = useInJackpotsChecker(positionId)
  const { positionInfo } = useBattleStakerPositionInfo(positionId)
  const { id, token } = positionInfo || {}

  const stage = useBattleStage()

  const { onClick } = useCardLoading(positionId)

  const collectionThumbnail = useNftPartnersLogos(token)

  if (!id) {
    return null
  }

  const { staker = '' } = position || {}
  const exporerLink = getExplorerLink(chainId, staker, ExplorerDataType.ADDRESS)

  const paddingBottom =
    (stage === BATTLE_STAGES.THIRD && votes.gt(ZERO)) ||
    stage === BATTLE_STAGES.SECOND ||
    stage === BATTLE_STAGES.FOURTH

  return (
    <WinnerCardContent>
      <CardForPoolContainerBoard paddingBottomEnabled={paddingBottom}>
        <NftImageWrapper token={token} id={id} onClick={onClick} />

        {stage >= BATTLE_STAGES.THIRD ? (
          <InfoRightStyled>
            <InBattleIcon positionId={positionId} />
          </InfoRightStyled>
        ) : null}

        <CardForPoolTextBox>
          <Info>
            <InfoLeft>
              <FirstLine>
                <LogoIcon el={LogoCo} src={collectionThumbnail} />

                <NftName token={token} tokenId={id} />
              </FirstLine>
              {isJackpot ? (
                <InJackpotsIcon />
              ) : (
                staker && (
                  <ExternalLinkBigSize href={exporerLink}>
                    <SecondLine>
                      <ContractText>{shortenAddress(staker)}</ContractText>
                      <EtherscanIcon exporerLink={exporerLink} />
                    </SecondLine>
                  </ExternalLinkBigSize>
                )
              )}
            </InfoLeft>
          </Info>

          <CardMiniForNftInfo allVotesAmount={votes} loading={false} />

          {stage === BATTLE_STAGES.THIRD ? (
            <PairNftStyled el={ButtonStakeFull} stakingPositionId={positionId} showWarning={false} />
          ) : null}
          {stage === BATTLE_STAGES.SECOND ? (
            <FooterContainer>
              <ButtonClaimFull onClick={onClick}>
                <Trans>Vote</Trans>
              </ButtonClaimFull>
            </FooterContainer>
          ) : null}
        </CardForPoolTextBox>
      </CardForPoolContainerBoard>
    </WinnerCardContent>
  )
}
