import { Trans } from '@lingui/macro'
import { utils } from 'ethers'
import { SupportedChainId } from 'constants/chainsinfo'
import { stakingPoolAbiExported, useChainStartBlock, useStakingSlpContract } from 'constants/zoodao'
import { useActiveWeb3React } from 'hooks/web3'
import { sortByIndex } from 'hooks/zoodao/useBattlesEventsData'
import { useState, useEffect } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { shortenAddress, shortenTx } from 'utils'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'
import { formatDecimal } from 'utils/numberWithCommas'
import { ExternalLinkStyled, TdId, Tr, Th, Td, TbodyStyled, TheadStyled } from './styleds'
import { LogDescription } from 'ethers/lib/utils'
import { TableTransactions, TABS_FOR_TABLE } from './TableTemplate'

const iface = new utils.Interface(stakingPoolAbiExported)

const useFarmTxs = (tab: string) => {
  const contract = useStakingSlpContract()
  const { account } = useActiveWeb3React()
  const latestBlock = useBlockNumber()
  const [loading, setLoading] = useState<boolean>(false)

  const [events, setEvents] = useState<LogDescription[]>([])
  const b = useChainStartBlock()

  useEffect(() => {
    const fetch = async () => {
      if (contract && account) {
        setEvents([])
        const target = tab === TABS_FOR_TABLE.ALL ? undefined : account

        const staked = contract.filters.Staked(target as any)
        const harvested = contract.filters.RewardPaid(target as any)
        const withdraw = contract.filters.Withdrawn(target as any)

        const stakedD = await contract.queryFilter(
          {
            topics: staked.topics,
          },
          b
        )
        const harvestedD = await contract.queryFilter(
          {
            topics: harvested.topics,
          },
          b
        )
        const withdrawD = await contract.queryFilter(
          {
            topics: withdraw.topics,
          },
          b
        )

        const parsed = [...stakedD, ...harvestedD, ...withdrawD]
          .sort(sortByIndex)
          .map((d) => ({ ...iface.parseLog(d), transactionHash: d.transactionHash }))

        setEvents(parsed)
        setLoading(false)
      }
    }

    fetch()
  }, [contract, b, account, latestBlock, tab])

  return {
    events,
    loading,
  }
}

export const FarmStakingTransactions = () => {
  return <TableTransactions el={FarmStakingTransactionsInner} />
}

export const FarmStakingTransactionsInner = ({ tab }: { tab: string }) => {
  const { events } = useFarmTxs(tab)

  return (
    <>
      <TheadStyled>
        <Tr>
          <Th>
            <Trans>Method</Trans>
          </Th>
          <Th>
            <Trans>Author</Trans>
          </Th>
          <Th>
            <Trans>Transaction</Trans>
          </Th>
        </Tr>
      </TheadStyled>
      <TbodyStyled>
        <List display={events} />
      </TbodyStyled>
    </>
  )
}

const List = ({ display }: { display: LogDescription[] }) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  return (
    <>
      {display.map((e: any) => {
        const {
          args: { user, reward, amount },
          transactionHash,
          name,
        } = e
        return (
          <Tr key={transactionHash}>
            <TdId>
              {name.toString()}: {formatDecimal(reward || amount)}
            </TdId>
            <Td>
              <ExternalLinkStyled href={getExplorerLink(chainId, user, ExplorerDataType.ADDRESS)}>
                {shortenAddress(user)}
              </ExternalLinkStyled>
            </Td>
            <Td>
              <ExternalLinkStyled href={getExplorerLink(chainId, transactionHash, ExplorerDataType.TRANSACTION)}>
                {shortenTx(transactionHash, 10)}
              </ExternalLinkStyled>
            </Td>
          </Tr>
        )
      })}
    </>
  )
}
