import { Trans } from '@lingui/macro'
import { ButtonGradient, ButtonGray } from 'components/Button'
import Modal from 'components/Modal'
import { useCallback, useContext } from 'react'
import { ApplicationModal } from 'state/application/actions'
import { useDegenModalToggle, useModalOpen } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { UserModesContext, USER_MODES } from './UserMode'
import DegenBgSvg from './degen-bg.svg'

const ModalStyled = styled(Modal)`
  width: auto;
  max-width: 710px !important;
`

const CancelBtn = styled(ButtonGray)`
  background-color: transparent;
  color: ${({ theme }) => theme.text5};
  border: 1px solid ${({ theme }) => theme.text5};
  margin-right: 20px;
  border-radius: 50px;
  padding: 12px 35px;
  font-size: 18px;
  line-height: 32px;

  text-align: right;
  letter-spacing: 0.75px;
  width: initial;
`

const SwitchBtn = styled(ButtonGradient)`
  padding: 12px 35px;
  font-size: 18px;
  line-height: 32px;

  text-align: right;
  letter-spacing: 0.75px;
`
const Ser = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: 400;
  font-size: 32px;
  line-height: 35px;
  margin-bottom: 20px;
`

const Description = styled.div`
  margin-top: 12px;
  color: ${({ theme }) => theme.text5};
  font-size: 18px;
  line-height: 28px;

  &:first-child {
    margin: 0;
  }
`

const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 45px 55px;
`
const ImgBlock = styled.img`
  margin-bottom: 8px;
`

const Btns = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
  width: 100%;
  justify-content: flex-end;
`

const Litepaper = styled.a`
  color: #f64562;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-underline-offset: 3px;
`

export const DegenModal = () => {
  const { toggle } = useContext(UserModesContext)

  const isModalOpen = useModalOpen(ApplicationModal.DEGEN_MODE)

  const toggleWalletModal = useDegenModalToggle()

  const onCancel = useCallback(() => {
    toggle && toggle(USER_MODES.LIGHT)
    toggleWalletModal()
  }, [toggle, toggleWalletModal])

  return (
    <ModalStyled isOpenFlag={isModalOpen} onDismissHandler={onCancel} minHeight={false} maxHeight={600}>
      <ConfirmBlock>
        <Ser>
          <Trans>You are switching to Degen Mode</Trans>
        </Ser>

        <ImgBlock src={DegenBgSvg} />

        <Description>
          <Trans>
            Degen Mode will give you access to more $ZOO utilities and Jackpots. For explanations on these features,
            please explore{' '}
            <Litepaper href="https://zoodao.com/docs/ZooDAO-Litepaper.pdf" rel="noreferrer" target="_blank">
              ZooDAO&apos;s litepaper
            </Litepaper>
            .
          </Trans>
        </Description>
        <Description>
          <Trans>Please understand that Degen Features are not required to play the game.</Trans>
        </Description>
        <Description>
          <Trans>Only enter Degen mode if you are a Degen.</Trans>
        </Description>

        <Btns>
          <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
          <SwitchBtn
            onClick={() => {
              toggle && toggle(USER_MODES.DEGEN)
              toggleWalletModal()
            }}
          >
            <Trans>I am a Degen</Trans>
          </SwitchBtn>
        </Btns>
      </ConfirmBlock>
    </ModalStyled>
  )
}
