import { useCallback, useEffect, useMemo, useState } from 'react'
import { DashboardCardFlex, CardLine } from 'pages/Dashboard/styleds'
import { CardHeadBox } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import IconZOO from 'assets/svg/token.svg'
import { LogoCoin, TxtBalance, InputLabel, MaxButton, StakeButton } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { GetZOO } from 'components/GetStablecoin'
import { useAddXZoo, useStakeXZoo, useTargetPositionForUser, useUnstakeXZoo, useWithdrawAndClaimXZoo } from './hooks'
import { BalanceInputWrapper } from 'pages/Portfolio/Panels/BalanceInput'
import { useZooBalanceTest } from 'hooks/zoodao/zooToken'
import { formatDecimal } from 'utils/numberWithCommas'
import { ApproveCheckerXZoo } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import { ButtonClaim, ButtonUnstake } from 'components/Button'
import { Dots } from 'pages/Pool/styleds'
import { ZERO } from 'utils/isZero'

export const FirstLine = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 15px;
`
export const SecondLine = styled(FirstLine)`
  margin-top: 25px;
  margin-bottom: 12px;
`
export const StakeOrUnstake = styled.div`
  width: 100%;
  background-color: #33334b;
  border-radius: 24px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const BtnSelect = styled.div<{ disabled?: boolean; active?: boolean }>`
  background-color: ${({ active }) => (active ? '#f56738' : '#33334b')};
  width: 150px;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  padding: 25px;
  height: 50px;
  align-items: center;
  font-weight: 700;
  font-family: Uniform Pro;
  font-size: 18px;
  line-height: 24px;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

const ButtonClaimStyled = styled(ButtonClaim)`
  margin-left: 12px;
`

const enum MODE {
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
}

const StakedXZOO = () => {
  const { position, loading: loadingPositions } = useTargetPositionForUser()

  const { tokenId: pid } = position || {}

  const zooBalance = useZooBalanceTest()

  const [value, onUserInput] = useState<number | undefined>()

  const [mode, setMode] = useState(MODE.STAKE)

  const { action: stake, pending: pendingStake } = useStakeXZoo(value)
  const { action: add, pending: pendingAdd } = useAddXZoo(value, pid)
  const { action: unstake, pending: pendingUnstake } = useUnstakeXZoo(value, pid)
  const { action: withdrawAndClaim, pending: pendingWandClaim } = useWithdrawAndClaimXZoo(pid)

  const addWrapper = useCallback(() => {
    return pid ? add() : stake()
  }, [pid, add, stake])

  useEffect(() => {
    onUserInput(0)
  }, [mode])

  const max = useMemo(() => {
    return mode === MODE.STAKE ? zooBalance : position?.amount || ZERO
  }, [position, zooBalance, mode])

  return (
    <DashboardCardFlex>
      <CardLine index={13} />

      <FirstLine>
        <CardHeadBox>
          <CardHeadContribution>
            <LogoCoin src={IconZOO} />
            <Trans>Stake ZOO</Trans>
          </CardHeadContribution>
        </CardHeadBox>
        {pid && (
          <StakeOrUnstake>
            <BtnSelect onClick={() => setMode(MODE.STAKE)} active={mode === MODE.STAKE} disabled={!pid}>
              <Trans>Stake</Trans>
            </BtnSelect>
            <BtnSelect onClick={() => pid && setMode(MODE.UNSTAKE)} active={mode === MODE.UNSTAKE}>
              <Trans>Unstake</Trans>
            </BtnSelect>
          </StakeOrUnstake>
        )}
      </FirstLine>

      <SecondLine>
        {mode === MODE.STAKE && (
          <InputLabel>
            <Trans>My ZOO balance:</Trans>&nbsp;
            <TxtBalance>{formatDecimal(zooBalance)}</TxtBalance>
          </InputLabel>
        )}
        {mode === MODE.UNSTAKE && position?.amount && (
          <InputLabel>
            <Trans>My xZOO:</Trans>&nbsp;
            <TxtBalance>{formatDecimal(position?.amount)}</TxtBalance>
          </InputLabel>
        )}
        {false && <GetZOO />}
      </SecondLine>

      <BalanceInputWrapper total={max} btn={MaxButton} balance={value} setBalance={onUserInput} />

      <Flex alignItems="center" justifyContent="space-between" mt={25}>
        {position && (
          <InputLabel>
            <Trans>
              You got xZOO:&nbsp;
              <TxtBalance>{formatDecimal(position?.amount)}</TxtBalance>
            </Trans>
          </InputLabel>
        )}

        <ApproveCheckerXZoo border={value}>
          <>
            {mode === MODE.STAKE && (
              <>
                <StakeButton
                  onClick={addWrapper}
                  disabled={pendingAdd || pendingStake || loadingPositions || value === 0}
                >
                  {pendingStake ? (
                    <Dots>
                      <Trans>Staking</Trans>
                    </Dots>
                  ) : (
                    <Trans>Stake</Trans>
                  )}
                </StakeButton>
              </>
            )}
          </>
          <>
            {mode === MODE.UNSTAKE && (
              <>
                <ButtonUnstake onClick={unstake} disabled={pendingUnstake || value === 0}>
                  {pendingUnstake ? (
                    <Dots>
                      <Trans>Unstaking</Trans>
                    </Dots>
                  ) : (
                    <Trans>Unstake</Trans>
                  )}
                </ButtonUnstake>
                <ButtonClaimStyled onClick={withdrawAndClaim} disabled={pendingWandClaim}>
                  {pendingWandClaim ? (
                    <Dots>
                      <Trans>Withdrawing</Trans>
                    </Dots>
                  ) : (
                    <Trans>Withdraw All & Claim</Trans>
                  )}
                </ButtonClaimStyled>
              </>
            )}
          </>
        </ApproveCheckerXZoo>
      </Flex>
    </DashboardCardFlex>
  )
}

export default StakedXZOO
