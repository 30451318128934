import request from 'graphql-request'
import gql from 'graphql-tag'
import { DocumentNode } from 'graphql/language/ast'
import { useMemo } from 'react'
import { useSWR } from './gql'

const query = (query: DocumentNode, variables: any) =>
  request(`https://api.thegraph.com/subgraphs/name/moonwell-fi/moonwell-moonbeam`, query, variables)

const apyQuery = gql`
  query A {
    markets {
      id
      underlyingSymbol
      supplyRewardProtocol
      supplyRewardNative
      supplyRate
      borrowRewardNative
      borrowRewardProtocol
      borrowRate
    }
  }
`

const getApyQuery = async (variables: any) => {
  return query(apyQuery, variables)
}

export const useAPYFrax = () => {
  const { data, error } = useSWR(`getMoonwellAPY`, () => {
    return getApyQuery({})
  })

  const markets = useMemo(() => {
    return data ? data.markets : []
  }, [data])

  const frax = useMemo(() => {
    return markets.find((item: any) => item.underlyingSymbol === 'FRAX')
  }, [markets])

  return { loading: !frax, frax, error }
}
