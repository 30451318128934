import React from 'react'
import styled from 'styled-components/macro'
import { CardLine, CardContent, CardDescription, CardText, DashboardCardFlex } from '../styleds'
import { Trans } from '@lingui/macro'
import { formatDecimal } from '../../../utils/numberWithCommas'
import IconXZOO from 'assets/svg/logo_xZOO_token.svg'
import { CardHead } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { useXZooTokenAddress, useZooRealToken } from '../../../constants/zoodao'
import { useBalance } from 'hooks/zoodao/useBalance'
import { Dots } from 'pages/Pool/styleds'
import { useTargetPositionForUser } from '../Panels/hooks'
import { ZERO } from 'utils/isZero'
import { BNZooPrice, PRICE_MULTIPLIER, ZOO_PRICE } from 'pages/Dashboard/InfoCards/DashboardCards'
import { formatBNToNumber } from './hooks'
import { useXZooTotalClaimed } from 'hooks/gql'
import { BigNumber } from 'ethers'

const CardTextStyled = styled(CardText)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
`
const CardHeadContribution = styled(CardHead)`
  align-items: center;
  line-height: 30px;
  width: 100%;
`
const LogoCoinClaimable = styled.img`
  border-radius: 100%;
  margin-right: 10px;

  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-left: 0px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    width: 38px;
    height: 38px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 35px;
    height: 35px;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 22px;
    height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 35px;
    height: 35px;
  `}
`

const useTotalStakedXZoo = () => {
  const contract = useZooRealToken()
  const address = useXZooTokenAddress()

  const balance = useBalance(contract, address)

  return balance
}

export const XZOOStaked = () => {
  const balance = useTotalStakedXZoo()

  return (
    <DashboardCardFlex>
      <CardLine index={19} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>
            <LogoCoinClaimable src={IconXZOO} />
            xZOO Staked
          </Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>{formatDecimal(balance)}</CardTextStyled>
        <CardDescription>For all users</CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}
export const XZOOBalance = () => {
  const { position, loading } = useTargetPositionForUser()

  return (
    <DashboardCardFlex>
      <CardLine index={19} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>
            <LogoCoinClaimable src={IconXZOO} />
            xZOO Balance
          </Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>
          {loading ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            formatDecimal(position ? position.amount : ZERO)
          )}
        </CardTextStyled>
        <CardDescription>My balance</CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}

export const XZOOTVL = () => {
  const balance = useTotalStakedXZoo()

  return (
    <DashboardCardFlex>
      <CardLine index={20} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>TVL</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardTextStyled>${formatDecimal(balance.mul(BNZooPrice).div(PRICE_MULTIPLIER))}</CardTextStyled>
        <CardDescription>For all users</CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}
export const IconLogoAPY = styled.div`
  border-radius: 100%;
  margin-right: 10px;

  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-left: 0px;

  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(90.34deg, #1fad1f, #3d6dfc);
  color: #fff;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
`
export const IconLogoAPRxZOO = styled.div`
  border-radius: 100%;
  margin-right: 10px;

  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-left: 0px;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(90deg, #2aef61, #f64562);
  color: #fff;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 30px;
    height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
`

const BoxForPercents = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`
const LeftColumnPercents = styled.div`
  color: #1fad1f;
`
const RightColumnPercents = styled.div`
  color: #3d6dfc;
`
const CardDescriptionLeft = styled(CardDescription)`
  color: #2aef61;
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: 'Uniform Pro';

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 32px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`
const CardDescriptionRight = styled(CardDescriptionLeft)`
  color: #f64562;
`

//const SECONDS_IN_YEAR = 365 * 24 * 60 * 60

const useCurrentAPR = () => {
  // FIXME: limit by 1 year
  const { value, loading } = useXZooTotalClaimed()
  const TOTAL_REWARDS = loading ? 0 : formatBNToNumber(BigNumber.from(value))

  const ZOO_AMOUNT_STAKED = useTotalStakedXZoo()

  const stakedTokensAmount = formatBNToNumber(ZOO_AMOUNT_STAKED.mul(BNZooPrice).div(PRICE_MULTIPLIER))

  //const { epochDuration } = useBattlesData()
  /*const BATTLE_PER_YEAR = useMemo(() => {
    return SECONDS_IN_YEAR / (epochDuration * 1000)
  }, [epochDuration])*/

  console.log(value)
  const apr = stakedTokensAmount === 0 ? 0 : 100 * (TOTAL_REWARDS / stakedTokensAmount) //* BATTLE_PER_YEAR

  return { loading, apr, noRewards: value === 0 }
}

export const CurrentAPRxZOO = () => {
  const { apr, loading, noRewards } = useCurrentAPR()

  return (
    <DashboardCardFlex>
      <CardLine index={19} />
      <CardHeadBox>
        <CardHeadContribution>
          <IconLogoAPRxZOO></IconLogoAPRxZOO>
          <Trans>Current APR (xZOO)</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        {loading ? (
          <CardTextStyled>
            <Dots>Loading</Dots>
          </CardTextStyled>
        ) : (
          <BoxForPercents>
            <LeftColumnPercents>
              <CardDescriptionLeft>Weekly</CardDescriptionLeft>
              <CardTextStyled>{(apr / 52).toFixed(8)}%</CardTextStyled>
            </LeftColumnPercents>
            <RightColumnPercents>
              <CardDescriptionRight>Yearly</CardDescriptionRight>
              <CardTextStyled>{apr.toFixed(6)}%</CardTextStyled>
            </RightColumnPercents>
          </BoxForPercents>
        )}
        <CardDescription>
          <Trans>Based on a value of {ZOO_PRICE}$/ZOO</Trans>
        </CardDescription>
        {noRewards && (
          <CardDescription>
            <Trans>No claimed rewards for APR calculation, wait stage 5</Trans>
          </CardDescription>
        )}
      </CardContent>
    </DashboardCardFlex>
  )
}
