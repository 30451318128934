import React from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import {
  ClaimRewards,
  DAIBalance,
  ZOOBalance,
  SLPBalance,
  CurrentAPY,
  NumberParticipants,
  SushiFarm,
  SLPStaked,
  FarmingSushiBigBox,
} from './InfoCards/DashboardCards'
import FireNFTRewards from './InfoCards/FireNFTRewards'
import ClaimedTotal from './InfoCards/ClaimedTotal'
import { DashboardContainer } from '../HomePage/HomePage'
import { FarmStakingTransactions } from 'pages/NftBattlesPageV2/FarmStakingTransactions'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

export const Blocks = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-gap: 35px;
  grid-template-columns: 285px 285px;
  align-self: start;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: initial;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  `};
`

export const RightInfoStyled = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 35px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 10px;
  `};
`
export const LeftInfoStyled = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;

  grid-gap: 35px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 10px;
  `};
`
const DashboardFull = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;
`
const DashboardBox = styled.div`
  display: grid;
  grid-template-columns: calc(60% - 12.5px) calc(40% - 12.5px);
  grid-gap: 25px;
  width: 100%;
`
const DashboardBoxFive = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  width: 100%;
`
const LeftBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`
const RightBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`

export default function Dashboard() {
  return <LiveDashboard />
}

interface IAnon {
  children: any
}

export const BlocksStyled = styled(Blocks)`
  grid-template-columns: 1fr 1fr !important;

  max-width: 400px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    max-width: 525px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 455px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 365px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    max-width: 365px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 365px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr 1fr !important;
  `};import Dashboard from './Dashboard';

`
const DashboardSecondLine = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 25px;
`
const FarmingColumnsSecondLine = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`
export const StakeDashboard = ({}: IAnon) => {
  return (
    <DashboardContainer>
      <DashboardFull>
        <DashboardBoxFive>
          <SLPStaked />
          <SLPBalance />
          <ClaimedTotal index={2} />
          <NumberParticipants index={5} />
        </DashboardBoxFive>
        <DashboardSecondLine>
          <FarmingSushiBigBox />
          <FarmingColumnsSecondLine>
            <CurrentAPY />
            <ClaimRewards />
          </FarmingColumnsSecondLine>
        </DashboardSecondLine>
        <FarmStakingTransactions />
      </DashboardFull>
    </DashboardContainer>
  )
}

const LiveDashboard = () => {
  const { account } = useActiveWeb3React()

  return (
    <Content>
      <DashboardContainer>
        <DashboardFull>
          <DashboardBoxFive>
            <ZOOBalance />
            <DAIBalance />
            <SLPBalance />
            <ClaimedTotal index={2} />
            <NumberParticipants index={5} />
          </DashboardBoxFive>
          <DashboardBox>
            <LeftBox>
              <SushiFarm />
            </LeftBox>
            <RightBox>
              <CurrentAPY />
            </RightBox>
          </DashboardBox>
          <DashboardBox>
            <LeftBox>{account ? <FireNFTRewards /> : null}</LeftBox>
            <LeftBox>
              <ClaimRewards />
            </LeftBox>
          </DashboardBox>
        </DashboardFull>
      </DashboardContainer>
    </Content>
  )
}
