import { CardLine, DashboardCardFlex } from 'pages/Dashboard/styleds'
import { CardHeadBox, LogoCoin } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import { TxtBalance, InputLabel } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import { ButtonBurgundy } from 'components/Button'
import { BigNumber } from 'ethers'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useActiveWeb3React } from 'hooks/web3'
import { useTransactionAdder, useHasPendingNftAction } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { useBattleStakerContract } from 'constants/zoodao'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BATTLE_STAGES, useBattleStage } from 'pages/NftBattlesPage/hooks'
import { Dots } from 'pages/Pool/styleds'
import { formatDecimal } from 'utils/numberWithCommas'
import IconUSD from 'assets/images/combousd.svg'
import IconZOO from 'assets/svg/token.svg'
import { MouseoverTooltip } from 'components/Tooltip'

const useBatchUnStake = (pids: string[]) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline() // custom from users settings

  const actionType = `batch_unstaking_battle_arena`

  const pending = useHasPendingNftAction('', '', actionType)

  const nftPoolContract = useBattleStakerContract()

  async function handler() {
    if (!chainId || !library || !account) return

    if (!nftPoolContract || pids.length === 0) {
      return
    }

    if (account && deadline) {
      const txData = await nftPoolContract.populateTransaction.batchUnstakeNft(pids)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'Batch unstaking nfts from staking pool',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return { handler, pending }
}

const UnstakeNFTs = ({ pids, totalUsd, totalZoo }: { pids: string[]; totalUsd: BigNumber; totalZoo: BigNumber }) => {
  const { pending, handler } = useBatchUnStake(pids)

  const stage = useBattleStage()
  const inactive = pids.length === 0 || stage !== BATTLE_STAGES.FIRST

  return (
    <DashboardCardFlex inactive={inactive}>
      <CardLine index={13} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>Unstake NFTs</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <InputLabel mt={25} alignItems="center">
        <Trans>For All Users:</Trans>
      </InputLabel>

      <InputLabel mt={25} alignItems="center">
        <LogoCoin src={IconUSD} />
        <Trans>USD in selected NFTs:</Trans>
        <TxtBalance>&nbsp;{formatDecimal(totalUsd)}</TxtBalance>
      </InputLabel>

      <InputLabel mt={15} alignItems="center">
        <LogoCoin src={IconZOO} />
        <Trans>ZOO in selected NFTs:</Trans>
        <TxtBalance>&nbsp;{formatDecimal(totalZoo)}</TxtBalance>
      </InputLabel>

      <Flex alignItems="center" justifyContent="end" mt={25}>
        <MouseoverTooltip placement="bottom" text={<Trans>You may unstake votes during Stage 1</Trans>}>
          <ButtonBurgundy disabled={pending || inactive} onClick={handler}>
            {pending ? (
              <Dots>
                <Trans>Unstaking</Trans>
              </Dots>
            ) : (
              <Trans>Unstake Selected</Trans>
            )}
          </ButtonBurgundy>
        </MouseoverTooltip>
      </Flex>
    </DashboardCardFlex>
  )
}

export default UnstakeNFTs
