import React from 'react'

import { InfoValue, InfoTitle, Info } from './styles'
import { Flex } from 'rebass/styled-components'

import { useExpectedCollectionRewards } from 'hooks/zoodao/veModel'
import { Dots } from 'pages/Pool/styleds'
import { formatDecimal } from 'utils/numberWithCommas'

export const VePieRewards = ({ percent }: { percent: number }) => {
  const { stakersRewards, votersRewards, loading } = useExpectedCollectionRewards(percent)
  return (
    <Flex>
      {loading ? (
        <Dots>Loading</Dots>
      ) : (
        <>
          <Info>
            <InfoValue>{formatDecimal(stakersRewards)}</InfoValue>
            <InfoTitle>USD</InfoTitle>
          </Info>
          <Info>
            <InfoValue>{formatDecimal(votersRewards)}</InfoValue>
            <InfoTitle>ZOO</InfoTitle>
          </Info>
        </>
      )}
    </Flex>
  )
}
