import IconTemplate from '../../assets/images/battles/zero-nft.png'
import useSWR from 'swr'
import { useMemo } from 'react'
import { useActiveWeb3React } from 'hooks/web3'
import { SupportedChainId } from 'constants/chainsinfo'
import { useNftPartnersList } from 'pages/VePie/useNftPartnersList'

export interface IProjectPartner {
  image_url: string
  banner_image_url: string
  name: string
  external_link: string
  payout_address: string
}

export const COLLECTION_DEFAULT: IProjectPartner = {
  image_url: IconTemplate,
  banner_image_url: IconTemplate,
  name: '',
  external_link: '',
  payout_address: '',
}

export const getMoonbeamMock = async (slug: string) => {
  switch (slug) {
    case 'FPB': {
      return 'https://i.seadn.io/gae/tFimJtkxolqZjELxeXQU0HFYEC1tQVlnsa_8Uaf_-1vvGmT7C36G0InQTyBfqS9bGhcWqyZIh3eUsDk8QMwOVO9oORAiR4mLPwjo8Vw?auto=format&w=3840'
    }
    case 'LSS': {
      return 'https://i.seadn.io/gcs/files/390b1133f888f9a503a794910ff7ee25.jpg?auto=format&w=3840'
    }
    case 'HBZ': {
      return 'https://i.seadn.io/gae/OJDkj5U6VTYqJl1XGud2KAGcc0Mx4lKoYh34Q4HIrPmWMitOlbvFzz9WkBBC_L4UjDD9erEElfKapKmhdrCW1d8yNlXHkkeEAppJ-fc?auto=format&w=3840'
    }
    case 'FF': {
      return 'https://cdn.tofunft.com/covers/ddhs1nmfkt7v3d2.png/1440.png'
    }
    case 'GLMA': {
      return 'https://cdn.tofunft.com/covers/sr478022a8bglru.png/280.jpg'
    }
    case 'MFBB': {
      return 'https://cdn.tofunft.com/covers/a9g7f93qxd5bn8l.png/280.jpg'
    }
    case 'CNA': {
      return 'https://cdn.tofunft.com/covers/tuvxjhk8bdp9hnw.jpg/280.jpg'
    }
    case 'MOONPETS': {
      return 'https://cdn.tofunft.com/covers/4mt3olwbmrq5bz9.png/1440.png'
    }
    case 'AlleyKatzOfficial': {
      return 'https://cdn.tofunft.com/covers/r62183gjtix2z41.png/1440.png'
    }
    case 'DFR': {
      return 'https://cdn.tofunft.com/covers/d6wjd4j29dy0no5.jpg/1440.png'
    }
    case 'Dork_S2': {
      return 'https://i.seadn.io/gcs/files/109af3929aadd5f15d39190db17cedb9.jpg?auto=format&w=3840'
    }
    case 'PACA': {
      return 'https://cdn.tofunft.com/covers/pr91a031t5xfih2.png/1440.png'
    }
    case 'COSMOS': {
      return 'https://i.seadn.io/gcs/files/18ebc32ed38af66a24ea1e28252c2705.png?auto=format&w=3840'
    }
    case 'MW': {
      return 'https://cdn.tofunft.com/covers/okbfm26lzv3i3rh.png/1440.png'
    }
    case 'FB': {
      return 'https://i.seadn.io/gae/ROw3OOIWAs8vfswB4PSugojQxDVmvb1gWJFIO4iP6q5qQR-Ntdv-cVf_46pz6EiQUnrPcqDXl-HOpbEjiDb-iIjRJLfXo-H_PSpTlQ?auto=format&w=3840'
    }
    case 'LIZARD': {
      return 'https://cdn.tofunft.com/covers/vbr92kwuiq2dlqc.png/1440.png'
    }
    case 'ELVN': {
      return 'https://cdn.tofunft.com/covers/o4r7ykpqdbcp0t3.png/1440.png'
    }
    case 'Dork': {
      return 'https://cdn.tofunft.com/covers/1yp2vjc9cdmzu8h.jpg/1440.png'
    }
    case 'FLUF': {
      return 'https://cdn.tofunft.com/covers/avvy9wqi4tl6vtt.png/1440.png'
    }
    case 'LUCHADORES': {
      return 'https://cdn.tofunft.com/covers/a0g9n55gq90b1q0.png/1440.png'
    }
    case 'LOBSTER': {
      return 'https://cdn.tofunft.com/covers/obgf6nfra8vhh33.png/1440.png'
    }
    case 'HOTM': {
      return 'https://cdn.tofunft.com/covers/z3gt2crsk0e2830.png/1440.png'
    }
    case 'AMC': {
      return 'https://cdn.tofunft.com/covers/d6ioidduq6sllbh.jpg/1440.png'
    }
    case 'HC': {
      return 'https://cdn.tofunft.com/covers/noey2ff4nvhnehx.jpg/1440.png'
    }
    case 'MNSTRS': {
      return 'https://cdn.tofunft.com/covers/87vxeab77j9nktj.png/1440.png'
    }
    case 'NIBIRU': {
      return 'https://cdn.tofunft.com/covers/f5dtqr741qyg2w8.jpg/1440.png'
    }
    case 'MORPH': {
      return 'https://cdn.tofunft.com/covers/q75p1e9h46lxyj3.png/1440.png'
    }
    case 'PWIZ': {
      return 'https://i.seadn.io/gae/PSVJwbAaDxxdpIpJalAFXHemyKubIG0gPZ-RpQawXpdDuxhKqhsDiAIGA1Iu8pfBJMJLwUOw8IO2vVZhDLnPVpgSRES_usHNEaeDk1s?auto=format&w=3840'
    }
    case 'MUTCATS': {
      return 'https://cdn.tofunft.com/covers/beung3h51zi8wak.png/1440.png'
    }
    case 'dino': {
      return 'https://cdn.tofunft.com/covers/24xkr1f09lkdsgp.png/1440.png'
    }
    case 'WBSC': {
      return 'https://cdn.tofunft.com/covers/hgymwk80n4e890e.png/1440.png'
    }
    case 'MNFT': {
      return 'https://cdn.tofunft.com/covers/yfp5u4kt3u48ytt.png/1440.png'
    }
    case 'LYKEIN': {
      return 'https://i.seadn.io/gcs/files/3025d3591389c07aac819952244eae82.jpg?auto=format&w=3840'
    }
    case 'PartyBears': {
      return 'https://i.seadn.io/gae/lyHF7pjYpGnSf1veZSw0Hlz5f1UhBwV8AwEs_WmjoN4y8kS8MfWmZyZT-NkJCgNNOACp8_djj1ehs2KVQZlSCndKaxt0ZRfXrJIa?auto=format&w=3840'
    }
    case 'MEC': {
      return 'https://cdn.tofunft.com/covers/g31xhopx6zh3s7s.jpg/1440.png'
    }
    case 'PURREVIL': {
      return 'https://cdn.tofunft.com/covers/w9vgljkn3pjd6d4.gif/1440.png'
    }
    case 'RMICE': {
      return 'https://i.seadn.io/gae/jX5gBNPQUiZoVXKYiW9JuKod3HrySFwZNjwJJdLz6WEYxfNDeO0lVcULOy5GC3itMSBcqdFCKjxNpWi00Z91AzsyhqvkE6K4emzhsQ?auto=format&w=3840'
    }
    case 'DEMON': {
      return 'https://cdn.tofunft.com/covers/uikqo7eld5wb9sx.jpg/1440.png'
    }
    case 'CC': {
      return 'https://cdn.tofunft.com/covers/aovv6axtsc97jl3.png/1440.png'
    }
    case 'MM': {
      return 'https://cdn.tofunft.com/covers/y3yr6ixpxa4glka.png/1440.png'
    }
    case 'OMM': {
      return 'https://cdn.tofunft.com/covers/036h4rq73in7hgb.png/1440.png'
    }
    case 'CB': {
      return 'https://i.seadn.io/gcs/files/f7310f1d01b601e5203f00b83256a9ca.jpg?auto=format&w=3840'
    }
    case 'Zoombie': {
      return 'https://i.seadn.io/gae/8gUxH5jjfwach7dDW657vRGiCMMaB_JJyd8lamd_MlErjUSRKpDNF64tXWJ6W-o1tqbtk5Yim3Y3hPGo6t9yXr7eAcRwq2-cr2ZK?auto=format&w=3840'
    }
    case 'SBSH': {
      return 'https://cdn.tofunft.com/covers/gyvzajh0jhslhks.png/1440.png'
    }
    case 'TOADZ': {
      return 'https://cdn.tofunft.com/covers/3xbawsmi0cgzvgt.png/1440.png'
    }
    case 'ZDC': {
      return 'https://cdn.tofunft.com/covers/78o4liwcf3epxgz.jpg/1440.png'
    }
    case 'ZDCP': {
      return 'https://i.seadn.io/gcs/files/45bc23c70e5ca8330f5822379efa0efd.png?auto=format&w=3840'
    }
    case 'gg': {
      return 'https://i.seadn.io/gae/gkPztARTHdABvLnd2BvmNN9U4zSSkwb8mkAWhns8r4mWrsQuvsKCjwERZJ1Ebontl99caCx4V7F0EcDkmugap0EIQYCG7QPSc1xtpA?auto=format&w=3840'
    }
    case 'TOSHIES': {
      return 'https://i.seadn.io/gcs/files/baa20adf8447844dd3fb663ef7b54376.gif?auto=format&w=3840'
    }
    case 'TABOY': {
      return 'https://i.seadn.io/gae/bWgbzHdOrgOtpDUm0LTIo4eH4JjzeDUsTce4qA3mrBkGw-wXYnWDQVxaKXBG28Y2kQ634cAFBYEfsBMpqggMirS9Vu6GKxAOeKnqJ00?auto=format&w=3840'
    }
    case 'SRLS': {
      return 'https://i.seadn.io/gae/nzHazAiTkgDTCDTT4C7syt0stXjvZTTLBZigWiuHab-gYo5mu4VDLfu60wxtlfTE-QGknzf2ZYSAuj8ZlrnS8KzfMjd77db83a0AwBk?auto=format&w=3840'
    }
    case 'DA': {
      return 'https://i.seadn.io/gcs/files/26a44bdf1740daed36ccf2f90a07b65d.png?auto=format&w=1920'
    }
    default: {
      return ''
    }
  }
}

const fetchCollectionInfo = async (slug = '', slugAddress = '', chainId?: SupportedChainId, name?: string) => {
  if (chainId === SupportedChainId.MOONBEAM) {
    return {
      collection: {
        payout_address: slugAddress,
        image_url: `https://img.tofunft.com/v2/${chainId}/${slugAddress}/1/280/image.jpg`,
        banner_image_url: (await getMoonbeamMock(slug)) || IconTemplate,
        name: name,
        slug: slug,
        external_link: '#',
      },
    }
  }

  return fetch(`https://api.opensea.io/api/v1/collection/${slug}`)
    .then((response) => response.json())
    .then((data) => ({
      ...data,
      collection: {
        ...data.collection,

        payout_address: slugAddress,
      },
    }))
}

export const useCollectionInfo = (address?: string) => {
  const { chainId } = useActiveWeb3React()
  const partners = useNftPartnersList()

  const {
    slug,
    address: slugAddress,
    name,
  } = useMemo(() => {
    const target = partners.allCollectionSlugs.find((item) => item.address.toLowerCase() === address?.toLowerCase())
    return (
      target || {
        slug: '',
        address: '',
        name: '',
      }
    )
  }, [address, partners])

  const result = useSWR(slug, () => fetchCollectionInfo(slug, slugAddress, chainId, name))

  return useMemo((): IProjectPartner => {
    const { data, error } = result
    return !error && typeof data === 'object' && data.collection ? data.collection : COLLECTION_DEFAULT
  }, [result])
}
