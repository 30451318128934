import { useZooDaoPair, useZooDaoSalePair } from '../constants/zoodao'
import { useCurrency } from './Tokens'

export const useZooCurrencies = () => {
  const { zoo: currencyIdA, dai: currencyIdB } = useZooDaoPair()
  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  return { baseCurrency, quoteCurrency }
}

export const useSaleZooCurrencies = () => {
  const { zoo: currencyIdA, stablecoin: currencyIdB } = useZooDaoSalePair()
  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  return { baseCurrency, quoteCurrency }
}
