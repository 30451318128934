import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { useActiveWeb3React } from '../web3'
import IconTemplate from '../../assets/images/battles/zero-nft.png'
import { useUnstakeBalanceSLP } from 'pages/Dashboard/InfoCards/hooks'
import useSWR from 'swr'

export const IconNftTemplate = IconTemplate

export const useNftInfo = () => {
  const balance = useUnstakeBalanceSLP()
  return useMemo(() => balance && !balance.isZero(), [balance])
}

export const getOpenseaFixtures = (chainId: number | undefined, address: string, tokenId: BigNumber) => {
  if (!chainId || !address || !tokenId) {
    return {
      id: '',
      targetAddress: '',
      URL: '',
    }
  }

  const targetAddress = address.toLowerCase()
  const id = tokenId.toString()

  return {
    id,
    targetAddress,
  }
}

export function fetcherJson(url: string) {
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
}

export const useNftMetaInfo = (address: string, tokenId: BigNumber, fallbackImg?: string): INftImgInfo => {
  const { chainId } = useActiveWeb3React()
  const { targetAddress, id } = useMemo(
    () => getOpenseaFixtures(chainId, address, tokenId),
    [chainId, address, tokenId]
  )

  const URL = useMemo(() => `https://api.opensea.io/api/v1/asset/${targetAddress}/${id}/`, [targetAddress, id])

  const result = useSWR(URL, fetcherJson)

  return useMemo(() => {
    const { data, error } = result
    return !error && typeof data === 'object' && data.success !== false ? data : NFT_DEFAULT(fallbackImg)
  }, [result, fallbackImg])
}

export interface INftImgInfo {
  tokenImage: string
  tokenThumbnail: string
  collection: {
    collectionThumbnail: string
  }
}
export const NFT_DEFAULT = (fallbackImg: string = IconNftTemplate): INftImgInfo => ({
  tokenImage: fallbackImg,
  tokenThumbnail: fallbackImg,

  collection: {
    collectionThumbnail: fallbackImg,
  },
})

export const useOpenseaImages = (token: string, id: BigNumber, fallbackImg?: string) => {
  const openSeaData = useNftMetaInfo(token, id, fallbackImg)

  return useMemo(() => {
    const tokenImageUrl = openSeaData.tokenImage || openSeaData.tokenThumbnail
    const {
      collection: { collectionThumbnail: imageCollection },
    } = openSeaData

    return {
      image_url: imageCollection || fallbackImg || IconNftTemplate,
      image_original_url: tokenImageUrl || fallbackImg || IconNftTemplate,
    }
  }, [openSeaData, fallbackImg])
}
