import { Trans } from '@lingui/macro'
import { ButtonRedSecond, ButtonVote } from 'components/Button'
import { GetOnStellarswap } from 'components/GetStablecoin'
import { DaiReVoting } from 'components/VoteForBattle/VoteForBattle'
import { BigNumber } from 'ethers'
import { useChainStablecoinBalance } from 'hooks/zoodao/zooToken'
import { useZooCurrencies } from 'hooks/useZooCurrencies'
import { ApproveCheckerBattleArena } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import {
  useBattleVotingPositionInfo,
  useBattleStage,
  BATTLE_STAGES,
  useWithdrawFromDaiVotingPosition,
  useAddDaiToVoting,
  useBattleStakerPositionInfo,
} from 'pages/NftBattlesPage/hooks'
import { Dots } from 'pages/Pool/styleds'
import { useMemo, useState } from 'react'
import { fromWei } from 'utils/fromWei'
import { formatDecimal } from 'utils/numberWithCommas'
import {
  CardHeadBox,
  LogoCoin,
  CardHead,
  CardContentBox,
  BalanceLine,
  BoxBtn2,
  InfoText,
  BalanceExplanation,
  BalanceValue,
  RowPicker,
  USD_OPTIONS,
} from './CardMiniForNftInfo'
import { CardMedium, CardLine } from './styleds'
import styled from 'styled-components/macro'
import { ZERO } from 'utils/isZero'
import { UsdOptionPicker } from 'pages/Portfolio/Panels/UsdOptionPicker'
import { BalanceInputWrapper } from 'pages/Portfolio/Panels/BalanceInput'
import { MaxButtonPurple } from 'pages/Portfolio/Panels/styles'
import { useStableCurrency } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks/web3'
import { SupportedChainId } from '../../constants/chainsinfo'
import { AlarmText } from './cards/JackpotsNFTOverview/styles'

const UsdOptionPickerStyled = styled(UsdOptionPicker)`
  width: 100%;
`

const GetDaiStyled = styled(GetOnStellarswap)`
  margin: 0 0 0 16px;
  min-width: 112px;
`

const DopBtn = styled.div``
const DopBtnL = styled.div`
  margin-left: 9px;
`
export const BoxLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export const LockedLabel = styled.div`
  color: #63c178;
`

const CardMediumStyled = styled(CardMedium)`
  margin-top: 16px;
`

export function min(balance: BigNumber, maxValue: BigNumber) {
  if (balance.gt(maxValue)) {
    return maxValue
  } else {
    return balance
  }
}

const LockedLabelComp = ({ symbol, balance }: { symbol?: string; balance: BigNumber }) => (
  <BoxLink>
    <LockedLabel>
      <Trans>My Locked</Trans>
      &nbsp;{symbol}:
    </LockedLabel>
    &nbsp;${formatDecimal(balance)}
  </BoxLink>
)

export const CardVotingDAI = ({ item }: { item: any }) => {
  const votingPositionId = useMemo(() => item.votingPositionId.toString(), [item])
  const stakingPositionId = useMemo(() => item.stakingPositionId.toString(), [item])
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()
  const { chainId } = useActiveWeb3React()

  const [value, onUserInput] = useState<number | undefined>()

  const { vote: vote, pending: pendingVote } = useAddDaiToVoting(value, votingPositionId)
  const { withdraw, pending: pendingWithdraw, isError } = useWithdrawFromDaiVotingPosition(value, votingPositionId)

  const { info: { daiInvested = ZERO } = {}, loading } = useBattleVotingPositionInfo(votingPositionId)

  const { endDate } = useBattleStakerPositionInfo(stakingPositionId)

  const daiInvestedBalance = useMemo(() => {
    return daiInvested ? daiInvested : ZERO
  }, [daiInvested])

  const daiBalance = useChainStablecoinBalance()

  const { quoteCurrency: dai } = useZooCurrencies()

  const stage = useBattleStage()

  const canAdd = stage === BATTLE_STAGES.SECOND
  const canWithdraw = stage === BATTLE_STAGES.FIRST || (endDate && !endDate.isZero())

  const minMax = useMemo(() => {
    if (canAdd) return daiBalance || ZERO
    else {
      return daiInvestedBalance || ZERO
    }
  }, [daiBalance, daiInvestedBalance, canAdd])

  const disabled = (!canAdd && !canWithdraw) || loading

  return (
    <CardMediumStyled inactive={disabled}>
      <CardLine index={18} />
      <CardHeadBox>
        <LogoCoin src={stableCurrencyIcon} />
        <CardHead>
          <Trans>{stableCurrency} Voting</Trans>
        </CardHead>
      </CardHeadBox>

      <CardContentBox>
        <BalanceExplanation>
          <InfoText>
            <Trans>My {stableCurrency} Balance:</Trans>
          </InfoText>
          <BalanceValue>{formatDecimal(daiBalance)}</BalanceValue>
        </BalanceExplanation>

        <BalanceInputWrapper
          btn={MaxButtonPurple}
          inactive={disabled || loading}
          balance={value}
          total={minMax}
          setBalance={onUserInput}
        />

        <RowPicker>
          {chainId !== SupportedChainId.MOONBEAM && chainId !== SupportedChainId.MOONBEAM_ALPHA ? (
            <UsdOptionPickerStyled options={USD_OPTIONS} />
          ) : (
            <LockedLabelComp symbol={dai?.symbol} balance={daiInvestedBalance} />
          )}
          <GetDaiStyled />
        </RowPicker>

        <BalanceLine>
          {chainId !== SupportedChainId.MOONBEAM && chainId !== SupportedChainId.MOONBEAM_ALPHA && (
            <LockedLabelComp symbol={dai?.symbol} balance={daiInvestedBalance} />
          )}

          <BoxBtn2>
            {dai ? (
              <ApproveCheckerBattleArena currency={dai} border={value}>
                {stage === BATTLE_STAGES.SECOND && (
                  <DopBtn>
                    <DaiReVoting votingPositionId={votingPositionId} />
                  </DopBtn>
                )}
                <DopBtnL>
                  <ButtonVote disabled={pendingVote || !canAdd || value === 0 || loading} onClick={vote}>
                    {pendingVote ? (
                      <Dots>
                        <Trans>Voting</Trans>
                      </Dots>
                    ) : (
                      <Trans>Vote</Trans>
                    )}
                  </ButtonVote>
                </DopBtnL>
                <DopBtnL>
                  <ButtonRedSecond
                    disabled={
                      loading ||
                      pendingWithdraw ||
                      !canWithdraw ||
                      daiInvestedBalance.isZero() ||
                      !value ||
                      value > +fromWei(daiInvestedBalance)
                    }
                    onClick={withdraw}
                  >
                    {pendingWithdraw ? (
                      <Dots>
                        <Trans>Withdraw</Trans>
                      </Dots>
                    ) : (
                      <Trans>Withdraw</Trans>
                    )}
                  </ButtonRedSecond>
                </DopBtnL>
              </ApproveCheckerBattleArena>
            ) : null}
          </BoxBtn2>
        </BalanceLine>

        {isError && (
          <AlarmText>
            <Trans>You must unstake your NFT from Jackpots or wait until Stage 1 to claim rewards</Trans>
          </AlarmText>
        )}
      </CardContentBox>
    </CardMediumStyled>
  )
}
