import { Currency, Token } from '@uniswap/sdk-core'
import { useCallback, useEffect, useState } from 'react'
import useLast from '../../hooks/useLast'
import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import Modal from '../Modal'
import { CurrencySearch } from './CurrencySearch'
import { ImportToken } from './ImportToken'
import usePrevious from 'hooks/usePrevious'
import Manage from './Manage'
import { TokenList } from '@uniswap/token-lists'
import { ImportList } from './ImportList'

interface CurrencySearchModalProps {
  isOpenFlag: boolean
  onDismissHandler?: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  onTokenInputChange?: (a: string) => void
  isProMode?: boolean
}

interface CurrencySearchModalTemplateProps extends CurrencySearchModalProps {
  as: any
}

export enum CurrencyModalView {
  search,
  manage,
  importToken,
  importList,
}

export function CurrencySearchTemplate({
  isOpenFlag,
  onDismissHandler,
  onTokenInputChange,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = false,
  showCurrencyAmount = true,
  as: AsItem = 'div',
  isProMode,
}: CurrencySearchModalTemplateProps) {
  const [modalView, setModalView] = useState<CurrencyModalView>(CurrencyModalView.search)
  const lastOpen = useLast(isOpenFlag)

  useEffect(() => {
    if (isOpenFlag && !lastOpen) {
      setModalView(CurrencyModalView.search)
    }
  }, [isOpenFlag, lastOpen])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismissHandler ? onDismissHandler() : setModalView(CurrencyModalView.search)
    },
    [onDismissHandler, onCurrencySelect]
  )

  // for token import view
  const prevView = usePrevious(modalView)

  // used for import token flow
  const [importToken, setImportToken] = useState<Token | undefined>()

  // used for import list
  const [importList, setImportList] = useState<TokenList | undefined>()
  const [listURL, setListUrl] = useState<string | undefined>()

  // change min height if not searching
  const minHeight = modalView === CurrencyModalView.importToken || modalView === CurrencyModalView.importList ? 40 : 80

  return (
    <AsItem isOpen={isOpenFlag} onDismissHandler={onDismissHandler} maxHeight={80} minHeight={minHeight}>
      {modalView === CurrencyModalView.search ? (
        <CurrencySearch
          isOpenFlag={isOpenFlag}
          onDismissHandler={onDismissHandler}
          onCurrencySelect={handleCurrencySelect}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          showImportView={() => setModalView(CurrencyModalView.importToken)}
          setImportToken={setImportToken}
          showManageView={() => setModalView(CurrencyModalView.manage)}
          onTokenInputChange={onTokenInputChange}
          isProMode={isProMode}
        />
      ) : modalView === CurrencyModalView.importToken && importToken ? (
        <ImportToken
          tokens={[importToken]}
          onDismissHandler={onDismissHandler}
          list={importToken instanceof WrappedTokenInfo ? importToken.list : undefined}
          onBack={() =>
            setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search)
          }
          handleCurrencySelect={handleCurrencySelect}
        />
      ) : modalView === CurrencyModalView.importList && importList && listURL ? (
        <ImportList
          list={importList}
          listURL={listURL}
          onDismissHandler={onDismissHandler}
          setModalView={setModalView}
        />
      ) : modalView === CurrencyModalView.manage ? (
        <Manage
          onSelectToken={handleCurrencySelect}
          onDismissHandler={onDismissHandler}
          setModalView={setModalView}
          setImportToken={setImportToken}
          setImportList={setImportList}
          setListUrl={setListUrl}
          showListsEnabled={false}
        />
      ) : (
        ''
      )}
    </AsItem>
  )
}

export default function CurrencySearchModal({
  isOpenFlag,
  onDismissHandler,
  onCurrencySelect,
  selectedCurrency,
  onTokenInputChange,
  otherSelectedCurrency,
  showCommonBases = false,
  showCurrencyAmount = true,
}: CurrencySearchModalProps) {
  return (
    <CurrencySearchTemplate
      onTokenInputChange={onTokenInputChange}
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={selectedCurrency}
      otherSelectedCurrency={otherSelectedCurrency}
      showCommonBases={showCommonBases}
      showCurrencyAmount={showCurrencyAmount}
      isOpenFlag={isOpenFlag}
      onDismissHandler={onDismissHandler}
      as={Modal}
    />
  )
}
