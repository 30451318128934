import styled from 'styled-components/macro'
import Dropdown from 'react-dropdown'
import IconArrowDown from 'assets/svg/angle-down-solid.svg'
import IconArrowDownTransform from 'assets/svg/angle-up-solid.svg'

export const DropdownContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;
`

export const StyledDd = styled(Dropdown)`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .Dropdown-control {
    background-color: #1fad1f;
    padding: 15px 25px 15px 25px;
    border: 0px;
    border-radius: 50px;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    min-width: 250px;
    text-align: center;

    transition: background-color 0.3s;
    font-family: Uniform Pro;
    font-weight: bold;
    line-height: 18px;
    font-size: 18px;
    color: #fff;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 16px 40px 16px 24px;
    font-size: 16px;
    line-height: 16px;
    min-width: 140px;
  `};

    &:hover {
      color: ${({ theme }) => theme.text1};
    }
  }
  .Dropdown-arrow {
    top: 15px;
    right: 25px;
    background: url(${IconArrowDown}) 50% 50% / cover no-repeat;
    color: #fff;
    height: 18px;
    width: 18px;
    border: 0 !important;
    .is-open .Dropdown-arrow {
      transform: rotate(90deg) !important;
    }
  }
  & .is-open .Dropdown-arrow {
    background: url(${IconArrowDownTransform}) 50% 50% / cover no-repeat !important;
    fill: #1fad1f;
  }

  .Dropdown-menu {
    font-size: 25px;
    line-height: 30px;
    background-color: ${({ theme }) => theme.bg2};
    border: none;
    cursor: pointer;
    top: 105%;
    border-radius: 24px;
    font-family: Uniform Pro;
    font-weight: bold;
    line-height: 18px;
    font-size: 18px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
    line-height: 16px;
  `};
    box-shadow: 0px 15px 15px rgb(0 0 0 / 25%);
  }

  .Dropdown-option {
    text-align: center;
    padding: 15px 25px;
    color: ${({ theme }) => theme.text2};
    &:hover {
      background: #9998b8;
      color: #202136;
    }
  }

  .Dropdown-option.is-selected {
    color: #fff;
    background: #f64562;
  }

  .Dropdown-placeholder {
    margin-right: 32px;
  }
`

export const AmountDd = styled.div`
  font-size: 24px;
  line-height: 32px;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #33334b;

  background-color: #202136;
  padding: 15px 25px 15px 25px;
  border-radius: 50px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 20px;
  line-height: 20px;
  margin-right: 24px;
`};
`
