import { IDashCard, CardLine, DashboardCard, CardHeader, CardContent, CardText, CardDescription } from '../../styleds'
import { Trans } from '@lingui/macro'
import { formatDecimal } from 'utils/numberWithCommas'
import styled from 'styled-components/macro'
import { Dots } from 'pages/Pool/styleds'
import { useJackpotsTotalClaimed } from 'hooks/gql'
import { IPanelJackpotsVariant } from '../ButtonPanel'
import { useMemo } from 'react'
import { BigNumber } from 'ethers'

export const CardTextStyled = styled(CardText)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

export const useJackpotsClaimed = () => {
  const { value: b, loading: loadingA } = useJackpotsTotalClaimed(IPanelJackpotsVariant.JackpotA)
  const { value: a, loading: loadingB } = useJackpotsTotalClaimed(IPanelJackpotsVariant.JackpotB)

  const loading = loadingA || loadingB
  const value = useMemo(() => {
    return BigNumber.from(a).add(BigNumber.from(b))
  }, [a, b])

  return {
    loading,
    value,
  }
}
const ClaimedTotal = ({ index }: IDashCard) => {
  const { loading, value } = useJackpotsClaimed()

  return (
    <DashboardCard>
      <CardLine index={index} />
      <CardHeader>
        <Trans>Claimed Total</Trans>
      </CardHeader>

      <CardContent>
        <CardTextStyled>{loading ? <Dots>Loading</Dots> : formatDecimal(value, 2)}</CardTextStyled>
        <CardDescription>
          <Trans>For all users</Trans>
        </CardDescription>
      </CardContent>
    </DashboardCard>
  )
}

export default ClaimedTotal
