import { Trans } from '@lingui/macro'
import { ButtonStake } from 'components/Button'
import { useERC721Contract } from 'constants/zoodao'
import { Contract } from 'ethers'
import { Dots } from 'pages/Pool/styleds'
import styled from 'styled-components/macro'
import { ApproveCheckerNFT, useIsOwner } from './ApproveCheckerBattleArena'
import { useCallback } from 'react'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { BATTLE_STAGES, useAllowedForStaking, useBattleStage } from './hooks'
import { AlarmText } from 'pages/NftBattlesPageV2/cards/JackpotsNFTOverview/styles'

const ButtonStake2 = styled(ButtonStake)`
  margin-top: auto;

  &:disabled {
    background-color: #202136;
    color: #1fad1f;
    border: 2px solid #1fad1f;
  }
`

const useStake = (contract: Contract, selectedNftAddress: string | undefined, tokenId: string | undefined) => {
  const dataFunc = useCallback(async () => {
    return await contract.populateTransaction.stakeNft(selectedNftAddress, tokenId)
  }, [contract, selectedNftAddress, tokenId])

  return useTxTemplate(`staking_battle_arena_${selectedNftAddress}_${tokenId}`, `Adding NFT to staking pool`, dataFunc)
}

export const ApproveStakingNfts = ({
  nftAddress,
  tokenId,
  disabled,
  contract,
}: {
  contract: Contract
  nftAddress: string
  tokenId?: string
  disabled?: boolean
}) => {
  const { action: onStake, pending: pendingStaking } = useStake(contract, nftAddress, tokenId)

  const defined = tokenId !== undefined && nftAddress

  const nftContract = useERC721Contract(nftAddress)
  const isOwner = useIsOwner(tokenId, nftContract)

  const { allowedForStaking, loading } = useAllowedForStaking(nftAddress)

  const stage = useBattleStage()

  if (!allowedForStaking) {
    return (
      <AlarmText>
        <Trans>Not Eligible</Trans>
      </AlarmText>
    )
  }

  if (stage !== BATTLE_STAGES.FIRST) {
    return null
  }

  return (
    <ApproveCheckerNFT nftAddress={nftAddress} poolContract={contract} tokenId={tokenId}>
      <ButtonStake2 onClick={onStake} disabled={loading || !defined || !isOwner || pendingStaking || disabled}>
        {pendingStaking ? (
          <Dots>
            <Trans>Staking</Trans>
          </Dots>
        ) : (
          <Trans>Stake</Trans>
        )}
      </ButtonStake2>
    </ApproveCheckerNFT>
  )
}
