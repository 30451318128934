import React, { useCallback } from 'react'
import Logo from '../../assets/svg/logo.svg'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import Web3Status from '../Web3Status'
import { Trans } from '@lingui/macro'
import { GradientStyles, ZooText, ZooTextGradient } from '../ZooText/ZooText'
import { SupportedChainId } from 'constants/chainsinfo'
import { ButtonVote as BtnSwitch } from 'components/Button'
import { useHandleChainSwitch } from 'components/Header/NetworkSelector'
import { useIsBridge, useIsCommunityRound, useIsPreSale, useIsYieldFarm } from 'components/Header'
import { useActiveBridge } from 'components/Sidebar/BridgeMode'
import BgSvg from './bg.svg'
import BgBaseSvg from './bg-base.svg'
import connectBg from './../../assets/images/bg-nfts.svg'
import { isMobile } from 'react-device-detect'
import Project1 from './project1.svg'
import Project2 from './project2.svg'
import Project3 from './project3.svg'

const ImgProject = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 20px;
`
export const StyledRow = styled.div`
  width: 100%;
  height: 100%;
  background: url('${connectBg}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 20px rgb(0 0 0 / 20%);
  border-radius: 50px;

  padding: 25px 120px;
  border-radius: 24px;
`

export const StyledRowWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url('${isMobile ? undefined : BgSvg}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 20px rgb(0 0 0 / 20%);
  border-radius: 50px;

  padding: 25px;
  border-radius: 24px;
`

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;

  width: 100%;
  height: 100%;
  padding: 90px;
`

export const ContentWarningStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  align-items: center;
  margin: auto;

  width: 100%;
  height: 100%;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
  font-size: 65px;
  line-height: 70px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 45px;
    line-height: normal;
  `};
`

export const TextConnect = styled.div`
  text-align: left;
  word-break: break-word;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 32px;
  font-size: 38px;
  margin-top: 12px;
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
`

const Right = styled.div`
  background: url('${BgBaseSvg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContainerExpl = styled.div`
  width: 480px;
  height: 330px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg2};
  padding: 20px 35px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
`

const CurText = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 44px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`
const TextGradientStyled = styled(ZooTextGradient)`
  font-style: normal;
  font-weight: 700;
  font-size: 78px;
  line-height: 72px;

  text-align: center;
`

const StakeVoteEarn = styled(ZooTextGradient)`
  font-style: normal;
  font-weight: 500;

  text-align: center;
`

const Explanation = styled.div`
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: ${({ theme }) => theme.text5};
  margin-top: 20px;
  text-align: center;
`

const TextConnectStyled = styled(TextConnect)`
  color: #2fd9c6;
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 28px;
    line-height: normal;
    ${GradientStyles}
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  margin-top: 24px;
  `};
`

export const Header = styled(ZooText)`
  margin-bottom: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
  background: linear-gradient(90deg, rgba(254, 94, 0, 1) 0%, rgba(246, 69, 98, 1) 50%, rgba(194, 45, 225, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Web3StatusStyled = styled(Web3Status)`
  width: auto;

  background: linear-gradient(90deg, rgb(254, 94, 0) 12.38%, rgb(246, 69, 98) 51.8%, rgb(194, 45, 225) 91.43%);
  box-shadow: 5px 10px 20px rgb(0 0 0 / 20%) !important;

  letter-spacing: 0.75px;
  border: 0px !important;
  border-radius: 50px;

  padding: 20px 60px;

  transition: all 0.3s;

  p {
    font-family: ${({ theme }) => theme.fontUniform};
    font-weight: bold;
    color: ${({ theme }) => theme.text1};
    -webkit-text-fill-color: ${({ theme }) => theme.text1};
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 22px;
    line-height: normal;
  `};
  }

  :hover {
    background: #fff;
    p {
      background: linear-gradient(90deg, #f56738 12.38%, #f64562 51.8%, #e138f5 91.43%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`

const Left = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const AlarmText = styled.div`
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px;
`

const WeAreWorking = styled.div`
  color: ${({ theme }) => theme.text1};
  font-family: Uniform Pro;
  font-weight: bold;
  font-size: 32px;
`
const AlarmBox = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  text-align: left;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 0 24px;
  align-items: flex-start;
`
const LogoImg = styled.img`
  width: 40px;
  height: 40px;
  image-rendering: pixelated;
  width: 100%;
  margin-top: 90px;
`

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
`

export default function WarningWrapper({ children }: IProps) {
  const { account, notSupportedChain } = useWarningFlag()
  const isPresale = useIsPreSale()
  const isBridge = useIsBridge()
  const isCommRound = useIsCommunityRound()

  if (isMobile && !isPresale && !isCommRound && !isBridge) {
    return (
      <MobileWrapper>
        {!account && <LogoImg width={'auto'} src={Logo} alt="logo" />}
        <AlarmBox>
          <WeAreWorking>
            <Trans>We are working on mobile version for this page</Trans>
          </WeAreWorking>
          <AlarmText>
            Please visit on desktop
            <br></br>
            to get started.
          </AlarmText>
        </AlarmBox>
        <Hidden>{children}</Hidden>
      </MobileWrapper>
    )
  }
  return (
    <>
      {account ? (
        !notSupportedChain ? (
          children
        ) : (
          <FightBanner />
        )
      ) : isBridge ? (
        <SwapBanner>{children}</SwapBanner>
      ) : isPresale ? (
        <CommRound>{children}</CommRound>
      ) : (
        <FightBanner />
      )}
    </>
  )
}

const CommRound = ({ children }: { children: any }) => (
  <>
    <StyledRowWrapper>
      <ContentWarningStyled>
        <Left>
          <Title>
            <Trans>ZooDAO&#8217;s</Trans>
            <br />
            <Trans>Community</Trans>
            <br />
            <Trans>Round</Trans>
          </Title>

          <TextConnectStyled>
            <Trans>Open Token Sale</Trans>
          </TextConnectStyled>
          <Web3StatusStyled text={<Trans>Contribute Now</Trans>} />
        </Left>
      </ContentWarningStyled>
    </StyledRowWrapper>
    <Hidden>{children}</Hidden>
  </>
)
const SwapBanner = ({ children }: { children: any }) => (
  <>
    <StyledRowWrapper>
      <ContentWarningStyled>
        <Left>
          <Title>
            <Trans>ZooDAO&#8217;s</Trans>
            <br />
            <Trans>Cross-chain Swap</Trans>
            <br />
            <Trans>& Bridge</Trans>
          </Title>

          <TextConnectStyled>
            {!isMobile && <Trans>Powered by</Trans>}
            <ImgProject src={Project1} />
            <ImgProject src={Project2} />
            <ImgProject src={Project3} />
          </TextConnectStyled>
          <Web3StatusStyled text={<Trans>Connect Wallet</Trans>} />
        </Left>
      </ContentWarningStyled>
    </StyledRowWrapper>
    <Hidden>{children}</Hidden>
  </>
)

const FightBanner = () => (
  <StyledRow>
    <ContentWarningStyled>
      <Left>
        <Title>
          <Trans>Fight for your opponent&#8217;s</Trans>
          <br />
          <Trans>earnings</Trans>
        </Title>

        <TextConnect style={{ fontWeight: '500' }}>
          <Trans> ZooDAO is a no-loss NFT Battle Platform powered by DeFi yields.</Trans>
          <br />
          <br />
          <StakeVoteEarn>
            <Trans> Stake, Vote, Earn.</Trans>
          </StakeVoteEarn>
        </TextConnect>

        <Web3StatusStyled text={<Trans>Let&#8217;s Battle</Trans>} />
      </Left>
      <Right>
        <ContainerExpl>
          <CurText>
            <Trans>Currently</Trans>
          </CurText>
          
          <TextGradientStyled>$ 3,000</TextGradientStyled>

          <Explanation>
            <Trans>To win every</Trans>
            <br />
            <Trans>Battle Season</Trans>
          </Explanation>
        </ContainerExpl>
      </Right>
    </ContentWarningStyled>
  </StyledRow>
)

const Hidden = styled.div`
  display: none;
  height: 0px;
`

export const AlmostHere = () => {
  const { chainId } = useActiveWeb3React()

  const handleChainSwitch = useHandleChainSwitch()

  const isYieldFarm = useIsYieldFarm()
  const isCommunityRound = useIsCommunityRound()

  const toggleChain = useCallback(() => {
    handleChainSwitch(isYieldFarm ? SupportedChainId.MAINNET : SupportedChainId.MOONBEAM, true)
  }, [handleChainSwitch, isYieldFarm])

  if (chainId !== SupportedChainId.MOONBEAM && isCommunityRound) {
    return (
      <ConfirmBlock>
        <Suggestion>
          <Trans>Page is working on the Moonbeam Network. Please Switch to the Moonbeam Network to continue</Trans>
        </Suggestion>

        <SwitchBtn onClick={toggleChain} active>
          <Trans>Switch Network</Trans>
        </SwitchBtn>
      </ConfirmBlock>
    )
  }

  if (chainId !== SupportedChainId.MOONBEAM) {
    return (
      <ConfirmBlock>
        <Suggestion>
          <Trans>ZooDAO&apos;s NFT Battles are on the Moonbeam Network. Please Switch to the Moonbeam Network</Trans>
        </Suggestion>

        <SwitchBtn onClick={toggleChain} active>
          <Trans>Switch Network</Trans>
        </SwitchBtn>
      </ConfirmBlock>
    )
  }

  return null
}

const SwitchBtn = styled(BtnSwitch)`
  margin-left: 83px;
  width: initial;
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 50px;
  padding: 16px 36px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin: 28px 0 0 0;
      width: 100%;
    `};
`

const Suggestion = styled.div`
  font-weight: bold;
`

const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.red};
  font-family: ${({ theme }) => theme.fontUniform};
  width: 100%;
  height: 70px;
  border-radius: 0 0 24px 24px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      flex-direction: column;
      border-radius: 24px 24px 0 0 ;
      height: auto;
    `};
`

interface IProps {
  children?: React.ReactNode
}

export const isTestnet = (chainId?: SupportedChainId) =>
  chainId === SupportedChainId.KOVAN ||
  chainId === SupportedChainId.RINKEBY ||
  chainId === SupportedChainId.GOERLI ||
  chainId === SupportedChainId.MOONBEAM_ALPHA

export const useWarningFlag = () => {
  const { account, chainId } = useActiveWeb3React()
  const isBridgePage = useIsBridge()

  const { targetToken } = useActiveBridge()

  if (!chainId) {
    return {
      notSupportedChain: false,
      account,
    }
  }

  if (isBridgePage || targetToken) {
    return {
      notSupportedChain: false,
      account,
    }
  }

  return { notSupportedChain: chainId !== SupportedChainId.MOONBEAM, account }
}
