import { Trans } from '@lingui/macro'
import Loading from 'components/Loading'
import { useBattleArenaContract } from 'constants/zoodao'
import { useBattleEpoch, useIsRandomRequested } from 'pages/NftBattlesPage/hooks'
import { useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import styled from 'styled-components/macro'
import { formatDecimal } from 'utils/numberWithCommas'
import { useJackpotUSD } from 'pages/NftBattlesPageV2/cards/JackpotsNFTOverview/hooks'
import {
  CardContentBox,
  CardHead,
  CardHeadBox,
  CardMiniForBattleBoard,
  InfoText,
  NumberText,
} from './CardMiniForNftInfo'
import { CardLine } from './styleds'
import { useStableCurrency } from 'hooks/Tokens'

const CardContentBoxRow = styled(CardContentBox)<{ pushDown?: boolean; noBottomMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-block-end: 10px;
  align-items: baseline;
  margin-block-start: ${(props) => (props.pushDown ? 'auto' : '0')};
  margin-block-end: ${(props) => (props.noBottomMargin ? '0' : '10px')};
`

const JACKPOTS_MULTIPLIER = 200

export const CardRewardsEpoch = ({ epoch, label }: { epoch: number; label?: any }) => {
  const currentEpoch = useBattleEpoch()
  const deps = useMemo(() => [epoch], [epoch])
  const battleC = useBattleArenaContract()

  const { loading: loadingWell, result: wellRewards } = useSingleCallResult(battleC, 'wellClaimedByEpoch', deps)
  const { loading: loadingGlmr, result: glmrRewards } = useSingleCallResult(battleC, 'glmrClaimedByEpoch', deps)

  const { rewards: jackpots, loading: loadingJackpots } = useJackpotUSD(epoch)
  const { stableCryptoSymbol } = useStableCurrency()

  const requested = useIsRandomRequested()

  const hasRewards = currentEpoch !== epoch || (currentEpoch === epoch && requested)
  return (
    <CardMiniForBattleBoard inactive={!hasRewards}>
      <CardLine index={17} />
      <CardHeadBox style={{ marginBottom: '16px' }}>
        <CardHead>{label || <Trans>Season Rewards</Trans>}</CardHead>
      </CardHeadBox>
      {!hasRewards ? (
        <CardContentBoxRow pushDown>
          <InfoText>Not available yet</InfoText>
        </CardContentBoxRow>
      ) : (
        <>
          <CardContentBoxRow>
            <NumberText>
              <Loading loading={loadingJackpots || !jackpots}>
                {formatDecimal(jackpots.mul(JACKPOTS_MULTIPLIER), 2)}
              </Loading>
            </NumberText>
            &nbsp;
            <InfoText>
              <Trans>{stableCryptoSymbol}</Trans>
            </InfoText>
          </CardContentBoxRow>
          <CardContentBoxRow>
            <NumberText>
              <Loading loading={loadingWell || !wellRewards}>{wellRewards && formatDecimal(wellRewards[0], 2)}</Loading>
            </NumberText>
            &nbsp;
            <InfoText>
              <Trans>WELL</Trans>
            </InfoText>
          </CardContentBoxRow>
          <CardContentBoxRow noBottomMargin>
            <NumberText>
              <Loading loading={loadingGlmr || !glmrRewards}>{glmrRewards && formatDecimal(glmrRewards[0], 2)}</Loading>
            </NumberText>
            &nbsp;
            <InfoText>
              <Trans>wGLMR</Trans>
            </InfoText>
          </CardContentBoxRow>
        </>
      )}
    </CardMiniForBattleBoard>
  )
}
