import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { ButtonGradient } from '../Button'
import { Dots } from '../swap/styleds'
import {
  BATTLE_STAGES,
  useBattleStage,
  useChooseWinners,
  useBattleVrfRequest,
  useIsRandomRequested,
  useIsRandomFullfilled,
} from '../../pages/NftBattlesPage/hooks'
import Delimiter2 from '../../assets/images/battles/shield.png'
import WinnerGif from './../../assets/images/battles/shield.gif'

const ButtonGradientRoll = styled(ButtonGradient)`
  width: 100%;
  margin-left: 0px;
`

const ChooseWinners = ({ pairIndex, el: El = ButtonGradientRoll }: { pairIndex: string; el?: any }) => {
  const { onRequestVrf, pending: pendingRandomizer } = useBattleVrfRequest()

  const requested = useIsRandomRequested()
  const fullfilled = useIsRandomFullfilled()

  const { onChoose, pending } = useChooseWinners(pairIndex)

  const stage = useBattleStage()

  if (stage !== BATTLE_STAGES.FIFTH) {
    return (
      <El disabled>
        <Trans>Choosing of winners available only at fifth stage</Trans>
      </El>
    )
  }

  return (
    <El
      disabled={pendingRandomizer || pending || (requested && !fullfilled)}
      onClick={requested ? onChoose : onRequestVrf}
    >
      {pending || pendingRandomizer ? (
        <Dots>{pendingRandomizer ? <Trans>Requesting</Trans> : <Trans>Rolling</Trans>}</Dots>
      ) : requested ? (
        !fullfilled ? (
          <Dots>
            <Trans>Waiting</Trans>
          </Dots>
        ) : (
          <Trans>Roll the Dice</Trans>
        )
      ) : (
        <Trans>Request VRF</Trans>
      )}
    </El>
  )
}

const windowInnerWidth = window.innerWidth

let NavBarLeftWidth = 300
if (windowInnerWidth < 1600) {
  NavBarLeftWidth = 200
}
const WidthShield = (windowInnerWidth - NavBarLeftWidth - 50 - 5 - 50) / 3
const HeightShield = WidthShield - 100

const ImgDelimiterBox = styled.div`
  align-self: center;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 400px;
  margin: 0 32px;
  height: ${HeightShield}px;
`

const ImgDelimiter = styled.img`
  max-height: ${WidthShield}px;
  margin-right: -10px;
  margin-top: 0px;
  ${({ theme }) => theme.mediaWidth.upToProSmall`
    margin-top: 0px;
  `}
`

const ImgContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WinnersChoosingGif = ({ positionIndex, onClick }: { positionIndex: string; onClick?: any }) => {
  const { onChoose, pending } = useChooseWinners(positionIndex)

  const [canToggle, setCanToggle] = useState(false)
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    if (pending) {
      setCanToggle(true)
    } else {
      if (canToggle && !finished && !pending) {
        setFinished(true)
      }
    }

    const tId = setTimeout(() => {
      if (finished) {
        setFinished(false)
      }
    }, 4200)

    return () => clearTimeout(tId)
  }, [pending, canToggle, finished])

  return (
    <ImgDelimiterBox onClick={onClick || onChoose}>
      <ImgContainer>
        <ImgDelimiter src={finished ? WinnerGif : Delimiter2} />
      </ImgContainer>
    </ImgDelimiterBox>
  )
}

export default ChooseWinners
