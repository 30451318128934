import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { CardHeader, CardContent, CardDescription, DashboardCardFlex, CardLine } from '../styleds'
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from '../../../components/Button'
import bgImg from './../../../assets/images/fire-bg.png'
import { Paths } from '../../../constants/paths'
import Modal from '../../../components/Modal'
import { useNftInfo } from '../../../hooks/zoodao/useNftInfo'
import { CloseIcon } from '../../../theme'
import { ButtonBoost } from 'components/Button'

const CardHeaderStyled = styled(CardHeader)`
  flex-direction: row;
  justify-content: space-between;
`

export const DashboardCardFlexStyled = styled(DashboardCardFlex)`
  padding: 25px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url('${bgImg}') 50% 50% / cover no-repeat;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 55px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 25px;
  `};
`

const CardDescriptionStyled = styled(CardDescription)`
  margin-top: 10px;
  color: #fff;
  font-weight: 500;
`

const Btns = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: end;
  align-items: end;
`
const ButtonBoostDisabled = styled(ButtonBoost)`
  // opacity: 0.4;
  cursor: not-allowed;
  background-color: #2d2137;
  border: 2px solid #f64562;
  color: #f64562;
`

const PoolBtn = ({ onClick }: { onClick: () => void }) => {
  return (
    <Btns>
      {/* <ButtonBoost onClick={onClick}>
        <Trans>Soon</Trans>
      </ButtonBoost> */}
      <ButtonBoostDisabled onClick={onClick}>
        <Trans>Soon</Trans>
      </ButtonBoostDisabled>
    </Btns>
  )
}

const FireNFTRewards = () => {
  const history = useHistory()

  const isParticipated = useNftInfo()

  function goToNft() {
    history.push(Paths.NFT_STAKING)
  }

  return (
    <DashboardCardFlexStyled>
      <CardLine index={1} />
      <CardHeaderStyled>
        <Trans>NFT Rewards</Trans>
      </CardHeaderStyled>

      <CardContent>
        <CardDescriptionStyled>
          <Trans>Unlock ZooDAO Fire Cards by staking any</Trans>
          <br></br>
          <Trans>NFT in the Staking Pool.</Trans>
        </CardDescriptionStyled>
        {!isParticipated ? <PoolBtn onClick={goToNft} /> : <FireModal />}
      </CardContent>
    </DashboardCardFlexStyled>
  )
}

const ModalStyled = styled(Modal)`
  max-width: 767px !important;
`

const ModalContent = styled.div`
  padding: 40px 50px;
  width: 100%;
`

const ModalTitle = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.75px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`

const ModalDescription = styled.div`
  margin: 20px 0 40px 0;
  color: ${({ theme }) => theme.text2};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
`

export const ButtonLiquidityStyled = styled(ButtonEmpty)`
  margin-top: auto;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.red};
  width: initial;
  padding: 25px;

  &:focus {
    text-decoration: none;
  }

  font-family: ${({ theme }) => theme.fontUniform};
  letter-spacing: 0.02em;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 25px;
    font-size: 24px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 20px;
    font-size: 18px;
    line-height: 18px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 16px;
    font-size: 15px;
    line-height: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    padding: 13px 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 13px 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 13px 15px;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  `};
`

const CloseIconStyled = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.text2};
`

const FireModal = () => {
  const [isOpen, setOpen] = useState(false)

  function wrappedonDismissHandler() {
    setOpen(false)
  }

  const history = useHistory()

  const goToLiquidity = () => {
    history.push(Paths.FARMING)
  }

  return (
    <>
      <PoolBtn onClick={() => setOpen(true)} />
      <ModalStyled isOpenFlag={isOpen} onDismissHandler={wrappedonDismissHandler} maxHeight={90}>
        <ModalContent>
          <ModalTitle>
            <Trans>You do not have enough ZOO to stake your NFTs</Trans>

            <CloseIconStyled onClick={wrappedonDismissHandler} />
          </ModalTitle>

          <ModalDescription>
            <Trans>Please create your first Yield Farm</Trans>
          </ModalDescription>

          <ButtonLiquidityStyled onClick={goToLiquidity}>
            <Trans>Add Liquidity</Trans>
          </ButtonLiquidityStyled>
        </ModalContent>
      </ModalStyled>
    </>
  )
}

export default FireNFTRewards
