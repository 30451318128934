import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { Contract } from '@ethersproject/contracts'
import { Currency } from '@uniswap/sdk-core'
import { ButtonLight } from '../../components/Button'
import { CurrencyInputTemplate } from '../../components/CurrencyInputPanel'
import { useActiveWeb3React } from '../../hooks/web3'
import { useCurrency } from '../../hooks/Tokens'
import { useBattleStakerAddress, useBattleStakerContract } from '../../constants/zoodao'
import { CurrencySearchTemplate } from '../../components/SearchModal/CurrencySearchModal'
import { BATTLE_STAGES, useAllowedForStaking, useBattleStage } from './hooks'
import { CardContainer } from '../../components/EpochBanner/EpochBanner'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useCreatedStakerPositions } from 'hooks/zoodao/useBattlesEventsData'
import { BigNumber } from 'ethers'
import { UnstakeBattleNft } from 'components/UnstakeBattleNft/UnstakeBattleNft'
import { SuggestedBattlesNfts } from 'pages/NftStaking/SuggestedNfts/SuggestedNfts'
import { CardHeadTitle } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { ApproveStakingNfts } from './ApproveStakingNfts'
import { useWalletModalToggle } from 'state/application/hooks'

const UnstakeBattleNftStyled = styled(UnstakeBattleNft)`
  &:disabled {
    background-color: #2d2137;
    border: 2px solid #f64562;
    color: #f64562;
  }

  margin-left: 16px;
`
const CardContainerNew = styled(CardContainer)`
  height: inherit;
`
const ContainerForStaking = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
`

const BoxForBtns = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
`
const GradBox = styled.div`
  background: linear-gradient(90deg, rgb(43, 35, 48) 0%, rgb(45, 33, 55) 50%, rgb(40, 31, 60) 100%);
  border-radius: 18px;
  display: flex;
  max-height: 50px;
  min-height: 50px;
  width: 100%;
  margin-top: 25px;
  align-items: center;
  text-align: center;
  justify-content: center;
`
const GradText = styled.div`
  background: linear-gradient(90deg, #f56738 0%, #f64562 50%, #e138f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Uniform Pro;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`

export function useUnstakePossibility(tokenId: string | BigNumber, nftContract: Contract | null) {
  const [canUnstake, setCanUnstake] = useState(false)
  const nftPoolContractAddress = useBattleStakerAddress()

  const { account } = useActiveWeb3React()
  const deps = useMemo(() => [tokenId], [tokenId])
  const target = useSingleCallResult(nftContract, 'ownerOf', deps)?.result?.[0]

  useEffect(() => {
    setCanUnstake(target === nftPoolContractAddress)
  }, [target, account, nftPoolContractAddress])

  return canUnstake
}

interface IProps {
  selectedNftAddress: string
  setSelectedNftAddress: (a: string) => void
  setTokenId: (t: string) => void
  tokenId: string | undefined
}

const UnstakeBtn = ({
  tokenId,
  contract,
  staked,
  loadingStaked,
}: {
  tokenId: string | undefined
  contract: string
  loadingStaked: boolean
  staked: any[]
}) => {
  const item = useMemo(() => {
    return staked.find((item) => item.token === contract && item.id.toString() === tokenId)
  }, [staked, contract, tokenId])

  return tokenId ? (
    <UnstakeBattleNftStyled
      stakingPositionId={item?.stakingPositionId.toString()}
      tokenId={tokenId || ''}
      tokenAddress={contract}
      disabled={loadingStaked}
    />
  ) : null
}

export default function NftBattleStakingInner({
  selectedNftAddress,
  setSelectedNftAddress,
  tokenId,
  setTokenId,
}: IProps) {
  const baseCurrency = useCurrency(selectedNftAddress)

  const onFieldAInput = useCallback(
    (v: string) => {
      setTokenId(v)
    },
    [setTokenId]
  )

  const onCurrencySelect = useCallback(
    (v: Currency) => {
      if (v) {
        // @ts-ignore
        setSelectedNftAddress(v.address)
      }
    },
    [setSelectedNftAddress]
  )

  const onTokenInputChange = useCallback(
    (v: string) => {
      if (v) {
        // @ts-ignore
        setSelectedNftAddress(v)
      }
    },
    [setSelectedNftAddress]
  )

  // check whether the user has approved the router on the tokens

  const defined = tokenId !== undefined && selectedNftAddress

  return (
    <>
      <ContainerForStaking>
        <CardContainerNew style={{ padding: 0 }}>
          <Header style={{ padding: '25px 25px 0 25px' }}>
            <CardHeadTitle>
              <Trans>Stake NFTs</Trans>
            </CardHeadTitle>
          </Header>

          <Suggestions setSelectedNftAddress={setSelectedNftAddress} setTokenId={setTokenId} />
        </CardContainerNew>
      </ContainerForStaking>

      <ContainerForStaking>
        <CardContainerNew>
          <Header>
            <CardHeadTitle>
              <Trans>Import Manually</Trans>
            </CardHeadTitle>
          </Header>

          <CurrencyInputTemplate
            as={CurrencySearchTemplate}
            value={tokenId !== undefined && tokenId ? `${tokenId}` : ''}
            showCommonBases={false}
            onUserInput={onFieldAInput}
            showMaxButton={false}
            currency={baseCurrency}
            id="add-liquidity-input-tokena"
            onCurrencySelect={onCurrencySelect}
            onTokenInputChange={onTokenInputChange}
            hideBalance
            placeholder={'Paste NFT ID here'}
            isProMode={false}
          />

          <Buttons tokenId={tokenId} defined={defined} selectedNftAddress={selectedNftAddress} />
        </CardContainerNew>
      </ContainerForStaking>
    </>
  )
}

const Suggestions = ({
  setSelectedNftAddress,
  setTokenId,
}: {
  setSelectedNftAddress: (a: string) => void
  setTokenId: (t: string) => void
}) => {
  const nftPoolContract = useBattleStakerContract()
  return (
    <>
      {nftPoolContract && (
        <SuggestedBattlesNfts
          onSelect={(address, tokenId) => {
            setSelectedNftAddress(address)
            setTokenId(tokenId)
          }}
          contract={nftPoolContract}
        />
      )}
    </>
  )
}

const Buttons = ({
  selectedNftAddress,
  tokenId,
  defined,
}: {
  selectedNftAddress: any
  tokenId?: string
  defined: boolean | string
}) => {
  const { allowedForStaking, loading } = useAllowedForStaking(selectedNftAddress)
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const { staked, loading: loadingStaked } = useCreatedStakerPositions()

  const stage = useBattleStage()

  const nftPoolContract = useBattleStakerContract()

  if (!allowedForStaking && !loading) {
    if (!defined) return null

    return (
      <GradBox>
        <GradText>
          <Trans>Staking is not allowed for this project!</Trans>
        </GradText>
      </GradBox>
    )
  }

  return !account ? (
    <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
      <Trans>Connect Wallet</Trans>
    </ButtonLight>
  ) : (
    <BoxForBtns>
      {nftPoolContract && (
        <ApproveStakingNfts
          nftAddress={selectedNftAddress}
          contract={nftPoolContract}
          tokenId={tokenId}
          disabled={stage !== BATTLE_STAGES.FIRST}
        />
      )}
      {stage === BATTLE_STAGES.FIRST && (
        <UnstakeBtn tokenId={tokenId} contract={selectedNftAddress} staked={staked} loadingStaked={loadingStaked} />
      )}
    </BoxForBtns>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-wrap: wrap;
  align-items: center;
`
