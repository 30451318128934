// a list of tokens by chain
import { Currency, Token } from '@uniswap/sdk-core'
import { SupportedChainId } from './chainsinfo'
import {
  AMPL,
  DAI,
  FEI,
  FRAX,
  FXS,
  renBTC,
  TRIBE,
  USDC,
  USDT,
  WBTC,
  ETH2X_FLI,
  WETH9_EXTENDED,
  DAI_OPTIMISM,
  USDT_OPTIMISM,
  WBTC_OPTIMISM,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WETH_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WETH9_EXTENDED).map(([key, value]) => [key, [value]])
)

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [SupportedChainId.MAINNET]: [...WETH_ONLY[SupportedChainId.MAINNET], DAI, USDC, USDT, WBTC],
  [SupportedChainId.OPTIMISM]: [...WETH_ONLY[SupportedChainId.OPTIMISM], DAI_OPTIMISM, USDT_OPTIMISM, WBTC_OPTIMISM],
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.MAINNET]: {
    '0xF16E4d813f4DcfDe4c5b44f305c908742De84eF0': [ETH2X_FLI],
    [FEI.address]: [TRIBE],
    [TRIBE.address]: [FEI],
    [FRAX.address]: [FXS],
    [FXS.address]: [FRAX],
    [WBTC.address]: [renBTC],
    [renBTC.address]: [WBTC],
  },
}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH9_EXTENDED[SupportedChainId.MAINNET]],
  },
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.ROPSTEN]: [],
  [SupportedChainId.KOVAN]: [],
  [SupportedChainId.RINKEBY]: [],
  [SupportedChainId.GOERLI]: [],
  [SupportedChainId.FANTOM]: [],
  [SupportedChainId.MOONBEAM]: [],
  [SupportedChainId.MOONBEAM_ALPHA]: [],
  [SupportedChainId.MOONBEAM]: [
    new Token(SupportedChainId.MOONBEAM, '0x8fBE243D898e7c88A6724bB9eB13d746614D23d6', 18, 'GLMA', 'GLMR Apes'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x139e9BA28D64da245ddB4cF9943aA34f6d5aBFc5',
      18,
      'CNA',
      'Canary Network Agency'
    ),
    new Token(SupportedChainId.MOONBEAM, '0x2159762693C629C5A44Fc9baFD484f8B96713467', 18, 'MOONPETS', 'MOONPETS'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x02A6DeC99B2Ca768D638fcD87A96F6069F91287c',
      18,
      'MFBB',
      'MoonFit Beast and Beauty'
    ),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x87C359d86bD8C794124090A82E0930c3Cab88F01',
      18,
      'DEMON',
      'Devious Demon Dudes'
    ),
    new Token(SupportedChainId.MOONBEAM, '0xeb628551b94ef928b8d32b5f94f9c9c1e8599652', 18, 'RMICE', 'Rando Mice'),
    new Token(SupportedChainId.MOONBEAM, '0xf25a1cf5be78a1c57b306ad5999f255bfd2d9225', 18, 'PURREVIL', 'Purr Evil'),
    new Token(SupportedChainId.MOONBEAM, '0xd0F8F69FF554f4bDb1fF0c877DaFEbF74628E791', 18, 'MEC', 'Meta Eagle Club'),

    new Token(SupportedChainId.MOONBEAM, '0x648763838b02054A237bdF86Cc1B70238cb50aF5', 18, 'PartyBears', 'Party Bears'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x756A9DC8181ABea18c2CC6575DCFcdD2c604aB6F',
      18,
      'LYKEIN',
      'Lyke Inhabitants'
    ),
    new Token(SupportedChainId.MOONBEAM, '0x5bd95E49994B4D637a3511B50c17ac115Dc31Fe8', 18, 'MNFT', 'Metheors'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x145B65171c064738F65c99bfC59042a957aC5182',
      18,
      'WBSC',
      'wulf boy social club'
    ),
    new Token(SupportedChainId.MOONBEAM, '0xEC66944391b743D87A3146905C2B9b8b85B2bDf1', 18, 'dino', 'tiny dinos'),
    new Token(SupportedChainId.MOONBEAM, '0x06D1a42382B2196eedDe76e0C90C3A49eE97233B', 18, 'MUTCATS', 'MutantCats'),
    new Token(SupportedChainId.MOONBEAM, '0xe5d6806396d4679Ab21D4BaB3fb5e724Fe82f6a0', 18, 'PWIZ', 'PixaWizards'),
    new Token(SupportedChainId.MOONBEAM, '0x958B6289e048C5BC21F3c9c61e58022d2358d06F', 18, 'MORPH', 'Polymorphs'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x8953356fb37cee93C601c48B249264923B2fFf66',
      18,
      'NIBIRU',
      'MetaTravelers: Nibiru'
    ),
    new Token(SupportedChainId.MOONBEAM, '0xC27FB62c388Ac0f5e55B91D6491f4f469e56Bf71', 18, 'MNSTRS', 'MonsterShelter'),
    new Token(SupportedChainId.MOONBEAM, '0x6ec049DB1142a51F13967A2C04143255CC99410C', 18, 'AMC', 'MoonCats'),
    new Token(SupportedChainId.MOONBEAM, '0xec17cD142795431B45b6FEce06B8b3682621A84C', 18, 'HC', 'HolyCows'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x3EdDc6f8C1cf8a6d7b95C456Bba80887226533AA',
      18,
      'HOTM',
      'Humans of the Metaverse'
    ),
    new Token(SupportedChainId.MOONBEAM, '0x80f7931E971317402002c1BE3B582867B9A24828', 18, 'LOBSTER', 'Lobby Lobsters'),
    new Token(SupportedChainId.MOONBEAM, '0xE9fDF067327411AaE71D6869631767926f600Ac6', 18, 'LUCHADORES', 'Luchadores'),
    new Token(SupportedChainId.MOONBEAM, '0x357FbA23587e47C6Ed25f0B3650Ed3F86e046627', 18, 'FLUF', 'FLUF'),
    new Token(SupportedChainId.MOONBEAM, '0x207F3F8ff92312DCB092f4087CdBeC3626E26cd4', 18, 'Dork', 'DumpsterDorks'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0xCd49e7b7515f053A07f2b577b6e87Cfd9B2589FC',
      18,
      'Dork_S2',
      'DumpsterDorks S2'
    ),
    new Token(SupportedChainId.MOONBEAM, '0xE665F4Cb94D176641E6c79f7dDcd7590d7cCb647', 18, 'ELVN', 'ELVEN'),
    new Token(SupportedChainId.MOONBEAM, '0x6f126696447A2B32D9A3160f5bB734d039B67B5A', 18, 'LIZARD', 'Ethlizards'),
    new Token(SupportedChainId.MOONBEAM, '0x43DF558DE9F4619600956fF7AC1894F74d26BBc6', 18, 'FB', 'FancyBirds'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0xF348800916C2e84c7B1630EB16FB0eFc52F3c235',
      18,
      'DFR',
      'Dead Freaks Resurrection'
    ),
    new Token(SupportedChainId.MOONBEAM, '0x4316f538ED997e2e92aB92ee5C1b9F126e21578e', 18, 'COSMOS', 'Cosmos Kidz'),
    new Token(SupportedChainId.MOONBEAM, '0xBc6219FC9f3521A8A566359906D6D0689c53DAcC', 18, 'MW', 'Moonwalkers'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x8C3c07B7Edd2F0BE5b4Ed8064Ec722Da57F9A9d2',
      18,
      'AlleyKatzOfficial',
      'Alley Katz'
    ),
    new Token(SupportedChainId.MOONBEAM, '0x62fA8dE3e52541e9e0cc07bf577e1BCEfD0aDAB6', 18, 'PACA', 'ALPACADABRAZ'),

    new Token(SupportedChainId.MOONBEAM, '0x81C60843A985636186CfdC8162Ec548c75aA42c9', 18, 'FPB', 'Fluffy Polar Bears'),
    new Token(SupportedChainId.MOONBEAM, '0x16688d4047021755b7358f515864f706b1d24405', 18, 'LSS', 'Lost Souls'),
    new Token(SupportedChainId.MOONBEAM, '0xcBdcb6Ff9F6A80173EFb63Af006a36446946A95a', 18, 'HBZ', 'The Habibiz'),
    new Token(SupportedChainId.MOONBEAM, '0x332d68561a7aee5879a369411e059856d4580094', 18, 'FF', 'Feline Fiendz'),

    new Token(SupportedChainId.MOONBEAM, '0x129f156FC1EF1EDF48186a123804B1510637F71B', 18, 'MM', 'Mecha Melters'),
    new Token(SupportedChainId.MOONBEAM, '0xA521e4cAe215cb56DF9Cf09b8A81a113A16C7C11', 18, 'CC', 'Creepy Creams'),

    new Token(SupportedChainId.MOONBEAM, '0x43BaA338734C1387391bb3C9a8Dd6952C46D1223', 18, 'TOSHIES', 'TOSHIES'),
    new Token(SupportedChainId.MOONBEAM, '0x135092F51D80E92185f8dA8a400b3828b3c4C094', 18, 'TABOY', 'The Alien Boy'),
    new Token(SupportedChainId.MOONBEAM, '0xa29b7e9dC598bFF4034758d91E0272d62ED6d3fB', 18, 'SRLS', 'The Surreals'),

    new Token(SupportedChainId.MOONBEAM, '0x0f00f81162abc95ee6741a802a1218c67c42e714', 18, 'DA', 'Divine Anarchy'),
    new Token(SupportedChainId.MOONBEAM, '0xc75deb97ca0afcb9f25c38d72ad34f22d6c58260', 18, 'gg', 'Gh0stlyGh0sts'),
    new Token(SupportedChainId.MOONBEAM, '0xbE1F1F4E1A4907d3a6debc110E1E9f551909c89C', 18, 'ZDC', 'ZooDAO Gen 0 Cards'),
    new Token(SupportedChainId.MOONBEAM, '0xe2bF99A2714e6ae3132c7C2bAA59756D09f59Ea2', 18, 'SBSH', 'SHIBOSHIS'),
    new Token(SupportedChainId.MOONBEAM, '0xA5c8C3EF88916E4f0D2A55E1F80Df8C5bBf5617D', 18, 'TOADZ', 'Cryptoadz'),
    new Token(
      SupportedChainId.MOONBEAM,
      '0x382c957863f0c3e53d9ebff713d171423c7f3e70',
      18,
      'ZDCP',
      'ZooDAO Gen P Cards'
    ),
    new Token(SupportedChainId.MOONBEAM, '0x2A314f5611BA26D947b346537AEB685f911fc26A', 18, 'CB', 'CultBears'),
    new Token(SupportedChainId.MOONBEAM, '0xd6E8A1d5E0675168aF078391Ec3AbD983Eb18EA0', 18, 'Zoombie', 'Zoombies'),
    new Token(SupportedChainId.MOONBEAM, '0x803dD875bC19524C40674397c565Ee0ea61864FF', 18, 'OMM', 'Old Master Memes'),
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [SupportedChainId.MAINNET]: [...WETH_ONLY[SupportedChainId.MAINNET], DAI, USDC, USDT, WBTC],
}
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [SupportedChainId.MAINNET]: [
    [
      new Token(SupportedChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(
        SupportedChainId.MAINNET,
        '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
        8,
        'cUSDC',
        'Compound USD Coin'
      ),
    ],
    [USDC, USDT],
    [DAI, USDT],
  ],
}
