import React, { useState, useCallback, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import {
  CardContainer,
  DEFAULT,
  formatEpochItem,
  getDates,
  ILeftDays,
  SECOND,
} from '../../components/EpochBanner/EpochBanner'
import { useBattleEpoch, useBattlesData, useBattleStage } from '../NftBattlesPage/hooks'
import { CardLine, CardMedium, CardRow, CardTextStyled as CardText } from './styleds'
import ChooseWinners from '../../components/ChooseWinners/ChooseWinners'

const CardTextStyled = styled(CardText)`
  max-width: 240px;
`

const Left = styled.div``

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`

const CardLineStyled = styled(CardLine)`
  margin: 0;
  margin-right: 10px;
`

const Header = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
`
const CardHead = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.75px;
  display: flex;
  word-break: break-word;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 30px;
    line-height: 38px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 22px;
    line-height: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 18px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

export const useBattleEpochDates = () => {
  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const { epochStartDate, epochDuration } = useBattlesData()

  const calculateBorder = useCallback((): ILeftDays => {
    const date = new Date(epochStartDate * 1000)

    return getDates(date, epochDuration * 1000)
  }, [epochStartDate, epochDuration])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  const { days, hours, seconds, minutes, endDate } = border

  const isValid = days >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0

  return {
    isValid,
    days,
    hours,
    seconds,
    minutes,
    endDate,
  }
}

const Time = styled.div`
  display: flex;
`
const Time2 = styled.div`
  display: flex;
  justify-content: center;
`

const TimeItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

const TimeItemValue = styled.div`
  font-size: 22px;
  line-height: 30px;
  color: ${({ theme }) => theme.text1};
  font-weight: 500;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: normal;
  `};
`
const TimeItemLabelUR = styled.div`
  color: ${({ theme }) => theme.text2};
  margin-top: 7px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`
const TimeItemValueUR = styled.div`
  font-size: 30px;
  line-height: 35px;
  color: ${({ theme }) => theme.text1};
`

const TimeItemLabel = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  line-height: 20px;
`

const LockedLabel = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
`

const Locked = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`

export const getMonthLabel = (index: number) => {
  switch (index) {
    case 1: {
      return <Trans>Jan</Trans>
    }
    case 2: {
      return <Trans>Feb</Trans>
    }
    case 3: {
      return <Trans>Mar</Trans>
    }
    case 4: {
      return <Trans>Apr</Trans>
    }
    case 5: {
      return <Trans>May</Trans>
    }
    case 6: {
      return <Trans>Jun</Trans>
    }
    case 7: {
      return <Trans>Jul</Trans>
    }
    case 8: {
      return <Trans>Aug</Trans>
    }
    case 9: {
      return <Trans>Sep</Trans>
    }
    case 10: {
      return <Trans>Oct</Trans>
    }
    case 11: {
      return <Trans>Nov</Trans>
    }
    default: {
      return <Trans>Dec</Trans>
    }
  }
}

const BoxBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  width: 100%;
`

export const BattleClosing = () => {
  const currentStage = useBattleStage()

  const epoch = useBattleEpoch()

  const data = useBattleEpochDates()

  return (
    <CardContainer>
      <CardRow>
        <Header>
          <CardTextStyled>
            <Trans>Season {epoch} ends in</Trans>
          </CardTextStyled>

          <Row>
            <CardLineStyled index={1} />
            <span>
              <Trans>Stage</Trans> {currentStage + 1}
            </span>
          </Row>
        </Header>

        <RenderDates data={data} />
      </CardRow>
    </CardContainer>
  )
}

export const RenderDates = ({
  showLocked = true,
  showLabels = false,
  forceShow = false,
  showSeconds = false,
  className,
  data,
}: {
  forceShow?: boolean
  showLocked?: boolean
  showLabels?: boolean
  className?: string
  showSeconds?: boolean
  data: ILeftDays
}) => {
  const { days, hours, minutes, seconds, endDate: date, isValid, noTime } = data

  const showDays = days !== 0

  if (noTime && !forceShow) {
    return null
  }

  return (
    <>
      {date ? (
        <Left className={className}>
          <Time>
            {showDays ? (
              <>
                <TimeItem>
                  <TimeItemValue>{formatEpochItem(days, isValid)}</TimeItemValue>
                  {showLabels && (
                    <TimeItemLabel>
                      <Trans>DAY</Trans>
                    </TimeItemLabel>
                  )}
                </TimeItem>

                <TimeItem>
                  <TimeItemValue>&nbsp;:&nbsp;</TimeItemValue>
                </TimeItem>
              </>
            ) : null}

            <TimeItem>
              <TimeItemValue>{formatEpochItem(hours, isValid)}</TimeItemValue>
              {showLabels && (
                <TimeItemLabel>
                  <Trans>HR</Trans>
                </TimeItemLabel>
              )}
            </TimeItem>

            <TimeItem>
              <TimeItemValue>&nbsp;:&nbsp;</TimeItemValue>
            </TimeItem>

            <TimeItem>
              <TimeItemValue>{formatEpochItem(minutes, isValid)}</TimeItemValue>
              {showLabels && (
                <TimeItemLabel>
                  <Trans>MIN</Trans>
                </TimeItemLabel>
              )}
            </TimeItem>

            {!showDays || showSeconds ? (
              <>
                <TimeItem>
                  <TimeItemValue>&nbsp;:&nbsp;</TimeItemValue>
                </TimeItem>

                <TimeItem>
                  <TimeItemValue>{formatEpochItem(seconds, isValid)}</TimeItemValue>
                  {showLabels && (
                    <TimeItemLabel>
                      <Trans>SEC</Trans>
                    </TimeItemLabel>
                  )}
                </TimeItem>
              </>
            ) : null}
          </Time>

          {showLocked ? (
            <Locked>
              <LockedLabel>
                <Trans>locked</Trans>
              </LockedLabel>
              &nbsp;
              {date.getDate()} {getMonthLabel(date.getMonth() + 1)} {date.getHours()}:{date.getMinutes()}
            </Locked>
          ) : null}
        </Left>
      ) : null}
    </>
  )
}

export const RenderDatesBattleInfo = ({
  className,
  data,
}: {
  showLocked?: boolean
  className?: string
  data: ILeftDays
}) => {
  const { days, hours, minutes, seconds, endDate: date, isValid, noTime } = data

  const showDays = days !== 0

  if (noTime) {
    return null
  }

  return (
    <>
      {date ? (
        <Left className={className}>
          <Time2>
            {showDays ? (
              <>
                <TimeItem>
                  <TimeItemValueUR>{formatEpochItem(days, isValid)}</TimeItemValueUR>
                  <TimeItemLabelUR>
                    <Trans>DAY</Trans>
                  </TimeItemLabelUR>
                </TimeItem>

                <TimeItem>
                  <TimeItemValueUR>&nbsp;:&nbsp;</TimeItemValueUR>
                </TimeItem>
              </>
            ) : null}

            <TimeItem>
              <TimeItemValueUR>{formatEpochItem(hours, isValid)}</TimeItemValueUR>
              <TimeItemLabelUR>
                <Trans>HR</Trans>
              </TimeItemLabelUR>
            </TimeItem>

            <TimeItem>
              <TimeItemValueUR>&nbsp;:&nbsp;</TimeItemValueUR>
            </TimeItem>

            <TimeItem>
              <TimeItemValueUR>{formatEpochItem(minutes, isValid)}</TimeItemValueUR>
              <TimeItemLabelUR>
                <Trans>MIN</Trans>
              </TimeItemLabelUR>
            </TimeItem>

            {!showDays ? (
              <>
                <TimeItem>
                  <TimeItemValueUR>&nbsp;:&nbsp;</TimeItemValueUR>
                </TimeItem>

                <TimeItem>
                  <TimeItemValueUR>{formatEpochItem(seconds, isValid)}</TimeItemValueUR>
                  <TimeItemLabelUR>
                    <Trans>SEC</Trans>
                  </TimeItemLabelUR>
                </TimeItem>
              </>
            ) : null}
          </Time2>
        </Left>
      ) : null}
    </>
  )
}

const CardContainerStyled = styled(CardMedium)`
  width: 100%;
  flex-grow: initial;
  margin: 0;
`

export const BattleClosing_2 = ({
  positionId,
  playedInEpoch = true,
}: {
  positionId: string
  playedInEpoch?: boolean
}) => {
  const currentStage = useBattleStage()

  if (playedInEpoch) {
    return null
  }

  return (
    <CardContainerStyled>
      <CardRow>
        <Header>
          <CardLine index={7} />
          <CardHeadBox>
            <CardHead>
              <Trans>Stage</Trans> {currentStage + 1}
            </CardHead>
          </CardHeadBox>
        </Header>
      </CardRow>
      {!playedInEpoch && (
        <BoxBtn>
          <ChooseWinners pairIndex={positionId} />
        </BoxBtn>
      )}
    </CardContainerStyled>
  )
}

const ColumnUntilResult = styled.div`
  display: grid;
  grid-template-column: 1fr;
  grip-gap: 25px;
  justify-content: center;
`
const CardHeadUntilResult = styled(CardHead)`
  color: ${({ theme }) => theme.text5};
  margin-bottom: 25px;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 35px;
  line-height: 45px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 35px;
  line-height: 45px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 35px;
  line-height: 45px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
  font-size: 35px;
  line-height: 45px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 35px;
  line-height: 45px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 35px;
  line-height: 45px;
  `}
`
const CardContainerUntilResult = styled(CardContainerStyled)`
  min-height: auto;
  background: none;
  box-shadow: none;
`

export const BattleClosingUntilResults = () => {
  const data = useBattleEpochDates()
  return (
    <CardContainerUntilResult>
      <ColumnUntilResult>
        <Header>
          <CardHeadBox>
            <CardHeadUntilResult>
              <Trans>Results In</Trans>
            </CardHeadUntilResult>
          </CardHeadBox>
        </Header>
        <RenderDatesBattleInfo data={data} />
      </ColumnUntilResult>
    </CardContainerUntilResult>
  )
}
