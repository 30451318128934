import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { IPanelJackpotsVariant } from '../ButtonPanel'
import { ButtonGradient } from 'components/Button'
import { Dots } from 'pages/Pool/styleds'
import { useJackpotAContract, useJackpotBContract, useJackpotContract } from 'constants/zoodao'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { useBattleEpoch } from 'pages/NftBattlesPage/hooks'
import { MouseoverTooltip } from 'components/Tooltip'
import { useSingleCallResult } from 'state/multicall/hooks'

const ButtonGradientRoll = styled(ButtonGradient)`
  margin-left: auto;
`

interface IProps {
  type: IPanelJackpotsVariant
  epoch: number
}

export const useChooseWinners = (epoch: number, type: IPanelJackpotsVariant) => {
  const a = useJackpotAContract()
  const b = useJackpotBContract()
  const target = type === IPanelJackpotsVariant.JackpotA ? a : b

  const dataFunc = useCallback(async () => {
    return await target?.populateTransaction.chooseWinner(epoch)
  }, [target, epoch])

  return useTxTemplate(
    `chooseJackpotWinner_${type}_${epoch}`,
    `Choose winner for Jackpot ${type} at epoch ${epoch}`,
    dataFunc,
    <Trans>No battles for this epoch</Trans>
  )
}

const MouseoverTooltipStyled = styled(MouseoverTooltip)`
  margin-left: auto;
`

const useIsChoosed = (epoch: number, type: IPanelJackpotsVariant) => {
  const contract = useJackpotContract(type)

  const deps = useMemo(() => [epoch], [epoch])
  const result = useSingleCallResult(contract, 'winners', deps)

  return !result.result?.[0].eq(0)
}

export const ChooseJackpotsWinner = ({ epoch, type }: IProps) => {
  const { pending, action } = useChooseWinners(epoch, type)
  const currentEpoch = useBattleEpoch()

  const isChoosed = useIsChoosed(epoch, type)
  const disabled = currentEpoch === +epoch || isChoosed

  if (!epoch) {
    return null
  }

  return (
    <MouseoverTooltipStyled text={<Trans>Choose Winner for epoch #{epoch}</Trans>} placement="bottom">
      <ButtonGradientRoll disabled={pending || disabled} onClick={action}>
        {pending ? (
          <Dots>
            <Trans>Rolling</Trans>
          </Dots>
        ) : isChoosed ? (
          <Trans>Rolled!</Trans>
        ) : (
          <Trans>Roll The Dice</Trans>
        )}
      </ButtonGradientRoll>
    </MouseoverTooltipStyled>
  )
}
