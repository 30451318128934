import styled from 'styled-components/macro'
import { Box, Button } from '../../../../components/MUI'

export const Container = styled(Box)`
  flex-direction: row;
  align-items: center;
  background-color: #33334b;
  border-radius: 26px;
  height: 52px;
`

export const TypeButton = styled(Button)<{ $active: boolean }>`
  width: 250px;
  height: 52px;
  border-radius: 26px;
  color: white;
  font-family: ${({ theme }) => theme.fontUniform};
  font-size: 18px;
  font-weight: 400;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 120px;
  `};

  ${({ $active }) =>
    $active &&
    ` &, &:hover {
        background-color: #F64562;
      }`}
`
