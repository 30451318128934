import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import { SupportedChainId } from '../../constants/chainsinfo'
import { shortenAddress, shortenTx } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { ExternalLinkStyled, TdId, Tr, Th, Td, TheadStyled, TbodyStyled, TableContainer, Table } from './styleds'
import { TableTransactionsForInfiniteScroll, TABS_FOR_TABLE } from './TableTemplate'
import moment from 'moment'
import styled from 'styled-components/macro'
import { useInfiniteScrollProps } from './NftBattlesNfts'
import InfiniteScroll from 'react-infinite-scroll-component'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import { formatDecimal } from 'utils/numberWithCommas'
import { useXZooLogs } from 'hooks/gql'

const useTxs = (tab: string) => {
  const { account } = useActiveWeb3React()

  const target = useMemo(() => (!tab || tab === TABS_FOR_TABLE.ALL ? undefined : account), [tab, account])

  const { logs, loading } = useXZooLogs(target)

  return useMemo(() => {
    return {
      events: logs,
      loading: loading,
    }
  }, [logs, loading])
}

/* INIFINTE SCROLL START */

const ZooLoaderStyled = styled(ZooLoader)`
  width: 100%;
  height: 200px;
`

const PER_PAGE = 15

interface IScrollProps {
  itemsTotal: any[]
  renderItem: (item: any, index: number) => any
  className?: string
  contentBuilder: (content: any) => any
}

const InfiniteScrollList = ({ className, itemsTotal, renderItem, contentBuilder }: IScrollProps) => {
  const { currentList, hasMore, fetchData } = useInfiniteScrollProps(itemsTotal, PER_PAGE)

  const content = useMemo(
    () => currentList.map((item: any, index: number) => renderItem(item, index)),
    [currentList, renderItem]
  )

  return (
    <InfiniteScroll
      className={className}
      dataLength={currentList.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<ZooLoaderStyled loading={true} />}
      scrollableTarget="body_over"
    >
      {contentBuilder(content)}
    </InfiniteScroll>
  )
}

const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  overflow: visible !important;
`

/* INFINITE SCROLL END */

const InfiniteScrollTemplate = ({ events }: { events: any }) => (
  <InfiniteScrollListStyled
    itemsTotal={events}
    renderItem={(item: any) => <ListItem e={item} key={item.id} />}
    contentBuilder={(content: any) => (
      <TableContainer>
        <Table>
          <TheadStyled>
            <Tr>
              <Th>
                <Trans>Timestamp</Trans>
              </Th>
              <Th>
                <Trans>Action</Trans>
              </Th>
              <Th>
                <Trans>Author</Trans>
              </Th>
              <Th>
                <Trans>Transaction</Trans>
              </Th>
            </Tr>
          </TheadStyled>
          <TbodyStyled>{content}</TbodyStyled>
        </Table>
      </TableContainer>
    )}
  />
)

export const XZooTransactions = () => {
  return <TableTransactionsForInfiniteScroll el={XZooTransactionsInner} />
}

const XZooTransactionsInner = ({ tab }: { tab: string }) => {
  const { events } = useTxs(tab)

  return <InfiniteScrollTemplate events={events} />
}

const ListItem = ({ e }: { e: any }) => {
  const { chainId = SupportedChainId.MAINNET } = useActiveWeb3React()

  const {
    staker,

    amount,
    timestamp,
    transactionHash,

    isStaked,
    isClaimed,
    isWithdrawed,
  } = e

  const isPositive = isStaked || isClaimed

  return (
    <Tr key={transactionHash}>
      <Td>{moment(new Date(timestamp)).format('MMMM Do YYYY, h:mm a')}</Td>
      <TdId positive={isPositive}>
        {isStaked && (
          <>
            <Trans>Staked</Trans>&nbsp;{formatDecimal(amount)}&nbsp;<Trans>ZOO</Trans>
          </>
        )}
        {isWithdrawed && (
          <>
            <Trans>Withdrew</Trans>&nbsp;{formatDecimal(amount)}&nbsp;<Trans>ZOO</Trans>
          </>
        )}
        {isClaimed && (
          <>
            <Trans>Claimed</Trans>&nbsp;{formatDecimal(amount, 2)}&nbsp;<Trans>USD</Trans>
          </>
        )}
      </TdId>
      <Td>
        <ExternalLinkStyled href={getExplorerLink(chainId, staker, ExplorerDataType.ADDRESS)}>
          {shortenAddress(staker)}
        </ExternalLinkStyled>
      </Td>
      <Td>
        <ExternalLinkStyled href={getExplorerLink(chainId, transactionHash, ExplorerDataType.TRANSACTION)}>
          {shortenTx(transactionHash, 10)}
        </ExternalLinkStyled>
      </Td>
    </Tr>
  )
}
