import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { BATTLE_STAGES, useBattleEpoch, useBattleStage, usePairInEpochInfo } from '../NftBattlesPage/hooks'
import ChooseWinners from '../../components/ChooseWinners/ChooseWinners'
import { Trans } from '@lingui/macro'
import ZooLoader from '../../components/ZooLoader/ZooLoader'
import { ButtonPair } from '../../components/Button'
import { BattlePairsNftOverview, BoostBtnWrapper } from './cards/NftOverviewCard'
import { BoxPercent, BoxPercent2, DaiTotalRewards, WglmrWellTotalRewards, ZooStakedTotal } from './CardMiniForNftInfo'
import { useCardLoading } from './cards/CardForPool'
import { isLooser, useGoToBattle, useWinChances, WINNER } from './utils'
import { BattleLabel, BattleLabelWrapper } from './NftCardPairInfo'

const FullBox = styled.div`
  align-items: center;
  margin-bottom: 25px;
  min-height: 300px;
  background-color: #27273e;
  padding: 25px;
  border-radius: 24px;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.25);
`

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`

const WinContent = styled.div`
  border-radius: 24px;
  align-self: center;
  min-height: 0;
  margin-top: 25px;
  display: block;
`

export const BattlePairsNftOverviewStyled = styled(BattlePairsNftOverview)`
  margin: 0;
  height: 100%;
  flex-grow: 1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: initial;
  `};
`
const BattleLabelWrapperBtn = styled(BattleLabelWrapper)`
  padding: 0px;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid #33334b;
  :hover {
    background-image: linear-gradient(#27273f, #27273f),
      linear-gradient(90deg, #f56738 12.38%, #f64562 51.8%, #e138f5 91.43%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 2px transparent;
  }
`
const BattleLabelIn = styled(BattleLabel)`
  font-size: 34px;
  padding: 15px 25px;
  width: 100%;
  line-height: 40px;
  cursor: pointer;
`
export const FirstLineBattlesBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

interface IBattlePairProps {
  positionIndex: string
  epoch: number
}

export const CardPairsPool = ({ positionIndex, epoch }: IBattlePairProps) => {
  const { data, loading } = usePairInEpochInfo(epoch, positionIndex)

  return (
    <FullBox>
      <ZooLoader loading={loading}>
        <DataWrapper data={data} epoch={epoch} positionIndex={positionIndex} />
      </ZooLoader>
    </FullBox>
  )
}

const DataWrapper = ({ data, epoch, positionIndex }: { data: any; epoch: number; positionIndex: string }) => {
  const { token1, token2 }: any = data || {}
  const battleID = parseInt(positionIndex) + 1

  const stakedFirst = useMemo(() => {
    return token1 ? token1.toString() : ''
  }, [token1])

  const stakedSecond = useMemo(() => {
    return token2 ? token2.toString() : ''
  }, [token2])

  const { chanceB, chanceA, loadingFirst, loadingSecond, winner } = useWinChances(data, epoch)

  const openPair = useGoToBattle(`${positionIndex.toString()}`, epoch)

  const hasWinner = winner === WINNER.A || winner === WINNER.B

  return (
    <ZooLoader loading={loadingFirst || loadingSecond}>
      <FirstLineBattlesBox>
        <BattleLabelWrapperBtn>
          <BattleLabelIn onClick={openPair}>
            <Trans>Battle #</Trans>
            {battleID}
          </BattleLabelIn>
        </BattleLabelWrapperBtn>
        <ChancesInfo epoch={epoch} pairData={data} />
      </FirstLineBattlesBox>
      <CardContent>
        <BattlePairsNftOverviewStyled type={WINNER.A} positionId={stakedFirst} winner={winner} epoch={epoch}>
          <BoxPercent looser={isLooser(WINNER.A, winner)}>
            {winner === WINNER.A ? <Trans>Won</Trans> : chanceA.toFixed(2) + '%'}
          </BoxPercent>
        </BattlePairsNftOverviewStyled>

        <BattlePairsNftOverviewStyled type={WINNER.B} positionId={stakedSecond} winner={winner} epoch={epoch}>
          <BoxPercent2 looser={isLooser(WINNER.B, winner)}>
            {winner === WINNER.B ? <Trans>Won</Trans> : chanceB.toFixed(2) + '%'}
          </BoxPercent2>
        </BattlePairsNftOverviewStyled>
      </CardContent>

      {data && !hasWinner ? <Chances epoch={epoch} pairData={data} positionIndex={positionIndex} /> : null}
    </ZooLoader>
  )
}

const FooterContainer = styled.div`
  margin-top: 12px;

  button {
    width: 100%;
  }
`

const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`

const StakedData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`
const DataUSDTotalV = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-left: 16px;
`

const ButtonPairStyled = styled(ButtonPair)`
  width: 100%;
`

const Chances = ({ epoch, pairData, positionIndex }: { pairData: any; epoch: number; positionIndex: string }) => {
  const winData = useWinChances(pairData, epoch)
  const { playedInEpoch, loadingFirst, loadingSecond } = winData
  const currentEpoch = useBattleEpoch()
  const stage = useBattleStage()

  const pid1 = useMemo(() => {
    return pairData?.token1?.toString()
  }, [pairData])

  const pid2 = useMemo(() => {
    return pairData?.token2?.toString()
  }, [pairData])

  const { onClick: boostFirst } = useCardLoading(pid1)
  const { onClick: boostSecond } = useCardLoading(pid2)

  return (
    <ZooLoader loading={loadingSecond || loadingFirst}>
      <WinContent>
        <StakedData>
          {stage === BATTLE_STAGES.FOURTH ? (
            <>
              <Column>
                <BoostBtnWrapper positionId={pid1} onClick={boostFirst} />
              </Column>
              <Column>
                <BoostBtnWrapper positionId={pid2} onClick={boostSecond} />
              </Column>
            </>
          ) : null}
        </StakedData>

        <FooterContainer>
          {+currentEpoch === +epoch ? (
            <>
              {!playedInEpoch && stage === BATTLE_STAGES.FIFTH ? (
                <ChooseWinners pairIndex={positionIndex} el={ButtonPairStyled} />
              ) : null}
            </>
          ) : (
            <ButtonPair disabled={true}>
              <Trans>Choosing a winner is unavailable</Trans>
            </ButtonPair>
          )}
        </FooterContainer>
      </WinContent>
    </ZooLoader>
  )
}
const ChancesInfo = ({ epoch, pairData }: { pairData: any; epoch: number }) => {
  const winData = useWinChances(pairData, epoch)
  const { loadingFirst, loadingSecond } = winData

  return (
    <ZooLoader loading={loadingSecond || loadingFirst}>
      <DataUSDTotalV>
        <DaiTotalRewards winData={winData} />
        <WglmrWellTotalRewards epoch={epoch} winData={winData} />
        {winData && winData.a && winData.b && <ZooStakedTotal winData={winData} />}
      </DataUSDTotalV>
    </ZooLoader>
  )
}
