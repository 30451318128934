import { Trans } from '@lingui/macro'
import Loading from 'components/Loading'
import { useDistributedRewards } from 'hooks/zoodao/useBattlesEventsData'
import styled from 'styled-components/macro'
import { formatDecimal } from 'utils/numberWithCommas'
import {
  CardContentBox,
  CardHead,
  CardHeadBox,
  CardMiniForBattleBoard,
  InfoText,
  NumberText,
} from './CardMiniForNftInfo'
import { CardLine } from './styleds'

const CardContentBoxRow = styled(CardContentBox)<{ pushDown?: boolean; noBottomMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-block-start: ${(props) => (props.pushDown ? 'auto' : '0')};
  margin-block-end: ${(props) => (props.noBottomMargin ? '0' : '10px')};
`
export const CardRewardsDistributed = () => {
  const { amountUsd, wells, wglmrs, loading } = useDistributedRewards()

  return (
    <CardMiniForBattleBoard>
      <CardLine index={17} />
      <CardHeadBox style={{ marginBottom: '16px' }}>
        <CardHead>
          <Trans>Distributed Rewards</Trans>
        </CardHead>
      </CardHeadBox>
      <CardContentBoxRow pushDown>
        <NumberText>
          <Loading loading={loading}>{formatDecimal(amountUsd, 2)}</Loading>
        </NumberText>
        &nbsp;
        <InfoText>
          <Trans>USD</Trans>
        </InfoText>
      </CardContentBoxRow>
      <CardContentBoxRow>
        <NumberText>
          <Loading loading={loading}>{formatDecimal(wells, 2)}</Loading>
        </NumberText>
        &nbsp;
        <InfoText>
          <Trans>WELL</Trans>
        </InfoText>
      </CardContentBoxRow>
      <CardContentBoxRow noBottomMargin>
        <NumberText>
          <Loading loading={loading}>{formatDecimal(wglmrs, 2)}</Loading>
        </NumberText>
        &nbsp;
        <InfoText>
          <Trans>wGLMR</Trans>
        </InfoText>
      </CardContentBoxRow>
    </CardMiniForBattleBoard>
  )
}
