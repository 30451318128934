import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { isAddress } from 'utils'
import { LiFiWidget, WidgetConfig } from '@lifi/widget'
import { ApplicationModal } from '../../state/application/actions'
import {
  useModalOpen,
  useBridgeModalToggle,
  useBridgeDaiModalToggle,
  useBridgeZooModalToggle,
} from '../../state/application/hooks'

import Modal from '../Modal'
import { SupportedChainId } from 'constants/chainsinfo'
import { useActiveWeb3React } from 'hooks/web3'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`

const ModalStyled = styled(Modal)`
  width: auto;
  max-width: 392px !important;
`

export interface IBridgeConfig {
  fromAsset?: string
  fromChain?: BRIDGE_CHAINS
  toAsset?: string
  toChain?: BRIDGE_CHAINS
  amount?: number
  hideSelectionFrom?: boolean
  hideSelectionTo?: boolean
}

interface IInjectTokens {
  eth: string[]
  fantom: string[]
  moonbeam: string[]
}

export enum BRIDGE_CHAINS {
  ETH = 'eth',
  FANTOM = 'ftm',
  MOONBEAM = 1284,
}

const getConfig = (
  {
    fromAsset = BRIDGE_CHAINS.ETH,
    fromChain = BRIDGE_CHAINS.ETH,
    toAsset = BRIDGE_CHAINS.ETH,
    toChain = BRIDGE_CHAINS.ETH,
    amount = 100,
    ...rest
  }: IBridgeConfig,
  chainId: BRIDGE_CHAINS
): WidgetConfig => {
  const injectTokens: IInjectTokens = {
    eth: [],
    fantom: [],
    moonbeam: [],
  }

  if (isAddress(toAsset)) {
    switch (toChain) {
      case BRIDGE_CHAINS.ETH: {
        injectTokens.eth.push(toAsset)
        break
      }
      case BRIDGE_CHAINS.FANTOM: {
        injectTokens.fantom.push(toAsset)
        break
      }
      case BRIDGE_CHAINS.MOONBEAM: {
        injectTokens.moonbeam.push(toAsset)
        break
      }
      default: {
        throw new Error('Not supported action for bridge')
      }
    }
  }

  if (isAddress(fromAsset)) {
    switch (fromChain) {
      case BRIDGE_CHAINS.ETH: {
        injectTokens.eth.push(fromAsset)
        break
      }
      case BRIDGE_CHAINS.FANTOM: {
        injectTokens.fantom.push(fromAsset)
        break
      }
      case BRIDGE_CHAINS.MOONBEAM: {
        injectTokens.moonbeam.push(fromAsset)
        break
      }
      default: {
        throw new Error('Not supported action for bridge')
      }
    }
  }

  const configuration: WidgetConfig = {
    // injectTokens: injectTokens,

    integrator: 'ZooDAO Bridge',
    containerStyle: {
      backgroundColor: '#27273F',
      display: 'flex',
      boxShadow: '5px 10px 20px rgb(0 0 0 / 20%)',
    },
    fromChain: fromChain || chainId,
    toChain: toChain || chainId,
    fromToken: fromAsset,
    toToken: toAsset,
    fromAmount: amount,
    ...rest,
  }

  return configuration
}

export const SwapTemplate = ({
  configs,
  walletModalOpen,
  toggleWalletModal,
}: {
  configs: IBridgeConfig
  walletModalOpen: boolean
  toggleWalletModal: () => void
}) => {
  const { chainId } = useActiveWeb3React()
  const widgetConfig = useMemo(
    () => getConfig(configs, chainId === SupportedChainId.MAINNET ? BRIDGE_CHAINS.ETH : BRIDGE_CHAINS.MOONBEAM),
    [configs, chainId]
  )
  return (
    <ModalStyled isOpenFlag={walletModalOpen} onDismissHandler={toggleWalletModal} minHeight={false} maxHeight={600}>
      <Wrapper>
        <LiFiWidget config={widgetConfig} />
      </Wrapper>
    </ModalStyled>
  )
}

export const SwapBridgeModal = ({ configs }: { configs: IBridgeConfig }) => {
  const walletModalOpen = useModalOpen(ApplicationModal.BRIDGE_MODAL)
  const toggleWalletModal = useBridgeModalToggle()

  return <SwapTemplate configs={configs} walletModalOpen={walletModalOpen} toggleWalletModal={toggleWalletModal} />
}

export const SwapDaiModal = ({ configs }: { configs: IBridgeConfig }) => {
  const walletModalOpen = useModalOpen(ApplicationModal.BRIDGE_DAI)
  const toggleWalletModal = useBridgeDaiModalToggle()

  return <SwapTemplate configs={configs} walletModalOpen={walletModalOpen} toggleWalletModal={toggleWalletModal} />
}

export const SwapZooModal = ({ configs }: { configs: IBridgeConfig }) => {
  const walletModalOpen = useModalOpen(ApplicationModal.BRIDGE_ZOO)
  const toggleWalletModal = useBridgeZooModalToggle()

  return <SwapTemplate configs={configs} walletModalOpen={walletModalOpen} toggleWalletModal={toggleWalletModal} />
}
