import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { DashboardContainer } from 'pages/HomePage/HomePage'
import NFTList from './NFTList'
import { Flex } from 'rebass'
import { ZOOClaimableStaking, ZOOClaimableVoting } from './Panels/ClaimableZOO'
import UnstakeVotesPanel from './Panels/ClaimableUnstakeVotes'
import UnstakeVotesUSDPanel from './Panels/ClaimableUnstakeVotesUSD'
import UnstakeNFTs from './Panels/ClaimableUnstakeNFTs'
import { getSelectedKey, ISelectedPortfolioNfts } from './utils'
import {
  MyTotalRewardsClaimed,
  ClaimableBattleStakingRewardsNew,
  ClaimableBattleVotingRewardsNew,
} from 'pages/NftBattlesPageV2/cards/ClaimableBattlerRewards'
import { BigNumber } from 'ethers'
import { ZERO } from 'utils/isZero'
import { BattlesDropdown, IOption, PORTFOLIO_TABS } from 'pages/NftBattlesPageV2/NftBattlesNfts'
import { useCreatedStakerPositions, useUserBattleEvents } from 'hooks/zoodao/useBattlesEventsData'
import { useJackpots } from 'hooks/gql'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'
import { useActiveWeb3React } from 'hooks/web3'
import { Checkbox } from 'components/SearchModal/styleds'
import { AutoRow } from 'components/Row'
import { TYPE } from 'theme'
import useTheme from 'hooks/useTheme'

export const DashboardContainerStyled = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const BridgeFullBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 25px;
`

export const FixedRight = styled.div`
  position: sticky;
  top: 25px;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  margin-left: 25px;
`

export const RightPanel = styled(Flex)``

export const StakedOrVotes = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const OPTIONS: IOption[] = [
  {
    value: PORTFOLIO_TABS.VOTES,
    label: <Trans>My Voted NFTs</Trans>,
  },
  {
    value: PORTFOLIO_TABS.STAKED,
    label: <Trans>My Staked NFTs</Trans>,
  },
]

export const SelectedPositionsContext = createContext<ISelectedPortfolioNfts>({})

const Portfolio = () => {
  const [tab, setTab] = useState(PORTFOLIO_TABS.VOTES)

  const [selected, setSelected] = useState<ISelectedPortfolioNfts>({})

  const positionsObjs = useMemo(() => Object.values(selected), [selected])

  const toggle = useCallback(
    (
      isUpdate: boolean,
      stakingPositionId: string,
      daiInvested: BigNumber,
      zooInvested: BigNumber,
      votingPositionId?: BigNumber
    ) => {
      const key = getSelectedKey(stakingPositionId, votingPositionId)

      setSelected((prev: ISelectedPortfolioNfts) => {
        if (isUpdate) {
          if (prev[key]) {
            return {
              ...prev,
              [key]: {
                daiInvested,
                zooInvested,
                stakingPositionId,
                votingPositionId,
              },
            }
          }
          return prev
        }

        if (prev[key]) {
          delete prev[key]
          return {
            ...prev,
          }
        } else {
          return {
            ...prev,
            [key]: {
              daiInvested,
              zooInvested,
              stakingPositionId,
              votingPositionId: votingPositionId,
            },
          }
        }
      })
    },
    []
  )
  const stakingPids: string[] = useMemo(() => {
    const pids = positionsObjs.map((i) => i.stakingPositionId)

    return [...new Set(pids.filter((i) => !!i))]
  }, [positionsObjs])

  const totalUsd = useMemo(() => {
    return positionsObjs.reduce((acc, item) => {
      return acc.add(item.daiInvested)
    }, ZERO)
  }, [positionsObjs])

  const totalZoo = useMemo(() => {
    return positionsObjs.reduce((acc, item) => {
      return acc.add(item.zooInvested)
    }, ZERO)
  }, [positionsObjs])

  const changeTab = useCallback(
    (tab: string) => {
      setTab(tab)
      setSelected({})
    },
    [setTab]
  )

  const votingsPids: BigNumber[] = useMemo(() => {
    const pids = positionsObjs.map((i) => i.votingPositionId)

    return pids.filter((i) => !!i) as BigNumber[]
  }, [positionsObjs])

  const [search, setSearch] = useState<string | undefined>()

  const [isDeleted, setShowDeleted] = useState(false)
  const { staked, loading: loadingStaked } = useCreatedStakerPositions(true, search, isDeleted)
  const { votings, loading } = useUserBattleEvents(search, isDeleted, false)

  const { account } = useActiveWeb3React()

  const { jackpots: jackpotsA, loading: loadingA } = useJackpots(account, IPanelJackpotsVariant.JackpotA)
  const { jackpots: jackpotsB, loading: loadingB } = useJackpots(account, IPanelJackpotsVariant.JackpotB)

  const jackpots = useMemo(() => (tab === PORTFOLIO_TABS.STAKED ? jackpotsA : jackpotsB), [tab, jackpotsA, jackpotsB])

  const theme = useTheme()

  return (
    <DashboardContainerStyled>
      <Flex flexDirection="column">
        <StakedOrVotes>
          <BattlesDropdown
            searchQuery={search}
            setSearchQuery={setSearch}
            tab={tab}
            setTab={changeTab}
            myStakedAmount={staked.length + jackpotsA.length}
            votedAmount={votings.length + jackpotsB.length}
            options={OPTIONS}
            hideText={true}
          >
            <AutoRow
              justify="center"
              style={{ cursor: 'pointer', marginTop: '12px' }}
              onClick={() => setShowDeleted(!isDeleted)}
            >
              <Checkbox
                name="confirmed"
                type="checkbox"
                checked={isDeleted}
                onChange={() => setShowDeleted(!isDeleted)}
              />
              <TYPE.body ml="10px" fontSize="16px" color={theme.red1} fontWeight={500}>
                <Trans>Show Liquidated Positions</Trans>
              </TYPE.body>
            </AutoRow>
          </BattlesDropdown>
        </StakedOrVotes>

        <BridgeFullBox>
          <SelectedPositionsContext.Provider value={selected}>
            <NFTList
              target={tab}
              toggle={toggle}
              loading={loading || loadingStaked || loadingA || loadingB}
              staked={staked}
              votings={votings}
              jackpots={jackpots}
            />
          </SelectedPositionsContext.Provider>
          <RightPanel>
            <FixedRight>
              {tab === PORTFOLIO_TABS.STAKED && (
                <>
                  <MyTotalRewardsClaimed isStaking={true} />
                  <ClaimableBattleStakingRewardsNew stakingPids={stakingPids} />
                  <ZOOClaimableStaking pids={stakingPids} />
                  <UnstakeNFTs pids={stakingPids} totalUsd={totalUsd} totalZoo={totalZoo} />
                </>
              )}

              {tab === PORTFOLIO_TABS.VOTES && (
                <>
                  <MyTotalRewardsClaimed isStaking={false} />
                  <ClaimableBattleVotingRewardsNew votingObjs={positionsObjs} />
                  <ZOOClaimableVoting pids={votingsPids} />
                  <UnstakeVotesPanel pids={votingsPids} totalZoo={totalZoo} />
                  <UnstakeVotesUSDPanel pids={votingsPids} totalUsd={totalUsd} />
                </>
              )}
            </FixedRight>
          </RightPanel>
        </BridgeFullBox>
      </Flex>
    </DashboardContainerStyled>
  )
}

export default Portfolio
