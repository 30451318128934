import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { Flex } from 'rebass/styled-components'
import { Container, WeightsWrapper, Weight, TxtWeights, VE_PIE_WEIGHTS_COLORS } from './styles'
import { DEFAULT, getDates, ILeftDays, SECOND } from 'components/EpochBanner/EpochBanner'
import { BigNumber } from 'ethers'
import { fromWei } from 'utils/fromWei'
import Tooltip from 'components/Tooltip'
import { useCollectionInfo } from 'hooks/zoodao/useCollectionInfo'
import styled from 'styled-components/macro'

const Empty = styled.div`
  width: 100%;
  height: 100%;
`

const TooltipStyled = styled(Tooltip)`
  width: 100%;
`

export const useVeModelTimer = (startDate: number, epochDuration: number) => {
  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const calculateBorder = useCallback((): ILeftDays => {
    const date = new Date(startDate * 1000)

    return getDates(date, epochDuration * 1000)
  }, [startDate, epochDuration])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  return border
}

export const usePositionTimer = (endDate: number) => {
  const [border, setBorder] = useState<ILeftDays>(DEFAULT)

  const calculateBorder = useCallback((): ILeftDays => {
    const now = new Date().getTime() / 1000
    return getDates(new Date(), (endDate - now) * 1000)
  }, [endDate])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBorder(calculateBorder())
    }, SECOND)

    return () => clearInterval(intervalId)
  }, [calculateBorder])

  return border
}

const Weights = ({ totalWeights, totalWeight }: { totalWeights: ITotalWeights; totalWeight: BigNumber }) => {
  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between">
        <TxtWeights>
          <Trans>ZOO Reward Distribution</Trans>
        </TxtWeights>
      </Flex>

      <Graph totalWeight={totalWeight} totalWeights={totalWeights} />
    </Container>
  )
}

interface ITotalWeights {
  [collection: string]: BigNumber
}

export const getWeightPercent = (totalPool: BigNumber, totalWeight: BigNumber) =>
  +fromWei(totalPool) / +fromWei(totalWeight)

export interface ICollectionColors {
  [collectionKey: string]: string
}

export const useCollectionColors = (totalWeights: ITotalWeights) => {
  const collections = useMemo(() => Object.keys(totalWeights), [totalWeights])

  const colors: ICollectionColors = collections.reduce((acc: any, item, index) => {
    acc[item] = VE_PIE_WEIGHTS_COLORS[index % (VE_PIE_WEIGHTS_COLORS.length - 1)]

    return acc
  }, {})

  return {
    collections,
    colors,
  }
}

const Graph = ({ totalWeights, totalWeight }: { totalWeights: ITotalWeights; totalWeight: BigNumber }) => {
  const { collections, colors } = useCollectionColors(totalWeights)
  const totalGap = useMemo(() => 5 * (collections.length - 1), [collections])

  const [hoveredCollection, setHoveredCollection] = useState<string | undefined>(undefined)

  const item = useCollectionInfo(hoveredCollection?.split('_')[0])

  const calcWeightPercent = useCallback(
    (targetCollection: string | undefined): number =>
      targetCollection ? 100 * getWeightPercent(totalWeights[targetCollection], totalWeight) : 0,
    [totalWeight, totalWeights]
  )

  const currentWeight = calcWeightPercent(hoveredCollection)

  return (
    <WeightsWrapper>
      {collections.map((collection) => {
        const percent = calcWeightPercent(collection)
        return (
          <Weight
            key={collection}
            $percent={percent}
            $totalGap={totalGap}
            color={colors[collection]}
            onMouseEnter={() => setHoveredCollection(collection)}
            onMouseLeave={() => setHoveredCollection(undefined)}
          >
            <TooltipStyled
              key={collection}
              text={item.name ? `${item.name}: ${currentWeight.toFixed(2)}%` : <Trans>Loading...</Trans>}
              placement="bottom"
              show={!!item?.name && !!hoveredCollection && collection === hoveredCollection}
            >
              <Empty>&nbsp;</Empty>
            </TooltipStyled>
          </Weight>
        )
      })}
    </WeightsWrapper>
  )
}

export default memo(Weights)
