import {
  useProjectStablecoinForCurrentChain,
  useSaleStablecoinForCurrentChain,
  useXZooContract,
  useZooSaleToken,
  useZooRealToken,
} from '../../constants/zoodao'
import { useTotalSupply } from './useTotalSupply'
import { useActiveWeb3React } from '../web3'
import { useBalance } from './useBalance'

export const useZooTokenTotalSupply = () => {
  const contract = useZooRealToken()

  return useTotalSupply(contract)
}

export const useXZooTokenTotalSupply = () => {
  const contract = useXZooContract()

  return useTotalSupply(contract)
}

export const useZooTokenBalance = () => {
  const contract = useZooRealToken()

  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}

export const useChainStablecoinBalance = () => {
  const contract = useProjectStablecoinForCurrentChain()

  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}

export const useChainSaleStablecoinBalance = () => {
  const contract = useSaleStablecoinForCurrentChain()

  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}

export const useZooBalanceTest = () => {
  const contract = useZooRealToken()

  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}

export const useZooSaleBalanceTest = () => {
  const contract = useZooSaleToken()

  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}

export const useZooBalanceReal = () => {
  const contract = useZooRealToken()

  const { account } = useActiveWeb3React()

  return useBalance(contract, account)
}
