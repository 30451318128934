import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { JackpotInfo } from './Panels/InfoCards/DashboardCards'
import ClaimedTotal from './Panels/InfoCards/ClaimedTotal'
import { DashboardContainer } from '../HomePage/HomePage'
import StakedJackpotsList from './StakedJackpotsList'
import { Flex } from 'rebass/styled-components'
import ButtonPanel, { IPanelJackpotsVariant } from './Panels/ButtonPanel'
import { Box } from '../../components/MUI'
import { useJackpots, useStakerPositions, useVoterPositions } from 'hooks/gql'
import { useActiveWeb3React } from 'hooks/web3'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import { JackpotsParticipants } from './Panels/InfoCards/JackpotParticipants'
import PositionsForJackpotsList from './PositionsForJackpotsList'
import { CardContainer } from './styleds'
import { NoJackpotsB, NoJackpotsA } from 'pages/NftBattlesPageV2/NoNfts'
import { EpochSelector } from 'pages/NftBattlesPageV2/NftBattlesPairsPool'
import { useParams } from 'react-router-dom'
import { Paths } from 'constants/paths'
import { JackpotsTransactions } from 'pages/NftBattlesPageV2/BattleStakingTransactions'
import ClaimableTotal from './Panels/InfoCards/ClaimableTotal'

const DashboardContainerStyled = styled(DashboardContainer)`
  height: initial;
  margin-bottom: 24px;
`
const DashboardFull = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const BoxGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`

const windowInnerWidth = window.innerWidth

let NavBarLeftWidth = 250
if (windowInnerWidth < 1600) {
  NavBarLeftWidth = 200
}

const widthWorkBox = windowInnerWidth - NavBarLeftWidth - 50 - 5 - 75
const ForBattleBoard = (widthWorkBox + 50) / 4
const ForBattleBoard2 = ForBattleBoard * 1.5

export const FixedRight = styled.div`
  position: sticky;
  top: 25px;
  width: ${ForBattleBoard2}px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`

export const RightPanel = styled(Flex)`
  position: sticky;
  top: 0;
`

const CountInfo = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #33334b;

  background-color: #202136;
  padding: 15px 25px 15px 25px;
  border-radius: 50px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 24px;
    font-size: 20px;
    line-height: 20px;
  `};
`

const ZooLoaderStyled = styled(ZooLoader)`
  width: 100%;
  height: 400px;
`

const Selectors = styled.div`
  width: 100%;
`

const Jackpots = () => {
  const [panelType, setPanelType] = useState(IPanelJackpotsVariant.JackpotA)

  const { account } = useActiveWeb3React()

  const { jackpots: jackpotsA, loading: loadingA } = useJackpots(account, IPanelJackpotsVariant.JackpotA)
  const { jackpots: jackpotsB, loading: loadingB } = useJackpots(account, IPanelJackpotsVariant.JackpotB)

  const { positions: positionsStaking, loading: loadingS } = useStakerPositions(undefined, account)

  const { positions: positionsVoting, loading: loadingV } = useVoterPositions(
    undefined,
    undefined,
    account,
    '',
    false,
    false
  )

  const jackpots = useMemo(() => {
    return panelType === IPanelJackpotsVariant.JackpotA ? jackpotsA : jackpotsB
  }, [jackpotsA, jackpotsB, panelType])

  const positions = useMemo(() => {
    return panelType === IPanelJackpotsVariant.JackpotA ? positionsStaking : positionsVoting
  }, [positionsStaking, positionsVoting, panelType])

  const amounts = useMemo(() => {
    return jackpots.length + positions.length
  }, [jackpots, positions])

  const loading = loadingA || loadingB || loadingS || loadingV

  const { id: epoch }: any = useParams()

  return (
    <>
      <Selectors>
        <EpochSelector url={Paths.BATTLES_JACKPOTS} />
      </Selectors>
      <DashboardContainerStyled>
        <DashboardFull>
          <Box flex={1} height="100%">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between" mr="20px">
              <ButtonPanel onChange={setPanelType} type={panelType} />
              <CountInfo>
                <Trans>{amounts} Available</Trans>
              </CountInfo>
            </Box>
            <Box flex={1} height="100%" pt="25px" mr="20px">
              <ZooLoaderStyled loading={loading}>
                <Box flex={1} height="100%">
                  {jackpots.length === 0 && positions.length === 0 ? (
                    panelType === IPanelJackpotsVariant.JackpotA ? (
                      <NoJackpotsA />
                    ) : (
                      <NoJackpotsB />
                    )
                  ) : (
                    <CardContainer>
                      <StakedJackpotsList type={panelType} projects={jackpots} epoch={epoch} />
                      <PositionsForJackpotsList
                        type={panelType}
                        positions={positions}
                        isAvailableToStake={jackpots.length === 0}
                      />
                    </CardContainer>
                  )}
                </Box>
              </ZooLoaderStyled>
            </Box>
          </Box>

          <RightPanel>
            <FixedRight>
              <Box>
                {epoch && <JackpotInfo panelType={panelType} epoch={epoch} />}
                <BoxGrid flexDirection="row" mt="25px">
                  <ClaimedTotal index={6} />
                  <JackpotsParticipants index={1} />
                </BoxGrid>
                <Box flexDirection="row" mt="25px">
                  <ClaimableTotal index={2} type={panelType} />
                </Box>
              </Box>
            </FixedRight>
          </RightPanel>
        </DashboardFull>
      </DashboardContainerStyled>

      <JackpotsTransactions type={panelType} />
    </>
  )
}

export default Jackpots
