import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  CardMiniForNftInfoDAI2,
  CardMiniForNftInfoZOO2,
  CardNFTinBattles,
  CardNumStakersAndVoters,
  CardMiniStage1,
  CardMiniStage2,
  CardMiniStage3,
  CardMiniStage4,
  CardMiniStage5,
  UpForGrabs,
} from './CardMiniForNftInfo'
import { ButtonBoost } from '../../components/Button'
import place1st from '../../assets/images/1st.png'
import place2nd from '../../assets/images/2nd.png'
import place3rd from '../../assets/images/3rd.png'
import stripsBg from './../../assets/images/strips.svg'
import top3SvgBg from './../../assets/images/top_3_banner.svg'
import { BattleBoardOverviewCard } from './cards/NftOverviewCard'
import { useAllBattleNftEvents, useBattleNftEventsForUser, useMostPopularNfts } from 'hooks/zoodao/useBattlesEventsData'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import { Paths } from 'constants/paths'
import { CardBattleAPY } from './CardBattlesApy'
import { CardRewardsDistributed } from './CardRewardsDistributed'
import { CardRewardsEpoch } from './CardRewardsEpoch'
import { useBattleEpoch } from 'pages/NftBattlesPage/hooks'
import ExplanationCard from 'pages/Dashboard/InfoCards/ExplanationCard'

const BgImg = styled.div`
  width: 100%;
  min-height: 300px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1;
  grid-gap: 25px;
`
const BattleColumn = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1.5fr;
  grid-gap: 25px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: unset;
    grid-template-rows: auto auto;
  `};
`

const BattleBoardContainer = styled.div`
  margin-top: 0px;
  width: 100%;
  height: 100%;
`
const Left = styled.div`
  width: 100%;
  align-self: start;
`

const Right = styled.div`
  width: 100%;
  height: 100%;
  align-self: start;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  align-self: start;
  margin-top: 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
  `};
`
const BoxTime = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 25px;
  width: 100%;
  align-self: start;
  border-radius: 24px;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToXMedium`
    grid-gap: 12px;
  `};
`
const BoxTopNFTs = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 25px;
  align-self: center;
  width: 100%;
  min-height: 400px;
  background: url(${stripsBg}) 50% 50% / cover no-repeat;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-height: 315px;
  `};
`

const CardHead = styled.div`
  font-family: ${({ theme }) => theme.fontUniform};
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.75px;
  display: flex;
  word-break: break-word;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 36px;
  line-height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 36px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 26px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 18px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
  `}
`
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`
const Row2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 25px;
`
const WinnerImg = styled.img`
  aling-items: center;
  margin-bottom: -35px;
  width: 75px;
  height: 75px;
  z-index: 100;
`
const Arrow = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
`

const ZooLoaderStyled = styled(ZooLoader)`
  height: 270px !important;
`
const BattleBoard = () => {
  const { loading, nfts } = useMostPopularNfts()

  const forBoard = useMemo(() => {
    return nfts.slice(0, 3)
  }, [nfts])

  const history = useHistory()

  const onClick = () => {
    history.push(Paths.BATTLES_NFTS)
  }

  const { votingsByUser, loading: loading1 } = useBattleNftEventsForUser(null)
  const { allVotings, loading: loading2 } = useAllBattleNftEvents(null)

  const epoch = useBattleEpoch()

  return (
    <BattleBoardContainer>
      <Container>
        <ExplanationCard />
        <BoxTime>
          <CardMiniStage1 />
          <CardMiniStage2 />
          <CardMiniStage3 />
          <CardMiniStage4 />
          <CardMiniStage5 />
        </BoxTime>
        <BattleColumn>
          <Left>
            <BoxTopNFTs>
              <Row>
                <CardHead>
                  <Trans>Top 3 NFTs</Trans>
                </CardHead>
                <ButtonBoost onClick={onClick}>
                  <Trans>See All</Trans>
                  <Arrow></Arrow>
                </ButtonBoost>
              </Row>
              <ZooLoaderStyled loading={loading}>
                {!loading && forBoard.length > 0 ? (
                  <>
                    <Row2>
                      <WinnerImg src={place1st} />
                      <WinnerImg src={place2nd} />
                      <WinnerImg src={place3rd} />
                    </Row2>
                    <Row2>
                      {forBoard.map((item) => (
                        <BattleBoardOverviewCard key={item.stakingPositionId} item={item} />
                      ))}
                    </Row2>
                  </>
                ) : (
                  <>
                    <BgImg
                      style={{
                        background: `url(${top3SvgBg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                      }}
                    />
                  </>
                )}
              </ZooLoaderStyled>
            </BoxTopNFTs>
          </Left>
          <Right>
            <UpForGrabs />
            <Grid>
              <CardMiniForNftInfoDAI2 allVotes={allVotings} userVotes={votingsByUser} loading={loading1 || loading2} />
              <CardMiniForNftInfoZOO2 allVotes={allVotings} userVotes={votingsByUser} loading={loading1 || loading2} />
              <CardNFTinBattles />
              <CardNumStakersAndVoters />
              <CardRewardsDistributed />
              <CardBattleAPY />
              {epoch && (
                <>
                  <CardRewardsEpoch epoch={epoch - 1} label={<Trans>Previous Season Rewards</Trans>} />
                  <CardRewardsEpoch epoch={epoch} />
                </>
              )}
            </Grid>
          </Right>
        </BattleColumn>
      </Container>
    </BattleBoardContainer>
  )
}

export default BattleBoard
