import { useBattleStage, BATTLE_STAGES } from 'pages/NftBattlesPage/hooks'
import { Fragment, RefObject } from 'react'
import { CardCreateVotingPosition } from './CardMiniForNftInfo'
import { CardVotingDAI } from './CardVotingDAI'
import { CardVotingZOO } from './CardVotingZOO'

export const CardsForVoting = ({
  hideSecondStage = false,
  votingsByUser,
  positionId,
  zooBoostRef,
}: {
  votingsByUser: any[]
  positionId: string
  hideSecondStage?: boolean
  zooBoostRef?: RefObject<HTMLDivElement>
}) => {
  const stage = useBattleStage()

  if (stage === BATTLE_STAGES.THIRD || stage === BATTLE_STAGES.FIFTH) {
    return null
  }

  return (
    <>
      {stage == BATTLE_STAGES.SECOND && votingsByUser.length === 0 && (
        <CardCreateVotingPosition positionId={positionId} votingsByUser={votingsByUser} />
      )}
      {votingsByUser.map((item) => (
        <Fragment key={item.votingPositionId.toString()}>
          {hideSecondStage && stage !== BATTLE_STAGES.SECOND ? null : <CardVotingDAI item={item} />}
          <CardVotingZOO item={item} zooBoostRef={zooBoostRef} />
        </Fragment>
      ))}
    </>
  )
}
