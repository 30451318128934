import React from 'react'
import JackpotsNFTOverview from '../NftBattlesPageV2/cards/JackpotsNFTOverview/JackpotsNFTOverview'
import { IPanelJackpotsVariant } from './Panels/ButtonPanel'

const StakedJackpotsList = ({
  type,
  projects,
  epoch,
}: {
  type: IPanelJackpotsVariant
  projects: any[]
  epoch: number
}) => {
  return (
    <>
      {projects.map((p) => {
        return <JackpotsNFTOverview key={p.id} panelType={type} jackpot={p} epoch={epoch} />
      })}
    </>
  )
}

export default StakedJackpotsList
