import React from 'react'
import { Trans } from '@lingui/macro'
import {
  StyledRow,
  ContentStyled,
  TextConnect,
  Header,
  Web3StatusStyled,
} from 'components/WarningWrapper/WarningWrapper'
import styled from 'styled-components/macro'

const TextConnectNoMargin = styled(TextConnect)`
  margin: auto !important;
  text-align: center !important;
`

export const NoNftsStaked = () => {
  return (
    <StyledRow>
      <ContentStyled>
        <Header>
          <Trans>No NFTs Staked</Trans>
        </Header>
        <Web3StatusStyled text={<Trans>Stake an NFT</Trans>} />
      </ContentStyled>
    </StyledRow>
  )
}

export const NoNftsEligble = () => {
  return (
    <StyledRow>
      <ContentStyled>
        <Header>
          <Trans>No eligible NFTs</Trans>
        </Header>
        <Web3StatusStyled text={<Trans>Stake an NFT</Trans>} />
      </ContentStyled>
    </StyledRow>
  )
}

export const NoNftVotes = () => {
  return (
    <StyledRow style={{ marginRight: 25, width: 'initial' }}>
      <ContentStyled>
        <Header>
          <Trans>You have not voted on any collections</Trans>
        </Header>
        <Web3StatusStyled text={<Trans>Stake an NFT</Trans>} />
      </ContentStyled>
    </StyledRow>
  )
}

export const NoJackpotsA = () => {
  return (
    <StyledRow>
      <ContentStyled>
        <TextConnectNoMargin>
          <Trans>You can stake your zVote positions here to compete for Jackpot A!</Trans>
        </TextConnectNoMargin>
      </ContentStyled>
    </StyledRow>
  )
}

export const NoJackpotsB = () => {
  return (
    <StyledRow>
      <ContentStyled>
        <TextConnectNoMargin>
          <Trans>You can stake your zNFT positions here to compete for Jackpot B!</Trans>
        </TextConnectNoMargin>
      </ContentStyled>
    </StyledRow>
  )
}
const StyledRowBridge = styled(StyledRow)`
  height: initial;
`

export const NoNftsBridge = () => {
  return (
    <StyledRowBridge>
      <ContentStyled>
        <Header>
          <Trans>No NFTs</Trans>
        </Header>
        <TextConnect>
          <Trans>Your NFTs will appear here</Trans>
        </TextConnect>
      </ContentStyled>
    </StyledRowBridge>
  )
}

export const NoNftsVote = () => {
  return (
    <StyledRow>
      <ContentStyled>
        <Header>
          <Trans>No Voted NFTs</Trans>
        </Header>

        <Web3StatusStyled text={<Trans>Vote an NFT</Trans>} />
      </ContentStyled>
    </StyledRow>
  )
}
