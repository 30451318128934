import styled from 'styled-components/macro'
import { Flex, Text } from 'rebass/styled-components'
import { ShadowCard } from '../../../components/Card'

export const VE_PIE_WEIGHTS_COLORS = [
  '#9AF645',
  '#05B6F9',
  '#3D6DFC',
  '#5E2FDD',
  '#C22DE1',
  '#FE01B0',
  '#F64562',
  '#FE5E00',
  '#F8AC00',
  '#1FAD1F',
]

export const Container = styled(ShadowCard)`
  height: 175px;
  border-radius: 24px;
  padding: 25px;
`

export const TxtWeights = styled(Text).attrs({
  fontSize: 36,
  fontWeight: 900,
})`
  color: white;
`

export const TxtTime = styled(Text).attrs({
  fontSize: 18,
  fontWeight: 600,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 42px;
  background: #202136;
  border: 2px solid #33334b;
  border-radius: 50px;
`

export const Weight = styled(Flex)<{ $percent: number; color: string; $totalGap: number }>`
  width: ${({ $percent, $totalGap }) => `calc((100% - ${$totalGap}px) * ${$percent})`};
  background-color: ${({ color }) => color};
`

export const WeightsWrapper = styled(Flex)`
  margin-top: 25px;
  border-radius: 25px;
  height: 50px;
  overflow: hidden;

  ${Weight} + ${Weight} {
    margin-left: 5px;
  }
`
