import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { DashboardContainer } from 'pages/HomePage/HomePage'
import NFTList, { MyVotesForNFTList, VeModelPositionDetails } from './NFTList'
import { Flex } from 'rebass'
import LockPanel from './Panels/LockPanel'
import VotingPanel from './Panels/VotingPanel'
import Weights, { useCollectionColors } from './Weights/Weights'
import { useNftPartnersList } from './useNftPartnersList'
import { IProjectPartner } from 'hooks/zoodao/useCollectionInfo'
import VeModelTabs, { IVeModelTabsVariant } from './Panels/VeModelTabs'
import { Box } from 'components/MUI'
import { useActiveWeb3React } from 'hooks/web3'
import { useAllPoolsWeights } from './hooks'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import { BigNumber } from 'ethers'
import { ZERO } from 'utils/isZero'
import { NoNftVotes } from 'pages/NftBattlesPageV2/NoNfts'
import { useVotedForCollectionsList } from 'hooks/gql'

export const DashboardContainerStyled = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const BridgeFullBox = styled.div`
  display: flex;
  align-items: flex-start;
`

const windowInnerWidth = window.innerWidth

let NavBarLeftWidth = 250
if (windowInnerWidth < 1600) {
  NavBarLeftWidth = 200
}

const widthWorkBox = windowInnerWidth - NavBarLeftWidth - 50 - 5 - 75
const ForBattleBoard = (widthWorkBox + 50) / 4
const ForBattleBoard2 = ForBattleBoard * 1.5

export const FixedRight = styled.div`
  position: sticky;
  top: 25px;
  width: ${ForBattleBoard2}px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`

export const RightPanel = styled(Flex)`
  position: sticky;
  top: 75px;
  padding-top: 25px;
`

export const LeftPanel = styled.div`
  padding-top: 25px;
`

const VePie = () => {
  const [selected, setSelected] = useState<VeModelPositionDetails | null>(null)

  const { allCollectionSlugs, loading } = useNftPartnersList()

  const [projectsArr, setProbjectsArr] = useState<IProjectPartner[]>([])

  const addProject = useCallback(
    (p: IProjectPartner) => {
      if (p.payout_address && !projectsArr.some((item) => item.payout_address === p.payout_address)) {
        setProbjectsArr([...projectsArr, p])
      }
    },
    [projectsArr, setProbjectsArr]
  )

  const [totalWeights, setTotalWeights] = useState<{
    [collection: string]: BigNumber
  }>({})

  const totalWeight = useAllPoolsWeights()

  const accumulateWeight = useCallback(
    (collection: string, weight: BigNumber) => {
      if (!totalWeights[collection] || !totalWeights[collection].eq(weight))
        setTotalWeights({
          ...totalWeights,
          [collection]: weight,
        })
    },
    [totalWeights]
  )

  const [tab, setTab] = useState<IVeModelTabsVariant>(IVeModelTabsVariant.AllProjects)

  const changeTab = useCallback(
    (value: IVeModelTabsVariant) => {
      setTab(value)
      setTotalWeights({})
    },
    [setTab]
  )

  const { account } = useActiveWeb3React()
  const { list: positions, loading: loadingL } = useVotedForCollectionsList(account)

  const amount = useMemo(() => {
    if (tab === IVeModelTabsVariant.AllProjects) {
      return allCollectionSlugs.length
    } else if (tab === IVeModelTabsVariant.MyVotes) {
      return positions?.length
    } else {
      throw Error('Not existing projects amount')
    }
  }, [allCollectionSlugs, tab, positions])

  const tv = useMemo((): BigNumber => {
    if (tab === IVeModelTabsVariant.AllProjects) {
      return totalWeight
    }

    return Object.values(totalWeights).reduce((acc, item) => acc.add(item), ZERO)
  }, [tab, totalWeight, totalWeights])

  const { colors } = useCollectionColors(totalWeights)

  return (
    <DashboardContainerStyled>
      <Flex flexDirection="column">
        {!loading && <Weights totalWeight={tv} totalWeights={totalWeights} />}
        <BridgeFullBox>
          <Box flex={1} height="100%" pt="25px">
            <VeModelTabs type={tab} onChange={changeTab} amount={amount || 0} />

            <Box flex={1} height="100%" mt={'25px'}>
              <ZooLoader loading={loading || loadingL}>
                {tab === IVeModelTabsVariant.AllProjects && (
                  <NFTList
                    projects={allCollectionSlugs}
                    addProject={addProject}
                    accumulateWeight={accumulateWeight}
                    totalWeight={totalWeight}
                    colors={colors}
                  />
                )}
                {tab === IVeModelTabsVariant.MyVotes && (
                  <>
                    {positions?.length === 0 && <NoNftVotes />}
                    <MyVotesForNFTList
                      accumulateWeight={accumulateWeight}
                      totalWeight={totalWeight}
                      positions={positions}
                      selected={selected}
                      setSelected={setSelected}
                      colors={colors}
                    />
                  </>
                )}
              </ZooLoader>
            </Box>
          </Box>
          {allCollectionSlugs.length && (
            <RightPanel>
              <FixedRight>
                {tab === IVeModelTabsVariant.AllProjects && <LockPanel projectsArr={projectsArr} />}
                {tab === IVeModelTabsVariant.MyVotes && <VotingPanel selected={selected} />}
              </FixedRight>
            </RightPanel>
          )}
        </BridgeFullBox>
      </Flex>
    </DashboardContainerStyled>
  )
}

export default VePie
