import { useBattleArenaContract, useJackpotContract } from 'constants/zoodao'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { IPanelJackpotsVariant } from 'pages/Jackpots/Panels/ButtonPanel'
import { useYTokensUsdValue } from 'pages/NftBattlesPage/hooks'
import { useCallback, useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { ZERO } from 'utils/isZero'

export const useJackpotsUnstake = (type: IPanelJackpotsVariant, positionId: string) => {
  const contract = useJackpotContract(type)
  const { account } = useActiveWeb3React()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.unstake(positionId, account || '')
  }, [contract, positionId, account])

  return useTxTemplate(
    `$unstake_jackpot_${type}_${positionId?.toString()}`,
    `Unstake Jackpot ${type} position ${positionId?.toString()}`,
    dataFunc
  )
}

export const useJackpotStake = (type: IPanelJackpotsVariant, positionId: string) => {
  const contract = useJackpotContract(type)
  const { account } = useActiveWeb3React()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.stake(positionId, account || '')
  }, [contract, positionId, account])

  return useTxTemplate(
    `$stake_jackpot_${type}_${positionId?.toString()}`,
    `Stake Jackpot ${type} using position ${positionId?.toString()}`,
    dataFunc,
    'Caller must have only one jackpot position'
  )
}

export const useClaimRewardsJackpot = (type: IPanelJackpotsVariant, positionId: BigNumber | string, epoch: number) => {
  const contract = useJackpotContract(type)
  const { account } = useActiveWeb3React()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.claimReward(positionId, epoch, account || '')
  }, [contract, positionId, account, epoch])

  return useTxTemplate(
    `$claimJackpot_${type}_${positionId.toString()}_${epoch}`,
    `Claim Jackpot${type} rewards`,
    dataFunc
  )
}

export const usePendingJackpotRewards = (type: IPanelJackpotsVariant, pid: string, epoch: number) => {
  const contract = useJackpotContract(type)

  const deps = useMemo(() => [pid, epoch], [pid, epoch])
  const result = useSingleCallResult(contract, 'checkReward', deps)
  const { usd, loading } = useYTokensUsdValue(result?.result?.[0] || ZERO)

  return { rewards: usd, loading: loading || result.loading }
}

export const useJackpotUSD = (epoch: number) => {
  const arena = useBattleArenaContract()

  const deps = useMemo(() => [epoch], [epoch])
  const rewardsAtEpoch = useSingleCallResult(arena, 'jackpotRewardsAtEpoch', deps)

  const { usd, loading } = useYTokensUsdValue(rewardsAtEpoch?.result?.[0] || ZERO)

  return { rewards: usd, loading: loading || rewardsAtEpoch.loading }
}
