import React from 'react'
import styled from 'styled-components/macro'
import { XZOOTVL, XZOOStaked, XZOOBalance, CurrentAPRxZOO } from './InfoCards/DashboardCards'
import ClaimedTotal from './InfoCards/ClaimedTotal'
import { DashboardContainer } from '../HomePage/HomePage'
import { InfoTextBoxXZOO, ParticipantsXZOO } from 'pages/Dashboard/InfoCards/DashboardCards'
import ClaimableRewardsXZOO from './Panels/ClaimableRewardsXZOO'
import { XZooTransactions } from 'pages/NftBattlesPageV2/XZooTransactions'

export const Blocks = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-gap: 35px;
  grid-template-columns: 285px 285px;
  align-self: start;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: initial;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  `};
`

export const RightInfoStyled = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 35px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 10px;
  `};
`
export const LeftInfoStyled = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;

  grid-gap: 35px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    grid-gap: 35px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-gap: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-gap: 10px;
  `};
`
const DashboardFull = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;
`
const DashboardBoxFive = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  width: 100%;
`

interface IAnon {
  children: any
}

export const BlocksStyled = styled(Blocks)`
  grid-template-columns: 1fr 1fr !important;

  max-width: 400px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    max-width: 525px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 455px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 365px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    max-width: 365px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 365px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr 1fr !important;
  `};import Dashboard from './Dashboard';

`
const DashboardSecondLine = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 25px;
`
const FarmingColumnsSecondLine = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`
export const XYield = ({}: IAnon) => {
  return (
    <>
      <DashboardContainer>
        <DashboardFull>
          <DashboardBoxFive>
            <XZOOTVL />
            <XZOOStaked />
            <XZOOBalance />
            <ClaimedTotal index={1} />
            <ParticipantsXZOO index={6} />
          </DashboardBoxFive>
          <DashboardSecondLine>
            <InfoTextBoxXZOO />
            <FarmingColumnsSecondLine>
              <CurrentAPRxZOO />
              <ClaimableRewardsXZOO />
            </FarmingColumnsSecondLine>
          </DashboardSecondLine>

          <XZooTransactions />
        </DashboardFull>
      </DashboardContainer>
    </>
  )
}
