import { ZERO_ADDRESS } from 'constants/misc'
import { useVeModelContract } from 'constants/zoodao'
import { Contract } from 'ethers'
import { useTransition } from 'hooks/gql'
import { toNumber } from 'pages/NftBattlesPage/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { useSingleCallResult } from 'state/multicall/hooks'
import { fromWei } from 'utils/fromWei'
import { ZERO } from 'utils/isZero'

export const useVeModelInfo = () => {
  const contract = useVeModelContract()

  const startDate = toNumber(useSingleCallResult(contract, 'startDate')?.result?.[0] || ZERO)
  const minTimelock = toNumber(useSingleCallResult(contract, 'minTimelock')?.result?.[0] || ZERO)
  const maxTimelock = toNumber(useSingleCallResult(contract, 'maxTimelock')?.result?.[0] || ZERO)
  const epochDuration = toNumber(useSingleCallResult(contract, 'epochDuration')?.result?.[0] || ZERO)

  return {
    startDate,
    minTimelock,
    maxTimelock,
    epochDuration,
  }
}

const NOW = Math.round(new Date().getTime() / 1000)

export const useVeMovelEpoch = (timestamp?: number) => {
  const contract = useVeModelContract()
  const deps = useMemo(() => [timestamp || NOW], [timestamp])
  const epoch = toNumber(useSingleCallResult(contract, 'getEpochNumber', deps)?.result?.[0] || ZERO)

  return epoch
}

export const usePoolWeight = (participant: string) => {
  const contract = useVeModelContract()
  const epoch = useVeMovelEpoch()
  const deps = useMemo(() => [participant, epoch], [participant, epoch])
  const weight = useSingleCallResult(contract, 'poolWeight', deps)?.result?.[0] || ZERO

  return useMemo(() => {
    return +fromWei(weight)
  }, [weight])
}

export const useExpectedPoolWeight = (participant: string) => {
  const contract = useVeModelContract()

  const deps = useMemo(() => [participant], [participant])
  const result = useCallStaticMethod(contract, 'updateCurrentEpochAndReturnPoolWeight', deps)
  return result.result
}

export const useCallStaticMethod = (contract: Contract | null, method: string, deps: any[], params?: any) => {
  const [val, setValue] = useState(ZERO)
  const [loading, setLoading] = useState(false)

  const blockNumber = useBlockNumber()

  useEffect(() => {
    const fetch = async () => {
      if (!contract) {
        return
      }
      setLoading(true)
      try {
        const result = params
          ? await contract.callStatic[method](...deps, params)
          : await contract.callStatic[method](...deps)
        setValue(result)
      } catch (e) {
        console.error('useCallStaticMethod', deps, method, e)
      } finally {
        setLoading(false)
      }
    }

    if (contract && !deps.some((i) => i === undefined || i === null) && !loading) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber, method, contract, deps])

  const transitioned = useTransition(val)

  return { result: transitioned, loading: !transitioned && loading }
}

export const useCallStaticMultiMethod = (contract: Contract | null, method: string, deps: any[], params?: any) => {
  const [val, setValue] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const blockNumber = useBlockNumber()

  useEffect(() => {
    const fetch = async () => {
      if (!contract) {
        return
      }
      setLoading(true)

      try {
        const result = await Promise.allSettled(deps.map((dep) => contract.callStatic[method](...dep, params)))

        // @ts-ignore
        setValue(result.map((i) => (i.status === 'fulfilled' ? i.value : ZERO)))
      } catch (e) {
        console.error('useCallStaticMultiMethod', e, method, deps)
      } finally {
        setLoading(false)
      }
    }

    if (contract && !deps.some((i) => i === undefined || i === null) && !loading) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber, method, contract, deps])

  return { result: val, loading, params }
}

export const useAllPoolsWeights = () => {
  return useExpectedPoolWeight(ZERO_ADDRESS)
}

export interface CallStateResult extends ReadonlyArray<any> {
  readonly [key: string]: any
}
