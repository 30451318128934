import { Currency, Token } from '@uniswap/sdk-core'
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { t, Trans } from '@lingui/macro'
import { FixedSizeList } from 'react-window'
import { ExtendedEther } from '../../constants/tokens'
import { useActiveWeb3React } from '../../hooks/web3'
import { useToken, useIsUserAddedToken, useSearchInactiveTokenLists } from '../../hooks/Tokens'
import { TYPE } from '../../theme'
import { isAddress } from '../../utils'
import Column from '../Column'
import Row from '../Row'
import CommonBases, { useAllSuggestedNFTTokens } from './CommonBases'
import CurrencyList from './CurrencyList'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import { useTokenComparator } from './sorting'
import { SearchInput, Separator } from './styleds'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components/macro'
import useToggle from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import ImportRow from './ImportRow'
import useDebounce from 'hooks/useDebounce'
import { ButtonOrangeStaking } from 'components/Button'

export const IconSearch = styled.div`
  font-family: 'Font Awesome 6 Pro';
  font-weight: bold;
  display: inline-block;
  margin-left: 25px;
  z-index: 3;
  position: absolute;
  font-size: 20px;
`

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const SearchInputWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
`

const Footer = styled.div`
  width: 100%;
  justify-content: flex-end;
  margin-top: 25px;
  // margin-bottom: 25px;
`

interface CurrencySearchProps {
  isOpenFlag: boolean
  onDismissHandler?: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  onTokenInputChange?: (a: string) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  showManageView: () => void
  showImportView: () => void
  setImportToken: (token: Token) => void
  isProMode?: boolean
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  onDismissHandler,
  isOpenFlag,
  showManageView,
  showImportView,
  setImportToken,
  onTokenInputChange,
  isProMode,
}: CurrencySearchProps) {
  const { chainId } = useActiveWeb3React()
  const theme = useTheme()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  const allTokens = useAllSuggestedNFTTokens() // useAllTokens()

  // if they input an address, use it
  const isAddressSearch = isAddress(debouncedQuery)

  const searchToken = useToken(debouncedQuery)

  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  useEffect(() => {
    if (isAddressSearch) {
      ReactGA.event({
        category: 'Currency Select',
        action: 'Search by address',
        label: isAddressSearch,
      })
    }
  }, [isAddressSearch])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery)
  }, [allTokens, debouncedQuery])

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator])

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)

  const ether = useMemo(() => chainId && ExtendedEther.onChain(chainId), [chainId])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismissHandler && onDismissHandler()
    },
    [onDismissHandler, onCurrencySelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpenFlag) setSearchQuery('')
  }, [isOpenFlag])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(
    (event: any) => {
      const input = event.target.value
      const checksummedInput = isAddress(input)
      const result = checksummedInput || input
      setSearchQuery(result)
      onTokenInputChange && onTokenInputChange(result)
      fixedList.current?.scrollTo(0)
    },
    [onTokenInputChange]
  )

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === 'eth' && ether) {
          handleCurrencySelect(ether)
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [debouncedQuery, ether, filteredSortedTokens, handleCurrencySelect]
  )

  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    filteredTokens.length === 0 || (debouncedQuery.length > 2 && !isAddressSearch) ? debouncedQuery : undefined
  )

  return (
    <ContentWrapper>
      <SearchInputWrapper>
        <Row>
          <IconSearch></IconSearch>
          <SearchInput
            type="text"
            id="token-search-input"
            placeholder={t`Search by name or NFT address`}
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </Row>
        {showCommonBases && <CommonBases onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency} />}
      </SearchInputWrapper>
      <Separator />
      {searchToken && !searchTokenIsAdded ? (
        <Column style={{ padding: '20px 0', height: '100%' }}>
          <ImportRow token={searchToken} showImportView={showImportView} setImportToken={setImportToken} />
        </Column>
      ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 ? (
        <div style={{ flex: '1', minHeight: 240, marginTop: 12 }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                currencies={filteredSortedTokens}
                otherListTokens={filteredInactiveTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showImportView={showImportView}
                setImportToken={setImportToken}
                showCurrencyAmount={showCurrencyAmount}
              />
            )}
          </AutoSizer>
        </div>
      ) : searchToken ? (
        <Column style={{ padding: '20px', height: '100%' }}>
          <TYPE.main color={theme.text3} textAlign="center" mb="20px">
            <Trans>No results found.</Trans>
          </TYPE.main>
        </Column>
      ) : null}
      {isProMode && (
        <Footer>
          <Row justify="center">
            <ButtonOrangeStaking onClick={showManageView} color={theme.primary1}>
              <Trans>Manage Token Lists</Trans>
            </ButtonOrangeStaking>
          </Row>
        </Footer>
      )}
    </ContentWrapper>
  )
}
