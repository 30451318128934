import React, { memo, useMemo } from 'react'
import styled from 'styled-components/macro'
import PortfolioNFTOverview, {
  PortfolioJackpotyNFTOverview,
} from '../NftBattlesPageV2/cards/PortfolioNFTOverview/PortfolioNFTOverview'
import { Flex } from 'rebass/styled-components'
import ZooLoader from 'components/ZooLoader/ZooLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PORTFOLIO_TABS, useInfiniteScrollProps } from 'pages/NftBattlesPageV2/NftBattlesNfts'
import { BigNumber } from 'ethers'
import { NoNftsStaked, NoNftsVote } from 'pages/NftBattlesPageV2/NoNfts'

const CardContainer = styled(Flex)`
  flex: 1;
  flex-wrap: wrap;
  min-height: 600px;
  height: 800px;

  .infinite-scroll-component__outerdiv {
    width: 100%;
    height: 100%;
  }
`

const NFTList = ({
  target,
  toggle,
  loading,
  staked,
  votings,
  jackpots,
}: {
  target: string
  toggle: (
    isUpdate: boolean,
    pid: string,
    daiInvested: BigNumber,
    zooInvested: BigNumber,
    votingPositionId?: BigNumber
  ) => void
  staked: any[]
  votings: any[]
  loading: boolean
  jackpots: any[]
}) => {
  const display = useMemo(() => {
    if (target === PORTFOLIO_TABS.STAKED) {
      return [...jackpots, ...staked]
    } else {
      return [...jackpots, ...votings]
    }
  }, [target, staked, votings, jackpots])

  return (
    <CardContainer>
      <ZooLoader loading={loading}>
        {display.length > 0 ? (
          <InfiniteScrollListStyled
            itemsTotal={display}
            renderItems={(items) => {
              return (
                <>
                  {items.map((item: any, index: number) => {
                    if (item.jackpotPositionId) {
                      return (
                        <PortfolioJackpotyNFTOverview
                          key={`${item.transactionHash}_${index}`}
                          stakingPositionId={item.positionId}
                          votingPositionId={item.positionId}
                          type={target}
                        />
                      )
                    } else {
                      const pid = item.stakingPositionId.toString()

                      return (
                        <PortfolioNFTOverview
                          key={`${pid}_${index}`}
                          isDeleted={item.isDeleted}
                          stakingPositionId={pid}
                          votingPositionId={item.votingPositionId}
                          type={target}
                          toggle={toggle}
                        />
                      )
                    }
                  })}
                </>
              )
            }}
          />
        ) : (
          <>
            {target === PORTFOLIO_TABS.STAKED && <NoNftsStaked />}
            {target === PORTFOLIO_TABS.VOTES && <NoNftsVote />}
          </>
        )}
      </ZooLoader>
    </CardContainer>
  )
}
interface IScrollProps {
  itemsTotal: any[]
  renderItems: (item: any[]) => any
  className?: string
  children?: any
}

const PER_PAGE = 9

const InfiniteScrollList = ({ className, itemsTotal, renderItems }: IScrollProps) => {
  const { currentList, hasMore, fetchData } = useInfiniteScrollProps(itemsTotal, PER_PAGE)

  return (
    <InfiniteScroll
      className={className}
      dataLength={currentList.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={null}
      scrollableTarget="body_over"
    >
      <>{renderItems(currentList)}</>
    </InfiniteScroll>
  )
}

const InfiniteScrollListStyled = styled(InfiniteScrollList)`
  width: 100%;
  max-width: calc((300px + 25px) * 4);
  overflow: visible !important;
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 260px));
  grid-gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: minmax(250px, 260px);
  `};
`

export default memo(NFTList)
