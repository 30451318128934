import React from 'react'
import PositionsForJackpotsOverview from '../NftBattlesPageV2/cards/JackpotsNFTOverview/PositionsForJackpotsOverview'
import { IPanelJackpotsVariant } from './Panels/ButtonPanel'

interface ICommonPosition {
  stakingPositionId: string
  votingPositionId: string
  id: string
}

const PositionsForJackpotsList = ({
  type,
  positions,
  isAvailableToStake,
}: {
  type: IPanelJackpotsVariant
  positions: ICommonPosition[]
  isAvailableToStake: boolean
}) => {
  return (
    <>
      {positions.map((p) => {
        return (
          <PositionsForJackpotsOverview
            key={p.id}
            panelType={type}
            positionId={p.votingPositionId || p.stakingPositionId}
            stakingPositionId={p.stakingPositionId}
            isAvailableToStake={isAvailableToStake}
          />
        )
      })}
    </>
  )
}

export default PositionsForJackpotsList
