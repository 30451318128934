import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { BigNumber } from '@ethersproject/bignumber'
import { useActiveWeb3React } from '../../hooks/web3'
import { useHasExecutedNftAction, useHasPendingNftAction, useTransactionAdder } from '../../state/transactions/hooks'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import {
  useBattleArenaContract,
  useBattleArenaFunctions,
  useBattleStakerContract,
  useBattleVoterContract,
  useBattleYiernContract,
  useVeModelContract,
  useZooDaoRoundAContract,
  useZooDaoCrowdsaleContract,
  useZooDaoRoundBContract,
} from '../../constants/zoodao'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { BN_1E18, ZERO } from '../../utils/isZero'
import { useCallback, useMemo, useState } from 'react'
import { WINNER } from '../NftBattlesPageV2/utils'
import { useAddPopup } from 'state/application/hooks'
import { usePairedNfts, useStakerPositions } from 'hooks/gql'
import { useCallStaticMethod } from 'pages/VePie/hooks'
import { isAddress } from 'utils'
import { useStableCurrency } from 'hooks/Tokens'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { formatDecimal } from 'utils/numberWithCommas'

export enum BATTLE_STAGES {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
  FOURTH = 3,
  FIFTH = 4,
}

export const toNumber = (bn: BigNumber | number) => {
  if (typeof bn === 'number') {
    return bn
  }
  return bn.toNumber()
}

export const useBattleStage = () => {
  const contract = useBattleArenaContract()
  return toNumber(useSingleCallResult(contract, 'getCurrentStage')?.result?.[0] || ZERO)
}

export const useBattleRewards = () => {
  const contract = useBattleArenaContract()
  const stakersRewards = useSingleCallResult(contract, 'baseStakerReward')
  const votersRewards = useSingleCallResult(contract, 'baseVoterReward')

  return {
    stakersRewards: stakersRewards?.result?.[0] || ZERO,
    votersRewards: votersRewards?.result?.[0] || ZERO,
    loading: stakersRewards.loading || votersRewards.loading,
  }
}

export const useBattleVrfRequest = () => {
  const contract = useBattleArenaContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `request_random`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onRequestVrf() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const txData = await contract.populateTransaction.requestRandom()

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'VRF request transmitted!',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    onRequestVrf,
    pending,
  }
}

export const useIsRandomRequested = () => {
  const contract = useBattleArenaFunctions()
  const result = useSingleCallResult(contract, 'isRandomRequested')?.result?.[0]
  return result
}

export const useIsRandomFullfilled = () => {
  const contract = useBattleArenaFunctions()
  const result = useSingleCallResult(contract, 'isRandomFulfilled')?.result?.[0]
  return result
}

export const useBattleEpoch = () => {
  const contract = useBattleArenaContract()

  const currentEpoch: number = toNumber(useSingleCallResult(contract, 'currentEpoch')?.result?.[0] || ZERO)

  return currentEpoch
}

export const useTotalRewardsBattle = (yTokens: BigNumber) => {
  const contract = useBattleArenaContract()

  const deps = useMemo(() => [yTokens], [yTokens])
  const res = useCallStaticMethod(contract, 'sharesToTokens', deps)

  return {
    result: res?.result || ZERO,
    loading: res?.loading,
  }
}

export const useBattlesData = () => {
  const contract = useBattleArenaContract()

  const firstStageDuration: number = toNumber(useSingleCallResult(contract, 'firstStageDuration')?.result?.[0] || ZERO)
  const secondStageDuration: number = toNumber(
    useSingleCallResult(contract, 'secondStageDuration')?.result?.[0] || ZERO
  )
  const thirdStageDuration: number = toNumber(useSingleCallResult(contract, 'thirdStageDuration')?.result?.[0] || ZERO)
  const fourthStageDuration: number = toNumber(
    useSingleCallResult(contract, 'fourthStageDuration')?.result?.[0] || ZERO
  )
  const fifthStageDuration: number = toNumber(useSingleCallResult(contract, 'fifthStageDuration')?.result?.[0] || ZERO)
  const epochStartDate: number = toNumber(useSingleCallResult(contract, 'epochStartDate')?.result?.[0] || ZERO)
  const epochDuration: number = toNumber(useSingleCallResult(contract, 'epochDuration')?.result?.[0] || ZERO)

  return {
    firstStageDuration,
    thirdStageDuration,
    secondStageDuration,
    epochStartDate,
    epochDuration,
    fourthStageDuration,
    fifthStageDuration,
  }
}

export const useBattleStakerPositionInfo = (positionId: string): any => {
  const arena = useBattleArenaContract()
  const staker = useBattleStakerContract()

  const deps = useMemo(() => [positionId], [positionId])
  const itemArena = useSingleCallResult(arena, 'stakingPositionsValues', deps)
  const itemStaker = useSingleCallResult(staker, 'positions', deps)

  return useMemo(
    () => ({
      loading: itemArena.loading || itemStaker.loading,
      positionInfo: {
        ...(itemArena?.result || {}),
        ...(itemStaker?.result || {}),
      },
    }),
    [itemStaker, itemArena]
  )
}

export const useRewardsFor = (positionId: string, epoch: number): any => {
  const contract = useBattleArenaContract()
  const deps = useMemo(() => [positionId || '', epoch], [positionId, epoch])
  const result = useSingleCallResult(contract, 'rewardsForEpoch', deps)

  return {
    data: result?.result ? result?.result : undefined,
    loading: result?.loading,
  }
}

export const useMaxZooValueConverter = (value: BigNumber) => {
  const contract = useBattleArenaFunctions()

  const deps = useMemo(() => [value], [value])
  return useSingleCallResult(contract, 'computeVotesByZoo', deps)?.result?.[0]
}

export const useBattleVotingPositionInfo = (positionId: BigNumber | string = '') => {
  const contract = useBattleArenaContract()
  const deps = useMemo(() => [positionId], [positionId])
  const data = useSingleCallResult(contract, 'votingPositionsValues', deps)
  return {
    info: data?.result as any,
    loading: data.loading,
  }
}
const DEFAULT: any[] = []

export const usePricePerShare = () => {
  const yiernC = useBattleYiernContract()

  const result = useCallStaticMethod(yiernC, 'exchangeRateCurrent', DEFAULT)

  return useMemo(
    () => ({
      result: result?.result || ZERO,
      loading: result.loading,
    }),
    [result]
  )
}

export const useYTokensUsdValue = (amount: BigNumber) => {
  const result = usePricePerShare()

  return useMemo(() => {
    return {
      usd: amount.mul(result.result).div(BN_1E18),
      loading: result.loading,
    }
  }, [result, amount])
}

export const useNftsInfo = (search?: string) => {
  const { positions, loading } = useStakerPositions(null, null, search)

  return useMemo(() => {
    return {
      loading: loading,
      nftsInEpoch: positions.length,
      nftsAsArray: positions,
    }
  }, [positions, loading])
}

export const useNftsPairsInEpoch = (epoch: number, searchQuery: string) => {
  const { nfts, loading } = usePairedNfts(null, null, epoch, searchQuery)

  return useMemo(() => {
    return {
      pairsInEpoch: nfts.length,
      pairs: nfts,
      loading: loading,
    }
  }, [nfts, loading])
}

export const usePairInEpochInfo = (epoch: number, index: number | string) => {
  const contract = useBattleArenaContract()

  const deps = useMemo(() => [epoch, index], [epoch, index])
  const result = useSingleCallResult(contract, 'pairsInEpoch', deps)
  const data = result?.result

  return useMemo(() => {
    return {
      data: {
        ...data,
        winner: data && data.playedInEpoch ? (data.win ? WINNER.A : WINNER.B) : WINNER.UNKNOWN,
      },
      loading: result?.loading,
    }
  }, [result, data])
}

export const useAvailablePairChecking = () => {
  const contract = useBattleArenaContract()

  const numberOfNftsWithNonZeroVotes: number = toNumber(
    useSingleCallResult(contract, 'numberOfNftsWithNonZeroVotes', DEFAULT)?.result?.[0] || 0
  )

  const nftsInGame: number = toNumber(useSingleCallResult(contract, 'nftsInGame', DEFAULT)?.result?.[0] || 0)

  return useMemo(() => {
    return numberOfNftsWithNonZeroVotes / 2 > nftsInGame / 2
  }, [numberOfNftsWithNonZeroVotes, nftsInGame])
}

export const useNftInEpoch = (index: number | string) => {
  const contract = useBattleArenaContract()

  const deps = useMemo(() => [index], [index])
  const positionId: string = (useSingleCallResult(contract, 'activeStakerPositions', deps)?.result?.[0] || 0).toString()

  return {
    positionId,
  }
}

export const useAllowedForStaking = (nftAddress: string) => {
  const contract = useVeModelContract()

  const deps = useMemo(() => [isAddress(nftAddress) ? nftAddress : ''], [nftAddress])
  const res = useSingleCallResult(contract, 'eligibleCollections', deps)

  return {
    allowedForStaking: res?.result?.[0] || false,
    loading: res.loading,
  }
}

export const useRecomputeMethod = (votingPositionId: string, method: string) => {
  const contract = useBattleArenaContract()
  const [error, setError] = useState(false)
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const epoch = useBattleEpoch()

  const actionType = `${method}_${votingPositionId}_${epoch}`
  const [isTried, setTried] = useState(false)
  const isExecutedAlready = useHasExecutedNftAction(actionType)

  const pending = useHasPendingNftAction('', '', actionType)

  const addPopup = useAddPopup()
  async function onRecompute() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      // @ts-ignore
      const txData = await contract.populateTransaction[method](votingPositionId)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Recompute votes for ${votingPositionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          setError(true)

          setTried(true)

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>You already have the maximum voting power</Trans>,
            },
          })
        })
    } else {
      return
    }
  }

  return {
    onRecompute,
    pending,
    isExecutedAlready: isExecutedAlready || isTried,
    error,
  }
}

const ROUNDER = 1e6

export const getBigNumberValue = (amount: any) =>
  BigNumber.from(Math.floor(amount * ROUNDER))
    .mul(BN_1E18)
    .div(ROUNDER)

export const useAddZooToVoting = (amount: number | undefined, votingPositionId: string) => {
  const contract = useBattleVoterContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `addZooToVoting_${votingPositionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  const addPopup = useAddPopup()
  const { stableCryptoSymbol } = useStableCurrency()

  async function onVote() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account && amount) {
      const value = getBigNumberValue(amount)

      const txData = await contract.populateTransaction.addZooToPosition(votingPositionId, value)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Vote with Zoo for ${votingPositionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: (
                <Trans>Insufficient ZOO balance or exceeds max ZOO based on {stableCryptoSymbol} votes</Trans>
              ),
            },
          })
        })
    } else {
      return
    }
  }

  return {
    vote: onVote,
    pending,
  }
}

export const useAddDaiToVoting = (amount: number | undefined, votingPositionId: string) => {
  const contract = useBattleVoterContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `addDaiToPosition_${votingPositionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  const addPopup = useAddPopup()

  async function onVote() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const value = getBigNumberValue(amount)

      const txData = await contract.populateTransaction.addDaiToPosition(votingPositionId, value)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Vote with USD for ${votingPositionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>Not available for voting</Trans>,
            },
          })
        })
    } else {
      return
    }
  }

  return {
    vote: onVote,
    pending,
  }
}

export const useNftPairing = (stakingPositionId: string) => {
  const contract = useBattleArenaContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `pairNft_${stakingPositionId}`
  const [disabled, setDisabled] = useState(false)

  const pending = useHasPendingNftAction('', '', actionType)
  const addPopup = useAddPopup()

  async function onCreatePair() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const txData = await contract.populateTransaction.pairNft(stakingPositionId)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Create pair for ${stakingPositionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
          addPopup({
            msg: {
              success: false,
              title: <Trans>Uneven Participants or Zero Votes</Trans>,
              description: <Trans>Cannot pair NFT</Trans>,
            },
          })
          setDisabled(true)
        })
    } else {
      return
    }
  }

  return {
    onCreatePair,
    pending,
    disabled,
  }
}

export const useCreateNewVotingPosition = (amount?: number, positionId?: string) => {
  const contract = useBattleVoterContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `$createNewVotingPosition_${positionId}`

  const pending = useHasPendingNftAction('', '', actionType)
  const addPopup = useAddPopup()

  async function onVote() {
    if (!chainId || !library || !account) return

    if (!contract || positionId === undefined) {
      return
    }

    if (account) {
      const value = getBigNumberValue(amount)

      const txData = await contract.populateTransaction.createNewVotingPosition(positionId, value)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Vote for position ${positionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })

        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>Can not vote for position. Please, wait few minutes.</Trans>,
            },
          })
        })
    } else {
      return
    }
  }

  return {
    vote: onVote,
    pending,
  }
}

export const useWithdrawFromDaiVotingPosition = (amount: number | undefined, votingPositionId: string | undefined) => {
  const contract = useBattleVoterContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [isError, setIsError] = useState(false)

  const actionType = `$withdrawDaiFromVotingPosition_${votingPositionId}`

  const pending = useHasPendingNftAction('', '', actionType)
  const addPopup = useAddPopup()

  async function withdraw() {
    if (!chainId || !library || !account) return

    if (!contract || votingPositionId === undefined) {
      return
    }

    if (account && amount) {
      const value = getBigNumberValue(amount)

      const txData = await contract.populateTransaction.withdrawDaiFromVotingPosition(votingPositionId, account, value)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Withdraw USD from position ${votingPositionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })

        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          setIsError(true)

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>Your NFT is in use</Trans>,
            },
          })
        })
    } else {
      return
    }
  }

  return {
    withdraw,
    pending,
    isError,
  }
}

export const useRoundASale = (amount: number | undefined) => {
  const contract = useZooDaoRoundAContract()

  const value = useMemo(() => (amount ? getBigNumberValue(amount) : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.buyZoo(value)
  }, [contract, value])

  return useTxTemplate(`angles_buy_zoo`, `Buy  ${formatDecimal(value)} ZOO with FRAX`, dataFunc)
}

export const useRoundAClaim = () => {
  const contract = useZooDaoRoundAContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.claimZoo()
  }, [contract])

  return useTxTemplate(`angles_claim_zoo`, `Claim ZOO from contribution`, dataFunc)
}

export const useRoundBSale = (amount: number | undefined) => {
  const contract = useZooDaoRoundBContract()

  const value = useMemo(() => (amount ? getBigNumberValue(amount) : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.buyZoo(value)
  }, [contract, value])

  return useTxTemplate(`angles_buy_zoo`, `Buy  ${formatDecimal(value)} ZOO with FRAX`, dataFunc)
}

export const useRoundBClaim = () => {
  const contract = useZooDaoRoundBContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.claimZoo()
  }, [contract])

  return useTxTemplate(`angles_claim_zoo`, `Claim ZOO from contribution`, dataFunc)
}
export const useSaleContribution = (amount: number, isWhitelisted = true) => {
  const contract = useZooDaoCrowdsaleContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `sale_contribution`

  const pending = useHasPendingNftAction('', '', actionType)
  const addPopup = useAddPopup()

  async function contribute() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }
    const value = getBigNumberValue(amount)

    if (value.isZero()) {
      addPopup({
        msg: {
          success: false,
          title: <Trans>Transaction Denied</Trans>,
          description: <Trans>Please input more than 0 USD</Trans>,
        },
      })
      return
    }

    if (account) {
      const txData = isWhitelisted
        ? await contract.populateTransaction.whitelistedBuy(value)
        : await contract.populateTransaction.notWhitelistedBuy(value)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Contribution ${amount} DAI`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>Can not create a new contribution</Trans>,
            },
          })
        })
    } else {
      return
    }
  }

  return {
    contribute,
    pending,
  }
}

export const useClaimZoo = () => {
  const contract = useZooDaoCrowdsaleContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `claim_zoo`

  const pending = useHasPendingNftAction('', '', actionType)
  const addPopup = useAddPopup()

  async function claim() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const txData = await contract.populateTransaction.claimZoo()

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Claim ZOO`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>Can not claim: locked by time</Trans>,
            },
          })
        })
    } else {
      return
    }
  }

  return {
    claim,
    pending,
  }
}

export const useWithdrawZooFromVotingPosition = (positionId: string, amount?: number) => {
  const contract = useBattleVoterContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `$withdrawZooFromVotingPosition_${positionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function withdraw() {
    if (!chainId || !library || !account) return

    if (!contract || positionId === undefined) {
      return
    }

    if (account && amount) {
      const value = getBigNumberValue(amount)
      const txData = await contract.populateTransaction.withdrawZooFromVotingPosition(positionId, value, account)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Withdraw ${amount} ZOO from voting position ${positionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    withdraw,
    pending,
  }
}

export const useWithdrawDaiFromVotingPosition = (positionId: string, amount: number) => {
  const contract = useBattleVoterContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `$withdrawDaiFromVotingPosition_${positionId}`

  const pending = useHasPendingNftAction('', '', actionType)

  async function withdraw() {
    if (!chainId || !library || !account) return

    if (!contract || positionId === undefined) {
      return
    }

    const value = getBigNumberValue(amount)

    if (account) {
      const txData = await contract.populateTransaction.withdrawDaiFromVotingPosition(positionId, account, value)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Withdraw ${amount} USD from voting position ${positionId} (Battle Arena)`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    withdraw,
    pending,
  }
}

export const useUpdateEpoch = () => {
  const contract = useBattleArenaContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `update_epoch`

  const pending = useHasPendingNftAction('', '', actionType)

  async function onUpdate() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const txData = await contract.populateTransaction.updateEpoch()

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'Updated Epoch in Battles',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return {
    onUpdate,
    pending,
  }
}

export const useChooseWinners = (pairIndex: string | number) => {
  const contract = useBattleArenaContract()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const actionType = `chooseWinners_${pairIndex}`

  const pending = useHasPendingNftAction('', '', actionType)
  const addPopup = useAddPopup()

  async function onChoose() {
    if (!chainId || !library || !account) return

    if (!contract) {
      return
    }

    if (account) {
      const txData = await contract.populateTransaction.chooseWinnerInPair(pairIndex)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Choose winners (Battle Arena) in pair ${pairIndex}`,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }

          addPopup({
            msg: {
              success: false,
              title: <Trans>Transaction Denied</Trans>,
              description: <Trans>Maybe winner was already selected?</Trans>,
            },
          })
        })
    } else {
      return
    }
  }
  return {
    onChoose,
    pending,
  }
}
