import { Trans } from '@lingui/macro'
import { useBattleStakerContract, useBattleVoterContract, useWellAddress, useWGlmrAddress } from 'constants/zoodao'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { useUserVotingRewards, useUserStakingRewards, useSumRewards } from 'hooks/zoodao/useBattlesEventsData'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import { BATTLE_STAGES, useBattleStage } from 'pages/NftBattlesPage/hooks'
import { Dots } from 'pages/Pool/styleds'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { CardHeadBox } from '../CardMiniForNftInfo'
import { useStableCurrency } from '../../../hooks/Tokens'
import { LogoCoin, InputLabel, LockButton } from 'pages/Portfolio/Panels/styles'
import { CardLine, DashboardCardFlex } from 'pages/Dashboard/styleds'
import { Flex } from 'rebass/styled-components'
import IconFRAX from 'assets/svg/frax.svg'
import IconGLMR from 'assets/svg/glmr.svg'
import IconWELL from 'assets/svg/well.svg'
import { formatDecimal } from 'utils/numberWithCommas'
import { useStakingClaimedRewards, useTransferRewards } from 'hooks/gql'
import Info from 'components/Info'

const TripleRewards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-top: 15px;
`
const MiniBoxRewards = styled.div`
  padding: 15px;
  background-color: #33334b;
  border-radius: 12px;
`
const TitleName = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: ${({ theme }) => theme.text2};
`
const LogoMiniCoin = styled(LogoCoin)`
  width: 22px;
  height: 22px;
  margin-right: 5px;
`
const LineNum = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
`

const useBatchClaimFromStakings = (vPids: BigNumber[] | string[]) => {
  const contract = useBattleStakerContract()
  const { account } = useActiveWeb3React()
  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.batchClaimRewardsFromStaking(vPids, account || '')
  }, [contract, vPids, account])

  return useTxTemplate(`use_batch_claim_for_staking_${vPids.join('_')}`, 'Claim rewards for staked NFTs', dataFunc)
}

const useBatchClaimFromVotings = (vPids: BigNumber[]) => {
  const contract = useBattleVoterContract()
  const { account } = useActiveWeb3React()
  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.batchClaimRewardsFromVotings(vPids, account || '')
  }, [contract, vPids, account])

  return useTxTemplate(`use_batch_claim_for_votings_${vPids.join('_')}`, 'Claim rewards for votings', dataFunc)
}

export const ClaimableBattleStakingRewardsNew = ({ stakingPids }: { stakingPids: string[] }) => {
  const { stakingAmountUsd } = useUserStakingRewards(stakingPids)
  const { action, pending } = useBatchClaimFromStakings(stakingPids)

  return (
    <RewardsTemplate2
      disabled={stakingPids.length === 0}
      usd={stakingAmountUsd}
      action={action}
      pending={pending}
      isStaking
    />
  )
}

export const ClaimableBattleVotingRewardsNew = ({ votingObjs }: { votingObjs: any[] }) => {
  const { votingAmountUsd, votingWells, votingGlmrs } = useUserVotingRewards(votingObjs)
  const pids = useMemo(() => votingObjs.map((item) => item.votingPositionId), [votingObjs])
  const { action, pending: pending } = useBatchClaimFromVotings(pids)

  return (
    <RewardsTemplate2
      disabled={votingObjs.length === 0}
      usd={votingAmountUsd}
      action={action}
      pending={pending}
      wells={votingWells}
      glmrs={votingGlmrs}
    />
  )
}

const RewardsTemplate2 = ({
  usd,
  wells,
  glmrs,
  action,
  disabled,
  pending,
  isStaking = false,
}: {
  usd: BigNumber
  glmrs?: BigNumber
  wells?: BigNumber
  action: () => void
  disabled: boolean
  pending: boolean
  isStaking?: boolean
}) => {
  const { stableCurrency, stableCurrencyIcon } = useStableCurrency()

  const stage = useBattleStage()

  const inactive = (usd && usd.isZero()) || stage !== BATTLE_STAGES.FIRST

  return (
    <DashboardCardFlex inactive={inactive}>
      <CardLine index={13} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>Claimable Rewards</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <InputLabel mt={25} alignItems="center">
        <Trans>Rewards from selected NFTs:</Trans>
      </InputLabel>

      <TripleRewards>
        <MiniBoxRewards>
          <TitleName>{stableCurrency}</TitleName>
          <LineNum>
            <LogoMiniCoin src={stableCurrencyIcon} />
            {formatDecimal(usd, 2)}
          </LineNum>
        </MiniBoxRewards>
        {isStaking || !glmrs || !wells ? null : (
          <>
            <MiniBoxRewards>
              <TitleName>GLMR</TitleName>
              <LineNum>
                <LogoMiniCoin src={IconGLMR} />
                <Trans>{formatDecimal(glmrs, 2)}</Trans>
              </LineNum>
            </MiniBoxRewards>
            <MiniBoxRewards>
              <TitleName>WELL</TitleName>
              <LineNum>
                <LogoMiniCoin src={IconWELL} />
                <Trans>{formatDecimal(wells, 2)}</Trans>
              </LineNum>
            </MiniBoxRewards>
          </>
        )}
      </TripleRewards>

      <Flex alignItems="center" justifyContent="end" mt={25}>
        <LockButton disabled={pending || disabled || inactive || usd.isZero()} onClick={action}>
          {pending ? (
            <Dots>
              <Trans>Claiming</Trans>
            </Dots>
          ) : (
            <Trans>Claim Rewards</Trans>
          )}
        </LockButton>
      </Flex>
    </DashboardCardFlex>
  )
}

// FIXME for glmr/well
export const MyTotalRewardsClaimed = ({ isStaking }: { isStaking: boolean }) => {
  const { account } = useActiveWeb3React()
  const { loading, rewards } = useStakingClaimedRewards(isStaking, account)

  const amount = useSumRewards(rewards)

  const stage = useBattleStage()

  return (
    <DashboardCardFlex>
      <CardLine index={13} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>My Total Rewards Claimed</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      {stage !== BATTLE_STAGES.FIRST && (
        <Info>
          <Trans>Rewards may be claimed during Stage 1</Trans>
        </Info>
      )}

      <TripleRewards>
        <MiniBoxRewards>
          <TitleName>FRAX</TitleName>
          <LineNum>
            <LogoMiniCoin src={IconFRAX} />
            {loading ? (
              <Trans>
                <Dots>Loading</Dots>
              </Trans>
            ) : (
              formatDecimal(amount, 2)
            )}
          </LineNum>
        </MiniBoxRewards>
        {!isStaking && <VoterRewards />}
      </TripleRewards>
    </DashboardCardFlex>
  )
}

const VoterRewards = () => {
  const wglmr = useWGlmrAddress()
  const well = useWellAddress()

  const { account } = useActiveWeb3React()

  const { value: valueWglmr, loading: loadingWglmr } = useTransferRewards(account, wglmr)
  const { value: valueWell, loading: loadingWell } = useTransferRewards(account, well)

  return (
    <>
      <MiniBoxRewards>
        <TitleName>GLMR</TitleName>
        <LineNum>
          <LogoMiniCoin src={IconGLMR} />
          {loadingWglmr ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            formatDecimal(valueWglmr, 2)
          )}
        </LineNum>
      </MiniBoxRewards>
      <MiniBoxRewards>
        <TitleName>WELL</TitleName>
        <LineNum>
          <LogoMiniCoin src={IconWELL} />
          {loadingWell ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            formatDecimal(valueWell, 2)
          )}
        </LineNum>
      </MiniBoxRewards>
    </>
  )
}
