import React from 'react'
import { Trans } from '@lingui/macro'
import { AlarmText, Container } from './styles'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'
import { IPanelJackpotsVariant } from '../../../Jackpots/Panels/ButtonPanel'
import { Dots } from 'pages/Pool/styleds'
import { useJackpotStake } from './hooks'
import { StakingPosition } from './PositionsView'
import { useBattleStakerAddress, useBattleVoterAddress, useJackpotContract } from 'constants/zoodao'
import { ApproveCheckerNFT } from 'pages/NftBattlesPage/ApproveCheckerBattleArena'
import { ButtonStake } from 'components/Button'

const PositionsForJackpotsOverview = ({
  className,
  panelType,
  positionId,
  stakingPositionId,
  isAvailableToStake,
}: {
  positionId: string
  stakingPositionId: string
  className?: string
  panelType: IPanelJackpotsVariant
  isAvailableToStake: boolean
}) => {
  const { pending, action } = useJackpotStake(panelType, positionId)

  const poolContract = useJackpotContract(panelType)

  const staking = useBattleStakerAddress()
  const voting = useBattleVoterAddress()

  const address = panelType === IPanelJackpotsVariant.JackpotA ? staking : voting

  return (
    <Container className={className} inactive={!isAvailableToStake}>
      <Flex flexDirection="column" pb={25} height="100%">
        <StakingPosition positionId={stakingPositionId} panelType={panelType} />

        <Flex pl={16} pr={16} flexDirection="column" marginTop={'auto'}>
          {isAvailableToStake ? (
            <ApproveCheckerNFT poolContract={poolContract} tokenId={positionId} nftAddress={address}>
              <ButtonStake onClick={action} disabled={pending}>
                {pending ? (
                  <Dots>
                    <Trans>Staking</Trans>
                  </Dots>
                ) : (
                  <Trans>Stake</Trans>
                )}
              </ButtonStake>
            </ApproveCheckerNFT>
          ) : (
            <AlarmText>
              <Trans>
                You may only stake one {panelType === IPanelJackpotsVariant.JackpotA ? 'zNFT' : 'zVote'} position in
                Jackpots
              </Trans>
            </AlarmText>
          )}
        </Flex>
      </Flex>
    </Container>
  )
}

export default styled(PositionsForJackpotsOverview)``
