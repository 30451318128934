import React from 'react'
import styled from 'styled-components/macro'
import VePieNFTOverview from '../NftBattlesPageV2/cards/VePieNFTOverview/VePieNFTOverview'
import { Flex } from 'rebass/styled-components'
import { IProjectPartner } from 'hooks/zoodao/useCollectionInfo'
import VePieVoteNFTOverview, { getVoteKey } from 'pages/NftBattlesPageV2/cards/VePieNFTOverview/VePieVoteNFTOverview'
import { IApiSlug } from './useNftPartnersList'
import { BigNumber } from 'ethers'
import { ICollectionColors } from './Weights/Weights'

const CardContainer = styled(Flex)`
  flex: 1;
  flex-wrap: wrap;
`

const NFTList = ({
  projects,
  addProject,
  totalWeight,
  accumulateWeight,
  colors,
}: {
  projects: IApiSlug[]
  colors: ICollectionColors
  addProject: (v: IProjectPartner) => void
  totalWeight: BigNumber
  accumulateWeight: (collection: string, value: BigNumber) => void
}) => {
  return (
    <CardContainer>
      {projects.map(({ id, address }) => {
        return (
          <VePieNFTOverview
            color={colors[address]}
            key={id}
            totalWeight={totalWeight}
            accumulateWeight={accumulateWeight}
            collectionAddress={address}
            addProject={addProject}
          />
        )
      })}
    </CardContainer>
  )
}

export interface VeModelPositionDetails {
  collection: string
  positionId: string
}

export const MyVotesForNFTList = ({
  positions,
  selected,
  setSelected,
  totalWeight,
  accumulateWeight,
  colors,
}: {
  colors: ICollectionColors
  positions?: VeModelPositionDetails[]
  selected: VeModelPositionDetails | null
  setSelected: (p: VeModelPositionDetails) => void
  totalWeight: BigNumber
  accumulateWeight: (collection: string, value: BigNumber) => void
}) => {
  return (
    <CardContainer>
      {positions &&
        positions.map((item) => {
          const { positionId, collection } = item
          return (
            <VePieVoteNFTOverview
              key={positionId.toString()}
              color={colors[getVoteKey(collection, positionId)]}
              totalWeight={totalWeight}
              accumulateWeight={accumulateWeight}
              item={item}
              selected={selected}
              setSelected={setSelected}
            />
          )
        })}
    </CardContainer>
  )
}

export default NFTList
