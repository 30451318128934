import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { StageContainer } from './styleds'
import NftBattleStakingInner from '../NftBattlesPage/NftBattleStaking'
import { BattleStakingTransactions } from './BattleStakingTransactions'

const StageContainer2 = styled(StageContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`
const StageRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 25px;
  height: 100%;
`

export const FirstStage = () => {
  const [selectedNftAddress, setSelectedNftAddress] = useState<string>('')
  const [tokenId, setTokenId] = useState<string | undefined>()

  return (
    <StageContainer2>
      <StageRow>
        <NftBattleStakingInner
          selectedNftAddress={selectedNftAddress}
          setSelectedNftAddress={setSelectedNftAddress}
          tokenId={tokenId}
          setTokenId={setTokenId}
        />
      </StageRow>
      <BattleStakingTransactions />
    </StageContainer2>
  )
}
