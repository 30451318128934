import { useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { useStakedForFarmingNfts } from './StakingPoolInfo/hooks'
import { Dots } from 'pages/Pool/styleds'
import { AmountDd, DropdownContainer, StyledDd } from 'pages/NftBattlesPageV2/NftsDropdown'
import { FarmNfts } from './SuggestedNfts/SuggestedNfts'

const Container = styled.div`
  margin-bottom: 24px;
`

const TABS = {
  ALL: 'ALL',
  UNSTAKE: 'UNSTAKE',
}

const OPTIONS = [
  {
    value: TABS.ALL,
    label: <Trans>All</Trans>,
  },
  {
    value: TABS.UNSTAKE,
    label: <Trans>Unstake</Trans>,
  },
]

export const NftsStaked = () => {
  const { staked: my, loading: loadingMy } = useStakedForFarmingNfts(true)
  const { staked: all, loading: loadingAll } = useStakedForFarmingNfts(false)

  const [tab, setTab] = useState<string>(TABS.ALL)

  const defaultOption = useMemo(() => {
    return OPTIONS.find(({ value }) => value === tab)
  }, [tab])

  const list = useMemo(() => {
    switch (tab) {
      case TABS.ALL: {
        return all
      }
      case TABS.UNSTAKE: {
        return my
      }
      default: {
        throw new Error('Not implemented case')
        break
      }
    }

    return []
  }, [my, all, tab])

  return (
    <Container>
      <DropdownContainer>
        <AmountDd>
          {loadingMy || loadingAll ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            <>
              <Trans>{list.length}</Trans>
              &nbsp;
              <Trans>Total</Trans>
            </>
          )}
        </AmountDd>
        <StyledDd
          options={OPTIONS}
          value={defaultOption}
          onChange={({ value }: any) => {
            setTab(value)
          }}
        />
      </DropdownContainer>

      <FarmNfts list={list} canUnstake={tab === TABS.UNSTAKE} />
    </Container>
  )
}
