import React from 'react'
import { t } from '@lingui/macro'
import SelectSearch, {
  SelectSearchOption,
  SelectedOptionValue,
  SelectedOption,
  SelectSearchProps,
} from 'react-select-search'

import styled from 'styled-components/macro'

interface IProps {
  options: SelectSearchOption[]
  onChange?: (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps
  ) => void
  value: string
  renderOption: (domProps: any, item: any) => any
}

const SelectSearchStyled = styled(SelectSearch)`
  width: 100%;

  .select-search-input {
    background-color: #33334b;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 25px;
    border: 0px;
    border-radius: 25px;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    min-width: 250px;
    width: 100%;

    transition: background-color 0.3s;
    font-family: Uniform Pro;
    font-weight: 600;
    line-height: 18px;
    font-size: 18px;
    color: #fff;
    text-align: left;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 12px 40px 12px 14px;
      font-size: 16px;
      line-height: 16px;
    `};

    &:hover {
      color: ${({ theme }) => theme.text1};
    }

    ::placeholder {
      color: ${({ theme }) => theme.text1};
    }
  }

  .Dropdown-arrow {
    top: 15px;
    right: 25px;
    color: #fff;
    height: 18px;
    width: 18px;
    border: 0 !important;
  }

  .select-search-select {
    border-radius: 24px;
    border-color: #4c4c66;
  }

  .select-search-options {
    margin-top: 0px;
    font-size: 25px;
    line-height: 30px;
    background-color: #4c4c66;
    border: none;
    cursor: pointer;
    font-family: Uniform Pro;
    font-weight: 600;
    line-height: 18px;
    font-size: 18px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 25%);
    color: #fff;

    transition: all 0.5s ease-in-out;
    padding: 0px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      font-size: 16px;
      line-height: 16px;
    `};
  }

  .select-search-row {
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    color: #fff;
    background-color: #27273e;
    color: ${({ theme }) => theme.text2};

    &:hover {
      background: #33334b;
      color: #fff;
    }
  }

  .Dropdown-option.is-selected {
    background: #33334b;
    color: #fff;
    ::after {
      height: 10px;
      width: 10px;
      background: #2fd9c6;
      border-radius: 10px;
      content: '';
      position: absolute;
      right: 30px;
    }
  }
`

const SearchDropdown = ({ options, value, onChange, renderOption }: IProps) => {
  return (
    <SelectSearchStyled
      options={options}
      disabled={!options || !options.length}
      value={value}
      search
      onChange={onChange}
      closeOnSelect
      fuzzySearch
      autoComplete="on"
      className="select-search"
      placeholder={t`Select NFT Project...`}
      renderOption={renderOption}
    />
  )
}

export default SearchDropdown
