import MuiButton from '@mui/material/Button'
import { ButtonProps } from '@mui/material'
import muiTheme from '../../../theme/muiTheme'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <MUIThemeProvider theme={muiTheme()}>
      <MuiButton {...props} />
    </MUIThemeProvider>
  )
}

export default Button