import React from 'react'
import styled from 'styled-components/macro'
import { BATTLE_STAGES } from '../NftBattlesPage/hooks'
import { NftBattlesContainer } from './styleds'
import { FirstStage } from './FirstStage'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
`

const NftBattlesPageV2 = () => {
  return (
    <NftBattlesContainer>
      <Container>
        <FirstStage key={BATTLE_STAGES.FIRST} />
      </Container>
    </NftBattlesContainer>
  )
}

export default NftBattlesPageV2
