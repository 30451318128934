import styled from 'styled-components/macro'
import { Flex } from 'rebass/styled-components'

export interface IDashCard {
  index?: number
}

export const CardContainer = styled(Flex)`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
`

export const DashboardCard = styled.div<{ inactive?: boolean }>`
  opacity: ${({ inactive = false }) => (inactive ? '0.4' : '1')};
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 25px;
  min-height: 240px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-height: 215px;
  `};
`

export const DashboardCardFlex = styled(DashboardCard)`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  min-height: 240px;
  margin: 0;
  width: initial;
  flex: 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-height: 215px;
  `};
`

export const CardLine = styled.div<{ index?: number }>`
  width: 38px;
  min-height: 6px;
  background: ${({ index, theme }) => {
    switch (index) {
      case 0: {
        return theme.orange
      }
      case 1: {
        return '#F64562'
      }
      case 2: {
        return '#e138f5'
      }
      case 3: {
        return '#FE01B0'
      }
      case 4: {
        return '#5e2fdd'
      }
      case 5: {
        return '#3D6DFC'
      }
      case 6: {
        return '#05b6f9'
      }
      case 7: {
        return '#1fad1f'
      }
      case 8: {
        return '#1fad1f'
      }
      case 9: {
        return '#effe04'
      }
      case 10: {
        return '#f8ac00'
      }
      case 11: {
        return '#fff'
      }
      case 12: {
        return 'linear-gradient(90.34deg, #1fad1f, #3d6dfc)'
      }
      case 13: {
        return 'linear-gradient(90deg, #FE5E00 12.38%, #F64562 51.8%, #C22DE1 91.43%)'
      }
      case 14: {
        return '#C627CD'
      }
      case 15: {
        return 'linear-gradient(90deg, #f8ac00 12.38%, #F64562 91.43%)'
      }
      case 16: {
        return 'linear-gradient(90deg, #016eda, #d900c0);'
      }
      case 17: {
        return 'linear-gradient(90deg, #fbc856, #f9ab0f);'
      }
      case 18: {
        return 'linear-gradient(116.07deg, #069144 20.71%, #EFFE04 84.17%), linear-gradient(98.32deg, #FE5E00 -5.19%, #F64562 50.07%, #C22DE1 102.4%)'
      }
      case 19: {
        return 'linear-gradient(90deg, #2aef61 0%, #f64562 100%);'
      }
      case 20: {
        return '#2aef61'
      }
    }

    return theme.bg2
  }};

  border-radius: 24px;
  margin-bottom: 35px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    margin-bottom: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-bottom: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    margin-bottom: 15px;
    display: none;
  `};
`

export const CardHeader = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  display: flex;
  word-break: break-word;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  font-size: 26px;
  line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 20px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 18px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 18px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

export const CardContent = styled.div`
  margin-top: auto;
  font-size: 34px;
  line-height: 42px;
  font-family: ${({ theme }) => theme.fontUniform};
  align-items: flex-end;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 34px;
    line-height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 24px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 15px;
    line-height: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`

  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
    line-height: 21px;
  `};
`

export const CardText = styled.div`
  color: ${({ theme }) => theme.text1};
  word-break: break-word;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 34px;
    line-height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 32px;
    line-height: 34px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 19px;
    line-height: 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}

  font-size: 34px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 34px;
    line-height: 42px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 24px;
    line-height: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    font-size: 18px;
    line-height: 24px;
  `};
`

export const CardDescription = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 21px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
    line-height: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`
