import { useZooDaoRoundBContract } from 'constants/zoodao'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { ZERO } from 'utils/isZero'

export const ANGELS_PHASES = {
  SalePhase: 0,
  LockPhase: 1,
  VestingPhase: 2,
  UnlockPhase: 3,
}

export const useAnglesCrowdsaleDates = () => {
  const contract = useZooDaoRoundBContract()

  const idoStart: number = +useSingleCallResult(contract, 'idoStart')?.result?.[0] || 0
  const lockPhaseDuration: number = +useSingleCallResult(contract, 'lockPhaseDuration')?.result?.[0] || 0
  const salePhaseDuration: number = +useSingleCallResult(contract, 'salePhaseDuration')?.result?.[0] || 0

  const vestingPhaseDuration: number = +useSingleCallResult(contract, 'vestingPhaseDuration')?.result?.[0] || 0

  const currentPhase: number = +useSingleCallResult(contract, 'getCurrentPhase')?.result?.[0] || 0

  return useMemo(() => {
    return {
      idoStart,
      lockPhaseDuration,
      vestingPhaseDuration,
      claimLockDuration: salePhaseDuration + lockPhaseDuration,
      currentPhase,
      salePhaseDuration,
    }
  }, [idoStart, salePhaseDuration, lockPhaseDuration, vestingPhaseDuration, currentPhase])
}

export const useSaleUnallocatedZoo = () => {
  const contract = useZooDaoRoundBContract()
  const { account } = useActiveWeb3React()
  const deps = useMemo(() => [account || ''], [account])
  const unlockedZoo: BigNumber = useSingleCallResult(contract, 'unlockedZoo', deps)?.result?.[0] || ZERO
  const zooClaimed: BigNumber = useSingleCallResult(contract, 'zooClaimed', deps)?.result?.[0] || ZERO
  const zooAllocated: BigNumber = useUserZooSaleInfo()

  return {
    claimable: unlockedZoo.sub(zooClaimed),
    remaining: zooAllocated.sub(zooClaimed),
    zooAllocated,
    zooClaimed,
  }
}
export const useAngelsSaleData = () => {
  const contract = useZooDaoRoundBContract()

  const totalZooForSale: BigNumber = useSingleCallResult(contract, 'totalZooForSale')?.result?.[0] || ZERO

  return { totalZooForSale }
}

const useUserZooSaleInfo = () => {
  const contract = useZooDaoRoundBContract()
  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [account || ''], [account])
  const allocated = useSingleCallResult(contract, 'zooAllocated', deps)?.result?.[0] || ZERO

  return allocated
}
