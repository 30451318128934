import { useXZooContract } from 'constants/zoodao'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useTxTemplate } from 'hooks/zoodao/tx-template'
import { getBigNumberValue } from 'pages/NftBattlesPage/hooks'
import { CallStateResult, useCallStaticMethod } from 'pages/VePie/hooks'
import { useMemo, useCallback } from 'react'
import { useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/hooks'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

export const useStakeXZoo = (amount?: number) => {
  const contract = useXZooContract()
  const { account = '' } = useActiveWeb3React()

  const value = useMemo(() => (amount ? getBigNumberValue(amount) : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.stakeZoo(value, account || '')
  }, [contract, value, account])

  return useTxTemplate(`$stakeXZoo`, `Stake ${formatDecimal(value)} ZOO for xZOO`, dataFunc)
}

export const useAddXZoo = (amount?: number, positionId?: string | BigNumber) => {
  const contract = useXZooContract()

  const value = useMemo(() => (amount ? getBigNumberValue(amount) : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.addZoo(positionId || '', value)
  }, [contract, value, positionId])

  return useTxTemplate(`$addZoo_${positionId}`, `Add ${formatDecimal(value)} ZOO for xZOO`, dataFunc)
}

export const useUnstakeXZoo = (amount?: number, positionId?: string | BigNumber) => {
  const contract = useXZooContract()
  const { account } = useActiveWeb3React()

  const value = useMemo(() => (amount ? getBigNumberValue(amount) : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.withdrawZoo(positionId || '', value, account || '')
  }, [contract, value, positionId, account])

  return useTxTemplate(`$unstakexZoo_${positionId}`, `Withdraw ${formatDecimal(value)} ZOO from xZOO`, dataFunc)
}

export const useWithdrawAndClaimXZoo = (positionId?: string | BigNumber) => {
  const contract = useXZooContract()
  const { account } = useActiveWeb3React()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.unlockAndClaim(positionId || '', account || '')
  }, [contract, positionId, account])

  return useTxTemplate(`$unstakexAndClaimZoo_${positionId}`, `Withdraw ZOO and claim rewards from xZOO`, dataFunc)
}

export const useClaimRewardsXZoo = (positionId?: string | BigNumber) => {
  const contract = useXZooContract()
  const { account } = useActiveWeb3React()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.claimRewards(positionId || '', account || '')
  }, [contract, positionId, account])

  return useTxTemplate(`$claimXZooRewards_${positionId}`, `Claim xZOO rewards`, dataFunc)
}

interface UseV3PositionsResults {
  loading: boolean
  positions: XZooPositionDetails[] | undefined
}

export interface XZooPositionDetails {
  tokenId: BigNumber
  amount: BigNumber
  startEpoch: BigNumber
  endEpoch: BigNumber
  yTokensDebt: BigNumber
}

export function useXZooPositions(account: string | null | undefined): UseV3PositionsResults {
  const positionManager = useXZooContract()

  const deps = useMemo(() => [account ?? undefined], [account])
  const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(positionManager, 'balanceOf', deps)

  // we don't expect any account balance to ever exceed the bounds of max safe int
  const accountBalance: number | undefined = balanceResult?.[0]?.toNumber()

  const tokenIdsArgs = useMemo(() => {
    if (accountBalance && account) {
      const tokenRequests = []
      for (let i = 0; i < accountBalance; i++) {
        tokenRequests.push([account, i])
      }
      return tokenRequests
    }
    return []
  }, [account, accountBalance])

  const tokenIdResults = useSingleContractMultipleData(positionManager, 'tokenOfOwnerByIndex', tokenIdsArgs)
  const someTokenIdsLoading = useMemo(() => tokenIdResults.some(({ loading }) => loading), [tokenIdResults])

  const tokenIds = useMemo(() => {
    if (account) {
      return tokenIdResults
        .map(({ result }) => result)
        .filter((result): result is CallStateResult => !!result)
        .map((result) => BigNumber.from(result[0]))
    }
    return []
  }, [account, tokenIdResults])

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

  return {
    loading: someTokenIdsLoading || balanceLoading || positionsLoading,
    positions,
  }
}

function useV3PositionsFromTokenIds(tokenIds: BigNumber[] | undefined): UseV3PositionsResults {
  const positionManager = useXZooContract()
  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
  const results = useSingleContractMultipleData(positionManager, 'xZooPositions', inputs)

  const loading = useMemo(() => results.some(({ loading }) => loading), [results])
  const error = useMemo(() => results.some(({ error }) => error), [results])

  const positions = useMemo(() => {
    if (!loading && !error && tokenIds) {
      return results.map((call, i) => {
        const tokenId = tokenIds[i]
        const result = call.result as CallStateResult
        return {
          tokenId,
          amount: result.amount,
          startEpoch: result.startEpoch,
          endEpoch: result.endEpoch,
          yTokensDebt: result.yTokensDebt,
        }
      })
    }
    return undefined
  }, [loading, error, results, tokenIds])

  return {
    loading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}

export const useXZooPendingRewards = (pid?: string | BigNumber) => {
  const contract = useXZooContract()

  const { account } = useActiveWeb3React()
  const deps = useMemo(() => [pid, account], [pid, account])
  const staticr = useCallStaticMethod(contract, 'claimRewards', deps)

  console.log('staticr', staticr)
  return {
    rewards: staticr.result || ZERO,
    loading: staticr.loading,
  }
}

export const useTargetPositionForUser = () => {
  const { account } = useActiveWeb3React()
  const { positions, loading } = useXZooPositions(account)

  console.log('positions', positions)
  const position = useMemo(
    () => (positions && positions.length > 0 ? positions.find((x) => x.amount.gt(ZERO)) : undefined),
    [positions]
  )

  return { position, loading }
}
