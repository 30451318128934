import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { Paths } from '../constants/paths'
import Sidebar, { useSidebarState } from '../components/Sidebar/Sidebar'
import Dashboard, { StakeDashboard } from './Dashboard/Dashboard'
import MobileMenu from '../components/MobileMenu/MobileMenu'
import './App.scss'
import NftBattlesPageV2 from './NftBattlesPageV2/NftBattlesPageV2'
import NftBattlesNfts from './NftBattlesPageV2/NftBattlesNfts'
import NftBattleStaked from './NftBattlesPageV2/NftBattleStaked'
import NftCardPairsPool from './NftBattlesPageV2/NftBattlesPairsPool'
import NftCardPairInfo from './NftBattlesPageV2/NftCardPairInfo'
// import PreSale from './PreSale/PreSale'
import BattleBoard from './NftBattlesPageV2/BattleBoard'
import BattlesFaucet from './NftBattlesPageV2/BattlesFaucet'
import VePie from './VePie/VePie'
import Portfolio from './Portfolio/Portfolio'
import { XYield } from './XYield/XYield'
import Jackpots from './Jackpots'
import WarningWrapper, { AlmostHere, useWarningFlag } from 'components/WarningWrapper/WarningWrapper'
import { UserModes } from 'components/Header/UserMode'
import { WarningModes } from 'components/Sidebar/BridgeMode'
import { WidgetSquid } from 'components/Sidebar/BuyBlock'
import { useActiveWeb3React } from '../hooks/web3'
import CommunityRoundA from './CommunityRound/CommunityRoundA'
import CommunityRoundB from './CommunityRound/CommunityRoundB'
import { isMobile } from 'react-device-detect'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 100%;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  z-index: 1;
  width: 100%;
  padding: 70px 0 0 0;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 70px 0 0 0;
  `};
  ${({ theme }) => theme.mediaWidth.upToXMedium`
    padding: 70px 0 0 0;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 70px 0 0 0;
  `};

  .pro-sidebar {
    max-width: 250px;
    min-width: 250px;
    position: fixed;
    height: calc(100% - 70px);

    ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    max-width: 250px;
    min-width: 250px;
    `};

    ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: 250px;
    min-width: 250px;
    `};

    ${({ theme }) => theme.mediaWidth.upToMedium`
      max-width: 200px;
      min-width: 200px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 200px;
      min-width: 200px;
    `};

    ${({ theme }) => theme.mediaWidth.upToTablet`
      max-width: 200px;
      min-width: 200px;
    `};
  }
  .pro-sidebar-footer {
    position: fixed;
    bottom: 25px;
    width: 250px;

    ${({ theme }) => theme.mediaWidth.upToExtraLarge`
      width: 250px;
    `};

    ${({ theme }) => theme.mediaWidth.upToLarge`
      width: 250px;
    `};

    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 200px;
      min-width: 200px;
    `};

    ${({ theme }) => theme.mediaWidth.upToTablet`
      width: 200px;
      min-width: 200px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 200px;
    min-width: 200px;
    `};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
    `};

    @media (max-height: 800px) {
      bottom: 5px;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 1;
  width: 100%;
  padding: 25px;
  padding-left: 275px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
  padding-left: 275px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
  padding-left: 275px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-left: 225px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding-left: 225px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
  padding-left: 225px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 16px;
    margin-bottom: 70px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
  overflow: visible;
`

export default function App() {
  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          <UserModes>
            <Content />
          </UserModes>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}

const Content = () => {
  const { open, onToggle } = useSidebarState()

  const { chainId } = useActiveWeb3React()

  if (!chainId) {
    return null
  }
  return (
    <WarningModes>
      <Popups />
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <WarningWrapper>
        <BodyWrapper>
          {!isMobile && <Sidebar onToggle={onToggle} open={open} />}
          <ContentWrapper>
            <AppRoutes />
          </ContentWrapper>
        </BodyWrapper>
      </WarningWrapper>

      <WidgetSquid />
      <MobileMenu />
      <Footer />
    </WarningModes>
  )
}

const Footer = () => {
  const { notSupportedChain: warning } = useWarningFlag()
  return (
    <>
      {isMobile && warning && <AlmostHere />}
      <MobileMenu />
    </>
  )
}

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact strict path={Paths.FARMING} component={StakeDashboard} />

      <Route exact strict path={Paths.BATTLES} component={BattleBoard} />
      <Route exact strict path={Paths.BATTLES_BOARD_STAKING} component={NftBattlesPageV2} />
      <Route exact strict path={Paths.BATTLES_NFTS} component={NftBattlesNfts} />
      <Route exact strict path={`${Paths.BATTLES_PAIRS}/:id?`} component={NftCardPairsPool} />
      <Route exact strict path={Paths.BATTLES_STAKED} component={NftBattleStaked} />
      <Route exact strict path={Paths.BATTLES_PAIR_INFO} component={NftCardPairInfo} />
      <Route exact strict path={Paths.BATTLES_BATTLEBOARD} component={BattleBoard} />
      <Route exact strict path={Paths.BATTLES_PORTFOLIO} component={Portfolio} />
      <Route exact strict path={Paths.BATTLES_YIELD} component={XYield} />
      <Route exact strict path={`${Paths.BATTLES_JACKPOTS}/:id?`} component={Jackpots} />
      <Route exact strict path={Paths.BATTLES_VE_PIE} component={VePie} />
      <Route exact strict path={Paths.BATTLES_FAUCET} component={BattlesFaucet} />
      <Route exact strict path={Paths.YIELD_DASHBOARD} component={Dashboard} />

      <Route exact strict path={Paths.YIELD_DASHBOARD} component={Dashboard} />
      <Route exact strict path={Paths.COMMUNITY_ROUND_A} component={CommunityRoundA} />
      <Route exact strict path={Paths.COMMUNITY_ROUND_B} component={CommunityRoundB} />
      <Redirect from="/yield" to={Paths.YIELD_DASHBOARD} />
      <Redirect from="/battles" to={Paths.BATTLES_BATTLEBOARD} />
      <Redirect from="*" to={Paths.BATTLES_BATTLEBOARD} />
    </Switch>
  )
}
