import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import {
  useAngelsRoundAAddress,
  useAngelsRoundBAddress,
  useBattleVoterAddress,
  useCrowdsaleAddress,
  useERC721Contract,
  useLayerZeroAddress,
  useStakingSLPAddress,
  useVeModelAddress,
  useXZooTokenAddress,
  useZooDaiSLPAddress,
  useZooTokenAddressReal,
} from '../../constants/zoodao'
import { useActiveWeb3React } from '../../hooks/web3'
import { ApprovalState, useApproveNft, useSimpleApproveCallback } from '../../hooks/useApproveCallback'
import { useArgentWalletContract } from '../../hooks/useArgentWalletContract'
import { Dots } from '../Pool/styleds'
import { ButtonClaim } from '../../components/Button'
import { useCurrency } from 'hooks/Tokens'
import { BigNumber, Contract } from 'ethers'
import { useSingleCallResult } from 'state/multicall/hooks'
import styled from 'styled-components/macro'
import { useMemo } from 'react'

const ApproveBtn = styled(ButtonClaim)``

interface ICheckerCommon {
  currency: Currency
  children?: any
}

interface IChecker extends ICheckerCommon {
  address?: string
  disabled?: boolean
  border?: number
}

export const useIsOwner = (tokenId: string | BigNumber | undefined, contract: Contract | null) => {
  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [tokenId], [tokenId])
  const currentOwner = useSingleCallResult(contract, 'ownerOf', deps)?.result?.[0]

  return currentOwner?.toLowerCase() === account?.toLowerCase()
}

export const ApproveCheckerNFT = ({
  nftAddress,
  poolContract,
  tokenId,
  children,
}: {
  poolContract: Contract | null
  nftAddress: string
  tokenId: string | undefined
  children: any
}) => {
  const nftContract = useERC721Contract(nftAddress)
  const isOwner = useIsOwner(tokenId, nftContract)

  // check whether the user has approved the router on the tokens
  const { approval, onApprove } = useApproveNft(poolContract?.address, nftContract, tokenId, isOwner)

  const notApproved = approval !== ApprovalState.APPROVED

  return (
    <>
      {(approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING) && (
        <>
          {notApproved && (
            <ApproveBtn onClick={onApprove} disabled={approval === ApprovalState.PENDING}>
              {approval === ApprovalState.PENDING ? (
                <Dots>
                  <Trans>Approving</Trans>
                </Dots>
              ) : (
                <Trans>Approve</Trans>
              )}
            </ApproveBtn>
          )}
        </>
      )}

      {approval === ApprovalState.APPROVED && children}
    </>
  )
}

const LOW_BORDER = 1

const ApproveCheckerERC20 = ({ currency, children, address, disabled = false, border = LOW_BORDER }: IChecker) => {
  const { chainId } = useActiveWeb3React()

  const argentWalletContract = useArgentWalletContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useSimpleApproveCallback(currency, border, chainId ? address : undefined)

  // we need an existence check on parsed amounts for single-asset deposits
  const showApprovalA = !argentWalletContract && approvalA !== ApprovalState.APPROVED && !!currency

  const needApprove = (approvalA === ApprovalState.NOT_APPROVED || approvalA === ApprovalState.PENDING) && showApprovalA

  return (
    <>
      {needApprove ? (
        <ApproveBtn onClick={approveACallback} disabled={approvalA === ApprovalState.PENDING || disabled}>
          {approvalA === ApprovalState.PENDING ? (
            <Dots>
              <Trans>Approving </Trans>
              &nbsp;{currency?.symbol?.toUpperCase()}
            </Dots>
          ) : (
            <>
              <Trans>Approve </Trans>&nbsp;
              {currency?.symbol?.toUpperCase()}
            </>
          )}
        </ApproveBtn>
      ) : (
        children
      )}
    </>
  )
}

interface IApproveProps {
  currency: Currency
  children?: any
  border?: number
}

export const ApproveCheckerBattleArena = ({ currency, children, border }: IApproveProps) => {
  const address = useBattleVoterAddress()

  return (
    <ApproveCheckerERC20 address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerVeModel = ({ currency, children, border }: IApproveProps) => {
  const address = useVeModelAddress()

  return (
    <ApproveCheckerERC20 address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerXZoo = ({ children, border }: { children: any; border?: number }) => {
  const address = useXZooTokenAddress()

  const stakingAddress = useZooTokenAddressReal()

  const currency = useCurrency(stakingAddress)

  if (!currency || !address) {
    return null
  }

  return (
    <ApproveCheckerERC20 address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerBridge = ({
  routerAddress,
  tokenAddress,
  children,
  border,
}: {
  routerAddress: string
  tokenAddress: string
  children?: any
  border?: number
}) => {
  const currency = useCurrency(tokenAddress)

  if (!currency || !routerAddress) {
    return null
  }

  return (
    <ApproveCheckerERC20 address={routerAddress} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerLayerZero = ({
  tokenAddress,
  children,
  border,
}: {
  tokenAddress: string
  children?: any
  border?: number
}) => {
  const currency = useCurrency(tokenAddress)

  const routerAddress = useLayerZeroAddress()

  if (!currency || !routerAddress) {
    return null
  }

  return (
    <ApproveCheckerERC20 address={routerAddress} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerCrowdsale = ({
  currency,
  disabled = false,
  children,
  border,
}: {
  disabled: boolean
  currency: Currency
  children?: any
  border?: number
}) => {
  const address = useCrowdsaleAddress()

  return (
    <ApproveCheckerERC20 disabled={disabled} address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerSaleA = ({
  currency,
  disabled = false,
  children,
  border,
}: {
  disabled: boolean
  currency: Currency
  children?: any
  border?: number
}) => {
  const address = useAngelsRoundAAddress()

  return (
    <ApproveCheckerERC20 disabled={disabled} address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}
export const ApproveCheckerSaleB = ({
  currency,
  disabled = false,
  children,
  border,
}: {
  disabled: boolean
  currency: Currency
  children?: any
  border?: number
}) => {
  const address = useAngelsRoundBAddress()

  return (
    <ApproveCheckerERC20 disabled={disabled} address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerSLPStaking = ({ children, border }: { children?: any; border: number }) => {
  const address = useStakingSLPAddress()

  const stakingAddress = useZooDaiSLPAddress()
  const currency = useCurrency(stakingAddress)

  if (!currency || !address) {
    return null
  }

  return (
    <ApproveCheckerERC20 address={address} currency={currency} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}
