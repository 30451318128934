import React from 'react'
import { Trans } from '@lingui/macro'
import { Container, RewardsLabel } from './styles'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components/macro'
import { IPanelJackpotsVariant } from '../../../Jackpots/Panels/ButtonPanel'
import { useClaimRewardsJackpot, useJackpotsUnstake, usePendingJackpotRewards } from './hooks'
import { Dots } from 'pages/Pool/styleds'
import { formatDecimal } from 'utils/numberWithCommas'
import { StakingPosition, VotingPosition } from './PositionsView'
import { ButtonStake, ButtonUnstake } from 'components/Button'

interface IJackpot {
  id: string
  beneficiary: string
  jackpotPositionId: string
  positionId: string
  timestamp: number
  transactionHash: string
  type: string
}

const JackpotsNFTOverview = ({
  className,
  panelType,
  jackpot,
  epoch,
}: {
  jackpot: IJackpot
  className?: string
  panelType: IPanelJackpotsVariant
  epoch: number
}) => {
  const { positionId, jackpotPositionId } = jackpot
  const { pending: pendingUnstaking, action: actionUnstake } = useJackpotsUnstake(panelType, jackpotPositionId)

  const { loading, rewards } = usePendingJackpotRewards(panelType, jackpotPositionId, epoch)

  return (
    <Container className={className}>
      {panelType === IPanelJackpotsVariant.JackpotA ? (
        <StakingPosition panelType={panelType} positionId={positionId} jackpotPositionId={jackpotPositionId} />
      ) : (
        <VotingPosition panelType={panelType} positionId={positionId} jackpotPositionId={jackpotPositionId} />
      )}

      <Flex flexDirection="column" px={25} pb={25} mt={0}>
        <RewardsLabel>
          <Trans>Rewards:</Trans>&nbsp;&nbsp;
          {loading ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            formatDecimal(rewards, 6)
          )}
        </RewardsLabel>

        {!rewards.isZero() && (
          <ClaimJackpot panelType={panelType} jackpotPositionId={jackpotPositionId} epoch={epoch} />
        )}

        <ButtonUnstake onClick={actionUnstake} disabled={pendingUnstaking} style={{ marginTop: '24px' }}>
          {pendingUnstaking ? (
            <Dots>
              <Trans>Unstaking</Trans>
            </Dots>
          ) : (
            <Trans>Unstake</Trans>
          )}
        </ButtonUnstake>
      </Flex>
    </Container>
  )
}

export const ClaimJackpot = ({
  panelType,
  jackpotPositionId,
  epoch,
  noRewards,
}: {
  panelType: IPanelJackpotsVariant
  jackpotPositionId: string
  epoch: number
  noRewards?: boolean
}) => {
  const { pending: pendingClaim, action: actionClaim } = useClaimRewardsJackpot(panelType, jackpotPositionId, epoch)

  if (noRewards) {
    return null
  }

  return (
    <ButtonStake onClick={actionClaim} disabled={pendingClaim || noRewards}>
      {pendingClaim ? (
        <Dots>
          <Trans>Claiming</Trans>
        </Dots>
      ) : noRewards ? (
        <Trans>Claimed</Trans>
      ) : (
        <Trans>Claim</Trans>
      )}
    </ButtonStake>
  )
}
export default styled(JackpotsNFTOverview)``
