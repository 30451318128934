import { BigNumber } from '@ethersproject/bignumber'
import { fromWei } from './fromWei'

export function numberWithCommas(x: number, precision: number) {
  return x.toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatDecimal = (value: BigNumber, precision = 2) => {
  const data = +fromWei(value)
  if (data > 1) {
    return numberWithCommas(data, precision)
  }

  return data.toFixed(precision)
}
