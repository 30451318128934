import React from 'react'
import styled from 'styled-components/macro'
import { CardLine, CardContent, CardDescription, CardText, DashboardCardFlex } from '../../styleds'
import { Trans } from '@lingui/macro'
import { CardHead } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { IPanelJackpotsVariant } from '../ButtonPanel'
import { Box } from '../../../../components/MUI'
import { useJackpotUSD } from 'pages/NftBattlesPageV2/cards/JackpotsNFTOverview/hooks'
import { Dots } from 'pages/Pool/styleds'
import { formatDecimal } from 'utils/numberWithCommas'
import { ChooseJackpotsWinner } from './ChooseJackpotsWinner'
import Info from 'components/Info'

export const CardTextStyled = styled(CardText)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  font-family: ${({ theme }) => theme.fontCarmen};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
    line-height: 28px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 24px;
  `};
`

const CardHeadBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
`
export const CardHeadContribution = styled(CardHead)`
  align-items: center;
  line-height: 30px;
  width: 100%;
  font-size: 28px;
`

const CardDescriptionLeft = styled(CardDescription)`
  color: #2aef61;
  margin-top: 0px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontUniform};
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 24px;
    line-height: 28px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 32px;
    line-height: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    line-height: 22px;
  `};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 15px;
    line-height: 20px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 18px;
  `}
`

export const JackpotInfo = ({ panelType, epoch }: { panelType: IPanelJackpotsVariant; epoch: number }) => {
  const { rewards, loading } = useJackpotUSD(epoch)

  return (
    <DashboardCardFlex>
      <CardLine index={13} />
      <CardHeadBox>
        <CardHeadContribution>
          <Trans>Jackpot {panelType === IPanelJackpotsVariant.JackpotA ? 'A' : 'B'}</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <CardContent>
        <CardDescriptionLeft>
          {loading ? (
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          ) : (
            <>{formatDecimal(rewards, 2)} USD</>
          )}

          <ChooseJackpotsWinner epoch={epoch - 1} type={panelType} />
        </CardDescriptionLeft>
        <Box height={15} />
        <CardDescription>
          <Info>
            <Trans>Stake a zVote or zNFT position to compete for Jackpot prizes every season</Trans>
          </Info>
        </CardDescription>
      </CardContent>
    </DashboardCardFlex>
  )
}
