import { useSingleCallResult } from 'state/multicall/hooks'
import { useErc721Contract } from './useContract'

export const useContractName = (token: string) => {
  const tokenContract = useErc721Contract(token)
  const result = useSingleCallResult(tokenContract, 'name')

  return {
    name: result?.result?.[0],
    loading: result.loading,
  }
}

export const useContractSymbol = (token: string) => {
  const tokenContract = useErc721Contract(token)
  const result = useSingleCallResult(tokenContract, 'symbol')

  return {
    symbol: result?.result?.[0],
    loading: result.loading,
  }
}
