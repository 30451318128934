import React, { useMemo } from 'react'
import { BalanceWrapper, MaxButtonRed } from './styles'
import { Trans } from '@lingui/macro'
import { fromWei } from 'utils/fromWei'
import { BigNumber } from 'ethers'
import styled from 'styled-components/macro'
import { NumericalInputStyled as Input } from 'components/NumericalInput'

const NumericalInputStyled = styled(Input)`
  text-align: left;
  padding-left: 25px;
  padding-right: 110px;
`

interface IProps {
  balance?: number
  setBalance: (v: number) => void
  inactive?: boolean
  total: BigNumber
  btn?: any
  className?: string
}

const PLACEHOLDER = '0'

export const BalanceInputWrapper = ({
  total,
  balance,
  setBalance,
  inactive,
  btn: El = MaxButtonRed,
  className,
}: IProps) => {
  const asNumber = useMemo(() => +fromWei(total), [total])

  return (
    <BalanceWrapper className={className}>
      <NumericalInputStyled
        value={balance}
        onUserInput={(val) => {
          setBalance(+val)
        }}
        max={total}
        disabled={inactive}
        placeholder={PLACEHOLDER}
      />
      <El onClick={() => setBalance(asNumber)} disabled={asNumber === 0}>
        <Trans>Max</Trans>
      </El>
    </BalanceWrapper>
  )
}
