import { useCallback } from 'react'
import { Container, TypeButton } from './styles'
import { Trans } from '@lingui/macro'
import { Box } from 'components/MUI'
import styled from 'styled-components/macro'

interface IButtonPanel {
  onChange: (type: IVeModelTabsVariant) => void
  type: IVeModelTabsVariant
  amount: number
}

export enum IVeModelTabsVariant {
  AllProjects = 'AllProjects',
  MyVotes = 'MyVotes',
}

const CountInfo = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #33334b;

  background-color: #202136;
  padding: 15px 25px 15px 25px;
  border-radius: 50px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  margin-left: 24px;
  font-size: 20px;
  line-height: 20px;
`};
`

const VeModelTabs = ({ onChange, type, amount }: IButtonPanel) => {
  const handleButtonClick = useCallback(
    (type: IVeModelTabsVariant) => {
      onChange(type)
    },
    [onChange]
  )

  return (
    <Box flexDirection="row" alignItems="center" justifyContent="space-between" mr="20px">
      <Container>
        <TypeButton
          onClick={() => handleButtonClick(IVeModelTabsVariant.AllProjects)}
          $active={IVeModelTabsVariant.AllProjects === type}
        >
          <Trans>All Projects</Trans>
        </TypeButton>
        <TypeButton
          onClick={() => handleButtonClick(IVeModelTabsVariant.MyVotes)}
          $active={IVeModelTabsVariant.MyVotes === type}
        >
          <Trans>My Votes</Trans>
        </TypeButton>
      </Container>
      <CountInfo>
        {amount}&nbsp;<Trans>Available</Trans>
      </CountInfo>
    </Box>
  )
}

export enum IBattleStakingTabsVariant {
  MyNfts = 'MyNfts',
  Staked = 'Staked',
}

interface IStakingTabPanel {
  onChange: (type: IBattleStakingTabsVariant) => void
  type: IBattleStakingTabsVariant
  amount: number
}

export const StakingTabs = ({ onChange, type, amount }: IStakingTabPanel) => {
  const handleButtonClick = useCallback(
    (type: IBattleStakingTabsVariant) => {
      onChange(type)
    },
    [onChange]
  )

  return (
    <Box flexDirection="row" alignItems="center" justifyContent="space-between" mr="20px" padding="0 25px 0 25px">
      <Container>
        <TypeButton
          onClick={() => handleButtonClick(IBattleStakingTabsVariant.MyNfts)}
          $active={IBattleStakingTabsVariant.MyNfts === type}
        >
          <Trans>My NFTs</Trans>
        </TypeButton>
        <TypeButton
          onClick={() => handleButtonClick(IBattleStakingTabsVariant.Staked)}
          $active={IBattleStakingTabsVariant.Staked === type}
        >
          <Trans>Staked</Trans>
        </TypeButton>
      </Container>
      <CountInfo>
        {amount}&nbsp;<Trans>NFTs</Trans>
      </CountInfo>
    </Box>
  )
}

export default VeModelTabs
