import React, { useState } from 'react'
import { DashboardCardFlex } from 'pages/Dashboard/styleds'
import { CardHeadBox } from 'pages/NftBattlesPageV2/CardMiniForNftInfo'
import { CardHeadContribution } from 'pages/Dashboard/InfoCards/DashboardCards'
import { LogoCoin, TxtBalance, InputLabel } from './styles'
import { Flex } from 'rebass/styled-components'
import { Trans } from '@lingui/macro'
import IconZOO from '../../../assets/svg/token.svg'
import { ButtonBurgundy } from 'components/Button'
import { BATTLE_STAGES, getBigNumberValue, useBattleStage } from 'pages/NftBattlesPage/hooks'
import { BigNumber } from 'ethers'
import { useBattleVoterContract } from 'constants/zoodao'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useActiveWeb3React } from 'hooks/web3'
import { useTransactionAdder, useHasPendingNftAction } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatDecimal } from 'utils/numberWithCommas'
import { Dots } from 'pages/Pool/styleds'
import { BalanceInputWrapper } from './BalanceInput'
import { TransactionResponse } from '@ethersproject/providers'
import Info from 'components/Info'

const useBatchWithdrawZoo = (pids: BigNumber[], amount?: number) => {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline() // custom from users settings

  const actionType = `batch_unstaking_zoo_battle_arena`

  const pending = useHasPendingNftAction('', '', actionType)

  const nftPoolContract = useBattleVoterContract()

  async function handler() {
    if (!chainId || !library || !account) return

    if (!nftPoolContract || pids.length === 0) {
      return
    }

    if (account && deadline && amount && amount > 0) {
      const value = getBigNumberValue(amount)
      const txData = await nftPoolContract.populateTransaction.batchWithdrawZooFromVoting(pids, value, account)

      const txn = {
        ...txData,
        value: '0x0',
      }

      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(chainId, estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: 'Batch unstaking ZOO from voting pool',
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: actionType,
                },
              })
            })
        })
        .catch((error) => {
          console.error('Failed to send transaction', error)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      return
    }
  }

  return { handler, pending }
}

const UnstakeVotesPanel = ({ pids, totalZoo }: { pids: BigNumber[]; totalZoo: BigNumber }) => {
  const stage = useBattleStage()
  const inactive = pids.length === 0 || stage !== BATTLE_STAGES.FIRST

  const [balance, setBalance] = useState<number | undefined>()
  const { pending, handler } = useBatchWithdrawZoo(pids, balance)

  return (
    <DashboardCardFlex inactive={inactive}>
      <CardHeadBox>
        <CardHeadContribution>
          <LogoCoin src={IconZOO} />
          <Trans>Unstake ZOO Votes</Trans>
        </CardHeadContribution>
      </CardHeadBox>

      <BalanceInputWrapper total={totalZoo} balance={balance} setBalance={setBalance} inactive={inactive} />

      <Flex alignItems="center" justifyContent="space-between" mt={25}>
        <InputLabel>
          <Trans>Total ZOO Staked:</Trans>
          <TxtBalance>&nbsp;{formatDecimal(totalZoo)}</TxtBalance>
        </InputLabel>
        <ButtonBurgundy onClick={handler} disabled={pending || inactive || !balance || balance <= 0}>
          {pending ? (
            <Dots>
              <Trans>Unstaking</Trans>
            </Dots>
          ) : (
            <Trans>Unstake</Trans>
          )}
        </ButtonBurgundy>
      </Flex>
      {stage !== BATTLE_STAGES.FIRST && (
        <Info>
          <Trans>Unstaking ZOO is only possible during stage 1</Trans>
        </Info>
      )}
    </DashboardCardFlex>
  )
}

export default UnstakeVotesPanel
